import React, { Component } from "react";
import { connect } from "react-redux";
import StyledLogin from "./styled";

class Login extends Component {
  componentDidMount() {
    const { isLogged, history } = this.props;

    if (isLogged) {
      return history.replace("/");
    }
  }

  render() {
    return (
      <StyledLogin>
        <p>LOGIN</p>
      </StyledLogin>
    );
  }
}

const mapStateToProps = state => ({
  isLogged: state.auth.authorize
});

export default connect(mapStateToProps)(Login);
