import React from "react";
import StyledTrackingItem from "./styled";
import Icon from "../Icon";

const TrackingItem = ({
  title,
  subtitle,
  extraInfo,
  place,
  date,
  completed,
  incomplete,
  noDotted,
  hasRiskZone
}) => {
  return (
    <StyledTrackingItem className="column is-12 is-paddingless is-flex flex-wrap">
      <div className="column is-2 is-paddingless">
        <div
          className={`circle-indicator is-flex align-items-center justify-content-center mx-auto ${
            completed ? "completed" : ""
          } ${incomplete ? "incomplete" : ""} ${
            hasRiskZone ? "risk-zone" : ""
          }`}
        >
          {completed && (
            <Icon icon="checked" color="#FEFEFE" width={11} height={9} />
          )}
          {incomplete && (
            <Icon icon="xCircle" color="#FEFEFE" width={8} height={8} />
          )}
        </div>
        {completed && !noDotted && <div className="dotted-indicator mx-auto" />}
      </div>
      <div className="column is-10 py-0">
        <p className="bold">{title}</p>
        <p className="bold has-text-grey">{subtitle}</p>
        {extraInfo && <p className="has-text-black">{extraInfo}</p>}
        <p className="has-text-grey-light">{place}</p>
        <p className="has-text-grey-light">{date}</p>
      </div>
    </StyledTrackingItem>
  );
};

export default TrackingItem;
