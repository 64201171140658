export const STRONGRED = "#FF0000";
export const RED = "#CF333C";
export const SOFTRED = "#FDCFD1";
export const MEDIUMRED = "#f96c73";
export const GRAY = "#979797";
export const LAYOUTBACKGROUND = "#F7F8FC";
export const WHITE = "#FFFFFF";
export const N3 = "#425961";
export const GB = "#4A4A4A";
export const G2 = "#979797";
export const A900 = "#25D09C";
export const NAVY = "#18353F";
//TEMA PARA TIGO
export const BLUETHEME = {
  primary: "#00377b",
  secondary: "#001b40",
  tertiary: "#43C7F4",
  quaternary: "#fcc400",
  opacity: "rgba(0, 55, 123, 0.4)"
};

//TEMA VERDE
export const GREENTHEME = {
  primary: "#048c4a",
  secondary: "#02522b",
  tertiary: "#37cc83",
  quaternary: "#f59300",
  opacity: "rgba(4, 140, 74, 0.4)"
};
