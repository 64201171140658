import { createSelector } from "reselect";

const authSelector = state => state.auth;

export const isLoggedSelector = createSelector(
  authSelector,
  state => state.isLogged
);

export const userSelector = createSelector(authSelector, state => state.user);

export const tokenSelector = createSelector(
  authSelector,
  state => state.user.token
);

export const isChanging = createSelector(authSelector, state => state.changing);
