import React, { useState } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { map, get } from "lodash";
import StyledClaimsTab from "./styled/claimsTab";

// custom
import Textarea from "../../components/Input-textarea";
import Button from "../../components/button";
import CollapseDetail from "../../components/collapse-detail";
import Select from "../../components/Input-select";

// validators
import { validateClaimsForm } from "./validators/validateFindUserForm";

// selectors
import { claimsTypesSelector } from "../../actions/postal/selectors";

const ClaimsTab = ({
  sendClaim,
  data,
  selectedGuide,
  successClaimMessage,
  errorClaimMessage,
  claimsTypes
}) => {
  const [onCollapse, setOnCollapse] = useState(null);
  let claims = data.find(item => item.hasOwnProperty("rec"));

  return (
    <StyledClaimsTab className="column is-12 is-flex flex-wrap p-0">
      <Formik
        initialValues={{
          type: "",
          observations: "",
          gui_numero: selectedGuide
        }}
        validationSchema={validateClaimsForm}
        onSubmit={(values, { setSubmitting }) => {
          sendClaim(values);
          setSubmitting(false);
        }}
      >
        {props => {
          const { errors, values, handleSubmit, handleChange } = props;
          return (
            <form
              onSubmit={handleSubmit}
              className="column is-12 has-background-white form-container is-flex flex-wrap"
            >
              <div className="column is-12 p-0">
                <div className="column is-12 is-flex flex-wrap p-0">
                  <Select
                    containerClass="column is-12 p-0"
                    label="Tipo de reclamo"
                    placeholder="Escoge tipo de reclamo"
                    data={map(get(claimsTypes, "data", []), item => ({
                      value: item.codigo,
                      label: item.descripcion
                    }))}
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                  />
                  <p className="help is-danger error">{errors.type}</p>
                </div>
                <Textarea
                  label="Digita observaciones del reclamo"
                  placeholder="Máximo de carácteres 250"
                  containerClass="input-margin"
                  name="observations"
                  value={values.observations}
                  onChange={handleChange}
                  errors={errors.observations}
                />
                <div className="is-flex align-items-center">
                  <Button text="Ingresar" containerClass="input-button" />
                  {successClaimMessage && (
                    <p className="help is-success bold message">
                      Reclamo ingresado
                    </p>
                  )}
                  {errorClaimMessage && (
                    <p className="help is-danger bold message">
                      Error al ingresar reclamo
                    </p>
                  )}
                </div>
                {claims && (
                  <React.Fragment>
                    <h3 className="bold">Historial de reclamos</h3>
                    <div className="collapse-container column is-12 p-0">
                      <CollapseDetail
                        onClick={() =>
                          setOnCollapse(onCollapse === 1 ? null : 1)
                        }
                        active={onCollapse === 1}
                        casenumber={claims.rec.caso}
                        comment={claims.rec.comentariopendiente}
                        description={claims.rec.descripcion}
                        time={claims.rec.fechahora}
                        url={claims.rec.url}
                        urlplan={claims.rec.urlplan}
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>
            </form>
          );
        }}
      </Formik>
    </StyledClaimsTab>
  );
};

const mapStateToProps = state => ({
  claimsTypes: claimsTypesSelector(state)
});
export default connect(mapStateToProps)(ClaimsTab);
