import React from "react";
import StyledTableActions from "./styled";
import Icon from "../Icon";

const TableActions = ({
  iconHorizontal,
  actions = [],
  isLast = false,
  classNameContainer = "column is-1 has-text-centered"
}) => {
  return (
    <StyledTableActions className={classNameContainer}>
      <div className={`dropdown is-right is-hoverable`}>
        <div className="dropdown-trigger">
          <button
            className="button-more"
            aria-haspopup="true"
            aria-controls={`dropdown-menu`}
          >
            <Icon
              icon="points"
              width={20}
              height={20}
              color={window.innerWidth > 1024 ? "#979797" : "#25D09C"}
              className={`${iconHorizontal ? "horizontal" : ""}`}
            />
          </button>
          <div
            className={`dropdown-menu ${isLast ? "last" : ""}`}
            id={`dropdown-menu-1`}
            role="menu"
          >
            <div className="dropdown-content">
              {actions.map((action, i) => (
                <p
                  className="table-action-options"
                  key={i}
                  onClick={action.onClick}
                >
                  {action.label}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </StyledTableActions>
  );
};

export default TableActions;
