import request from "../../api";
import getEnvironment from "../../utils/getEnvironment";

//? Verifica si esta en modo dev o producción
const api = getEnvironment();

//TIPOS DE PRODUCTOS
const productsRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/shipper_products`,
    data
  });
};

const courierPartsRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/courier_parts_details`,
    data
  });
};

const courierPartsCompleteRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/courier_parts_details`,
    data
  });
};

const updateUserPasswordRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/change_password`,
    data
  });
};

export {
  productsRequest,
  courierPartsRequest,
  courierPartsCompleteRequest,
  updateUserPasswordRequest
};
