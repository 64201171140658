import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { get, trim, map, isEqual } from "lodash";
import moment from "moment";
import StyledCommentsTab from "./styled/commentsTab";
import { getParamsRequest } from "../../utils/paramsRequest";

// custom
import Input from "../../components/Input";
import Button from "../../components/button";
import CommentBubble from "./CommentBubble";

//actions
import { fetchCommentsList } from "../../actions/postal";

//selectors
import { commentsListSelector } from "../../actions/postal/selectors";
import { tokenSelector, userSelector } from "../../actions/auth/selectors";

// validators
import { validateCommentForm } from "./validators/validateFindUserForm";

const CommentsTab = ({ ...props }) => {
  const [loading, setLoading] = useState(false);

  const getCommentsList = async () => {
    const { token, info, dispatch } = props;
    let data = getParamsRequest({
      token,
      shi_codigo: get(info, "shi_codigo", null),
      ciclo_inicio: moment(get(info, "cic_inicio", "")).format("L"),
      cli_codigo: trim(get(info, "cliente", "")),
      pro_codigo: get(info, "pro_codigo", "")
    });

    await new Promise((resolve, reject) => {
      dispatch(
        fetchCommentsList(data, (error, data) => {
          if (error) {
            return reject(error);
          }
          return resolve();
        })
      );
    });
  };

  useEffect(() => {
    getCommentsList();
  }, []);

  const { commentsList, createComment } = props;

  return (
    <StyledCommentsTab className="column is-12 is-flex flex-wrap p-0">
      <Formik
        initialValues={{
          comment: ""
        }}
        validationSchema={validateCommentForm}
        onSubmit={async (values, { setSubmitting, setFieldValue }) => {
          setLoading(true);
          const { token, user, info } = props;
          let data = getParamsRequest({
            token,
            shi_codigo: get(info, "shi_codigo", null),
            ciclo_inicio: moment(get(info, "cic_inicio", "")).format("L"),
            cli_codigo: trim(get(info, "cliente", "")),
            pro_codigo: get(info, "pro_codigo", ""),
            comentario: get(values, "comment", ""),
            area: isEqual(get(user, "nivel", ""), 3) ? "CTE" : "UEX",
            usuario: get(user, "usr", "--")
          });
          await createComment(data);
          getCommentsList(data);
          setLoading(false);
          setSubmitting(false);
          setFieldValue("comment", "");
        }}
      >
        {props => {
          const { errors, values, handleSubmit, handleChange } = props;
          return (
            <form
              onSubmit={handleSubmit}
              className="column is-12 has-background-white form-container is-flex flex-wrap is-paddingless"
            >
              <div id="commentBox" className="column is-12 comments-box">
                {commentsList.loading && (
                  <div className="h-100 w-100 is-flex justify-content-center align-items-center">
                    <h2>Cargando comentarios...</h2>
                  </div>
                )}

                {!commentsList.loading &&
                  commentsList.data.length > 0 &&
                  map(get(commentsList, "data", []), (comment, index) => {
                    return (
                      <CommentBubble
                        key={index}
                        isLeft={comment.area.toUpperCase() !== "UEX"}
                        name={`${get(
                          comment,
                          "aud_usuario_proc",
                          ""
                        ).toUpperCase()}`}
                        date={`${get(comment, "aud_fecha_proc", "")} ${get(
                          comment,
                          "hora",
                          ""
                        )}`}
                        text={comment.comentario}
                      />
                    );
                  })}

                {!commentsList.loading && commentsList.data.length === 0 && (
                  <div className="h-100 w-100 is-flex justify-content-center align-items-center">
                    <h2>No existen comentarios creados</h2>
                  </div>
                )}
              </div>
              <div className="column is-8">
                <Input
                  maxLength="140"
                  placeholder="Digite comentario"
                  containerClass="column is-12 p-0 input-margin"
                  name="comment"
                  value={values.comment}
                  onChange={handleChange}
                  errors={errors.comment}
                />
              </div>
              <div className="column is-4 ">
                <Button
                  text="Ingresar"
                  containerClass="comment-button column is-12"
                  loading={loading}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </StyledCommentsTab>
  );
};

const MapStateToProps = state => ({
  commentsList: commentsListSelector(state),
  token: tokenSelector(state),
  user: userSelector(state)
});

export default connect(MapStateToProps)(CommentsTab);
