import {
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_ERROR,
  FETCH_RGO_CICLOS,
  FETCH_RGO_CICLOS_SUCCESS,
  FETCH_RGO_CICLOS_ERROR,
  FETCH_RGO_ABIERTOS,
  FETCH_RGO_ABIERTOS_SUCCESS,
  FETCH_RGO_ABIERTOS_ERROR,
  FETCH_RGO_RANKS,
  FETCH_RGO_RANKS_SUCCESS,
  FETCH_RGO_RANKS_ERROR,
  FETCH_TRANSIT_TIMES,
  FETCH_TRANSIT_TIMES_SUCCESS,
  FETCH_TRANSIT_TIMES_ERROR,
  FETCH_TRANSIT_TIMES_PRODUCTS,
  FETCH_TRANSIT_TIMES_PRODUCTS_SUCCESS,
  FETCH_TRANSIT_TIMES_PRODUCTS_ERROR,
  FETCH_CUSTOMER_QUERY,
  FETCH_CUSTOMER_QUERY_SUCCESS,
  FETCH_CUSTOMER_QUERY_ERROR,
  FETCH_CUSTOMER_NAME,
  FETCH_CUSTOMER_NAME_SUCCESS,
  FETCH_CUSTOMER_NAME_ERROR,
  FETCH_CUSTOMER_TRACKING,
  FETCH_CUSTOMER_TRACKING_SUCCESS,
  FETCH_CUSTOMER_TRACKING_ERROR,
  FETCH_ZONIFICACION_CIERRE_ENVIOS,
  FETCH_ZONIFICACION_CIERRE_ENVIOS_SUCCESS,
  FETCH_ZONIFICACION_CIERRE_ENVIOS_ERROR,
  FETCH_QUERY,
  FETCH_QUERY_SUCCESS,
  FETCH_QUERY_ERROR,
  FETCH_QUERY_ALL_DATA,
  FETCH_QUERY_ALL_DATA_SUCCESS,
  FETCH_QUERY_ALL_DATA_ERROR,
  CLEAN_TRACKING,
  CLEAN_CUSTOMER_QUERY,
  ADD_CLAIM,
  ADD_CLAIM_SUCCESS,
  ADD_CLAIM_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  FETCH_USERS_LIST,
  FETCH_USERS_LIST_SUCCESS,
  FETCH_USERS_LIST_ERROR,
  INACTIVE_USER,
  INACTIVE_USER_SUCCESS,
  INACTIVE_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  FETCH_CHECKPOINTS,
  FETCH_CHECKPOINTS_SUCCESS,
  FETCH_CHECKPOINTS_ERROR,
  FETCH_COURIER_PARTS_DETAILS,
  FETCH_COURIER_PARTS_DETAILS_SUCCESS,
  FETCH_COURIER_PARTS_DETAILS_ERROR,
  FETCH_COURIER_PARTS_DETAILS_COMPLETE,
  FETCH_COURIER_PARTS_DETAILS_COMPLETE_SUCCESS,
  FETCH_COURIER_PARTS_DETAILS_COMPLETE_ERROR,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_ERROR,
  CLEAN_RGO_CICLOS,
  CLEAN_RGO_ABIERTOS,
  GET_ALL_SHIPPERS,
  GET_ALL_SHIPPERS_SUCCESS,
  GET_ALL_SHIPPERS_ERROR,
  FETCH_CLAIMS,
  FETCH_CLAIMS_SUCCESS,
  FETCH_CLAIMS_ERROR,
  FETCH_CLAIMS_ALL,
  FETCH_CLAIMS_ALL_SUCCESS,
  FETCH_CLAIMS_ALL_ERROR,
  FETCH_CLAIMS_SUMMARY,
  FETCH_CLAIMS_SUMMARY_SUCCESS,
  FETCH_CLAIMS_SUMMARY_ERROR,
  FETCH_ZONING,
  FETCH_ZONING_SUCCESS,
  FETCH_ZONING_ERROR,
  FETCH_ZONING_ALL,
  FETCH_ZONING_ALL_SUCCESS,
  FETCH_ZONING_ALL_ERROR,
  INSERT_COMMENT,
  INSERT_COMMENT_SUCCESS,
  INSERT_COMMENT_ERROR,
  FETCH_COMMENTS_LIST,
  FETCH_COMMENTS_LIST_SUCCESS,
  FETCH_COMMENTS_LIST_ERROR,
  FETCH_CLAIMS_TYPES,
  FETCH_CLAIMS_TYPES_SUCCESS,
  FETCH_CLAIMS_TYPES_ERROR,
  FETCH_UPLOADED_FILES,
  FETCH_UPLOADED_FILES_SUCCESS,
  FETCH_UPLOADED_FILES_ERROR,
  FETCH_REMOVED_FILES,
  FETCH_REMOVED_FILES_SUCCESS,
  FETCH_REMOVED_FILES_ERROR,
  POST_UPLOAD_FILES,
  POST_UPLOAD_FILES_SUCCESS,
  POST_UPLOAD_FILES_ERROR,
  DELETE_INBOX_FILE,
  DELETE_INBOX_FILE_SUCCESS,
  DELETE_INBOX_FILE_ERROR
} from "../../action-types/postal";

const PRODUCTS_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const RGO_RANKS_INITIAL_STATE = {
  data: {},
  loading: false,
  error: null
};

const RGO_CICLOS_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const RGO_ABIERTOS_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const TRANSIT_TIMES_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const TRANSIT_TIMES_PRODUCTS_INITIAL_STATE = {
  data: {},
  loading: false,
  error: null
};

const CUSTOMER_QUERY_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const CUSTOMER_NAME_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const CUSTOMER_TRACKING_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const ZONIFICACION_CIERRE_ENVIOS_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const QUERY_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const QUERY_ALL_DATA_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const USERS_LIST_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const CHEKPOINTS_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const COURIER_PARTS_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const COURIER_PARTS_COMPLETE_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const GET_ALL_SHIPPERS_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const FETCH_CLAIMS_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const FETCH_CLAIMS_ALL_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const FETCH_ZONING_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const FETCH_ZONING_ALL_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const FETCH_CLAIMS_SUMMARY_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const COMMENTS_LIST_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const CLAIMS_TYPES_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const UPLOADED_FILES_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const REMOVED_FILES_INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

const POST_UPLOAD_FILES_INITIAL_STATE = {
  data: {},
  loading: false,
  error: null
};
const INITIAL_STATE = {
  products: PRODUCTS_INITIAL_STATE,
  RGO_CICLOS: RGO_CICLOS_INITIAL_STATE,
  RGO_ABIERTOS: RGO_ABIERTOS_INITIAL_STATE,
  RGO_RANKS: RGO_RANKS_INITIAL_STATE,
  transitTimes: TRANSIT_TIMES_INITIAL_STATE,
  transitTimesProducts: TRANSIT_TIMES_PRODUCTS_INITIAL_STATE,
  customerQuery: CUSTOMER_QUERY_INITIAL_STATE,
  customerName: CUSTOMER_NAME_INITIAL_STATE,
  customerTracking: CUSTOMER_TRACKING_INITIAL_STATE,
  ZCEnvios: ZONIFICACION_CIERRE_ENVIOS_INITIAL_STATE,
  query: QUERY_INITIAL_STATE,
  queryAllData: QUERY_ALL_DATA_INITIAL_STATE,
  usersList: USERS_LIST_INITIAL_STATE,
  checkpoints: CHEKPOINTS_INITIAL_STATE,
  courierParts: COURIER_PARTS_INITIAL_STATE,
  courierPartsComplete: COURIER_PARTS_COMPLETE_INITIAL_STATE,
  shippers: GET_ALL_SHIPPERS_INITIAL_STATE,
  claims: FETCH_CLAIMS_INITIAL_STATE,
  claimsAll: FETCH_CLAIMS_ALL_INITIAL_STATE,
  claimsSummary: FETCH_CLAIMS_SUMMARY_INITIAL_STATE,
  zoning: FETCH_ZONING_INITIAL_STATE,
  zoningAll: FETCH_ZONING_ALL_INITIAL_STATE,
  commentsList: COMMENTS_LIST_INITIAL_STATE,
  claimsTypes: CLAIMS_TYPES_INITIAL_STATE,
  uploadedFiles: UPLOADED_FILES_INITIAL_STATE,
  removedFiles: REMOVED_FILES_INITIAL_STATE,
  currentFiles: POST_UPLOAD_FILES_INITIAL_STATE
};

function postal(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_RGO_CICLOS:
      return {
        ...state,
        RGO_CICLOS: {
          ...state.RGO_CICLOS,
          error: null,
          loading: true
        }
      };
    case FETCH_RGO_CICLOS_SUCCESS:
      return {
        ...state,
        RGO_CICLOS: {
          ...state.RGO_CICLOS,
          ...action.payload,
          error: null,
          loading: false
        }
      };
    case FETCH_RGO_CICLOS_ERROR:
      return {
        ...state,
        RGO_CICLOS: {
          ...state.RGO_CICLOS,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case FETCH_RGO_ABIERTOS:
      return {
        ...state,
        RGO_ABIERTOS: {
          ...state.RGO_ABIERTOS,
          error: null,
          loading: true
        }
      };
    case FETCH_RGO_ABIERTOS_SUCCESS:
      return {
        ...state,
        RGO_ABIERTOS: {
          ...state.RGO_ABIERTOS,
          ...action.payload,
          error: null,
          loading: false
        }
      };
    case FETCH_RGO_ABIERTOS_ERROR:
      return {
        ...state,
        RGO_ABIERTOS: {
          ...state.RGO_ABIERTOS,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case FETCH_RGO_RANKS:
      return {
        ...state,
        RGO_RANKS: {
          ...state.RGO_RANKS,
          error: null,
          loading: true
        }
      };
    case FETCH_RGO_RANKS_SUCCESS:
      return {
        ...state,
        RGO_RANKS: {
          ...state.RGO_RANKS,
          data: { ...action.payload },
          error: null,
          loading: false
        }
      };
    case FETCH_RGO_RANKS_ERROR:
      return {
        ...state,
        RGO_RANKS: {
          ...state.RGO_RANKS,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case FETCH_PRODUCTS:
      return {
        ...state,
        products: {
          ...state.products,
          error: null,
          loading: true
        }
      };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          data: [...action.payload],
          error: null,
          loading: false
        }
      };
    case FETCH_PRODUCTS_ERROR:
      return {
        ...state,
        products: {
          ...state.products,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case FETCH_TRANSIT_TIMES:
      return {
        ...state,
        transitTimes: {
          ...state.transitTimes,
          error: null,
          loading: true
        }
      };
    case FETCH_TRANSIT_TIMES_SUCCESS:
      return {
        ...state,
        transitTimes: {
          ...state.transitTimes,
          data: [...action.payload],
          error: null,
          loading: false
        }
      };
    case FETCH_TRANSIT_TIMES_ERROR:
      return {
        ...state,
        transitTimes: {
          ...state.transitTimes,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case FETCH_TRANSIT_TIMES_PRODUCTS:
      return {
        ...state,
        transitTimesProducts: {
          ...state.transitTimesProducts,
          error: null,
          loading: true
        }
      };
    case FETCH_TRANSIT_TIMES_PRODUCTS_SUCCESS:
      return {
        ...state,
        transitTimesProducts: {
          ...state.transitTimesProducts,
          data: { ...action.payload },
          error: null,
          loading: false
        }
      };
    case FETCH_TRANSIT_TIMES_PRODUCTS_ERROR:
      return {
        ...state,
        transitTimesProducts: {
          ...state.transitTimesProducts,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case FETCH_CUSTOMER_QUERY:
      return {
        ...state,
        customerQuery: {
          ...state.customerQuery,
          error: null,
          loading: true
        }
      };
    case FETCH_CUSTOMER_QUERY_SUCCESS:
      return {
        ...state,
        customerQuery: {
          ...state.customerQuery,
          data: [...action.payload],
          error: null,
          loading: false
        }
      };
    case FETCH_CUSTOMER_QUERY_ERROR:
      return {
        ...state,
        customerQuery: {
          ...state.customerQuery,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case FETCH_CUSTOMER_NAME:
      return {
        ...state,
        customerName: {
          ...state.customerName,
          error: null,
          loading: true
        }
      };
    case FETCH_CUSTOMER_NAME_SUCCESS:
      return {
        ...state,
        customerName: {
          ...state.customerName,
          data: [...action.payload],
          error: null,
          loading: false
        }
      };
    case FETCH_CUSTOMER_NAME_ERROR:
      return {
        ...state,
        customerName: {
          ...state.customerName,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case FETCH_CUSTOMER_TRACKING:
      return {
        ...state,
        customerTracking: {
          ...state.customerTracking,
          error: null,
          loading: true
        }
      };
    case FETCH_CUSTOMER_TRACKING_SUCCESS:
      return {
        ...state,
        customerTracking: {
          ...state.customerTracking,
          data: [...action.payload],
          error: null,
          loading: false
        }
      };
    case FETCH_CUSTOMER_TRACKING_ERROR:
      return {
        ...state,
        customerTracking: {
          ...state.customerTracking,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case FETCH_ZONIFICACION_CIERRE_ENVIOS:
      return {
        ...state,
        ZCEnvios: {
          ...state.ZCEnvios,
          error: null,
          loading: true
        }
      };
    case FETCH_ZONIFICACION_CIERRE_ENVIOS_SUCCESS:
      return {
        ...state,
        ZCEnvios: {
          ...state.ZCEnvios,
          data: [...action.payload],
          error: null,
          loading: false
        }
      };
    case FETCH_ZONIFICACION_CIERRE_ENVIOS_ERROR:
      return {
        ...state,
        ZCEnvios: {
          ...state.ZCEnvios,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case FETCH_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          error: null,
          loading: true
        }
      };
    case FETCH_QUERY_SUCCESS:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.payload,
          error: null,
          loading: false
        }
      };
    case FETCH_QUERY_ERROR:
      return {
        ...state,
        query: {
          ...state.query,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case FETCH_QUERY_ALL_DATA:
      return {
        ...state,
        queryAllData: {
          ...state.queryAllData,
          error: null,
          loading: true
        }
      };
    case FETCH_QUERY_ALL_DATA_SUCCESS:
      return {
        ...state,
        queryAllData: {
          ...state.queryAllData,
          data: [...action.payload],
          error: null,
          loading: false
        }
      };
    case FETCH_QUERY_ALL_DATA_ERROR:
      return {
        ...state,
        queryAllData: {
          ...state.queryAllData,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case ADD_CLAIM:
      return {
        ...state,
        error: false,
        loading: true
      };
    case ADD_CLAIM_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false
      };
    case ADD_CLAIM_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case FETCH_USERS_LIST:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          error: null,
          loading: true
        }
      };
    case FETCH_USERS_LIST_SUCCESS:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          data: [...action.payload],
          error: null,
          loading: false
        }
      };
    case FETCH_USERS_LIST_ERROR:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case REGISTER_USER:
      return {
        ...state,
        error: false,
        loading: true
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false
      };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case INACTIVE_USER:
      return {
        ...state,
        error: false,
        loading: true
      };
    case INACTIVE_USER_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false
      };
    case INACTIVE_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case UPDATE_USER:
      return {
        ...state,
        error: false,
        loading: true
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case UPDATE_USER_PASSWORD:
      return {
        ...state,
        error: false,
        loading: true
      };
    case UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false
      };
    case UPDATE_USER_PASSWORD_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case FETCH_CHECKPOINTS:
      return {
        ...state,
        checkpoints: {
          ...state.checkpoints,
          error: null,
          loading: true
        }
      };
    case FETCH_CHECKPOINTS_SUCCESS:
      return {
        ...state,
        checkpoints: {
          ...state.checkpoints,
          data: [...action.payload],
          error: null,
          loading: false
        }
      };
    case FETCH_CHECKPOINTS_ERROR:
      return {
        ...state,
        checkpoints: {
          ...state.checkpoints,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case FETCH_COURIER_PARTS_DETAILS:
      return {
        ...state,
        courierParts: {
          ...state.courierParts,
          error: null,
          loading: true
        }
      };
    case FETCH_COURIER_PARTS_DETAILS_SUCCESS:
      return {
        ...state,
        courierParts: {
          ...state.courierParts,
          ...action.payload,
          error: null,
          loading: false
        }
      };
    case FETCH_COURIER_PARTS_DETAILS_ERROR:
      return {
        ...state,
        courierParts: {
          ...state.courierParts,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case FETCH_COURIER_PARTS_DETAILS_COMPLETE:
      return {
        ...state,
        courierPartsComplete: {
          ...state.courierPartsComplete,
          error: null,
          loading: true
        }
      };
    case FETCH_COURIER_PARTS_DETAILS_COMPLETE_SUCCESS:
      return {
        ...state,
        courierPartsComplete: {
          ...state.courierPartsComplete,
          data: [...action.payload],
          error: null,
          loading: false
        }
      };
    case FETCH_COURIER_PARTS_DETAILS_COMPLETE_ERROR:
      return {
        ...state,
        courierPartsComplete: {
          ...state.courierPartsComplete,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case CLEAN_CUSTOMER_QUERY:
      return {
        ...state,
        customerQuery: CUSTOMER_QUERY_INITIAL_STATE
      };
    case CLEAN_TRACKING:
      return {
        ...state,
        customerTracking: CUSTOMER_TRACKING_INITIAL_STATE
      };
    case CLEAN_RGO_CICLOS:
      return {
        ...state,
        RGO_CICLOS: RGO_CICLOS_INITIAL_STATE
      };
    case CLEAN_RGO_ABIERTOS:
      return {
        ...state,
        RGO_ABIERTOS: RGO_ABIERTOS_INITIAL_STATE
      };
    case GET_ALL_SHIPPERS:
      return {
        ...state,
        shippers: {
          ...state.shippers,
          error: null,
          loading: true
        }
      };
    case GET_ALL_SHIPPERS_SUCCESS:
      return {
        ...state,
        shippers: {
          ...state.shippers,
          ...action.payload,
          error: null,
          loading: false
        }
      };
    case GET_ALL_SHIPPERS_ERROR:
      return {
        ...state,
        shippers: {
          ...state.shippers,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case FETCH_CLAIMS:
      return {
        ...state,
        claims: {
          ...state.claims,
          error: null,
          loading: true
        }
      };
    case FETCH_CLAIMS_SUCCESS:
      return {
        ...state,
        claims: {
          ...state.claims,
          ...action.payload,
          error: null,
          loading: false
        }
      };
    case FETCH_CLAIMS_ERROR:
      return {
        ...state,
        claims: {
          ...state.claims,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case FETCH_CLAIMS_ALL:
      return {
        ...state,
        claimsAll: {
          ...state.claimsAll,
          error: null,
          loading: true
        }
      };
    case FETCH_CLAIMS_ALL_SUCCESS:
      return {
        ...state,
        claimsAll: {
          ...state.claimsAll,
          data: [...action.payload],
          error: null,
          loading: false
        }
      };
    case FETCH_CLAIMS_ALL_ERROR:
      return {
        ...state,
        claimsAll: {
          ...state.claimsAll,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case FETCH_CLAIMS_SUMMARY:
      return {
        ...state,
        claimsSummary: {
          ...state.claimsSummary,
          error: null,
          loading: true
        }
      };
    case FETCH_CLAIMS_SUMMARY_SUCCESS:
      return {
        ...state,
        claimsSummary: {
          ...state.claimsSummary,
          data: [...action.payload],
          error: null,
          loading: false
        }
      };
    case FETCH_CLAIMS_SUMMARY_ERROR:
      return {
        ...state,
        claimsSummary: {
          ...state.claimsSummary,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case FETCH_ZONING:
      return {
        ...state,
        zoning: {
          ...state.zoning,
          error: null,
          loading: true
        }
      };
    case FETCH_ZONING_SUCCESS:
      return {
        ...state,
        zoning: {
          ...state.zoning,
          ...action.payload,
          error: null,
          loading: false
        }
      };
    case FETCH_ZONING_ERROR:
      return {
        ...state,
        zoning: {
          ...state.zoning,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case FETCH_ZONING_ALL:
      return {
        ...state,
        zoningAll: {
          ...state.zoningAll,
          error: null,
          loading: true
        }
      };
    case FETCH_ZONING_ALL_SUCCESS:
      return {
        ...state,
        zoningAll: {
          ...state.zoningAll,
          data: [...action.payload],
          error: null,
          loading: false
        }
      };
    case FETCH_ZONING_ALL_ERROR:
      return {
        ...state,
        zoningAll: {
          ...state.zoningAll,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case INSERT_COMMENT:
      return {
        ...state,
        error: false,
        loading: true
      };
    case INSERT_COMMENT_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false
      };
    case INSERT_COMMENT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case FETCH_COMMENTS_LIST:
      return {
        ...state,
        commentsList: {
          ...state.commentsList,
          error: null,
          loading: true
        }
      };
    case FETCH_COMMENTS_LIST_SUCCESS:
      return {
        ...state,
        commentsList: {
          ...state.commentsList,
          data: [...action.payload],
          error: null,
          loading: false
        }
      };
    case FETCH_COMMENTS_LIST_ERROR:
      return {
        ...state,
        commentsList: {
          ...state.commentsList,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case FETCH_CLAIMS_TYPES:
      return {
        ...state,
        claimsTypes: {
          ...state.claimsTypes,
          error: null,
          loading: true
        }
      };
    case FETCH_CLAIMS_TYPES_SUCCESS:
      return {
        ...state,
        claimsTypes: {
          ...state.claimsTypes,
          data: [...action.payload],
          error: null,
          loading: false
        }
      };
    case FETCH_CLAIMS_TYPES_ERROR:
      return {
        ...state,
        claimsTypes: {
          ...state.claimsTypes,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case FETCH_UPLOADED_FILES:
      return {
        ...state,
        uploadedFiles: {
          ...state.uploadedFiles,
          error: null,
          loading: true
        }
      };
    case FETCH_UPLOADED_FILES_SUCCESS:
      return {
        ...state,
        uploadedFiles: {
          ...state.uploadedFiles,
          ...action.payload,
          error: null,
          loading: false
        }
      };
    case FETCH_UPLOADED_FILES_ERROR:
      return {
        ...state,
        uploadedFiles: {
          ...state.uploadedFiles,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case FETCH_REMOVED_FILES:
      return {
        ...state,
        removedFiles: {
          ...state.removedFiles,
          error: null,
          loading: true
        }
      };
    case FETCH_REMOVED_FILES_SUCCESS:
      return {
        ...state,
        removedFiles: {
          ...state.removedFiles,
          ...action.payload,
          error: null,
          loading: false
        }
      };
    case FETCH_REMOVED_FILES_ERROR:
      return {
        ...state,
        removedFiles: {
          ...state.removedFiles,
          error: action.payload.status || action.payload,
          loading: false
        }
      };
    case POST_UPLOAD_FILES:
      return {
        ...state,
        error: false,
        loading: true
      };
    case POST_UPLOAD_FILES_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false
      };
    case POST_UPLOAD_FILES_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case DELETE_INBOX_FILE:
      return {
        ...state,
        error: false,
        loading: true
      };
    case DELETE_INBOX_FILE_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false
      };
    case DELETE_INBOX_FILE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
}

export default postal;
