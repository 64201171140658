import React from "react";
import StyledSeacrhInputTable from "./styled";
import Icon from "../Icon";

const SeacrhInputTable = ({
  placeholder = "Buscar",
  name = "",
  value = "",
  onChange = () => {}
}) => {
  return (
    <StyledSeacrhInputTable className="column is-5 is-paddingless is-flex justify-content-end align-items-center">
      <Icon
        icon="search"
        width={18}
        height={19}
        color="#9FA1B7"
        className="search-icon"
      />
      <input
        name={name}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={e => onChange(e)}
      />
    </StyledSeacrhInputTable>
  );
};

export default SeacrhInputTable;
