import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import StyledTrackingTab from "./styled/trackingTab";
import moment from "moment";
import "moment/locale/es";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Magnifier from "react-magnifier";
import { isEqual, includes, get, map } from "lodash";
import axios from "axios";

//custom
import TrackingItem from "../../components/tracking-item";
import MyMapComponent from "../../components/map";
import { trackingScope } from "../../utils/constants";

//selectors
import { userSelector } from "../../actions/auth/selectors";

const images = [
  "https://www.bienesonline.com/usa/photos/vendo-casa-en-el-salvador-ahuachapan-1436662578.jpg",
  "http://www.elsv.info/wp-content/uploads/2017/04/imagen-de-una-casa.jpg",
  "https://images.evisos.com.sv/2009/06/25/vendo-bonita-casa-en-el-salvador-sonsonate-izalco_d7a993fc6_3.jpg",
  "https://images.evisos.com.sv/2012/06/05/casa-en-venta-en-residencial-panameno-ii-sonsonate_da45fdf989_3.jpg",
];

const ContractsTab = ({
  data = [],
  customerDirection,
  user,
  selectedGuide,
}) => {
  moment.locale("es");
  const trackingFiles = data.find((item) => item.movimiento === "DL");
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isImageValid, setIsImageValid] = useState(true);

  const [imagesContract, setImagesContract] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://www.urbano.com.sv/jwt2/Api/guide_files?reference=${selectedGuide}&type=contract_files`
      )
      .then((resp) => {
        setImagesContract(resp.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  let getData = isEqual(Number(user.nivel), 3)
    ? data.filter((item) => includes(trackingScope, item.movimiento))
    : data;

  return (
    <>
      {imagesContract.length > 0 ? (
        <StyledTrackingTab className="column is-12 is-flex flex-wrap p-0">
          <div className="column is-multiline is-12 h-200 px-6">
            {data && trackingFiles && trackingFiles.ruta && isImageValid && (
              <div
                style={{ height: "80%" }}
                className=" is-flex align-items-start justify-content-start mx-3 columns is-multiline"
              >
                {map(imagesContract, (item) => {
                  return (
                    <div
                      style={{ marginRight: "15px" }}
                      className="card mx-6 column is-2"
                    >
                      <div className="card-image">
                        <img
                          key={item}
                          onError={() => setIsImageValid(false)}
                          src={item}
                          className="image is-128x128 mx-3"
                          onClick={() => setIsOpen(true)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          {isOpen && (
            <Lightbox
              mainSrc={imagesContract[photoIndex]}
              nextSrc={imagesContract[(photoIndex + 1) % imagesContract.length]}
              prevSrc={
                imagesContract[
                  (photoIndex + images.length - 1) % imagesContract.length
                ]
              }
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex(
                  (photoIndex + imagesContract.length - 1) %
                    imagesContract.length
                )
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % imagesContract.length)
              }
            />
          )}
        </StyledTrackingTab>
      ) : (
        <div className="h-100 w-100 is-flex justify-content-center align-items-center">
          <h2>No existen imágenes</h2>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: userSelector(state),
});
export default connect(mapStateToProps)(ContractsTab);
