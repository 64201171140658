import styled from "styled-components";
import { LAYOUTBACKGROUND, RED, WHITE } from "../../../utils/colors";

const StyledClients = styled.div`
  .form-header {
    height: 6rem;
    .form-header-input {
      margin: 0 1rem;
    }
    .form-header-button {
      margin-top: 1.45rem;
    }
  }
  .error-form {
    position: absolute;
    /* bottom: -1.3rem; */
  }
  .tracking-section {
    height: calc(100% - 6rem);
    .tracking-section-container {
      padding: 1.2rem 0.75rem;
      border-top: 1.25rem solid ${LAYOUTBACKGROUND};
      border-bottom: 1.25rem solid ${LAYOUTBACKGROUND};
      border-left: 1.75rem solid ${LAYOUTBACKGROUND};
      border-right: 1.75rem solid ${LAYOUTBACKGROUND};

      .tracking-info-content {
        &:nth-child(1) {
          border-right: 1px solid #dde2ff;
        }
        .tracking-info-title {
          color: ${RED};
          /* margin-bottom: 0.5rem; */
          @media (min-width: 1408px) {
            margin-bottom: 1.25rem;
          }
        }
        .tracking-info-details {
          margin-bottom: 1.25rem;
        }
        .tabs-container {
          height: 2.6875rem;
          background-color: ${WHITE};
          padding: 0 1rem;
          border-bottom: 1px solid #dde2ff;
          .tab-content {
            cursor: pointer;
            padding: 0 1rem 0 0;
            p {
              &:hover,
              &.active {
                color: #3e4345;
                border-bottom: 0.1875rem solid #ff0000;
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
`;

export default StyledClients;
