import { combineReducers } from "redux";

//reducers
import auth from "./auth";
import postal from "./postal";

export default combineReducers({
  auth,
  postal
});
