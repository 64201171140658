import React from "react";
import { connect } from "react-redux";
import numeral from "numeral";
import cx from "classnames";
import StyledTable from "./styled";
import * as theme from "../../utils/colors";
import { has } from "lodash";

//Selectors
import { userSelector } from "../../actions/auth/selectors";

const Table = ({
  tableHead = [],
  tableBody = [],
  actions = false,
  filter = false,
  emptyMessage = "No Existen datos",
  headClass,
  bodyClass,
  user
}) => {
  let color = user.theme
    ? theme[user.theme.toUpperCase()].opacity
    : "rgba(223, 156, 159, 0.6)";
  return (
    <StyledTable className="orders-table-container">
      {window.innerWidth > 769 && (
        <div className={`orders-table-head ${headClass}`}>
          <div className="columns is-fullhd is-gapless">
            {tableHead.map((title, i) => (
              <div
                key={i}
                className={`column has-text-centered ${
                  i === 0 ? "is-2 first-column" : ""
                }`}
                title={title.hasOwnProperty("tooltip") ? title.tooltip : ""}
              >
                <strong>{title.value}</strong>
              </div>
            ))}
            {actions && <div className="column is-1"></div>}
          </div>
        </div>
      )}
      {window.innerWidth > 769 && (
        <div className={`orders-table-body uex-scrollbar ${bodyClass}`}>
          {tableBody.length > 0 &&
            tableBody.map((item, index) => {
              return (
                <div
                  className="columns is-fullhd is-gapless is-marginless"
                  key={index}
                >
                  {item.map((title, i) => {
                    return (
                      <div
                        key={i}
                        className={`column has-text-centered ${
                          i === 0 ? "is-2 border-space first-column" : ""
                        } ${has(title, "onClick") ? "c-pointer" : ""}`}
                        style={title.active ? { backgroundColor: color } : {}}
                        title={
                          title.hasOwnProperty("cant")
                            ? `cantidad: ${numeral(Number(title.cant)).format(
                                "0,0"
                              )}`
                            : ""
                        }
                        onClick={title.onClick}
                      >
                        <p
                          // className={title.complete ? "has-text-green" : ""}
                          className={cx("", {
                            "has-text-green": title.complete
                          })}
                        >
                          {title.value}
                        </p>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          {tableBody.length === 0 && (
            <div className="columns is-fullhd is-gapless is-marginless">
              <div className="column has-text-centered">
                <p>{emptyMessage}</p>
              </div>
            </div>
          )}
        </div>
      )}
    </StyledTable>
  );
};

const mapStateToProps = state => ({
  user: userSelector(state)
});

export default connect(mapStateToProps)(Table);
