import React, { Component } from "react";
import { connect } from "react-redux";
import StyledElSalvadorMap from "./styled";
import numeral from "numeral";
import { Fetch } from "react-request";
import Highmaps from "highcharts/highmaps";
import {
  HighchartsMapChart,
  withHighmaps,
  Tooltip,
  MapSeries
} from "react-jsx-highmaps";
import * as theme from "../../utils/colors";

//Selectors
import { userSelector } from "../../actions/auth/selectors";

class ElSalvadorMap extends Component {
  render() {
    const { mapData, user } = this.props;

    const plotOptions = {
      series: {
        point: {
          events: {
            click: function() {
              // console.log(this.name);
            }
          }
        }
      }
    };

    const colorAxis = {
      minColor: "#FFFFFF",
      maxColor: user.theme ? theme[user.theme.toUpperCase()].primary : "#FDCFD1"
    };

    const tooltipFormatter = function() {
      let mapKey = this.key
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toUpperCase()
        .split(" ")
        .join("");

      // let mapText = `${
      //   mapData[mapKey]
      //     ? `${this.key}<br/>
      //         Total Entrega: ${mapData[mapKey].delivery}<br/>
      //         Total Recibido:${mapData[mapKey].received}<br/>
      //         Total Devolución:${mapData[mapKey].return}<br/>
      //         Saldo Gestion:${mapData[mapKey].balance}`
      //     : `${this.key}`
      // }`;

      let mapText = `${
        mapData[mapKey]
          ? `<b>${this.key}</b><br/>
              <b>Zona: ${mapData[mapKey].zone}</b><br/>
              <b>Total Entrega: ${numeral(
                Number(mapData[mapKey].delivery)
              ).format("0,0")}</b>`
          : `${this.key}`
      }`;

      return mapText;
    };
    return (
      <StyledElSalvadorMap className={this.props.active ? "d-none" : ""}>
        <Fetch url={process.env.REACT_APP_EL_SALVADOR_HIGHMAPS_URL}>
          {({ fetching, failed, data }) => {
            if (fetching) return <div>Loading…</div>;
            if (failed) return <div>Failed to load map.</div>;

            if (data) {
              return (
                <HighchartsMapChart
                  map={data}
                  colorAxis={colorAxis}
                  plotOptions={plotOptions}
                >
                  {/* <Title>El Salvador</Title>

                  <Subtitle>
                    Demo of drawing all areas in the map, only highlighting
                    partial data
                  </Subtitle> */}

                  <MapSeries
                    name="Area"
                    states={{
                      hover: {
                        color: user.theme
                          ? theme[user.theme.toUpperCase()].primary
                          : "#CF333C"
                      }
                    }}
                    data={[
                      ["sv-un", 1],
                      ["sv-li", 1],
                      ["sv-pa", 1],
                      ["sv-cu", 1],
                      ["sv-so", 1],
                      ["sv-ss", 1],
                      ["sv-mo", 1],
                      ["sv-sm", 1],
                      ["sv-sv", 1],
                      ["sv-us", 1],
                      ["sv-ch", 1],
                      ["sv-sa", 1],
                      ["sv-ah", 1],
                      ["sv-ca", 1]
                    ]}
                    dataLabels={{
                      enabled: false,
                      color: "#FFFFFF",
                      format: "{point.name}"
                    }}
                  />

                  {/* <MapNavigation>
                    <MapNavigation.ZoomIn />
                    <MapNavigation.ZoomOut />
                  </MapNavigation> */}

                  <Tooltip formatter={tooltipFormatter} />
                  {/* <Credits /> */}
                  {/* <Legend /> */}
                </HighchartsMapChart>
              );
            }

            return null;
          }}
        </Fetch>
      </StyledElSalvadorMap>
    );
  }
}

const mapStateToProps = state => ({
  user: userSelector(state)
});

export default connect(mapStateToProps)(withHighmaps(ElSalvadorMap, Highmaps));
