import styled from "styled-components";

const StyledModalSuccess = styled.div`
  .modal-background {
    background-color: rgba(10, 10, 10, 0.5);
  }
  .modal-container {
    padding: 2rem 0rem 0.5rem 0;
    border-radius: 5px;
    img {
      margin-bottom: 1.625rem;
    }
    h2 {
      margin-bottom: 0.625rem;
    }
    p {
      color: #979797;
    }
    .button-footer {
    }
    .left-btn {
      color: #979797;
      &:hover {
        color: #000000;
      }
      &:active {
        background-color: rgba(242, 242, 242, 0.5);
      }
    }
    .right-btn {
      color: #ff0000;
      &:hover {
        color: #cf333c;
      }
      &:active {
        background-color: rgba(242, 242, 242, 0.5);
      }
    }
  }
`;

export default StyledModalSuccess;
