import styled from "styled-components";
import { RED } from "../../../utils/colors";

const StyledUserInfo = styled.div`
  .user-dropdown {
    height: 100%;
    border: 0;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:hover {
      opacity: 0.6;
    }
    /* &.arrow-dropdown{
      background-image:
        linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%),
        linear-gradient(to right, #ccc, #ccc);
      background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        calc(100% - 2.5em) 0.5em;
      background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
      background-repeat: no-repeat;
    } */
    .user-dropdown-name {
      margin: 0 1rem;
      p {
        text-transform: uppercase;
        &:nth-child(1) {
          font-weight: bold;
        }
        &:nth-child(2) {
          color: #9fa1b7;
        }
      }
    }
    .user-dropdown-shortname-rounded {
      width: 2.7rem;
      height: 2.7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      /* background: ${RED}; */
      border-radius: 50%;
      color: #ffffff;
      font-weight: bold;
      text-transform: uppercase;
    }
    .arrow-icon {
      margin: 0 0 0 1rem;
    }
  }
  .dropdown-menu {
    border: 0;
    .dropdown-content {
      border-radius: 0;
      box-shadow: 0px 4px 4px rgba(187, 187, 187, 0.25);
      border-radius: 4px;
      .dropdown-item {
        cursor: pointer;
        font-weight: bold;
        letter-spacing: 0.0125em;
        text-align: center;
        &:hover {
          color: ${RED};
        }
      }
    }
  }
`;

export default StyledUserInfo;
