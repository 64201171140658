import styled from "styled-components";

const StyledHeaderPage = styled.div`
  height: 5rem;
  display: flex;
  align-items: center;
  h1 {
    color: #000000;
    text-transform: capitalize;
  }
`;

export default StyledHeaderPage;
