import XLSX from "xlsx";

export const getExcelFile = (data, fileName, bookName = "Reporte") => {
  /* convert state to workbook */
  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, bookName);
  /* generate XLSX file and send to client */
  return XLSX.writeFile(wb, `${fileName}.xlsx`);
};
