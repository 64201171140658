import React, { Component } from "react";
import StyledDashboard from "./styled";
import { connect } from "react-redux";
import { Formik } from "formik";
import moment from "moment";
import "moment/locale/es";
import queryString from "query-string";
import numeral from "numeral";
import { getParamsRequest } from "../../utils/paramsRequest";
import {
  SHIPPERS_WITH_CICLES,
  TIGO_SHIPPER_CODES,
} from "../../utils/shipperPermissions";
import {
  includes,
  intersection,
  split,
  isEmpty,
  get,
  reverse,
  reject,
  isEqual,
  isNaN,
  filter,
  map,
} from "lodash";

// custom
import SelectAutocomplete from "../../components/select-autocomplete";
import HeaderPage from "../../components/header-page";
import DownloadButton from "../../components/download-button";
import CalendarDropdown from "../../components/calendar-range-dropdown";
import DropdownLabel from "../../components/dropdown-label";
import TableDashboard from "./table-dashboard";
import CombinedChart from "../../components/combined-chart";
import LoadingScreen from "../../components/loading_screen";
import NotFound from "../../components/NotFound";
import Button from "../../components/button";
import Input from "../../components/Input";
import ModalDetail from "./modal-details";
import Paginator from "../../components/paginator";

// actions
import {
  fetchRGOCiclos,
  fetchRGOAbiertos,
  fetchProducts,
  fetchCourierParts,
  fetchCourierPartsComplete,
  fetchCheckpoints,
  cleanRgosAbiertos,
  cleanRgosCiclos,
} from "../../actions/postal";

// selectors
import {
  RGOCiclosSelector,
  RGOAbiertosSelector,
  productsSelector,
  courierPartsSelector,
  courierPartsCompleteSelector,
  checkpointsSelector,
} from "../../actions/postal/selectors";
import { tokenSelector, userSelector } from "../../actions/auth/selectors";

//DATE-RANGES
import { firstDate, lastDate } from "../../utils/rangeDates";

// validators
import { validateForm } from "./validators/validateForm";

const allOption = { value: "%", label: "Todos" };

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  state = {
    selectedShipperOption: null,
    selectedOption: { value: "%", label: "Todos" },
    selectionRange: {
      startDate: firstDate,
      endDate: lastDate,
      key: "selection",
    },
    rangeStep: 0,
    region: false,
    transit: false,
    labelActive: 2,
    modalDetails: false,
    loadingModal: false,
    modalData: null,
    clientCode: "",
    anexo: "",
    selectedCheckpoint: null,
    location: false,
    detailTitle: "",
    showCheckpointSelect: false,
    clientCicle: "",
    isChanging: false,
    products: [],
  };

  async componentDidMount() {
    const { token, history, user } = this.props;
    let userToken = getParamsRequest({
      token,
      shi_codigo: get(user, "shi_codigo", null),
    });
    await this.getProducts(userToken);
    await this.getCheckpoints(
      getParamsRequest({
        token,
        checkpoint_scope: 1,
      })
    );
    let data;
    if (this.props.location.search.length > 0) {
      let params = queryString.parse(this.props.location.search);
      if (parseInt(params.type) === 1) {
        data = getParamsRequest({
          token,
          shi_codigo: get(user, "shi_codigo", null),
          pro_codigo:
            params.cat.toLowerCase() === "all"
              ? "%"
              : `"${params.cat.split(" ").join(",")}"`,
          ...(!isEmpty(params.cicle) && {
            cic_cliente: params.cicle,
          }),
        });

        this.setState({
          clientCicle: !isEmpty(params.cicle) ? params.cicle : "",
        });
        await this.getRGOAbiertos(data);
      } else if (parseInt(params.type) === 2) {
        data = getParamsRequest({
          token,
          p: 1,
          shi_codigo: get(user, "shi_codigo", null),
          pro_codigo:
            params.cat.toLowerCase() === "all"
              ? "%"
              : `"${params.cat.split(" ").join(",")}"`,
          f_inicio: moment(params.start, "DDMMYYYY").format("L"),
          f_fin: moment(params.end, "DDMMYYYY").format("L"),
          ...(!isEmpty(params.cicle) && {
            cic_cliente: params.cicle,
          }),
        });

        this.setState({
          clientCicle: !isEmpty(params.cicle) ? params.cicle : "",
        });
        await this.getRGOCiclos(data);
      }
    } else {
      data = getParamsRequest({
        token,
        shi_codigo: get(user, "shi_codigo", null),
        pro_codigo: "%",
      });
      history.push(`/dashboard?type=1&cat=all`);
      this.setState({
        clientCicle: "",
        selectedOption: [
          {
            value: "%",
            label: "Todos",
          },
        ],
      });
      await this.getRGOAbiertos(data);
    }
    this.setAttributeCalendar();
  }

  setAttributeCalendar = () => {
    let component = document.getElementsByClassName("dropdown-calendar-button");
    for (let i = 0; i < component.length; i++) {
      component[i].setAttribute("type", "button");
    }
  };

  handleSelect = (ranges) => {
    this.setState(
      {
        selectionRange: ranges.selection,
        rangeStep: this.state.rangeStep + 1,
      },
      () => {
        if (this.state.rangeStep === 2) {
          this.setState({ rangeStep: 0 });
        }
      }
    );
  };

  getRGOCiclos = async (dataFilter) => {
    const { dispatch, history } = this.props;

    let parseData = queryString.parse(dataFilter);
    let categories = (parseData.pro_codigo === "%"
      ? "all"
      : parseData.pro_codigo.split(",").join("+")
    )
      .split('"')
      .join("");

    let cic_cliente = !isEmpty(parseData.cic_cliente)
      ? `&cicle=${parseData.cic_cliente}`
      : "";

    history.push(
      `/dashboard?type=2&cat=${categories}&start=${moment(
        parseData.f_inicio,
        "DD-MM-YYYY"
      ).format("DDMMYYYY")}&end=${moment(parseData.f_fin, "DD-MM-YYYY").format(
        "DDMMYYYY"
      )}${cic_cliente}`
    );

    this.setState({ labelActive: 1 });

    await new Promise((resolve, reject) => {
      dispatch(
        fetchRGOCiclos(dataFilter, (error, data) => {
          if (error) {
            return reject(error);
          }
          resolve();
          return this.setAttributeCalendar();
        })
      );
    });
  };

  getRGOAbiertos = async (data) => {
    let parseData = queryString.parse(data);

    let categories = (parseData.pro_codigo === "%"
      ? "all"
      : parseData.pro_codigo.split(",").join("+")
    )
      .split('"')
      .join("");

    let cic_cliente = !isEmpty(parseData.cic_cliente)
      ? `&cicle=${parseData.cic_cliente}`
      : "";

    const { dispatch, history } = this.props;

    history.push(`/dashboard?type=1&cat=${categories}${cic_cliente}`);

    this.setState({ labelActive: 2 });

    await new Promise((resolve, reject) => {
      dispatch(
        fetchRGOAbiertos(data, (error, data) => {
          if (error) {
            return reject(error);
          }
          resolve();
          return this.setAttributeCalendar();
        })
      );
    });
  };

  getProducts = async (data) => {
    const {
      dispatch,
      location: { search },
    } = this.props;
    let params = queryString.parse(search);

    await new Promise((resolve, reject) => {
      dispatch(
        fetchProducts(data, (error, data) => {
          if (!error) {
            resolve();

            let selectedOption = [
              {
                value: "%",
                label: "Todos",
              },
            ];

            if (!includes(["all"], params.cat.split(" ")[0])) {
              selectedOption = map(
                filter(data, (item) =>
                  includes(params.cat.split(" "), item.pro_codigo)
                ),
                (item) => ({
                  value: item.pro_codigo,
                  label: isEmpty(get(item, "shipper_product_code"))
                    ? item.pro_codigo
                    : `${item.shipper_product_code} - ${item.pro_codigo}`,
                })
              );
            }

            return this.setState({ selectedOption });
          }
          return reject(error);
        })
      );
    });
  };

  getCheckpoints = async (data) => {
    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        fetchCheckpoints(data, (error, data) => {
          if (error) {
            return reject(error);
          }
          return resolve();
        })
      );
    });
  };

  getCourierParts = async (page = 1) => {
    this.setState({ loadingModal: true });
    const { dispatch, token, user } = this.props;
    const { modalData } = this.state;

    let data = getParamsRequest({
      token,
      shi_codigo: get(user, "shi_codigo", null),
      ...modalData,
      p: page,
    });

    await new Promise((resolve, reject) => {
      dispatch(
        fetchCourierParts(data, (error, data) => {
          this.setState({ loadingModal: false });
          if (error) {
            return reject(error);
          }
          resolve();
          return this.setState({ modalDetails: true });
        })
      );
    });
  };

  getCourierPartsComplete = async (page = 1) => {
    const { dispatch, token, user } = this.props;
    const { modalData } = this.state;

    let data = getParamsRequest({
      token,
      shi_codigo: get(user, "shi_codigo", null),
      ...modalData,
      p: page,
      getAll: 1,
    });

    await new Promise((resolve, reject) => {
      dispatch(
        fetchCourierPartsComplete(data, (error, data) => {
          if (error) {
            return reject(error);
          }
          return resolve();
        })
      );
    });
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleShipperChange = (selectedShipperOption) => {
    this.setState({ selectedShipperOption }, () => {
      this.changeCurrentShipper(selectedShipperOption.value);
    });
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  applyRGOFilter = async () => {
    const {
      selectedOption,
      selectionRange: { startDate, endDate },
      labelActive,
      clientCicle,
    } = this.state;
    const { token, user } = this.props;
    let data;
    if (labelActive === 1) {
      data = getParamsRequest({
        token,
        p: 1,
        shi_codigo: get(user, "shi_codigo", null),
        pro_codigo:
          selectedOption[0].value === "%"
            ? "%"
            : `"${selectedOption.map((item) => item.value).join()}"`,
        f_inicio: moment(startDate).format("L"),
        f_fin: moment(endDate).format("L"),
        ...(!isEmpty(clientCicle) && {
          cic_cliente: clientCicle,
        }),
      });
      await this.getRGOCiclos(data);
    } else if (labelActive === 2) {
      data = getParamsRequest({
        token,
        shi_codigo: get(user, "shi_codigo", null),
        pro_codigo:
          selectedOption[0].value === "%"
            ? "%"
            : `"${selectedOption.map((item) => item.value).join()}"`,
        ...(!isEmpty(clientCicle) && {
          cic_cliente: clientCicle,
        }),
      });
      await this.getRGOAbiertos(data);
    }
  };

  handleScroll = () => {
    this.myRef.current.scrollTo(0, 0);
  };

  setLabelActive = (labelActive) => {
    const { token, user } = this.props;
    let dataCiclos = getParamsRequest({
      token,
      p: 1,
      shi_codigo: get(user, "shi_codigo", null),
      pro_codigo: "%",
      f_inicio: moment(firstDate).format("L"),
      f_fin: moment(lastDate).format("L"),
    });

    let dataAbiertos = getParamsRequest({
      token,
      shi_codigo: get(user, "shi_codigo", null),
      pro_codigo: "%",
    });

    this.setState(
      {
        labelActive,
        selectedOption: [{ value: "%", label: "Todos" }],
        clientCicle: "",
      },
      async () => {
        const { dispatch } = this.props;
        await dispatch(cleanRgosAbiertos());
        await dispatch(cleanRgosCiclos());
        if (this.state.labelActive === 1)
          return await this.getRGOCiclos(dataCiclos);
        await this.getRGOAbiertos(dataAbiertos);
      }
    );
  };

  handleModalDetails = (modalDetails) => {
    this.setState({
      modalDetails,
      clientCode: "",
      anexo: "",
      selectedCheckpoint: null,
      location: false,
      showCheckpointSelect: false,
    });
  };

  showModalDetails = (modalData, detailTitle, showCheckpoints) => {
    this.setState(
      {
        modalData,
        detailTitle,
        ...(showCheckpoints && {
          showCheckpointSelect: true,
        }),
      },
      () => {
        this.getCourierParts();
        this.getCourierPartsComplete();
      }
    );
  };

  onChangePagination = async (page) => {
    await this.getCourierParts(page);
  };

  onChangeDashboardPagination = async (page) => {
    const {
      selectedOption,
      selectionRange: { startDate, endDate },
      clientCicle,
    } = this.state;
    const { token, user } = this.props;

    let data = getParamsRequest({
      token,
      p: page,
      shi_codigo: get(user, "shi_codigo", null),
      pro_codigo:
        selectedOption[0].value === "%"
          ? "%"
          : `"${selectedOption.map((item) => item.value).join()}"`,
      f_inicio: moment(startDate).format("L"),
      f_fin: moment(endDate).format("L"),
      ...(!isEmpty(clientCicle) && {
        cic_cliente: clientCicle,
      }),
    });
    await this.getRGOCiclos(data);
  };

  handleChangeModalSelect = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  findUser = () => {
    let {
      modalData,
      clientCode,
      anexo,
      location,
      selectedCheckpoint,
    } = this.state;

    modalData = {
      ...modalData,
      cli_codigo: clientCode,
      anexo: anexo,
      location: location ? 1 : 0,
      checkpoint_pieza: selectedCheckpoint ? selectedCheckpoint.value : "%",
    };

    this.setState({ modalData }, () => {
      this.getCourierParts();
      this.getCourierPartsComplete();
    });
  };

  handleChangeCheckpoint = (selectedCheckpoint) => {
    this.setState({ selectedCheckpoint });
  };

  cleanFilters = () => {
    this.setState({
      clientCode: "",
      anexo: "",
      selectedCheckpoint: null,
      location: false,
    });
  };

  onChangeToggle = () => {
    const { location } = this.state;
    this.setState({ location: !location });
  };

  render() {
    moment.locale("es");
    const {
      selectedOption,
      selectionRange,
      transit,
      region,
      labelActive,
      modalDetails,
      loadingModal,
      selectedCheckpoint,
      location,
      detailTitle,
      showCheckpointSelect,
    } = this.state;
    const {
      RGOCiclos,
      RGOAbiertos,
      products,
      history,
      user,
      checkpoints,
      courierPartsComplete,
    } = this.props;
    const { loading } = RGOCiclos;
    let rgoData = labelActive === 2 ? RGOAbiertos.data : RGOCiclos.data;
    let tableData = reject(rgoData, ["fecha_recepcion", "31-12-1969"]);

    let parseProducts = products.data.map((item) => ({
      value: item.pro_codigo,
      label: isEmpty(get(item, "shipper_product_code"))
        ? item.pro_codigo
        : `${item.shipper_product_code} - ${item.pro_codigo}`,
    }));

    let parseCheckpoints = checkpoints.data.map((item) => ({
      value: item.codigo,
      label: `${item.codigo} - ${item.descripcion}`,
    }));
    parseCheckpoints.unshift(allOption);

    parseProducts.unshift(allOption);

    let XLSXData = [
      [
        "Ciclo",
        ...(!isEmpty(
          intersection(SHIPPERS_WITH_CICLES, split(user.shi_codigos, ","))
        )
          ? ["Ciclo Shipper"]
          : []),
        "Total Recibido",
        "En ruta",
        "Entregas",
        "% Avance de entregas",
        "Devueltas",
        "% Devuelto",
        "Saldo en gestion (Ciclo)",
        "Inventario en gestión",
        "Fecha de recepción",
      ],
    ];

    tableData &&
      tableData.map((item) =>
        XLSXData.push([
          item.ciclo,
          ...(!isEmpty(
            intersection(SHIPPERS_WITH_CICLES, split(user.shi_codigos, ","))
          )
            ? [get(item, "ciclo_shipper", "--")]
            : []),
          numeral(Number(item.ar)).format("0,0"),
          numeral(Number(item.ld)).format("0,0"),
          numeral(Number(item.dl)).format("0,0"),
          parseFloat(item.porc_dl).toFixed(2) + "%",
          numeral(Number(item.dv)).format("0,0"),
          parseFloat(item.porc_dv).toFixed(2) + "%",
          item.saldo,
          item.inventario,
          item.fecha_recepcion,
        ])
      );

    let XLSXDashboardData = [
      [
        "Corr",
        "Prod",
        !isEmpty(intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ",")))
          ? "Cod. Cliente"
          : "Cuenta",
        "Nombre",
        !isEmpty(intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ",")))
          ? "Anexo"
          : "Telefono",
        "Chk",
        "GPS",
      ],
    ];

    courierPartsComplete.data.map((item, i) =>
      XLSXDashboardData.push([
        i + 1,
        item.pro_codigo,
        item.cli_codigo,
        isEmpty(item.cli_nombre) ? "--" : item.cli_nombre,
        ...(!isEmpty(
          intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ","))
        )
          ? [isEmpty(item.anexo) ? "--" : item.anexo]
          : [
              `${
                isEmpty(item.cli_telefono1) || isNaN(item.cli_telefono1)
                  ? "--"
                  : item.cli_telefono1
              }${
                isEmpty(item.cli_telefono2) || isNaN(item.cli_telefono2)
                  ? " / --"
                  : ` / ${item.cli_telefono2}`
              }`,
            ]),
        isEmpty(item.esto_codigo) ? item.chk_codigo : item.esto_codigo,
        !isEmpty(intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ",")))
          ? parseInt(item.latitud) === 0 || item.latitud.length === 0
            ? "NO"
            : `${item.latitud},${item.longitud}`
          : parseInt(item.latitud) === 0 || item.latitud.length === 0
          ? "NO"
          : "SI",
      ])
    );

    if (loading || RGOAbiertos.loading) return <LoadingScreen />;

    return (
      <StyledDashboard
        className="section-container uex-scrollbar"
        ref={this.myRef}
      >
        {loadingModal && <LoadingScreen isRequest />}
        {!region && !transit && (
          <div className="w-100">
            <HeaderPage
              title="Dashboard"
              content={
                <Formik
                  initialValues={this.state}
                  validationSchema={validateForm}
                  enableReinitialize
                  onSubmit={(values, { setSubmitting }) => {
                    this.applyRGOFilter();
                    setSubmitting(false);
                  }}
                >
                  {(props) => {
                    const { errors, values, handleSubmit } = props;
                    return (
                      <form
                        onSubmit={handleSubmit}
                        className="column is-flex align-items-center justify-content-end px-0"
                      >
                        <DownloadButton
                          data={XLSXData}
                          fileName={`RGOCiclos_${moment(
                            selectionRange.startDate
                          ).format("MMDDYY")}${moment(
                            selectionRange.endDate
                          ).format("MMDDYY")}`}
                        />
                        {!isEmpty(
                          intersection(
                            SHIPPERS_WITH_CICLES,
                            split(user.shi_codigos, ",")
                          )
                        ) && (
                          <Input
                            containerClass="input-form filters-input button-space"
                            placeholder={"Ingrese ciclo"}
                            name="clientCicle"
                            value={values.clientCicle}
                            onChange={this.handleInputChange}
                          />
                        )}
                        <SelectAutocomplete
                          onChange={this.handleChange}
                          containerClass="input-form filters-input"
                          options={parseProducts}
                          selectedOption={selectedOption}
                          isMulti
                        />
                        {labelActive === 1 && (
                          <CalendarDropdown
                            rangeName="Ciclo"
                            containerClass="input-form filters-input"
                            selectionRange={selectionRange}
                            onChange={this.handleSelect}
                          />
                        )}
                        <div className="is-relative">
                          <Button
                            containerClass="form-header-button"
                            text="Buscar"
                          />
                          <p className="help is-danger error error-form">
                            {errors.productValidation}
                          </p>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              }
            />

            {(RGOAbiertos.error === 404 || RGOCiclos.error === 404) && (
              <React.Fragment>
                <div className="has-background-white chart-container">
                  <DropdownLabel
                    active={
                      labelActive === 1 ? "Histórico" : "Distribución de envíos"
                    }
                    actions={[
                      {
                        onClick: () => this.setLabelActive(1),
                        label: "Histórico",
                        active: labelActive === 1,
                      },
                      {
                        onClick: () => this.setLabelActive(2),
                        label: "Distribución de envíos",
                        active: labelActive === 2,
                      },
                    ]}
                  />
                </div>
              </React.Fragment>
            )}
            {RGOAbiertos.data &&
            RGOCiclos.data &&
            RGOAbiertos.error !== 404 &&
            RGOCiclos.error !== 404 ? (
              <React.Fragment>
                <div className="has-background-white chart-container">
                  <DropdownLabel
                    active={
                      labelActive === 1 ? "Histórico" : "Distribución de envíos"
                    }
                    actions={[
                      {
                        onClick: () => this.setLabelActive(1),
                        label: "Histórico",
                        active: labelActive === 1,
                      },
                      {
                        onClick: () => this.setLabelActive(2),
                        label: "Distribución de envíos",
                        active: labelActive === 2,
                      },
                    ]}
                  />
                  <CombinedChart data={reverse(tableData) || []} />
                </div>
                <h2 className="has-text-black bold subtext-space">
                  Resumen de distribución de envíos
                </h2>
                <TableDashboard
                  tableHead={[
                    "Ciclo",
                    ...(!isEmpty(
                      intersection(
                        SHIPPERS_WITH_CICLES,
                        split(user.shi_codigos, ",")
                      )
                    )
                      ? ["Ciclo Shipper"]
                      : []),
                    "Total Recibido",
                    "En ruta",
                    "Entregas",
                    "% Avance de entregas",
                    "Devueltas",
                    "% Devuelto",
                    "Saldo en gestion (Ciclo)",
                    "Inventario en gestión",
                    "Fecha de recepción",
                  ]}
                  tableBody={
                    reverse(tableData).map((item) => [
                      {
                        title: item.ciclo,
                      },
                      ...(!isEmpty(
                        intersection(
                          SHIPPERS_WITH_CICLES,
                          split(user.shi_codigos, ",")
                        )
                      )
                        ? [{ title: get(item, "ciclo_shipper", "--") }]
                        : []),
                      {
                        title: numeral(Number(item.ar)).format("0,0"),
                        onClick: () =>
                          this.showModalDetails(
                            { cic_inicio: item.ciclo },
                            "Total Recibido",
                            true
                          ),
                      },
                      {
                        title: numeral(Number(item.ld)).format("0,0"),
                        onClick: () =>
                          this.showModalDetails(
                            {
                              cic_inicio: item.ciclo,
                              estado: "ER",
                            },
                            "En Ruta"
                          ),
                      },
                      {
                        title: numeral(Number(item.dl)).format("0,0"),
                        onClick: () =>
                          this.showModalDetails(
                            {
                              cic_inicio: item.ciclo,
                              checkpoint: "DL",
                            },
                            "Entregas"
                          ),
                      },
                      {
                        title: parseFloat(item.porc_dl).toFixed(2) + "%",
                      },
                      {
                        title: numeral(Number(item.dv)).format("0,0"),
                        onClick: () =>
                          this.showModalDetails(
                            {
                              cic_inicio: item.ciclo,
                              checkpoint: "DV",
                            },
                            "Devueltas"
                          ),
                      },
                      {
                        title: parseFloat(item.porc_dv).toFixed(2) + "%",
                      },
                      {
                        title: item.saldo,
                        onClick: () =>
                          this.showModalDetails(
                            {
                              cic_inicio: item.ciclo,
                              estado: "%",
                            },
                            "Saldo en gestion"
                          ),
                      },
                      {
                        title: item.inventario,
                        onClick: () =>
                          this.showModalDetails(
                            {
                              cic_inicio: item.ciclo,
                              estado: "PS",
                            },
                            "Inventario en gestión"
                          ),
                      },
                      {
                        title: item.fecha_recepcion,
                      },
                      [
                        {
                          onClick: () =>
                            history.push(
                              `region/all/${item.ciclo}${
                                isEmpty(get(item, "ciclo_shipper", "")) ||
                                isEqual(get(item, "ciclo_shipper", ""), "--")
                                  ? ""
                                  : `/${item.ciclo_shipper}`
                              }`
                            ),
                          label: "Distribución por Región",
                        },
                        {
                          onClick: () =>
                            history.push(
                              `transit/all/${item.ciclo}${
                                isEmpty(get(item, "ciclo_shipper", "")) ||
                                isEqual(get(item, "ciclo_shipper", ""), "--")
                                  ? ""
                                  : `/${item.ciclo_shipper}`
                              }`
                            ),
                          label: "Tiempo en Transito",
                        },
                      ],
                    ]) || []
                  }
                  actions
                />
                {labelActive === 1 && (
                  <Paginator
                    onChangePage={this.onChangeDashboardPagination}
                    totalText="Total Ciclos"
                    totalData={get(RGOCiclos, "total", 0)}
                    total={
                      get(RGOCiclos, "total", 0)
                        ? get(RGOCiclos, "total", 0)
                        : 0
                    }
                    perPage={20}
                    currentPage={parseInt(get(RGOCiclos, "page", 0))}
                  />
                )}
              </React.Fragment>
            ) : (
              <NotFound
                containerClass="emptyState"
                title="Sin registros"
                subtitle="Actualmente no existen registros para su busqueda"
              />
            )}
          </div>
        )}
        <ModalDetail
          active={modalDetails}
          onCloseModal={() => this.handleModalDetails(false)}
          detailTitle={detailTitle}
          initialValues={this.state}
          onSubmit={this.findUser}
          onChangeInput={this.handleChangeModalSelect}
          onChangeAutocomplete={this.handleChangeCheckpoint}
          autocompleteOptions={parseCheckpoints}
          autocompleteSelectedOption={selectedCheckpoint}
          hasLocation={location}
          onChangeToggle={() => this.onChangeToggle()}
          onCleanFilters={() => this.cleanFilters()}
          onChangePagination={this.onChangePagination}
          showCheckpointSelect={showCheckpointSelect}
          xlsxData={XLSXDashboardData}
        />
      </StyledDashboard>
    );
  }
}
const mapStateToProps = (state) => ({
  user: userSelector(state),
  RGOCiclos: RGOCiclosSelector(state),
  RGOAbiertos: RGOAbiertosSelector(state),
  products: productsSelector(state),
  courierParts: courierPartsSelector(state),
  courierPartsComplete: courierPartsCompleteSelector(state),
  token: tokenSelector(state),
  checkpoints: checkpointsSelector(state),
});
export default connect(mapStateToProps)(Dashboard);
