import React, { PureComponent } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { includes, gt, lt, get, find, trim, map, isEmpty } from "lodash";
import moment from "moment";
import { getParamsRequest } from "../../utils/paramsRequest";
import StyledDashboard from "./styled";

// custom
import MenuOption from "../../components/menu_option";
import UEXLogo from "../../assets/images/urbano-logo.svg";
import UserInfo from "../../components/user_info";
import ModalSession from "../../components/modal_alert";
import ModalChangePassword from "./ChangePassword";
import ModalSuccess from "../../components/modal_alert";
import ModalError from "../../components/modal_alert";
import SuccessIcon from "../../assets/images/success-icon.svg";
import ErrorIcon from "../../assets/images/error-icon.svg";
import AlertIcon from "../../assets/images/alert-icon.svg";
import LoadingScreen from "../../components/loading_screen";
import SelectAutocomplete from "../../components/select-autocomplete";
import { getTabsPermissions } from "../../utils/userPermissions";
// import AddModal from "../../components/AddModal";

// actions
import { updateUserPassword, getAllShippers } from "../../actions/postal";
import { fetchCurrentUserProfile } from "../../actions/auth";

//Selectors
import {
  isLoggedSelector,
  userSelector,
  tokenSelector,
  isChanging,
} from "../../actions/auth/selectors";

import { shippersSelector } from "../../actions/postal/selectors";

//actions
import { logout, changeCurrentShipper } from "../../actions/auth";

class Dashboard extends PureComponent {
  state = {
    modalSession: false,
    modalPassword: false,
    password: "",
    confirmPassword: "",
    modalSuccess: false,
    modalError: false,
    modalAlert: false,
    loadingRequest: false,
    selectedShipperOption: null,
    isChanging: false,
  };

  async componentDidMount() {
    const { isLogged, location, user, token } = this.props;

    if (!isLogged && !location.pathname.includes("/token"))
      return this.goToHome();

    let passwordExpiration = moment(moment().format()).diff(
      moment(user.password_expiration, "YYYY-MM-DD").format(),
      "days"
    );

    this.setState({
      modalAlert: gt(passwordExpiration, -5) && lt(passwordExpiration, 0),
    });

    let userToken = getParamsRequest({
      token,
      ...(!isEmpty(get(user, "shi_codigo", null)) && {
        shi_codigo: get(user, "shi_codigo", null),
      }),
    });

    await this.getAllShippers(userToken);
  }

  async componentDidUpdate(nextProps) {
    const { isLogged, location } = nextProps;

    if (!isLogged && !location.pathname.includes("/token"))
      return this.goToHome();
  }

  getAllShippers = async (data) => {
    const { dispatch, user } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        getAllShippers(data, (error, data) => {
          if (error) {
            return reject(error);
          }
          const currentShipper = get(user, "shi_codigo", "");
          const shipper = find(
            get(data, "data", []),
            (shipper) => shipper.shi_codigo === currentShipper
          );

          this.setState({
            selectedShipperOption: {
              value: shipper.shi_codigo,
              label: `${shipper.shi_codigo}-${shipper.shi_nombre}`,
            },
          });

          return resolve();
        })
      );
    });
  };

  updateModalSession = (modalSession) => {
    this.setState({
      modalSession,
    });
  };

  onLogout = () => {
    localStorage.removeItem("addActive");

    const { dispatch } = this.props;

    this.setState({
      modalSession: false,
    });

    dispatch(logout());

    return this.goToHome();
  };

  goToHome = () => window.location.replace(process.env.REACT_APP_REDIRECT_URL);

  handleModalPassword = (modalPassword) => {
    this.setState({
      modalPassword,
      password: "",
      confirmPassword: "",
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSavePassword = async (password) => {
    this.setState({ loadingRequest: true });
    const { dispatch, token, user } = this.props;
    const data = getParamsRequest({
      token,
      user_id: user.id_usr,
      password,
    });
    await new Promise((resolve, reject) => {
      dispatch(
        updateUserPassword(data, (error, data) => {
          if (error) {
            return this.setState({ loadingRequest: false, modalError: true });
          }
          this.setState({ loadingRequest: false, modalSuccess: true }, () => {
            setTimeout(() => {
              this.setState({ modalSuccess: false });
              this.onLogout();
            }, 1500);
          });
          resolve();
        })
      );
    });
  };

  handleShipperChange = (selectedShipperOption) => {
    this.setState({ selectedShipperOption }, () => {
      this.changeCurrentShipper(selectedShipperOption.value);
    });
  };

  changeCurrentShipper = async (shipperId) => {
    this.setState({ isChanging: true });
    const { dispatch, token } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        changeCurrentShipper(shipperId, (error, data) => {
          if (error) {
            return reject(error);
          }
          dispatch(
            fetchCurrentUserProfile(
              getParamsRequest({ token, id_usuclie: shipperId }),
              (profileerror) => {
                if (profileerror) {
                  return reject(profileerror);
                }
                // return resolve();
                return window.location.reload();
              }
            )
          );
        })
      );
    });
    // await this.getProfile();
  };

  getProfile = async () => {
    const { dispatch, token } = this.props;
    await new Promise((resolve, reject) => {
      dispatch(
        fetchCurrentUserProfile(getParamsRequest({ token }), (error, data) => {
          if (error) {
            return reject(error);
          }
          return resolve();
        })
      );
    });
    return window.location.reload();
  };

  render() {
    const {
      modalSession,
      modalPassword,
      modalSuccess,
      modalError,
      modalAlert,
      loadingRequest,
      selectedShipperOption,
      isChanging,
    } = this.state;
    const {
      children,
      isLogged,
      user,
      location,
      history,
      shippers,
    } = this.props;

    if (
      location.pathname === "/404" ||
      location.pathname === "/500" ||
      location.pathname.includes("/token")
    ) {
      return children;
    }

    if (!isLogged) {
      return null;
    }

    if (location.pathname === "/administration") {
      if (!getTabsPermissions(user.user_permissions, "ADMINISTRACION")) {
        return history.push("/404");
      }
    }

    if (
      location.pathname === "/dashboard" ||
      location.pathname.includes("/transit") ||
      location.pathname.includes("/region") ||
      location.pathname.includes("type")
    ) {
      if (!getTabsPermissions(user.user_permissions, "DASHBOARD")) {
        return history.push("/404");
      }
    }

    if (location.pathname === "/") {
      if (!getTabsPermissions(user.user_permissions, "CONSULTAS")) {
        return history.push("/404");
      }
    }

    if (location.pathname === "/reports") {
      if (!getTabsPermissions(user.user_permissions, "REPORTES")) {
        return history.push("/404");
      }
    }

    if (location.pathname === "/zones") {
      if (!getTabsPermissions(user.user_permissions, "ZONAS")) {
        return history.push("/404");
      }
    }

    if (location.pathname === "/files") {
      if (!getTabsPermissions(user.user_permissions, "ARCHIVOS")) {
        return history.push("/404");
      }
    }

    if (isChanging)
      return (
        <div className="column is-12">
          <h1 className="has-text-centered">Cambiando de Shipper</h1>
        </div>
      );

    let passwordExpiration = moment(moment().format()).diff(
      moment(user.password_expiration, "YYYY-MM-DD").format(),
      "days"
    );

    return (
      <StyledDashboard
        theme={user.theme}
        className={`${user.theme ? user.theme : "default"}`}
      >
        {loadingRequest && <LoadingScreen isRequest />}
        <nav
          className="navbar uex-navbar"
          role="navigation"
          aria-label="main navigation"
        >
          <div className="navbar-brand">
            <Link to="/" className="navbar-item">
              <img src={UEXLogo} width="132" height="48" alt="logo" />
            </Link>
          </div>
          {includes([1, 2], Number(user.nivel)) && (
            <SelectAutocomplete
              onChange={this.handleShipperChange}
              containerClass="input-form filters-input"
              options={map(get(shippers, "data", []), (shipper) => ({
                value: shipper.shi_codigo,
                label: `${trim(shipper.shi_codigo)}-${trim(
                  shipper.shi_nombre
                )}`,
              }))}
              selectedOption={selectedShipperOption}
              placeholder="Seleccione Shipper"
            />
          )}

          <div className="navbar-menu">
            <div className="navbar-end">
              {getTabsPermissions(user.user_permissions, "ADMINISTRACION") && (
                <MenuOption
                  text="Administración"
                  to="/administration"
                  active={location.pathname === "/administration"}
                />
              )}

              {getTabsPermissions(user.user_permissions, "DASHBOARD") && (
                <MenuOption
                  text="Dashboard"
                  to="/dashboard"
                  active={
                    location.pathname === "/dashboard" ||
                    location.pathname.includes("/transit") ||
                    location.pathname.includes("/region")
                  }
                />
              )}
              {getTabsPermissions(user.user_permissions, "CONSULTAS") && (
                <MenuOption
                  text="Consultas"
                  to="/"
                  active={location.pathname === "/"}
                />
              )}
              {getTabsPermissions(user.user_permissions, "REPORTES") && (
                <MenuOption
                  text="Reportes"
                  to="/reports"
                  active={location.pathname === "/reports"}
                />
              )}
              {/* {getTabsPermissions(user.user_permissions, "ZONAS") && (
                <MenuOption
                  text="Zonas"
                  to="/zones"
                  active={location.pathname === "/zones"}
                />
              )} */}
              {getTabsPermissions(user.user_permissions, "ARCHIVOS") && (
                <MenuOption
                  text="Archivos"
                  to="/files"
                  active={location.pathname === "/files"}
                />
              )}
              <UserInfo
                userName={user.user_name || ""}
                userCode={user.user_codemp || ""}
                actions={[
                  {
                    onClick: () => this.updateModalSession(true),
                    label: "Cerrar sesión",
                  },
                  {
                    onClick: () => this.handleModalPassword(true),
                    label: "Cambiar contraseña",
                  },
                ]}
              />
            </div>
          </div>
        </nav>
        <div className="content-layout uex-scrollbar">{children}</div>
        <ModalSession
          active={modalSession}
          onClose={() => this.updateModalSession(false)}
          onAccept={() => this.onLogout()}
          title="¿Está seguro que desea Cerrar Sesión?"
          titleClass="column is-10 mx-auto"
          isIcon
        />
        <ModalChangePassword
          active={gt(passwordExpiration, -1) ? true : modalPassword}
          onClose={() =>
            gt(passwordExpiration, -1) ? {} : this.handleModalPassword(false)
          }
          titleText={
            user.password_expiration === moment().format("YYYY-MM-DD") ||
            gt(passwordExpiration, -1)
              ? "Generar nueva contraseña"
              : "Cambio de contraseña"
          }
          state={this.state}
          onChange={this.handleChange}
          onSubmit={this.onSavePassword}
        />
        <ModalSuccess
          active={modalSuccess}
          onClose={() => this.setState({ modalSuccess: false })}
          cancelText="Aceptar"
          icon={SuccessIcon}
          title="Contraseña editada con éxito"
          titleClass="column is-10 mx-auto"
        />
        <ModalError
          active={modalError}
          onClose={() => this.setState({ modalError: false })}
          cancelText="Aceptar"
          icon={ErrorIcon}
          title={`Error al editar contraseña`}
          titleClass="column is-10 mx-auto"
        />
        <ModalError
          active={modalAlert}
          onClose={() => this.setState({ modalAlert: false })}
          cancelText="Aceptar"
          icon={AlertIcon}
          title={`Su contraseña esta por expirar, por favor actualize su contraseña`}
          titleClass="column is-10 mx-auto is-size-6"
        />
        {/* <AddModal /> */}
      </StyledDashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  isLogged: isLoggedSelector(state),
  user: userSelector(state),
  token: tokenSelector(state),
  changing: isChanging(state),
  shippers: shippersSelector(state),
});

export default connect(mapStateToProps)(withRouter(Dashboard));
