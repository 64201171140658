import {
  LOGOUT,
  FETCH_CURRENT_USER_PROFILE,
  FETCH_CURRENT_USER_PROFILE_SUCCESS,
  FETCH_CURRENT_USER_PROFILE_ERROR,
  CHANGE_CURRENT_SHIPPER,
  CHANGE_CURRENT_SHIPPER_SUCCESS,
  CHANGE_CURRENT_SHIPPER_ERROR,
  FINISH_CHANGE_CURRENT_SHIPPER,
  FETCH_HAS_IP,
  FETCH_HAS_IP_SUCCESS,
  FETCH_HAS_IP_ERROR
} from "../../action-types/auth";

export const logout = () => ({
  type: LOGOUT
});

export const fetchCurrentUserProfile = (token, callback) => ({
  type: FETCH_CURRENT_USER_PROFILE,
  payload: token,
  callback
});

export const fetchCurrentUserProfileSuccess = data => ({
  type: FETCH_CURRENT_USER_PROFILE_SUCCESS,
  payload: data
});

export const fetchCurrentUserProfileError = error => ({
  type: FETCH_CURRENT_USER_PROFILE_ERROR,
  payload: error
});

export const changeCurrentShipper = (payload, callback) => ({
  type: CHANGE_CURRENT_SHIPPER,
  payload,
  callback
});

export const changeCurrentShipperSuccess = payload => ({
  type: CHANGE_CURRENT_SHIPPER_SUCCESS,
  payload
});

export const changeCurrentShipperError = error => ({
  type: CHANGE_CURRENT_SHIPPER_ERROR,
  payload: error
});

export const finishChangeCurrentShipper = () => ({
  type: FINISH_CHANGE_CURRENT_SHIPPER
});

export const fetchHasIp = (payload, callback) => ({
  type: FETCH_HAS_IP,
  payload,
  callback
});

export const fetchHasIpSuccess = payload => ({
  type: FETCH_HAS_IP_SUCCESS,
  payload
});

export const fetchHasIpError = error => ({
  type: FETCH_HAS_IP_ERROR,
  payload: error
});
