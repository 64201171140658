import React from "react";
import { Link } from "react-router-dom";
import StyledMenuOption from "./styled";

const MenuOption = ({ to, text, active }) => {
  return (
    <StyledMenuOption>
      <Link
        to={to}
        className={`navbar-item uex-navbar-item bold ${active ? "active" : ""}`}
      >
        {text}
      </Link>
    </StyledMenuOption>
  );
};

export default MenuOption;
