import request from "../../../api";
import getEnvironment from "../../../utils/getEnvironment";

//? Verifica si esta en modo dev o producción
const api = getEnvironment();
//REPORTE DE REGION
const RGORegionRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/rgo_region`,
    data
  });
};

export { RGORegionRequest };
