import * as Yup from "yup";

export const validateFindUserForm = Yup.object().shape({
  // clientCode: Yup.string().required("Nombre es un campo requerido"),
  // anexo: Yup.string().required("Anexo es un campo requerido"),
  // selectedOption: Yup.object().required("objeto requerido"),
  // user_roles: Yup.array(),
  clientCode: Yup.string(),
  anexo: Yup.string(),
  clientName: Yup.string(),
  tel: Yup.string(),
  userValidation: Yup.string().when(
    ["clientCode", "anexo", "clientName", "tel", "hasND"],
    {
      is: (clientCode, anexo, clientName, tel, hasND) => {
        if (hasND) {
          return (
            (!clientCode || clientCode.length === 0) &&
            (!anexo || anexo.length === 0) &&
            (!tel || tel.length === 0)
          );
        }
        return (
          (!clientCode || clientCode.length === 0) &&
          (!anexo || anexo.length === 0) &&
          (!clientName || clientName.length === 0)
        );
      },
      then: Yup.string().required("Debe llenar al menos un campo")
    }
  )
});

export const validateClaimsForm = Yup.object().shape({
  type: Yup.string().required("Debe ingresar tipo de reclamo"),
  observations: Yup.string().required("Debe ingresar observación del reclamo")
});

export const validateCommentForm = Yup.object().shape({
  comment: Yup.string().required("Debe ingresar comentario")
});
