import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/es";
import { Formik } from "formik";
import { isEmpty, get, map, split, intersection } from "lodash";
import {
  TIGO_SHIPPER_CODES,
  SHIPPERS_WITH_CICLES
} from "../../utils/shipperPermissions";

//custom
import { firstDateMonthRange, lastDate } from "../../utils/rangeDates";
import { getParamsRequest } from "../../utils/paramsRequest";
import Table from "../../components/Table-delivery-detail";
import Button from "../../components/button";
import CalendarRangeDropdown from "../../components/calendar-range-dropdown";
import SelectAutocomplete from "../../components/select-autocomplete";
import FilterDropdown from "../../components/filter-dropdown";
import DownloadButton from "../../components/download-button";
import Input from "../../components/Input";
import LoadingScreen from "../../components/loading_screen";
import Paginator from "../../components/paginator";
import ModalSuccess from "../../components/modal_document";
import SuccessIcon from "../../assets/images/success-icon.svg";
import { getExcelWithTabsFile } from "../../utils/excelWithTabsFileGenerator";

//actions
import {
  fetchZoning,
  fetchZoningAll,
  fetchProducts
} from "../../actions/postal";

//selectors
import { tokenSelector, userSelector } from "../../actions/auth/selectors";
import {
  zoningSelector,
  zoningAllSelector,
  productsSelector
} from "../../actions/postal/selectors";

// validators
import { validateFilterForm } from "./validators/validateForm";

const allOption = { value: "%", label: "Todos" };

class ZoningReport extends Component {
  state = {
    selectionRange: {
      startDate: firstDateMonthRange,
      endDate: lastDate,
      key: "selection"
    },
    hasCicle: !isEmpty(
      intersection(
        SHIPPERS_WITH_CICLES,
        split(this.props.user.shi_codigos, ",")
      )
    ),
    selectedOption: false,
    cicle: "",
    modalDocument: false,
    loading: false
  };

  async componentDidMount() {
    const { token, user } = this.props;
    let userToken = getParamsRequest({
      shi_codigo: get(user, "shi_codigo", null),
      token
    });

    await this.getProducts(userToken);

    this.setAttributeCalendar();
  }

  getProducts = async data => {
    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        fetchProducts(data, (error, data) => {
          if (error) {
          }
          resolve();
        })
      );
    });
  };

  fetchZoning = async (page = 1) => {
    this.setState({ loading: true });
    const { dispatch, token, user } = this.props;
    const { selectionRange, cicle, selectedOption } = this.state;

    let data = getParamsRequest({
      token,
      cic_cliente: cicle,
      shi_codigo: get(user, "shi_codigo", null),
      pro_codigo:
        selectedOption[0].value === "%"
          ? "%"
          : `"${selectedOption.map(item => item.value).join()}"`,
      p: page,
      ciclo_inicio: moment(selectionRange.startDate).format("L"),
      ciclo_fin: moment(selectionRange.endDate).format("L")
    });

    await new Promise((resolve, reject) => {
      dispatch(
        fetchZoning(data, (error, data) => {
          if (error) {
          }
          resolve();
          this.setState({ loading: false });
          return this.setAttributeCalendar();
        })
      );
    });
  };

  fetchZoningDocument = async data => {
    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        fetchZoningAll(data, (error, data) => {
          if (error) {
          }
          resolve();
        })
      );
    });
  };

  setAttributeCalendar = () => {
    let component = document.getElementsByClassName("dropdown-calendar-button");
    for (let i = 0; i < component.length; i++) {
      component[i].setAttribute("type", "button");
    }
  };

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleInputDate = e => {
    if (e.target.value.length === 8) {
      const date = moment(e.target.value, "YYYYMMDD").format();
      let convertedStartDate = new Date(date).setMonth(
        new Date(date).getMonth() + 1
      );
      let convertedEndDate = new Date(
        new Date(date).setMonth(new Date(date).getMonth() + 2)
      );

      this.setState({
        selectedOption: [allOption],
        selectionRange: {
          startDate: convertedStartDate,
          endDate: convertedEndDate,
          key: "selection"
        }
      });
    } else if (e.target.value.length === 0) {
      this.setState({
        selectedOption: false,
        selectionRange: {
          startDate: firstDateMonthRange,
          endDate: lastDate,
          key: "selection"
        }
      });
    }
  };

  handleCleanFilters = () => {
    this.setState({
      cicle: "",
      selectedOption: false,
      selectionRange: {
        startDate: firstDateMonthRange,
        endDate: lastDate,
        key: "selection"
      }
    });
  };

  handleSelectProcess = ranges => {
    this.setState({
      selectionRange: ranges.selection
    });
  };

  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };

  onChangePagination = async page => {
    await this.fetchZoning(page);
  };

  handleModalDocument = modalDocument => {
    this.setState({ modalDocument });
  };

  handleReportFile = async () => {
    this.handleModalDocument(true);
    const { token, user } = this.props;
    const { selectionRange, cicle, selectedOption } = this.state;

    let data = getParamsRequest({
      token,
      cic_cliente: cicle,
      shi_codigo: get(user, "shi_codigo", null),
      pro_codigo:
        selectedOption[0].value === "%"
          ? "%"
          : `"${selectedOption.map(item => item.value).join()}"`,
      ciclo_inicio: moment(selectionRange.startDate).format("L"),
      ciclo_fin: moment(selectionRange.endDate).format("L"),
      tipo: 2
    });

    await this.fetchZoningDocument(data);

    let fileData = [];
    let cicloInicio = moment(selectionRange.startDate).format("L");
    let cicloFin = moment(selectionRange.endDate).format("L");
    let getData = await this.generateDocument();
    fileData.push({ data: getData, bookName: "Detalle" });

    getExcelWithTabsFile(
      fileData,
      `ZONIFICACION_${isEmpty(cicle) ? `${cicloInicio}_${cicloFin}` : cicle}`
    );

    this.handleModalDocument(false);
  };

  generateDocument = () => {
    const {
      zoningAll: { data },
      user
    } = this.props;
    let zoningReport = [["Código", "Anexo", "Agencia", "Zona", "Correlativo"]];

    let counter = 1;
    let product;

    map(data, (item, index) => {
      if (product === item.pro_codigo) {
        counter = counter + 1;
      } else {
        counter = 1;
      }
      zoningReport.push([
        ...(!isEmpty(
          intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ","))
        )
          ? [item.codigo.replace(/^0+/, "")]
          : [item.codigo]),
        item.anexo,
        item.agencia,
        item.zona,
        counter
      ]);

      product = item.pro_codigo;
    });

    return zoningReport;
  };

  render() {
    const {
      selectionRange,
      hasCicle,
      selectedOption,
      modalDocument,
      loading
    } = this.state;
    const { zoning, products } = this.props;
    let parseProducts = products.data.map(item => ({
      value: item.pro_codigo,
      label: isEmpty(get(item, "shipper_product_code"))
        ? item.pro_codigo
        : `${item.shipper_product_code} - ${item.pro_codigo}`
    }));
    parseProducts.unshift(allOption);

    if (loading) return <LoadingScreen />;

    return (
      <div className="h-100 w-100">
        <div className="column is-12 is-flex justify-content-end px-0">
          <DownloadButton
            // data={queryAllXLSXData}
            fileName={`DetalleDeEnvios_PROCESADO${moment(
              selectionRange.startDate
            ).format("L")}_${moment(selectionRange.endDate).format(
              "L"
            )}_CORTE${moment(selectionRange.startDate).format("L")}_${moment(
              selectionRange.endDate
            ).format("L")}`}
            onClick={() => this.handleReportFile()}
            disabled={zoning.data.length === 0}
          />
          <FilterDropdown>
            <Formik
              initialValues={this.state}
              validationSchema={validateFilterForm}
              enableReinitialize
              onSubmit={(values, { setSubmitting }) => {
                this.fetchZoning();
                setSubmitting(false);
              }}
            >
              {props => {
                const { errors, values, handleSubmit } = props;
                return (
                  <form
                    onSubmit={handleSubmit}
                    className="column is-12 is-flex align-items-center flex-wrap"
                  >
                    <div className="column is-9 is-paddingless is-flex flex-wrap">
                      {hasCicle && (
                        <div className="column is-4">
                          <Input
                            containerClass="column is-12 p-0"
                            placeholder={"Ingrese ciclo"}
                            name="cicle"
                            value={values.cicle}
                            onChange={e => {
                              this.handleInputChange(e);
                              this.handleInputDate(e);
                            }}
                            errors={errors.cicle}
                          />
                        </div>
                      )}
                      <div className="column is-4">
                        <SelectAutocomplete
                          onChange={this.handleChange}
                          containerClass="input-form filters-input filters-input-select w-100 is-paddingless m-0"
                          options={parseProducts}
                          selectedOption={selectedOption}
                          error={errors.productsValidation}
                          isMulti
                        />
                      </div>
                      <div className="column is-4">
                        <CalendarRangeDropdown
                          rangeName="Fecha"
                          containerClass="input-form filters-input w-100 m-0"
                          selectionRange={selectionRange}
                          onChange={this.handleSelectProcess}
                        />
                      </div>
                    </div>
                    <div className="column is-3 is-flex flex-wrap justify-content-center">
                      <div className="column is-10">
                        <Button
                          containerClass="w-100 clean-filter-button"
                          text="Limpiar filtros"
                          type="button"
                          onClick={() => this.handleCleanFilters()}
                        />
                      </div>
                      <div className="column is-10">
                        <Button containerClass="w-100" text="Buscar" />
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </FilterDropdown>
        </div>
        <Table
          tableHead={[
            "Corr",
            "Código",
            "Anexo",
            "Agencia",
            "Zona",
            "CHK",
            "Pro. Codigo"
          ]}
          tableBody={map(get(zoning, "data", []), (item, index) => [
            index + 1,
            item.codigo,
            item.anexo,
            item.agencia,
            item.zona,
            item.chk_codigo,
            `${item.shipper_prod} - ${item.pro_codigo}`
          ])}
        />
        <Paginator
          onChangePage={this.onChangePagination}
          totalText="Total"
          totalData={get(zoning, "total", 0)}
          total={get(zoning, "total", 0)}
          perPage={20}
          currentPage={parseInt(get(zoning, "page", 0))}
        />
        <ModalSuccess
          active={modalDocument}
          onClose={() => this.handleModalDocument(false)}
          icon={SuccessIcon}
          title="Generando Reporte"
          titleClass="column is-10 mx-auto"
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: tokenSelector(state),
  zoning: zoningSelector(state),
  zoningAll: zoningAllSelector(state),
  user: userSelector(state),
  products: productsSelector(state)
});
export default connect(mapStateToProps)(ZoningReport);
