import React from "react";
import { connect } from "react-redux";
import StyledDateRangeCalendar from "./styled";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { es } from "date-fns/esm/locale";
import * as theme from "../../utils/colors";

//Selectors
import { userSelector } from "../../actions/auth/selectors";

const DateRangeCalendar = ({ selectionRange, onChange, user }) => {
  return (
    <StyledDateRangeCalendar>
      <DateRange
        ranges={[selectionRange]}
        onChange={onChange}
        showMonthAndYearPickers={false}
        locale={es}
        showDateDisplay={false}
        rangeColors={[
          user.theme ? theme[user.theme.toUpperCase()].primary : "#CF333C",
          user.theme ? theme[user.theme.toUpperCase()].secondary : "#999999"
        ]}
      />
    </StyledDateRangeCalendar>
  );
};

const mapStateToProps = state => ({
  user: userSelector(state)
});

export default connect(mapStateToProps)(DateRangeCalendar);
