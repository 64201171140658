import styled from "styled-components";

const StyledLoadingScreen = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  @media (max-width: 1024px) {
    position: fixed;
    background: rgba(255, 255, 255, 0.5);
    z-index: 1;
  }
  &.isRequest {
    background: rgba(255, 255, 255, 0.6);
  }
`;

export default StyledLoadingScreen;
