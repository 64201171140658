import React from "react";
import StyledCollapseDetail from "./styled";
import Icon from "../Icon";

const CollapseDetail = ({
  onClick,
  active,
  casenumber,
  comment,
  description,
  time,
  url,
  urlplan
}) => {
  return (
    <StyledCollapseDetail>
      <div
        className="collapse-detail-header is-flex align-items-center"
        onClick={onClick}
      >
        <div className="column is-10 p-0 is-flex flex-wrap align-items-center justify-content-between">
          <p className="bold">
            <span className="has-text-grey">Caso</span> {casenumber}{" "}
          </p>
          <p className="bold">
            {time} {url || urlplan ? "RECLAMO FINALIZADO" : description}
          </p>
        </div>
        <Icon
          className={`arrow-icon ${active ? "active" : ""}`}
          icon="arrow"
          width={14}
          height={9}
          color="#9FA1B7"
        />
      </div>
      <div className={`collapse-detail-body ${active ? "active" : ""}`}>
        <div className="column is-12">
          {url && (
            <p className="bold">
              Auditoria de Campo{" "}
              <a
                className="has-text-link"
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Solución Auditoria {casenumber}
              </a>
            </p>
          )}
          {urlplan && (
            <p className="bold">
              Plan de acción{" "}
              <a
                className="has-text-link"
                href={urlplan}
                target="_blank"
                rel="noopener noreferrer"
              >
                Solución plan de acción {casenumber}
              </a>
            </p>
          )}
          <p className="bold has-text-grey">Comentario</p>
          <p className="bold">{comment}</p>
        </div>
      </div>
    </StyledCollapseDetail>
  );
};

export default CollapseDetail;
