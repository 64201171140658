import styled from "styled-components";

const StyledSortButton = styled.div`
  cursor: pointer;
  padding: 0.75rem;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-left: 1px solid #dfe0eb;
  &:active {
    background-color: rgba(242, 242, 242, 0.5);
  }
  p {
    color: #4b506d;
    font-weight: bold;
    margin-left: 0.5rem;
  }
`;

export default StyledSortButton;
