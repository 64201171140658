import React from "react";
import { connect } from "react-redux";
import StyledCreateUSer from "./styled/createUser";
import Modal from "../../components/modal";
import { Formik } from "formik";
import { userPermissions } from "../../utils/userPermissions";
import { userTypes } from "../../utils/userTypes";
import { get, map } from "lodash";

//custom
import Input from "../../components/Input";
import Select from "../../components/Input-select";
import Button from "../../components/button";

// validators
import { validateClientUserForm } from "./validators/validateForm";

// Selectors
import { shippersSelector } from "../../actions/postal/selectors";

const CreateUSer = ({
  modalType,
  active,
  onClose,
  data,
  onChangeToggle,
  onChange,
  userRol,
  onCreate,
  shippers
}) => {
  let shippersList = map(get(shippers, "data", []), shipper => ({
    label: shipper.shi_nombre,
    value: shipper.shi_codigo
  }));

  return (
    <StyledCreateUSer>
      <Modal
        active={active}
        onClose={onClose}
        contentClass="column is-6-desktop is-4-fullhd"
        content={
          <Formik
            initialValues={{ ...data, userType: "", shipperId: "" }}
            validationSchema={validateClientUserForm}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
              onCreate(values);
              setSubmitting(false);
            }}
          >
            {props => {
              const { errors, values, handleSubmit, handleChange } = props;

              return (
                <form
                  onSubmit={handleSubmit}
                  className="column is-12 has-background-white form-container is-flex flex-wrap"
                >
                  <h1 className="bold has-text-black w-100">
                    Crear usuario de cliente
                  </h1>
                  <div className="column is-12 is-flex flex-wrap justify-content-between p-0">
                    <div className="column is-flex align-items-center is-paddingless form-input p-0">
                      <Input
                        containerClass="column is-12 p-0 input-form"
                        label="Usuario"
                        placeholder="Ingrese nombre de usuario"
                        name="usr"
                        value={values.usr}
                        onChange={handleChange}
                        errors={errors.usr}
                      />
                    </div>
                    <div className="column is-flex align-items-center is-paddingless form-input p-0">
                      <Input
                        containerClass="column is-12 p-0 input-form"
                        label="Nombre"
                        placeholder="Ingrese su nombre"
                        name="usr_nombre"
                        value={values.usr_nombre}
                        onChange={handleChange}
                        errors={errors.usr_nombre}
                      />
                    </div>
                    <div className="column is-flex align-items-center is-paddingless form-input p-0">
                      <Input
                        containerClass="column is-12 p-0 input-form"
                        label="Codigo empleado"
                        placeholder="Ej:000303393"
                        name="usr_codemp"
                        value={values.usr_codemp}
                        onChange={handleChange}
                        errors={errors.usr_codemp}
                      />
                    </div>
                    <div className="column is-flex align-items-center is-paddingless form-input p-0">
                      <Input
                        containerClass="column is-12 p-0 input-form"
                        label="Correo electrónico"
                        placeholder="Ingrese su correo electrónico"
                        name="usr_email"
                        value={values.usr_email}
                        onChange={handleChange}
                        errors={errors.usr_email}
                      />
                    </div>
                    <div className="column is-flex align-items-center is-paddingless form-input p-0">
                      <Input
                        inputType="password"
                        containerClass="column is-12 p-0 input-form"
                        label="Contraseña"
                        placeholder="*********"
                        name="pass"
                        value={values.pass}
                        onChange={handleChange}
                        errors={errors.pass}
                      />
                    </div>
                    <div className="column is-12 is-flex flex-wrap px-0">
                      <Select
                        containerClass="column is-12 p-0"
                        label="Cliente"
                        placeholder="Escoge tipo de Cliente"
                        data={shippersList}
                        name="shipperId"
                        value={values.shipperId}
                        onChange={handleChange}
                      />
                      <p className="help is-danger error">{errors.shipperId}</p>
                    </div>
                    <div className="column is-12 is-flex flex-wrap px-0">
                      <Select
                        containerClass="column is-12 p-0"
                        label="Tipo de usuario"
                        placeholder="Escoge tipo de usuario"
                        data={userTypes}
                        name="userType"
                        value={values.userType}
                        onChange={handleChange}
                      />
                      <p className="help is-danger error">{errors.userType}</p>
                    </div>
                    <div className="column is-12 is-flex flex-wrap px-0">
                      {(Number(values.userType) === 2 ||
                        Number(values.userType) === 3) && (
                        <Select
                          containerClass="column is-12 p-0"
                          label="Permisos de usuario"
                          placeholder="Escoge permisos de usuario"
                          data={userPermissions}
                          name="permisos"
                          value={values.permisos}
                          onChange={handleChange}
                        />
                      )}
                      <p className="help is-danger error">{errors.permisos}</p>
                    </div>
                    <Button
                      containerClass="form-header-button"
                      text={`Crear Usuario`}
                      // onClick={() => this.setState({ createUserModal: true })}
                    />
                  </div>
                </form>
              );
            }}
          </Formik>
        }
      />
    </StyledCreateUSer>
  );
};

const mapStateToProps = state => ({
  shippers: shippersSelector(state)
});

export default connect(mapStateToProps)(CreateUSer);
