import styled from "styled-components";

const StyledSelectAutocomplete = styled.div`
  .input-label {
    color: #60626e;
    margin-bottom: 4px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    text-transform: capitalize;
  }
  .select-input {
    height: 100%;
    border: 1px solid #dfe0eb;
    border-radius: 5px;
    .select-input__control {
      box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
      height: 100%;
      border: 0;
      &:hover {
        border: 1px solid #60626e;
        border-radius: 4px;
        box-shadow: none !important;
      }
    }
    .select-input__control--is-focused {
      border: 1px solid #60626e;
      border-radius: 4px;
      box-shadow: none;
    }

    .select-input__placeholder {
      color: #c6c6c6;
      font-family: "Muli";
      font-size: 14px;
    }

    .select-input__input {
      font-family: "Muli";
      font-size: 14px;
    }

    .select-input__menu {
      margin-top: 3px;
    }

    .select-input__option {
      font-family: "Muli";
      font-size: 14px;
      cursor: pointer;
    }
  }
`;

export default StyledSelectAutocomplete;
