import styled from 'styled-components';

const Styled = styled.div`
  width: 100;
  padding-top: 0.875rem;
  padding-bottom: 0.938rem;
  padding-right: 2.625rem;
  padding-left: 4.563rem;
  background-color: #ffffff;
  // margin-left: 1.25rem;
  // margin-right: 1.25rem;
  margin-top: 0.938rem;
  @media (max-width: 769px) {
    margin: 0;
    padding: 0;
  }

  .is-justify-end {
    justify-content: flex-end;
  }
  .pagination-space {
    @media (max-width: 769px) {
      justify-content: center;
      padding: 0.5rem;
    }
  }
`;

export default Styled;
