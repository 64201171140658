import styled from "styled-components";

const StyledMap = styled.div`
  .house-picture {
    width: 3.0625rem;
    height: 3.0625rem;
  }
`;

export default StyledMap;
