import styled from "styled-components";

const StyledDownloadButton = styled.button`
  cursor: pointer;
  height: 2.5rem;
  width: 2.8125rem;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dfe0eb;
  background-color: #ffffff;
  &:focus {
    outline: none;
  }
  &:active {
    background-color: #dfe0eb;
  }
`;

export default StyledDownloadButton;
