import React, { Component } from "react";
import StyledDashboard from "./styled";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/es";
import numeral from "numeral";
import { get, isEmpty, split, intersection } from "lodash";
import GoBackPage from "../../components/go-back-page";
import { getParamsRequest } from "../../utils/paramsRequest";
import { TIGO_SHIPPER_CODES } from "../../utils/shipperPermissions";

// custom
import HeaderPage from "../../components/header-page";
import DownloadButton from "../../components/download-button";
import TableRegion from "./table-region";
import DateButton from "../../components/date-button";
import ElSalvadorMap from "../../components/el-salvador-map";
import LoadingScreen from "../../components/loading_screen";
import NotFound from "../../components/NotFound";
import ModalDetail from "./modal-details";

// actions
import {
  fetchRGORanks,
  fetchCourierParts,
  fetchCourierPartsComplete
} from "../../actions/postal";

// selectors
import {
  RGORanksSelector,
  courierPartsSelector,
  courierPartsCompleteSelector,
  checkpointsSelector
} from "../../actions/postal/selectors";
import { tokenSelector, userSelector } from "../../actions/auth/selectors";

const allOption = { value: "%", label: "Todos" };

class Region extends Component {
  state = {
    modalDetails: false,
    loadingModal: false,
    modalData: null,
    clientCode: "",
    anexo: "",
    selectedCheckpoint: null,
    location: false,
    detailTitle: "",
    showCheckpointSelect: false
  };

  async componentDidMount() {
    const {
      token,
      user,
      match: {
        params: { product, cicle }
      }
    } = this.props;

    let data = getParamsRequest({
      token,
      shi_codigo: get(user, "shi_codigo", null),
      pro_codigo: product === "all" ? "%" : product,
      f_inicio: cicle,
      f_fin: cicle
    });

    await this.getRGORanks(data);
  }

  getRGORanks = async dataFilter => {
    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        fetchRGORanks(dataFilter, (error, data) => {
          if (error) {
          }
          resolve();
        })
      );
    });
  };

  getCourierParts = async (page = 1) => {
    this.setState({ loadingModal: true });
    const { dispatch, token, user } = this.props;
    const { modalData } = this.state;

    let data = getParamsRequest({
      token,
      shi_codigo: get(user, "shi_codigo", null),
      ...modalData,
      p: page
    });

    await new Promise((resolve, reject) => {
      dispatch(
        fetchCourierParts(data, (error, data) => {
          this.setState({ loadingModal: false });
          if (error) {
            return;
          }
          this.setState({ modalDetails: true });
          resolve();
        })
      );
    });
  };

  getCourierPartsComplete = async (page = 1) => {
    const { dispatch, token, user } = this.props;
    const { modalData } = this.state;

    let data = getParamsRequest({
      token,
      shi_codigo: get(user, "shi_codigo", null),
      ...modalData,
      p: page,
      getAll: 1
    });

    await new Promise((resolve, reject) => {
      dispatch(
        fetchCourierPartsComplete(data, (error, data) => {
          if (error) {
            return;
          }
          resolve();
        })
      );
    });
  };

  handleChangeAutocomplete = selectedOption => {
    this.setState({ selectedOption }, async () => {
      const {
        props: {
          token,
          user,
          match: {
            params: { cicle }
          }
        }
      } = this;

      let data = getParamsRequest({
        token,
        shi_codigo: get(user, "shi_codigo", null),
        pro_codigo: this.state.selectedOption.value,
        ciclo: cicle
      });

      await this.getTransitTimes(data);
    });
  };

  handleModalDetails = modalDetails => {
    this.setState({
      modalDetails,
      clientCode: "",
      anexo: "",
      selectedCheckpoint: null,
      location: false,
      showCheckpointSelect: false
    });
  };

  showModalDetails = (modalData, detailTitle, showCheckpoints) => {
    this.setState(
      {
        modalData,
        detailTitle,
        ...(showCheckpoints && {
          showCheckpointSelect: true
        })
      },
      () => {
        this.getCourierParts();
        this.getCourierPartsComplete();
      }
    );
  };

  onChangePagination = async page => {
    await this.getCourierParts(page);
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  findUser = () => {
    let {
      modalData,
      clientCode,
      anexo,
      location,
      selectedCheckpoint
    } = this.state;

    modalData = {
      ...modalData,
      cli_codigo: clientCode,
      anexo: anexo,
      location: location ? 1 : 0,
      checkpoint_pieza: selectedCheckpoint ? selectedCheckpoint.value : "%"
    };

    this.setState({ modalData }, () => {
      this.getCourierParts();
      this.getCourierPartsComplete();
    });
  };

  handleChangeCheckpoint = selectedCheckpoint => {
    this.setState({ selectedCheckpoint });
  };

  cleanFilters = () => {
    this.setState({
      clientCode: "",
      anexo: "",
      selectedCheckpoint: null,
      location: false
    });
  };

  onChangeToggle = () => {
    const { location } = this.state;
    this.setState({ location: !location });
  };

  render() {
    moment.locale("es");
    const {
      modalDetails,
      loadingModal,
      selectedCheckpoint,
      location,
      detailTitle,
      showCheckpointSelect
    } = this.state;
    const {
      RGORanks,
      match: {
        params: { cicle, shipperCicle }
      },
      history,
      checkpoints,
      user,
      courierPartsComplete
    } = this.props;
    const { data, loading, error } = RGORanks;

    let parseCheckpoints = checkpoints.data.map(item => ({
      value: item.codigo,
      label: `${item.codigo} - ${item.descripcion}`
    }));
    parseCheckpoints.unshift(allOption);

    let XLSXData = [
      [
        "Agencia",
        "Total Recibido",
        "Total Entregas",
        "% Entregas",
        "Total devuelto",
        "% Devuelto",
        "% Distribuido",
        "Por Salir",
        "En ruta"
      ]
    ];

    data &&
      data.data &&
      data.data.collection.map(item =>
        XLSXData.push([
          item.prov_nombre,
          numeral(Number(item.recibido)).format("0,0"),
          numeral(Number(item.entregados)).format("0,0"),
          item.porc_entregados + "%",
          numeral(Number(item.devueltos)).format("0,0"),
          item.porc_devueltos + "%",
          item.porc_distribuido + "%",
          numeral(Number(item.porsalir)).format("0,0"),
          numeral(Number(item.enruta)).format("0,0")
        ])
      );

    let mapData = {};

    if (get(data, "data.departamento")) {
      data.data.departamento.map(
        item =>
          (mapData[
            item.departamento
              .trim()
              .split(" ")
              .join("")
              .split("-")
              .join("")
          ] = {})
      );

      data.data.departamento.map(item => {
        for (let itemMap in mapData) {
          if (
            item.departamento
              .trim()
              .split(" ")
              .join("")
              .split("-")
              .join("") === itemMap
          ) {
            mapData[itemMap].delivery = item.cantidad;
            mapData[itemMap].zone = item.zona;
          }
        }
      });
    }

    let XLSXDashboardData = [
      [
        "Corr",
        "Prod",
        !isEmpty(intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ",")))
          ? "Cod. Cliente"
          : "Cuenta",
        "Nombre",
        !isEmpty(intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ",")))
          ? "Anexo"
          : "Telefono",
        "Chk",
        "GPS"
      ]
    ];

    courierPartsComplete.data.map((item, i) =>
      XLSXDashboardData.push([
        i + 1,
        item.pro_codigo,
        item.cli_codigo,
        isEmpty(item.cli_nombre) ? "--" : item.cli_nombre,
        !isEmpty(intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ",")))
          ? isEmpty(item.anexo)
            ? "--"
            : item.anexo
          : `${(isEmpty(item.cli_telefono1) ? "--" : item.cli_telefono1) /
              (isEmpty(item.cli_telefono2) ? "--" : item.cli_telefono2)}`,
        isEmpty(item.esto_codigo) ? item.chk_codigo : item.esto_codigo,
        !isEmpty(intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ",")))
          ? parseInt(item.latitud) === 0 || item.latitud.length === 0
            ? "NO"
            : `${item.latitud},${item.longitud}`
          : parseInt(item.latitud) === 0 || item.latitud.length === 0
          ? "NO"
          : "SI"
      ])
    );

    if (loading) return <LoadingScreen />;

    return (
      <StyledDashboard className="section-container uex-scrollbar">
        {loadingModal && <LoadingScreen isRequest />}
        {error === 404 ? (
          <div className="w-100">
            <GoBackPage onClick={() => history.goBack()} />
            <HeaderPage
              title={`Distribución por región: ${cicle} ${
                isEmpty(shipperCicle) ? "" : `- ${shipperCicle}`
              }`}
            />
            <NotFound
              containerClass="emptyState"
              title="Sin registros"
              subtitle="Actualmente no existen registros para su búsqueda"
            />
          </div>
        ) : (
          <div className="w-100">
            <GoBackPage onClick={() => history.goBack()} />
            <HeaderPage
              title={`Distribución por región: ${cicle} ${
                isEmpty(shipperCicle) ? "" : `- ${shipperCicle}`
              }`}
              content={
                <div className="column is-flex align-items-center justify-content-end">
                  <DownloadButton
                    data={XLSXData}
                    fileName={`RGORegion_${cicle}`}
                  />
                  <DateButton
                    text={`Tiempo de ciclo: ${moment(
                      cicle,
                      "DD-MM-YYYY"
                    ).format("D MMM YYYY")}`}
                  />
                </div>
              }
            />
            <div className="column is-12 is-paddingless has-background-white">
              <ElSalvadorMap test={this.props.test} mapData={mapData} />
            </div>
            <h2 className="has-text-black bold subtext-space">
              Reporte de Gestión Operativa
            </h2>
            <TableRegion
              tableHead={[
                "Agencia",
                "Total Recibido",
                "Total Entregas",
                "% Entregas",
                "Total devuelto",
                "% Devuelto",
                "% Distribuido",
                "Por Salir",
                "En ruta"
              ]}
              tableBody={
                data && data.data
                  ? data.data.collection.map(item => [
                      {
                        title: item.prov_nombre
                      },
                      {
                        title: numeral(Number(item.recibido)).format("0,0"),
                        onClick: () =>
                          this.showModalDetails(
                            {
                              pro_codigo: "%",
                              cic_inicio: cicle,
                              prov_codigo: item.prov_codigo
                            },
                            "Total Recibido",
                            true
                          )
                      },
                      {
                        title: numeral(Number(item.entregados)).format("0,0"),
                        onClick: () =>
                          this.showModalDetails(
                            {
                              pro_codigo: "%",
                              cic_inicio: cicle,
                              prov_codigo: item.prov_codigo,
                              checkpoint: "DL"
                            },
                            "Total Entregas"
                          )
                      },
                      { title: item.porc_entregados + "%" },
                      {
                        title: numeral(Number(item.devueltos)).format("0,0"),
                        onClick: () =>
                          this.showModalDetails(
                            {
                              pro_codigo: "%",
                              cic_inicio: cicle,
                              prov_codigo: item.prov_codigo,
                              checkpoint: "DV"
                            },
                            "Total Devuelto"
                          )
                      },
                      { title: item.porc_devueltos + "%" },
                      { title: item.porc_distribuido + "%" },
                      {
                        title: numeral(Number(item.porsalir)).format("0,0"),
                        onClick: () =>
                          this.showModalDetails(
                            {
                              pro_codigo: "%",
                              cic_inicio: cicle,
                              prov_codigo: item.prov_codigo,
                              estado: "PS"
                            },
                            "Por Salir"
                          )
                      },
                      {
                        title: numeral(Number(item.enruta)).format("0,0"),
                        onClick: () =>
                          this.showModalDetails(
                            {
                              pro_codigo: "%",
                              cic_inicio: cicle,
                              prov_codigo: item.prov_codigo,
                              estado: "ER"
                            },
                            "En Ruta"
                          )
                      }
                    ])
                  : []
              }
            />
            <ModalDetail
              active={modalDetails}
              onCloseModal={() => this.handleModalDetails(false)}
              detailTitle={detailTitle}
              initialValues={this.state}
              onSubmit={this.findUser}
              onChangeInput={this.handleChange}
              onChangeAutocomplete={this.handleChangeCheckpoint}
              autocompleteOptions={parseCheckpoints}
              autocompleteSelectedOption={selectedCheckpoint}
              hasLocation={location}
              onChangeToggle={() => this.onChangeToggle()}
              onCleanFilters={() => this.cleanFilters()}
              onChangePagination={this.onChangePagination}
              showCheckpointSelect={showCheckpointSelect}
              xlsxData={XLSXDashboardData}
            />
          </div>
        )}
      </StyledDashboard>
    );
  }
}
const mapStateToProps = state => ({
  user: userSelector(state),
  RGORanks: RGORanksSelector(state),
  token: tokenSelector(state),
  courierParts: courierPartsSelector(state),
  courierPartsComplete: courierPartsCompleteSelector(state),
  checkpoints: checkpointsSelector(state)
});
export default connect(mapStateToProps)(Region);
