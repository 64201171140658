import React from "react";
import { connect } from "react-redux";
import StyledCreateUSer from "./styled/createUser";
import Modal from "../../components/modal";
import { Formik } from "formik";
import { userPermissions } from "../../utils/userPermissions";
import {
  get,
  map,
  filter,
  includes,
  isEmpty,
  intersection,
  split,
  isEqual
} from "lodash";

//custom
import Input from "../../components/Input";
import Select from "../../components/Input-select";
import Button from "../../components/button";
import Toggle from "../../components/toggle";
import { ADMINISTRATION_PERMISSIONS } from "../../utils/shipperPermissions";

// Selectors
import { userSelector } from "../../actions/auth/selectors";

// validators
import { validateUsersForm } from "./validators/validateForm";

const CreateUSer = ({
  modalType,
  active,
  onClose,
  data,
  onChangeToggle,
  onChange,
  userRol,
  onCreate,
  onEdit,
  user
}) => {
  let permissions = !isEmpty(
    intersection(
      ADMINISTRATION_PERMISSIONS.hasFilesPermissions,
      split(user.shi_codigos, ",")
    )
  )
    ? userPermissions
    : filter(
        userPermissions,
        permission => !includes(permission.label, "ARCHIVOS")
      );

  return (
    <StyledCreateUSer>
      <Modal
        active={active}
        onClose={onClose}
        contentClass="column is-6-desktop is-4-fullhd"
        content={
          <Formik
            initialValues={data}
            validationSchema={validateUsersForm}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
              modalType === 1 ? onCreate(values) : onEdit(values);
              setSubmitting(false);
            }}
          >
            {props => {
              const { errors, values, handleSubmit } = props;
              return (
                <form
                  onSubmit={handleSubmit}
                  className="column is-12 has-background-white form-container is-flex flex-wrap"
                >
                  <h1 className="bold has-text-black w-100">
                    {modalType === 1 ? "Crear" : "Editar"} Usuario
                  </h1>
                  <div className="column is-12 is-flex flex-wrap justify-content-between p-0">
                    <div className="column is-flex align-items-center is-paddingless form-input p-0">
                      <Input
                        containerClass="column is-12 p-0 input-form"
                        label="Usuario"
                        placeholder="Ingrese nombre de usuario"
                        name="usr"
                        value={values.usr}
                        onChange={onChange}
                        errors={errors.usr}
                      />
                    </div>
                    <div className="column is-flex align-items-center is-paddingless form-input p-0">
                      <Input
                        containerClass="column is-12 p-0 input-form"
                        label="Nombre"
                        placeholder="Ingrese su nombre"
                        name="usr_nombre"
                        value={values.usr_nombre}
                        onChange={onChange}
                        errors={errors.usr_nombre}
                      />
                    </div>
                    <div className="column is-flex align-items-center is-paddingless form-input p-0">
                      <Input
                        containerClass="column is-12 p-0 input-form"
                        label="Codigo empleado"
                        placeholder="Ej:000303393"
                        name="usr_codemp"
                        value={values.usr_codemp}
                        onChange={onChange}
                        errors={errors.usr_codemp}
                      />
                    </div>
                    <div className="column is-flex align-items-center is-paddingless form-input p-0">
                      <Input
                        containerClass="column is-12 p-0 input-form"
                        label="Correo electrónico"
                        placeholder="Ingrese su correo electrónico"
                        name="usr_email"
                        value={values.usr_email}
                        onChange={onChange}
                        errors={errors.usr_email}
                      />
                    </div>
                    <div className="column is-flex align-items-center is-paddingless form-input p-0">
                      <Input
                        inputType="password"
                        containerClass="column is-12 p-0 input-form"
                        label="Contraseña"
                        placeholder="*********"
                        name="pass"
                        value={values.pass}
                        onChange={onChange}
                        errors={errors.pass}
                      />
                    </div>
                    <p className="column is-12 p-0 bold">Rol</p>
                    <div className="column is-12 is-flex flex-wrap px-0">
                      <div className="is-flex align-items-center">
                        <p>Administrador</p>
                        <Toggle
                          containerClass="form-toggle"
                          checked={
                            (data && values.rol === "ADMINISTRADOR") ||
                            userRol === "ADMINISTRADOR" ||
                            false
                          }
                          onChange={() =>
                            onChangeToggle("ADMINISTRADOR", data ? true : false)
                          }
                        />
                      </div>
                      <div className="is-flex align-items-center">
                        <p>Usuario</p>
                        <Toggle
                          containerClass="form-toggle"
                          checked={
                            (data && values.rol === "USUARIO") ||
                            userRol === "USUARIO" ||
                            false
                          }
                          onChange={() =>
                            onChangeToggle("USUARIO", data ? true : false)
                          }
                        />
                      </div>
                      <p className="help is-danger error">{errors.rol}</p>
                    </div>
                    <div className="column is-12 is-flex flex-wrap px-0">
                      {((data && values.rol === "USUARIO") ||
                        userRol === "USUARIO") && (
                        <Select
                          containerClass="column is-12 p-0"
                          label="Permisos de usuario"
                          placeholder="Escoge permisos de usuario"
                          data={permissions}
                          name="permisos"
                          value={values.permisos}
                          onChange={onChange}
                        />
                      )}
                      <p className="help is-danger error">{errors.permisos}</p>
                    </div>
                    <Button
                      containerClass="form-header-button"
                      text={`${modalType === 1 ? "Crear" : "Editar"} Usuario`}
                      // onClick={() => this.setState({ createUserModal: true })}
                    />
                  </div>
                </form>
              );
            }}
          </Formik>
        }
      />
    </StyledCreateUSer>
  );
};

const mapStateToProps = state => ({
  user: userSelector(state)
});

export default connect(mapStateToProps)(CreateUSer);
