import React, { useState } from "react";
import StyledTable from "./styled";

const Table = ({
  tableHead = [],
  tableBody = [],
  actions = false,
  filter = false,
  emptyMessage = "No Existen datos",
  headClass,
  bodyClass
}) => {
  const [selected, setSelected] = useState(null);
  return (
    <StyledTable className="orders-table-container">
      {window.innerWidth > 769 && (
        <div className={`orders-table-head ${headClass}`}>
          <div className="columns is-fullhd is-gapless">
            {tableHead.map((title, i) => (
              <div
                key={i}
                className={`column has-text-centered ${i === 0 ? "is-1" : ""}`}
              >
                <p>{title}</p>
              </div>
            ))}
            {actions && <div className="column is-1"></div>}
          </div>
        </div>
      )}
      {window.innerWidth > 769 && (
        <div className={`orders-table-body uex-scrollbar ${bodyClass}`}>
          {tableBody.length > 0 &&
            tableBody.map((item, index) => {
              return (
                <div
                  className={`columns is-fullhd is-gapless is-marginless table-row ${
                    selected === item[1] ? "active" : ""
                  }`}
                  key={index}
                  onClick={() => setSelected(item[1])}
                >
                  {item.map((title, i) => {
                    if (i !== item.length - 1) {
                      return (
                        <div
                          key={i}
                          onClick={item[item.length - 1]}
                          className={`column has-text-centered h-100 is-flex align-items-center justify-content-center ${
                            i === 0 ? "is-1" : ""
                          }`}
                        >
                          <p>{title}</p>
                        </div>
                      );
                    }
                  })}
                </div>
              );
            })}
          {tableBody.length === 0 && (
            <div className="columns is-fullhd is-gapless is-marginless">
              <div className="column has-text-centered">
                <p>{emptyMessage}</p>
              </div>
            </div>
          )}
        </div>
      )}
    </StyledTable>
  );
};

export default Table;
