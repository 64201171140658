import styled from "styled-components";

const StyledBackPage = styled.div`
  h3 {
    margin-left: 1rem;
    color: #9fa1b7;
  }
`;

export default StyledBackPage;
