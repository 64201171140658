import styled from "styled-components";
import { RED } from "../../../utils/colors";

const StyledDropdownLabel = styled.div`
  .user-dropdown {
    height: 100%;
    border: 0;
    background-color: transparent;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    .user-dropdown-name {
      margin: 0 1rem;
      p {
        text-transform: uppercase;
        &:nth-child(1) {
          font-weight: bold;
        }
        &:nth-child(2) {
          color: #9fa1b7;
        }
      }
    }
    .user-dropdown-shortname-rounded {
      width: 2.7rem;
      height: 2.7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${RED};
      border-radius: 50%;
      color: #ffffff;
      font-weight: bold;
      text-transform: uppercase;
    }
    .arrow-icon {
      margin: 0 0 0 1rem;
    }
  }
  .dropdown-menu {
    border: 0;
    width: 100%;
    .dropdown-content {
      border-radius: 0;
      box-shadow: 0px 4px 4px rgba(187, 187, 187, 0.25);
      border-radius: 4px;
      .dropdown-item {
        cursor: pointer;
        font-weight: bold;
        letter-spacing: 0.0125em;
        text-align: center;
        &:hover {
          color: ${RED};
        }
      }
    }
  }
`;

export default StyledDropdownLabel;
