import styled from "styled-components";

const StyledDropdownCalendar = styled.div`
  border: 1px solid #dfe0eb;
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  height: 2.5rem;
  .dropdown-trigger {
    width: 100%;
    display: flex;
    align-items: center;
    .dropdown-calendar-button {
      width: 100%;
      height: 100%;
      border: 0;
      padding: 0;
      justify-content: space-between;
      &:focus {
        box-shadow: none;
      }
      p {
        color: #9fa1b7;
      }
    }
  }
`;

export default StyledDropdownCalendar;
