import request from "../../../api";
import getEnvironment from "../../../utils/getEnvironment";

//? Verifica si esta en modo dev o producción
const api = getEnvironment();

const uploadedFilesRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/get_uploaded_files`,
    data
  });
};

const removedFilesRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/get_removed_files`,
    data
  });
};

const postUploadFilesRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/save_file`,
    data
  });
};

const deleteInboxFileRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/delete_file`,
    data
  });
};

export {
  uploadedFilesRequest,
  removedFilesRequest,
  postUploadFilesRequest,
  deleteInboxFileRequest
};
