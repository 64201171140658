import React from "react";
import StyledSortButton from "./styled";
import Icon from "../Icon";

const SortButton = ({ containerClass, text = "Ordenar", onClick }) => {
  return (
    <StyledSortButton onClick={onClick} className={containerClass}>
      <Icon icon="sort" color="#9FA1B7" height={19} width={16} />
      <p>{text}</p>
    </StyledSortButton>
  );
};

export default SortButton;
