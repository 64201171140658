import React, { Component } from "react";
import StyledDashboard from "./styled";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/es";
import numeral from "numeral";
import { isEmpty, get, split, intersection } from "lodash";
import GoBackPage from "../../components/go-back-page";
import { getParamsRequest } from "../../utils/paramsRequest";
import { TIGO_SHIPPER_CODES } from "../../utils/shipperPermissions";

// custom
import HeaderPage from "../../components/header-page";
import DownloadButton from "../../components/download-button";
import TableTransit from "../../components/Table-transit";
import DateButton from "../../components/date-button";
import TransitChart from "../../components/transit-chart";
import LoadingScreen from "../../components/loading_screen";
import NotFound from "../../components/NotFound";
import SelectAutocomplete from "../../components/select-autocomplete";
import ModalDetail from "./modal-details";

// actions
import {
  fetchTransitTimes,
  fetchTransitTimesProducts,
  fetchCourierParts,
  fetchCourierPartsComplete,
  fetchCheckpoints
} from "../../actions/postal";

// selectors
import {
  transitTimesSelector,
  transitTimesProductsSelector,
  courierPartsSelector,
  courierPartsCompleteSelector,
  checkpointsSelector
} from "../../actions/postal/selectors";
import { tokenSelector, userSelector } from "../../actions/auth/selectors";

const allOption = { value: "%", label: "Todos" };
class Transit extends Component {
  state = {
    selectedOption: allOption,
    productsOptions: [],
    showDetails: false,
    modalDetails: false,
    loadingModal: false,
    modalData: null,
    clientCode: "",
    anexo: "",
    selectedCheckpoint: null,
    location: false,
    detailTitle: "",
    showCheckpointSelect: false
  };
  async componentDidMount() {
    const {
      token,
      user,
      match: {
        params: { product, cicle }
      }
    } = this.props;

    let data = getParamsRequest({
      token,
      shi_codigo: get(user, "shi_codigo", null),
      pro_codigo: product === "all" ? "%" : product,
      ciclo: cicle
    });

    this.getCheckpoints(
      getParamsRequest({
        token,
        shi_codigo: get(user, "shi_codigo", null),
        checkpoint_scope: 1
      })
    );

    await this.getTransitTimes(data);

    await this.getTransitTimesProducts(data);
  }

  getTransitTimes = async data => {
    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        fetchTransitTimes(data, (error, data) => {
          if (error) {
          }
          resolve();
        })
      );
    });
  };

  getTransitTimesProducts = async data => {
    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        fetchTransitTimesProducts(data, (error, data) => {
          if (error) {
          }
          let productsOptions = [{ value: "%", label: "Todos" }];
          data.map(item =>
            productsOptions.push({
              value: item.pro_codigo,
              label: isEmpty(get(item, "shipper_product_code"))
                ? item.pro_codigo
                : `${item.shipper_product_code} - ${item.pro_codigo}`
            })
          );
          this.setState({ productsOptions });
          resolve();
        })
      );
    });
  };

  getCheckpoints = async data => {
    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        fetchCheckpoints(data, (error, data) => {
          if (error) {
          }
          resolve();
        })
      );
    });
  };

  getCourierParts = async (page = 1) => {
    this.setState({ loadingModal: true });
    const { dispatch, token, user } = this.props;
    const { modalData } = this.state;

    let data = getParamsRequest({
      token,
      shi_codigo: get(user, "shi_codigo", null),
      ...modalData,
      p: page
    });

    await new Promise((resolve, reject) => {
      dispatch(
        fetchCourierParts(data, (error, data) => {
          this.setState({ loadingModal: false });
          if (error) {
            return;
          }
          this.setState({ modalDetails: true });
          resolve();
        })
      );
    });
  };

  getCourierPartsComplete = async (page = 1) => {
    const { dispatch, token, user } = this.props;
    const { modalData } = this.state;

    let data = getParamsRequest({
      token,
      shi_codigo: get(user, "shi_codigo", null),
      ...modalData,
      p: page,
      getAll: 1
    });

    await new Promise((resolve, reject) => {
      dispatch(
        fetchCourierPartsComplete(data, (error, data) => {
          if (error) {
            return;
          }
          resolve();
        })
      );
    });
  };

  handleChangeAutocomplete = selectedOption => {
    this.setState({ selectedOption }, async () => {
      const {
        props: {
          token,
          user,
          match: {
            params: { cicle }
          }
        }
      } = this;

      let data = getParamsRequest({
        token,
        shi_codigo: get(user, "shi_codigo", null),
        pro_codigo: this.state.selectedOption.value,
        ciclo: cicle
      });

      await this.getTransitTimes(data);
    });
  };

  handleModalDetails = modalDetails => {
    this.setState({
      modalDetails,
      clientCode: "",
      anexo: "",
      selectedCheckpoint: null,
      location: false,
      showCheckpointSelect: false
    });
  };

  showModalDetails = (modalData, detailTitle, showCheckpoints) => {
    this.setState(
      {
        modalData,
        detailTitle,
        ...(showCheckpoints && {
          showCheckpointSelect: true
        })
      },
      () => {
        this.getCourierParts();
        this.getCourierPartsComplete();
      }
    );
  };

  onChangePagination = async page => {
    await this.getCourierParts(page);
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  findUser = () => {
    let {
      modalData,
      clientCode,
      anexo,
      location,
      selectedCheckpoint
    } = this.state;

    modalData = {
      ...modalData,
      cli_codigo: clientCode,
      anexo: anexo,
      location: location ? 1 : 0,
      checkpoint_pieza: selectedCheckpoint ? selectedCheckpoint.value : "%"
    };

    this.setState({ modalData }, () => {
      this.getCourierParts();
      this.getCourierPartsComplete();
    });
  };

  handleChangeCheckpoint = selectedCheckpoint => {
    this.setState({ selectedCheckpoint });
  };

  cleanFilters = () => {
    this.setState({
      clientCode: "",
      anexo: "",
      selectedCheckpoint: null,
      location: false
    });
  };

  onChangeToggle = () => {
    const { location } = this.state;
    this.setState({ location: !location });
  };

  render() {
    moment.locale("es");
    const {
      selectedOption,
      productsOptions,
      modalDetails,
      loadingModal,
      selectedCheckpoint,
      location,
      detailTitle,
      showCheckpointSelect
    } = this.state;
    const {
      transitTimes,
      history,
      match: {
        params: { product, cicle, shipperCicle }
      },
      checkpoints,
      courierPartsComplete,
      user
    } = this.props;
    const { data, loading, error } = transitTimes;

    let parseCheckpoints = checkpoints.data.map(item => ({
      value: item.codigo,
      label: `${item.codigo} - ${item.descripcion}`
    }));
    parseCheckpoints.unshift(allOption);

    let XLSXData = [
      [
        "Provincia",
        "Cant",
        // "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "+7",
        "DV",
        "Stock"
      ]
    ];

    data &&
      data.map(item =>
        XLSXData.push([
          item.prov,
          numeral(Number(item.recibido)).format("0,0"),
          // `${item["0"].porc}%`,
          `${item["1"].porc}%`,
          `${item["2"].porc}%`,
          `${item["3"].porc}%`,
          `${item["4"].porc}%`,
          `${item["5"].porc}%`,
          `${item["6"].porc}%`,
          `${item["7"].porc}%`,
          numeral(Number(item.dv)).format("0,0"),
          item.stock
        ])
      );

    let stopPercentage = 0;
    for (let i = 0; i < 8; i++) {
      let sum = 0;
      data.map(item => {
        sum = sum + item[i].porc;
      });
      let total = 100 * data.length;
      if (parseFloat(sum) === total) {
        stopPercentage = i;
        break;
      }
    }

    let total = {
      recibido: 0,
      "0": 0,
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0,
      "5": 0,
      "6": 0,
      "7": 0,
      dv: 0,
      stock: 0
    };
    let totalPercentage = {
      "0": 0,
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0,
      "5": 0,
      "6": 0,
      "7": 0,
      dv: 0,
      stock: 0
    };

    let dailyPercentage = {
      "0": 0,
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0,
      "5": 0,
      "6": 0,
      "7": 0,
      dv: 0,
      stock: 0
    };

    for (const [i, item] of data.entries()) {
      Object.keys(item).map(j => {
        if (total.hasOwnProperty(j)) {
          if (typeof item[j] === "object") {
            total[j] = parseFloat(item[j].data) + parseFloat(total[j]);
          } else {
            total[j] = parseFloat(item[j]) + parseFloat(total[j]);
          }
        }
      });
    }

    let currentColumn = data.map(item =>
      Object.keys(item).find(
        j => item[j].date === moment().format("DD-MM-YYYY")
      )
    )[0];

    for (let i = 0; i < 9; i++) {
      if (i > 7) {
        totalPercentage.dv =
          (parseFloat(total.dv) * 100) / parseFloat(total.recibido);
        totalPercentage.stock =
          (parseFloat(total.stock) * 100) / parseFloat(total.recibido);
      } else {
        if (stopPercentage > 0 && i >= stopPercentage) {
          totalPercentage[`${i}`] = 100;
        } else if (
          Number(currentColumn) === i &&
          parseFloat(total[`${i}`]) === 0
        ) {
          totalPercentage[`${i}`] = totalPercentage[`${i - 1}`];
        } else if (parseFloat(total[`${i}`]) !== 0 && i !== 0) {
          totalPercentage[`${i}`] =
            (parseFloat(total[`${i}`]) * 100) / parseFloat(total.recibido) +
            totalPercentage[`${i - 1}`];
        } else {
          totalPercentage[`${i}`] =
            (parseFloat(total[`${i}`]) * 100) / parseFloat(total.recibido);
        }
      }
    }

    for (let i = 0; i < 9; i++) {
      dailyPercentage[i] =
        (parseFloat(total[i]) * 100) / parseFloat(total.recibido);
    }

    let grafico = { ...totalPercentage };

    Object.keys(totalPercentage).map(i => {
      if (i !== 0 && grafico[i] === 0) {
        grafico[i - 1] > 0 ? (grafico[i] = grafico[i - 1]) : (grafico[i] = 0);

        if (i < Number(currentColumn)) {
          totalPercentage[i - 1] > 0
            ? (totalPercentage[i] = totalPercentage[i - 1])
            : (totalPercentage[i] = 0);
        }
      }
    });

    let currentDay = "";
    let xLabels = [];
    let fechas = [];
    data &&
      data[0] &&
      Object.keys(data[0]).some(item => {
        if (data[0][item].hasOwnProperty("date")) {
          if (data[0][item].date === moment().format("DD-MM-YYYY")) {
            currentDay = moment().format("DD-MM-YYYY");
            return true;
          } else if (item === "7") {
            currentDay = data[0][item].date;
            return true;
          }
        }
      });

    data &&
      data[0] &&
      Object.keys(data[0]).map((item, i) => {
        if (typeof data[0][item] === "object") {
          if (i !== 0) {
            xLabels.push(
              `Día ${Number(item)} (${moment(
                data[0][item].date,
                "DD-MM-YYYY"
              ).format("DD/MM/YYYY")})`
            );
          }
        }
      });

    data &&
      data[0] &&
      Object.keys(data[0]).map((item, i) => {
        if (typeof data[0][item] === "object") {
          fechas.push(
            moment(data[0][item].date, "DD-MM-YYYY").format("DD/MM/YYYY")
          );
        }
      });

    let XLSXDashboardData = [
      [
        "Corr",
        "Prod",
        !isEmpty(intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ",")))
          ? "Cod. Cliente"
          : "Cuenta",
        "Nombre",
        !isEmpty(intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ",")))
          ? "Anexo"
          : "Telefono",
        "Chk",
        "GPS"
      ]
    ];

    courierPartsComplete.data.map((item, i) =>
      XLSXDashboardData.push([
        i + 1,
        item.pro_codigo,
        item.cli_codigo,
        isEmpty(item.cli_nombre) ? "--" : item.cli_nombre,
        !isEmpty(intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ",")))
          ? isEmpty(item.anexo)
            ? "--"
            : item.anexo
          : `${(isEmpty(item.cli_telefono1) ? "--" : item.cli_telefono1) /
              (isEmpty(item.cli_telefono2) ? "--" : item.cli_telefono2)}`,
        isEmpty(item.esto_codigo) ? item.chk_codigo : item.esto_codigo,
        !isEmpty(intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ",")))
          ? parseInt(item.latitud) === 0 || item.latitud.length === 0
            ? "NO"
            : `${item.latitud},${item.longitud}`
          : parseInt(item.latitud) === 0 || item.latitud.length === 0
          ? "NO"
          : "SI"
      ])
    );

    if (loading) return <LoadingScreen />;

    return (
      <StyledDashboard className="section-container uex-scrollbar">
        {loadingModal && <LoadingScreen isRequest />}
        {error === 404 ? (
          <div className="w-100">
            <GoBackPage onClick={() => history.push("/")} />
            <HeaderPage
              title={`Tiempos en Tránsito: ${cicle} ${
                isEmpty(shipperCicle) ? "" : `- ${shipperCicle}`
              }`}
            />
            <NotFound
              containerClass="emptyState"
              title="Sin registros"
              subtitle="Actualmente no existen registros para su búsqueda"
            />
          </div>
        ) : (
          <div className="w-100">
            <React.Fragment>
              <GoBackPage onClick={() => history.goBack()} />
              <HeaderPage
                title={`Tiempos en Tránsito: ${cicle} ${
                  isEmpty(shipperCicle) ? "" : `- ${shipperCicle}`
                }`}
                content={
                  <div className="column is-flex align-items-center justify-content-end">
                    <DownloadButton
                      data={XLSXData}
                      fileName={`Tiempos_de_transito_${cicle}`}
                    />
                    {product === "all" && (
                      <SelectAutocomplete
                        onChange={this.handleChangeAutocomplete}
                        containerClass="input-form filters-input"
                        options={productsOptions}
                        selectedOption={selectedOption}
                      />
                    )}
                    <DateButton
                      text={`Tiempo de ciclo: ${moment(
                        cicle,
                        "DD-MM-YYYY"
                      ).format("D MMM YYYY")}`}
                    />
                  </div>
                }
              />
              <TransitChart
                total={total}
                totalPercentage={grafico}
                xLabels={xLabels}
              />
              <div className="column is-12 is-paddingless has-background-white"></div>
              <TableTransit
                tableHead={[
                  { value: "Provincia" },
                  { value: "Cant" },
                  // { value: "0", tooltip: fechas[0] ? fechas[0] : "" },
                  { value: "1", tooltip: fechas[1] ? fechas[1] : "" },
                  { value: "2", tooltip: fechas[2] ? fechas[2] : "" },
                  { value: "3", tooltip: fechas[3] ? fechas[3] : "" },
                  { value: "4", tooltip: fechas[4] ? fechas[4] : "" },
                  { value: "5", tooltip: fechas[5] ? fechas[5] : "" },
                  { value: "6", tooltip: fechas[6] ? fechas[6] : "" },
                  { value: "+7", tooltip: fechas[7] ? fechas[7] : "" },
                  { value: "DV" },
                  { value: "Stock" }
                ]}
                tableBody={
                  data.map(item => [
                    { value: item.prov },
                    {
                      value: numeral(Number(item.recibido)).format("0,0"),
                      onClick: () =>
                        this.showModalDetails(
                          {
                            pro_codigo: selectedOption.value,
                            cic_inicio: cicle,
                            prov_codigo: item.prov_cod
                          },
                          "Total",
                          true
                        )
                    },
                    // {
                    //   value: `${item["0"].porc}%`,
                    //   active: item["0"].date === currentDay,
                    //   cant: item["0"].data
                    //   // complete: stopPercentage === 0
                    // },
                    {
                      value: `${item["1"].porc}%`,
                      active: item["1"].date === currentDay,
                      cant: item["1"].data + item["0"].data,
                      complete: stopPercentage === 1, //|| parseInt(item["1"].porc) === 100
                      onClick: () =>
                        this.showModalDetails(
                          {
                            pro_codigo: selectedOption.value,
                            cic_inicio: cicle,
                            prov_codigo: item.prov_cod,
                            cic_dia: item["1"].date,
                            is_first_day: 1,
                            checkpoint_pieza: "DL"
                          },
                          `${item.prov} / Fecha: ${item["1"].date}`
                        )
                    },
                    {
                      value: `${item["2"].porc}%`,
                      active: item["2"].date === currentDay,
                      cant: item["2"].data,
                      complete: stopPercentage === 2, //|| parseInt(item["2"].porc) === 100
                      onClick: () => {
                        let cicleDay = moment(
                          item["2"].date,
                          "DD-MM-YYYY"
                        ).format();
                        let ciclePreviousDay = moment(
                          item["1"].date,
                          "DD-MM-YYYY"
                        ).format();
                        let previousDay = item["1"].date;
                        let dayDifferece = moment(cicleDay).diff(
                          ciclePreviousDay,
                          "days"
                        );

                        if (dayDifferece > 1) {
                          if (dayDifferece === 3) {
                            previousDay = moment(item["1"].date, "DD-MM-YYYY")
                              .add(1, "d")
                              .format("DD-MM-YYYY");
                          }
                        }
                        return this.showModalDetails(
                          {
                            pro_codigo: selectedOption.value,
                            cic_inicio: cicle,
                            prov_codigo: item.prov_cod,
                            cic_dia: item["2"].date,
                            ...(dayDifferece > 1 && {
                              cic_dia_anterior: previousDay
                            }),
                            checkpoint_pieza: "DL"
                          },
                          `${item.prov} / Fecha: ${item["2"].date}`
                        );
                      }
                    },
                    {
                      value: `${item["3"].porc}%`,
                      alert:
                        parseFloat(item["3"].porc) < 20 && stopPercentage >= 3,
                      active: item["3"].date === currentDay,
                      cant: item["3"].data,
                      complete: stopPercentage === 3, //|| parseInt(item["3"].porc) === 100
                      onClick: () => {
                        let cicleDay = moment(
                          item["3"].date,
                          "DD-MM-YYYY"
                        ).format();
                        let ciclePreviousDay = moment(
                          item["2"].date,
                          "DD-MM-YYYY"
                        ).format();
                        let previousDay = item["2"].date;
                        let dayDifferece = moment(cicleDay).diff(
                          ciclePreviousDay,
                          "days"
                        );

                        if (dayDifferece > 1) {
                          if (dayDifferece === 3) {
                            previousDay = moment(item["2"].date, "DD-MM-YYYY")
                              .add(1, "d")
                              .format("DD-MM-YYYY");
                          }
                        }
                        return this.showModalDetails(
                          {
                            pro_codigo: selectedOption.value,
                            cic_inicio: cicle,
                            prov_codigo: item.prov_cod,
                            cic_dia: item["3"].date,
                            ...(dayDifferece > 1 && {
                              cic_dia_anterior: previousDay
                            }),
                            checkpoint_pieza: "DL"
                          },
                          `${item.prov} / Fecha: ${item["3"].date}`
                        );
                      }
                    },
                    {
                      value: `${item["4"].porc}%`,
                      alert:
                        parseFloat(item["4"].porc) < 20 && stopPercentage >= 4,
                      active: item["4"].date === currentDay,
                      cant: item["4"].data,
                      complete: stopPercentage === 4, //|| parseInt(item["4"].porc) === 100
                      onClick: () => {
                        let cicleDay = moment(
                          item["4"].date,
                          "DD-MM-YYYY"
                        ).format();
                        let ciclePreviousDay = moment(
                          item["3"].date,
                          "DD-MM-YYYY"
                        ).format();
                        let previousDay = item["3"].date;
                        let dayDifferece = moment(cicleDay).diff(
                          ciclePreviousDay,
                          "days"
                        );

                        if (dayDifferece > 1) {
                          if (dayDifferece === 3) {
                            previousDay = moment(item["3"].date, "DD-MM-YYYY")
                              .add(1, "d")
                              .format("DD-MM-YYYY");
                          }
                        }
                        return this.showModalDetails(
                          {
                            pro_codigo: selectedOption.value,
                            cic_inicio: cicle,
                            prov_codigo: item.prov_cod,
                            cic_dia: item["4"].date,
                            ...(dayDifferece > 1 && {
                              cic_dia_anterior: previousDay
                            }),
                            checkpoint_pieza: "DL"
                          },
                          `${item.prov} / Fecha: ${item["4"].date}`
                        );
                      }
                    },
                    {
                      value: `${item["5"].porc}%`,
                      alert:
                        parseFloat(item["5"].porc) < 20 && stopPercentage >= 5,
                      active: item["5"].date === currentDay,
                      cant: item["5"].data,
                      complete: stopPercentage === 5, //|| parseInt(item["5"].porc) === 100
                      onClick: () => {
                        let cicleDay = moment(
                          item["5"].date,
                          "DD-MM-YYYY"
                        ).format();
                        let ciclePreviousDay = moment(
                          item["4"].date,
                          "DD-MM-YYYY"
                        ).format();
                        let previousDay = item["4"].date;
                        let dayDifferece = moment(cicleDay).diff(
                          ciclePreviousDay,
                          "days"
                        );

                        if (dayDifferece > 1) {
                          if (dayDifferece === 3) {
                            previousDay = moment(item["4"].date, "DD-MM-YYYY")
                              .add(1, "d")
                              .format("DD-MM-YYYY");
                          }
                        }
                        return this.showModalDetails(
                          {
                            pro_codigo: selectedOption.value,
                            cic_inicio: cicle,
                            prov_codigo: item.prov_cod,
                            cic_dia: item["5"].date,
                            ...(dayDifferece > 1 && {
                              cic_dia_anterior: previousDay
                            }),
                            checkpoint_pieza: "DL"
                          },
                          `${item.prov} / Fecha: ${item["5"].date}`
                        );
                      }
                    },
                    {
                      value: `${item["6"].porc}%`,
                      alert:
                        parseFloat(item["6"].porc) < 20 && stopPercentage >= 6,
                      active: item["6"].date === currentDay,
                      cant: item["6"].data,
                      complete: stopPercentage === 6, //|| parseInt(item["6"].porc) === 100
                      onClick: () => {
                        let cicleDay = moment(
                          item["6"].date,
                          "DD-MM-YYYY"
                        ).format();
                        let ciclePreviousDay = moment(
                          item["5"].date,
                          "DD-MM-YYYY"
                        ).format();
                        let previousDay = item["5"].date;
                        let dayDifferece = moment(cicleDay).diff(
                          ciclePreviousDay,
                          "days"
                        );

                        if (dayDifferece > 1) {
                          if (dayDifferece === 3) {
                            previousDay = moment(item["5"].date, "DD-MM-YYYY")
                              .add(1, "d")
                              .format("DD-MM-YYYY");
                          }
                        }
                        return this.showModalDetails(
                          {
                            pro_codigo: selectedOption.value,
                            cic_inicio: cicle,
                            prov_codigo: item.prov_cod,
                            cic_dia: item["6"].date,
                            ...(dayDifferece > 1 && {
                              cic_dia_anterior: previousDay
                            }),
                            checkpoint_pieza: "DL"
                          },
                          `${item.prov} / Fecha: ${item["6"].date}`
                        );
                      }
                    },
                    {
                      value: `${item["7"].porc}%`,
                      alert:
                        parseFloat(item["7"].porc) < 20 && stopPercentage >= 7,
                      active:
                        item["7"].date === currentDay && stopPercentage === 0,
                      cant: item["7"].data,
                      complete: stopPercentage === 7, //|| parseInt(item["7"].porc) === 100
                      onClick: () => {
                        let cicleDay = moment(
                          item["7"].date,
                          "DD-MM-YYYY"
                        ).format();
                        let ciclePreviousDay = moment(
                          item["6"].date,
                          "DD-MM-YYYY"
                        ).format();
                        let previousDay = item["6"].date;
                        let dayDifferece = moment(cicleDay).diff(
                          ciclePreviousDay,
                          "days"
                        );

                        if (dayDifferece > 1) {
                          if (dayDifferece === 3) {
                            previousDay = moment(item["6"].date, "DD-MM-YYYY")
                              .add(1, "d")
                              .format("DD-MM-YYYY");
                          }
                        }
                        return this.showModalDetails(
                          {
                            pro_codigo: selectedOption.value,
                            cic_inicio: cicle,
                            prov_codigo: item.prov_cod,
                            cic_dia: item["7"].date,
                            ...(dayDifferece > 1 && {
                              cic_dia_anterior: previousDay
                            }),
                            checkpoint_pieza: "DL"
                          },
                          `${item.prov} / Fecha: ${item["7"].date}`
                        );
                      }
                    },
                    {
                      value: numeral(Number(item.dv)).format("0,0"),
                      onClick: () =>
                        this.showModalDetails(
                          {
                            pro_codigo: selectedOption.value,
                            cic_inicio: cicle,
                            prov_codigo: item.prov_cod,
                            checkpoint: "DV"
                          },
                          "Devoluciones"
                        )
                    },
                    {
                      value: item.stock,
                      onClick: () =>
                        this.showModalDetails(
                          {
                            pro_codigo: selectedOption.value,
                            cic_inicio: cicle,
                            prov_codigo: item.prov_cod,
                            estado: "%"
                          },
                          "Stock"
                        )
                    }
                  ]) || []
                }
              />
              <div className="column is-12 is-paddingless is-flex flex-wrap footerTable w-margin">
                <div className="column is-2 has-text-centered">
                  <p className="bold">TOTAL</p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">
                    {numeral(Number(total.recibido)).format("0,0")}
                  </p>
                </div>
                {/* <div className="column has-text-centered">
                <p className="bold">
                  {numeral(Number(total["0"])).format("0,0")}
                </p>
              </div> */}
                <div className="column has-text-centered">
                  <p className="bold">
                    {numeral(Number(total["1"]) + Number(total["0"])).format(
                      "0,0"
                    )}
                    {/* {numeral(Number(total["0"]) + Number(total["1"])).format("0,0")} */}
                  </p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">
                    {numeral(Number(total["2"])).format("0,0")}
                  </p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">
                    {numeral(Number(total["3"])).format("0,0")}
                  </p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">
                    {numeral(Number(total["4"])).format("0,0")}
                  </p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">
                    {numeral(Number(total["5"])).format("0,0")}
                  </p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">
                    {numeral(Number(total["6"])).format("0,0")}
                  </p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">
                    {numeral(Number(total["7"])).format("0,0")}
                  </p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">
                    {numeral(Number(total.dv)).format("0,0")}
                  </p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">
                    {numeral(Number(total.stock)).format("0,0")}
                  </p>
                </div>
              </div>
              <div className="column is-12 is-paddingless is-flex flex-wrap footerTable w-margin">
                <div className="column is-2 has-text-centered">
                  <p className="bold">TOTAL DIARIO%</p>
                </div>
                <div className="column has-text-centered"></div>
                {/* <div className="column has-text-centered">
                <p className="bold">{totalPercentage["0"].toFixed(2)}%</p>
              </div> */}
                <div className="column has-text-centered">
                  <p className="bold">
                    {(dailyPercentage["0"] + dailyPercentage["1"]).toFixed(2)}%
                  </p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">{dailyPercentage["2"].toFixed(2)}%</p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">{dailyPercentage["3"].toFixed(2)}%</p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">{dailyPercentage["4"].toFixed(2)}%</p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">{dailyPercentage["5"].toFixed(2)}%</p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">{dailyPercentage["6"].toFixed(2)}%</p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">{dailyPercentage["7"].toFixed(2)}%</p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">{dailyPercentage.dv.toFixed(2)}%</p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">{dailyPercentage.stock.toFixed(2)}%</p>
                </div>
              </div>
              <div className="column is-12 is-paddingless is-flex flex-wrap footerTable w-margin">
                <div className="column is-2 has-text-centered">
                  <p className="bold">TOTAL%</p>
                </div>
                <div className="column has-text-centered"></div>
                {/* <div className="column has-text-centered">
                <p className="bold">{totalPercentage["0"].toFixed(2)}%</p>
              </div> */}
                <div className="column has-text-centered">
                  <p className="bold">{totalPercentage["1"].toFixed(2)}%</p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">{totalPercentage["2"].toFixed(2)}%</p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">{totalPercentage["3"].toFixed(2)}%</p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">{totalPercentage["4"].toFixed(2)}%</p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">{totalPercentage["5"].toFixed(2)}%</p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">{totalPercentage["6"].toFixed(2)}%</p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">{totalPercentage["7"].toFixed(2)}%</p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">{totalPercentage.dv.toFixed(2)}%</p>
                </div>
                <div className="column has-text-centered">
                  <p className="bold">{totalPercentage.stock.toFixed(2)}%</p>
                </div>
              </div>
              <ModalDetail
                active={modalDetails}
                onCloseModal={() => this.handleModalDetails(false)}
                detailTitle={detailTitle}
                initialValues={this.state}
                onSubmit={this.findUser}
                onChangeInput={this.handleChange}
                onChangeAutocomplete={this.handleChangeCheckpoint}
                autocompleteOptions={parseCheckpoints}
                autocompleteSelectedOption={selectedCheckpoint}
                hasLocation={location}
                onChangeToggle={() => this.onChangeToggle()}
                onCleanFilters={() => this.cleanFilters()}
                onChangePagination={this.onChangePagination}
                showCheckpointSelect={showCheckpointSelect}
                xlsxData={XLSXDashboardData}
              />
            </React.Fragment>
          </div>
        )}
      </StyledDashboard>
    );
  }
}
const mapStateToProps = state => ({
  user: userSelector(state),
  transitTimes: transitTimesSelector(state),
  transitTimesProducts: transitTimesProductsSelector(state),
  token: tokenSelector(state),
  courierParts: courierPartsSelector(state),
  courierPartsComplete: courierPartsCompleteSelector(state),
  checkpoints: checkpointsSelector(state)
});
export default connect(mapStateToProps)(Transit);
