import React, { Component } from "react";
import Highcharts from "highcharts/highstock";
import { connect } from "react-redux";
// import applyExporting from "highcharts/modules/exporting";

import * as theme from "../../utils/colors";

//Selectors
import { userSelector } from "../../actions/auth/selectors";

import {
  HighchartsChart,
  Chart,
  withHighcharts,
  XAxis,
  YAxis,
  Legend,
  ColumnSeries,
  SplineSeries,
  PieSeries,
  Tooltip,
  Navigator
} from "react-jsx-highstock";

class CombinedChart extends Component {
  render() {
    const { data, user } = this.props;
    const color = {
      totalReceived: user.theme
        ? theme[user.theme.toUpperCase()].primary
        : "#DF9C9F",
      onRoute: user.theme
        ? theme[user.theme.toUpperCase()].secondary
        : // : "#B57073",
          "rgba(36, 39, 42, 0.8)",
      deliveries: user.theme
        ? theme[user.theme.toUpperCase()].tertiary
        : "#CF333C",
      returns: user.theme
        ? theme[user.theme.toUpperCase()].quaternary
        : "#990202",
      effectiveDeliveries: user.theme
        ? theme[user.theme.toUpperCase()].primary
        : "#24272a"
    };

    const plotOptions = {
      scrollbar: {
        enabled: true
      },
      series: {
        dataLabels: {
          // stacking: "overlap"
          // enabled: false
        },
        pointWidth: 20,
        // pointRange: 0.5
        ...(data.length <= 2 && {
          pointRange: 0.15
        }),
        ...(data.length <= 5 && {
          pointRange: 0.5
        })
      }
    };

    const navigatorOptions = {
      maskFill: user.theme
        ? theme[user.theme.toUpperCase()].opacity
        : "rgba(223, 156, 159, 0.6)",
      outlineColor: user.theme
        ? theme[user.theme.toUpperCase()].primary
        : "#80060E",
      outlineWidth: 1,
      handles: {
        backgroundColor: "#FFFFFF",
        borderColor: user.theme
          ? theme[user.theme.toUpperCase()].primary
          : "#80060E"
      },
      xAxis: {
        labels: {
          enabled: false
        }
      },
      scrollbar: {
        enabled: true
      }
    };

    const XAxisOptions = {
      ...(data.length > 8 && {
        min: 0,
        max: window.innerWidth > 1408 ? 7 : 5
      })
    };

    let categories = [];
    let totalReceivedData = [];
    let onRouteData = [];
    let deliveriesData = [];
    let returnsData = [];
    let lineData = [];

    for (let item of data) {
      categories.push(item.ciclo);
      totalReceivedData.push(parseFloat(item.ar));
      onRouteData.push(parseFloat(item.ld));
      deliveriesData.push(parseFloat(item.dl));
      returnsData.push(parseFloat(item.dv));
      lineData.push(
        (parseFloat(item.ar) +
          parseFloat(item.ld) +
          parseFloat(item.dl) +
          parseFloat(item.dv)) /
          4
      );
    }

    const pieData = [
      {
        name: "Total Recibido",
        y: totalReceivedData.reduce((a, b) => a + b, 0),
        color: color.totalReceived
      },
      {
        name: "En Ruta",
        y: onRouteData.reduce((a, b) => a + b, 0),
        color: color.onRoute
      },
      {
        name: "Entregas",
        y: deliveriesData.reduce((a, b) => a + b, 0),
        color: color.deliveries
      },
      {
        name: "Devoluciones",
        y: returnsData.reduce((a, b) => a + b, 0),
        color: color.returns
      }
    ];

    return (
      <div className="app">
        <HighchartsChart plotOptions={plotOptions}>
          <Chart zoomType="x" height={450} />

          {/* <Title>Combination chart</Title> */}

          <Legend />

          <XAxis categories={categories} {...XAxisOptions} />

          <YAxis id="left">
            <ColumnSeries
              color={color.totalReceived}
              name="Total Recibido"
              data={totalReceivedData}
              id="totalReceived"
            />
            <ColumnSeries
              color={color.onRoute}
              name="En Ruta"
              data={onRouteData}
              id="onRoute"
            />
            <ColumnSeries
              grouping={false}
              color={color.deliveries}
              name="Entregas"
              data={deliveriesData}
              id="deliveries"
            />
            <ColumnSeries
              color={color.returns}
              name="Devoluciones"
              data={returnsData}
              id="returns"
            />
            <PieSeries
              name="Total"
              data={pieData}
              center={["95%", "10%"]}
              size={60}
              showInLegend={false}
            />
            <Tooltip />
          </YAxis>
          <YAxis
            id="right"
            opposite
            min={0}
            max={100}
            tickInterval={20}
            labels={{
              formatter: function() {
                return this.value + "%";
              }
            }}
          >
            <YAxis.Title>Porcentage entrega efectiva</YAxis.Title>
            <SplineSeries
              color={color.effectiveDeliveries}
              name="Entrega efectiva"
              data={data.map(item =>
                parseFloat(((item.dl * 100) / item.ar).toFixed(2))
              )}
              tooltip={{
                valueSuffix: " %"
              }}
            />
          </YAxis>
          {/* {scrollbarEnable && <Scrollbar {...scrollbarStyles} />} */}
          <Navigator {...navigatorOptions}>
            <Navigator.Series seriesId="totalReceived" />
            <Navigator.Series seriesId="onRoute" />
            <Navigator.Series seriesId="deliveries" />
            <Navigator.Series seriesId="returns" />
            {/* <Navigator.Series /> */}
          </Navigator>
          {/* <Scrollbar {...scrollbarNavigatorStyles} /> */}
        </HighchartsChart>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: userSelector(state)
});
// applyExporting(Highcharts);
export default connect(mapStateToProps)(
  withHighcharts(CombinedChart, Highcharts)
);
