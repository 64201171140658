import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import StyledClients from "./styled";
import { getParamsRequest } from "../../utils/paramsRequest";
import {
  TIGO_SHIPPER_CODES,
  CLARO_SHIPPER_CODES,
} from "../../utils/shipperPermissions";
import { MdLocationOn } from "react-icons/md";
import * as theme from "../../utils/colors";
import queryString from "query-string";
import { get, head, find, isEmpty, intersection, split, concat } from "lodash";

// custom
import Input from "../../components/Input";
import Button from "../../components/button";
import TableTracking from "../../components/table-tracking";
import SeacrhInputTable from "../../components/search-input-table";
import SortButton from "../../components/sort-button";
import TrackingTab from "./TrackingTab";
import ClaimsTab from "./ClaimsTab";
import CommentsTab from "./CommentsTab";
import ContractTab from "./ContractsTab";
import PhotoTab from "./PhotosTab";
import InputAutocomplete from "../../components/input-autocomplete";
import LoadingScreen from "../../components/loading_screen";
import NotFound from "../../components/NotFound";

// actions
import {
  fetchCustomerQuery,
  fetchCustomerName,
  fetchCustomerTracking,
  cleanTracking,
  cleanCustomerQuery,
  addClaim,
  insertComment,
  fetchClaimsTypes,
} from "../../actions/postal";

// selectors
import {
  customerQuerySelector,
  customerNameSelector,
  customerTrackingSelector,
} from "../../actions/postal/selectors";
import { tokenSelector, userSelector } from "../../actions/auth/selectors";

// validators
import { validateFindUserForm } from "./validators/validateFindUserForm";

class Clients extends Component {
  state = {
    loadingRequest: false,
    clientCode: "",
    anexo: "",
    clientName: "",
    tel: "",
    hasND: !isEmpty(
      intersection(CLARO_SHIPPER_CODES, split(this.props.user.shi_codigos, ","))
    ),
    searchText: "",
    activeTab: 1,
    selectedOption: null,
    notFoundData: false,
    TableTrackingBody: [],
    selectedGuide: null,
    successClaimMessage: false,
    errorClaimMessage: false,
  };

  async componentDidMount() {
    const { token, user } = this.props;
    let params = queryString.parse(this.props.location.search);

    if (Object.keys(params).length > 0) {
      let data = getParamsRequest({
        token,
        shi_codigo: get(user, "shi_codigo", null),
        ...(params.account && {
          cli_codigo: params.account,
        }),
        ...(params.reference && {
          referencia: params.reference,
        }),
      });
      await this.getCustomerQuery(data);
      // this.ifExist();
    }
  }

  // ifExist = async () => {
  //   let url = `https://www.urbano.com.sv/jpgs2/0303141600SON66.jpg`;
  //   const exists = await urlExists(url);
  //   console.log("Clients -> ifExist -> exists", exists);
  // };

  getClaimsTypes = async (data) => {
    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        fetchClaimsTypes(data, (error, data) => {
          if (error) {
            return reject(error);
          }
          return resolve();
        })
      );
    });
  };

  getCustomerQuery = async (data) => {
    this.setState({ notFoundData: false, selectedGuide: null });
    const { dispatch } = this.props;
    dispatch(cleanCustomerQuery());
    dispatch(cleanTracking());

    await new Promise((resolve, reject) => {
      dispatch(
        fetchCustomerQuery(data, (error, data) => {
          if (error) {
            this.setState({ notFoundData: true });
          } else {
            this.setState({
              TableTrackingBody: data,
              selectedGuide: head(data),
            });
          }
          resolve();
        })
      );
    });
  };

  getCustomerName = async (data) => {
    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        fetchCustomerName(data, (error, data) => {
          if (error) {
          }
          resolve();
        })
      );
    });
  };

  getCustomerTracking = async (data) => {
    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        fetchCustomerTracking(data, (error, data) => {
          if (error) {
          }
          resolve();
        })
      );
    });
  };

  addClaimToGuide = async (data) => {
    this.setState({ loadingRequest: true });
    const { dispatch, token, user } = this.props;
    const { selectedGuide } = this.state;

    await new Promise((resolve, reject) => {
      dispatch(
        addClaim(data, (error, data) => {
          this.setState({ loadingRequest: false });
          if (error) {
            this.setState({ errorClaimMessage: true }, () => {
              setTimeout(() => {
                this.setState({ errorClaimMessage: false });
              }, 2000);
            });
          } else {
            this.setState({ successClaimMessage: true }, () => {
              setTimeout(() => {
                this.setState({ successClaimMessage: false });
              }, 2000);
            });
          }

          let formData = getParamsRequest({
            token,
            shi_codigo: get(user, "shi_codigo", null),
            gui_numero: get(selectedGuide, "gui_numero", ""),
          });
          this.getCustomerTracking(formData);
          resolve();
        })
      );
    });
  };

  sortTable = () => {
    let { TableTrackingBody } = this.state;
    TableTrackingBody.reverse();
    this.setState({ TableTrackingBody });
  };

  setActiveTab = (activeTab) => {
    this.setState({ activeTab });
  };

  onKeyDown = async (e) => {
    const { token } = this.props;
    let data = getParamsRequest({
      token,
      nombre: e.target.value.toUpperCase(),
    });
    await this.getCustomerName(data);
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeAutocomplete = (selectedOption) => {
    this.setState({ selectedOption, clientName: selectedOption.value });
  };

  findUser = async () => {
    const {
      props: { token, user },
      state: { clientCode, clientName, anexo, tel },
    } = this;
    let data = getParamsRequest({
      token,
      shi_codigo: get(user, "shi_codigo", null),
      ...(clientCode.length > 0 && {
        cli_codigo: clientCode,
      }),
      ...(clientName.length > 0 && {
        nombre: clientName.trim(),
      }),
      ...(anexo.length > 0 && {
        referencia: anexo,
      }),
      ...(tel.length > 0 && {
        tel,
      }),
    });
    await this.getCustomerQuery(data);
    this.setState({
      // clientCode: "",
      clientName: "",
      // anexo: "",
      selectedOption: null,
    });
  };

  getUserTracking = async (info) => {
    const { token, user, customerQuery } = this.props;
    let data = getParamsRequest({
      token,
      shi_codigo: get(user, "shi_codigo", null),
      gui_numero: info.gui_numero,
    });
    let claimsData = getParamsRequest({
      shi_codigo: get(info, "shi_codigo", null),
    });

    await this.getCustomerTracking(data);
    await this.getClaimsTypes(claimsData);

    let selectedGuide = find(get(customerQuery, "data", []), [
      "gui_numero",
      info.gui_numero,
    ]);

    this.setState({ activeTab: 1, selectedGuide });
  };

  sendClaim = async (values) => {
    const { token, user } = this.props;
    let data = getParamsRequest({
      token,
      shi_codigo: get(user, "shi_codigo", null),
      gui_numero: values.gui_numero,
      motivo: values.type.toUpperCase(),
      comentario: values.observations,
    });

    await this.addClaimToGuide(data);
  };

  insertComment = async (data) => {
    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        insertComment(data, (error, data) => {
          if (error) {
            return reject(error);
          }
          return resolve();
        })
      );
    });
  };

  render() {
    const {
      hasND,
      loadingRequest,
      searchText,
      activeTab,
      selectedOption,
      notFoundData,
      TableTrackingBody,
      selectedGuide,
      successClaimMessage,
      errorClaimMessage,
    } = this.state;
    const { customerQuery, customerName, customerTracking, user } = this.props;
    const { data, loading } = customerQuery;

    let tableHead = [
      "",
      "Código",
      ...(!isEmpty(
        intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ","))
      )
        ? ["Ciclo Shipper", "Cliente", "Anexo"]
        : []),
      "Producto",
      "Ciclo",
    ];

    let tableBody = TableTrackingBody.map((item) => [
      <MdLocationOn
        color={
          parseInt(item.latitud) === 0
            ? "gray"
            : user.theme
            ? theme[user.theme.toUpperCase()].primary
            : "#DF9C9F"
        }
      />,
      item.gui_numero,
      ...(!isEmpty(
        intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ","))
      )
        ? [
            get(item, "ciclo_shipper", "--"),
            get(item, "cliente_shipper", "--").replace(/^0+/, ""),
            get(item, "anexo", "--"),
          ]
        : []),
      isEmpty(item.shipper_prod)
        ? item.pro_codigo
        : `${item.shipper_prod}-${item.pro_codigo}`,
      item.ciclo,
      () => this.getUserTracking(item),
    ]);

    let itemToRender = tableBody.filter((item) => {
      let title = item[1];
      let response = title.match(new RegExp(searchText.toUpperCase(), "g"));
      return response;
    });

    // if (loading) return <LoadingScreen isRequest />;

    return (
      <StyledClients className="column is-12 h-100 is-paddingless">
        {(loading || loadingRequest) && <LoadingScreen isRequest />}
        <Formik
          initialValues={this.state}
          validationSchema={validateFindUserForm}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            this.findUser();
            setSubmitting(false);
          }}
        >
          {(props) => {
            const { errors, values, handleSubmit } = props;
            return (
              <form
                onSubmit={handleSubmit}
                className="column is-12 has-background-white form-container is-flex flex-wrap"
              >
                <div className="column is-12 has-background-white form-header is-flex flex-wrap align-items-center p-0">
                  <div className="column is-9 is-flex flex-wrap align-items-center justify-content-end p-0">
                    <div className="column is-3 is-flex align-items-center is-paddingless form-header-input">
                      <Input
                        containerClass="column is-12 p-0"
                        label="Codigo cliente"
                        placeholder="Ej:000303393"
                        name="clientCode"
                        value={values.clientCode}
                        onChange={this.handleChange}
                        // errors={errors.clientCode}
                      />
                    </div>
                    {!isEmpty(
                      intersection(
                        CLARO_SHIPPER_CODES,
                        split(user.shi_codigos, ",")
                      )
                    ) && (
                      <div className="column is-3 is-flex align-items-center is-paddingless form-header-input">
                        <Input
                          containerClass="column is-12 p-0"
                          label="Factura"
                          placeholder="Número de factura"
                          name="anexo"
                          value={values.anexo}
                          onChange={this.handleChange}
                          // errors={errors.anexo}
                        />
                      </div>
                    )}
                    {isEmpty(
                      intersection(
                        concat(TIGO_SHIPPER_CODES, CLARO_SHIPPER_CODES),
                        split(user.shi_codigos, ",")
                      )
                    ) && (
                      <div className="column is-3 is-flex align-items-center is-paddingless form-header-input">
                        <Input
                          containerClass="column is-12 p-0"
                          label="Ventana"
                          placeholder="Número de ventana"
                          name="anexo"
                          value={values.anexo}
                          onChange={this.handleChange}
                          // errors={errors.anexo}
                        />
                      </div>
                    )}
                    <div className="column is-3 is-flex align-items-center is-paddingless form-header-input">
                      {isEmpty(
                        intersection(
                          CLARO_SHIPPER_CODES,
                          split(user.shi_codigos, ",")
                        )
                      ) && (
                        <InputAutocomplete
                          label="Nombre Cliente"
                          onKeyDown={this.onKeyDown}
                          onChange={this.handleChangeAutocomplete}
                          options={customerName.data.map((item) => ({
                            label: item,
                            value: item,
                          }))}
                          selectedOption={selectedOption}
                        />
                      )}
                      {hasND && (
                        <Input
                          containerClass="column is-12 p-0"
                          label="ND"
                          placeholder="Ej: 2222-2222"
                          name="tel"
                          value={values.tel}
                          onChange={this.handleChange}
                          // errors={errors.anexo}
                        />
                      )}
                    </div>
                  </div>
                  <div className="column is-3 is-flex flex-wrap align-items-center p-0 is-relative">
                    <Button
                      containerClass="form-header-button"
                      text="consultar"
                    />
                    <p className="help is-danger error error-form">
                      {errors.userValidation}
                    </p>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
        {data.length > 0 && (
          <section className="tracking-section">
            <div className="tracking-section-container h-100 column is-12 is-flex flex-wrap has-background-white">
              <div className="column is-6-desktop is-6-fullhd py-0 h-100 tracking-info-content">
                <h3 className="bold tracking-info-title">
                  1. Detalle de Cliente
                </h3>
                <div className="column is-12 p-0 is-flex flex-wrap tracking-info-details">
                  <div className="w-100 is-flex flex-wrap">
                    <p className="bold has-text-grey-light column is-2 p-0">
                      Barra
                    </p>
                    <p className="bold column is-10 p-0">
                      {!isEmpty(
                        intersection(
                          TIGO_SHIPPER_CODES,
                          split(user.shi_codigos, ",")
                        )
                      )
                        ? get(selectedGuide, "barra", "--").replace(/^0+/, "")
                        : get(selectedGuide, "barra", "--")}
                    </p>
                  </div>
                  <div className="w-100 is-flex flex-wrap">
                    <p className="bold has-text-grey-light column is-2 p-0">
                      Codigo cliente
                    </p>
                    <p className="bold column is-10 p-0">
                      {!isEmpty(
                        intersection(
                          TIGO_SHIPPER_CODES,
                          split(user.shi_codigos, ",")
                        )
                      )
                        ? get(selectedGuide, "cliente", "--").replace(/^0+/, "")
                        : get(selectedGuide, "cliente", "--")}{" "}
                      | {data && data[0] ? data[0].nombre_cliente : ""}
                    </p>
                  </div>
                  <div className="w-100 is-flex flex-wrap">
                    <p className="bold has-text-grey-light column is-2 p-0">
                      {!isEmpty(
                        intersection(
                          TIGO_SHIPPER_CODES,
                          split(user.shi_codigos, ",")
                        )
                      )
                        ? "Anexo"
                        : "Ventana"}
                    </p>
                    <p className="bold column is-10 p-0">
                      {get(selectedGuide, "anexo", "--")}
                    </p>
                  </div>
                  <div className="w-100 is-flex flex-wrap">
                    <p className="bold has-text-grey-light column is-2 p-0">
                      Código Urbano
                    </p>
                    <p className="bold column is-10 p-0">
                      {!isEmpty(
                        intersection(
                          TIGO_SHIPPER_CODES,
                          split(user.shi_codigos, ",")
                        )
                      )
                        ? get(selectedGuide, "cli_codigo", "--").replace(
                            /^0+/,
                            ""
                          )
                        : get(selectedGuide, "cli_codigo", "--")}
                    </p>
                  </div>
                  <div className="w-100 is-flex flex-wrap">
                    <p className="bold has-text-grey-light column is-2 p-0">
                      Ciclo
                    </p>
                    <p className="bold column is-10 p-0">
                      {get(selectedGuide, "ciclo", "--")}
                    </p>
                  </div>
                  <div className="w-100 is-flex flex-wrap">
                    <p className="bold has-text-grey-light column is-2 p-0">
                      Doc Fiscal
                    </p>
                    <p className="bold column is-10 p-0">
                      {!isEmpty(
                        intersection(
                          TIGO_SHIPPER_CODES,
                          split(user.shi_codigos, ",")
                        )
                      )
                        ? get(selectedGuide, "list_cuentas", "--").replace(
                            /^0+/,
                            ""
                          )
                        : get(selectedGuide, "list_cuentas", "--")}
                    </p>
                  </div>
                  <div className="w-100 is-flex flex-wrap">
                    <p className="bold has-text-grey-light column is-2 p-0">
                      Tipo factura
                    </p>
                    <p className="bold column is-10 p-0">
                      {!isEmpty(
                        intersection(
                          TIGO_SHIPPER_CODES,
                          split(user.shi_codigos, ",")
                        )
                      )
                        ? get(selectedGuide, "tipo_factura", "--").replace(
                            /^0+/,
                            ""
                          )
                        : get(selectedGuide, "tipo_factura", "--")}
                    </p>
                  </div>
                  <div className="w-100 is-flex flex-wrap">
                    <p className="bold has-text-grey-light column is-2 p-0">
                      Dirección
                    </p>
                    <p className="bold column is-10 p-0">
                      {get(selectedGuide, "direccion", "--")}
                    </p>
                  </div>
                </div>
                <h3 className="bold tracking-info-title">2. Tracking </h3>
                <div className="column is-12 is-flex justify-content-between align-items-center px-0">
                  <p className="bold has-text-grey-light">
                    {itemToRender.length}
                  </p>
                  <div className="column is-10 p-0 is-flex justify-content-end">
                    <SeacrhInputTable
                      name="searchText"
                      value={searchText}
                      onChange={this.handleChange}
                    />
                    <SortButton
                      containerClass="py-0"
                      onClick={() => this.sortTable()}
                    />
                  </div>
                </div>
                <TableTracking tableHead={tableHead} tableBody={itemToRender} />
              </div>
              <div className="column is-6desktop is-6-fullhd py-0 h-100 tracking-info-content">
                <h3 className="bold tracking-info-title">3. Estado tracking</h3>
                {customerTracking.data.length > 0 && (
                  <div className="column is-12 tabs-container is-flex p-0 overflow-auto uex-scrollbar">
                    <div
                      onClick={() => this.setActiveTab(1)}
                      className="tab-content column is-one-fifth p-0"
                    >
                      <p
                        className={`bold has-text-black h-100 is-flex align-items-center justify-content-center ${
                          activeTab === 1 ? "active" : ""
                        }`}
                      >
                        Tracking
                      </p>
                    </div>
                    <div
                      onClick={() => this.setActiveTab(2)}
                      className="tab-content column is-one-fifth p-0"
                    >
                      <p
                        className={`bold has-text-black h-100 is-flex align-items-center justify-content-center ${
                          activeTab === 2 ? "active" : ""
                        }`}
                      >
                        Reclamos
                      </p>
                    </div>
                    <div
                      onClick={() => this.setActiveTab(3)}
                      className="tab-content column is-one-fifth p-0"
                    >
                      <p
                        className={`bold has-text-black h-100 is-flex align-items-center justify-content-center ${
                          activeTab === 3 ? "active" : ""
                        }`}
                      >
                        Comentarios
                      </p>
                    </div>
                    <div
                      onClick={() => this.setActiveTab(4)}
                      className="tab-content column is-one-fifth p-0"
                    >
                      <p
                        className={`bold has-text-black h-100 is-flex align-items-center justify-content-center ${
                          activeTab === 4 ? "active" : ""
                        }`}
                      >
                        Contratos
                      </p>
                    </div>
                    <div
                      onClick={() => this.setActiveTab(5)}
                      className="tab-content column is-one-fifth p-0"
                    >
                      <p
                        className={`bold has-text-black h-100 is-flex align-items-center justify-content-center ${
                          activeTab === 5 ? "active" : ""
                        }`}
                      >
                        Fotos
                      </p>
                    </div>
                  </div>
                )}
                {activeTab === 1 && !customerTracking.loading && (
                  <TrackingTab
                    data={customerTracking.data}
                    customerDirection={
                      data && data[0] ? data[0].direccion : "Sin dirección"
                    }
                  />
                )}
                {activeTab === 2 && !customerTracking.loading && (
                  <ClaimsTab
                    sendClaim={this.sendClaim}
                    data={customerTracking.data}
                    selectedGuide={get(selectedGuide, "gui_numero", "")}
                    successClaimMessage={successClaimMessage}
                    errorClaimMessage={errorClaimMessage}
                  />
                )}
                {activeTab === 3 && !customerTracking.loading && (
                  <CommentsTab
                    info={selectedGuide}
                    createComment={this.insertComment}
                  />
                )}
                {activeTab === 4 && !customerTracking.loading && (
                  <ContractTab
                    data={customerTracking.data}
                    selectedGuide={get(selectedGuide, "gui_numero", "")}
                    customerDirection={
                      data && data[0] ? data[0].direccion : "Sin dirección"
                    }
                  />
                )}
                {activeTab === 5 && !customerTracking.loading && (
                  <PhotoTab
                    data={customerTracking.data}
                    selectedGuide={get(selectedGuide, "gui_numero", "")}
                    customerDirection={
                      data && data[0] ? data[0].direccion : "Sin dirección"
                    }
                  />
                )}
                {customerTracking.loading && <LoadingScreen isRequest />}
              </div>
            </div>
          </section>
        )}
        {notFoundData && (
          <NotFound
            containerClass="tracking-section"
            title="Sin registros"
            subtitle="Actualmente no existen registros para su busqueda"
          />
        )}
      </StyledClients>
    );
  }
}
const mapStateToProps = (state) => ({
  customerQuery: customerQuerySelector(state),
  customerName: customerNameSelector(state),
  customerTracking: customerTrackingSelector(state),
  token: tokenSelector(state),
  user: userSelector(state),
});
export default connect(mapStateToProps)(Clients);
