import XLSX from "xlsx";
import { map } from "lodash";

export const getExcelWithTabsFile = (data = [], fileName = "File") => {
  //* convert state to workbook
  let wb = XLSX.utils.book_new();
  map(data, item => {
    let ws = XLSX.utils.aoa_to_sheet(item.data);
    XLSX.utils.book_append_sheet(wb, ws, item.bookName);
  });
  //* generate XLSX file and send to client
  return XLSX.writeFile(wb, `${fileName}.xlsx`);
};
