import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { isNil } from "lodash";
import StyledDropdownCalendar from "./styled";
import Icon from "../Icon";
import CalendarDateRange from "../date-range-calendar";
import moment from "moment";
import "moment/locale/es";
import * as theme from "../../utils/colors";

//Selectors
import { userSelector } from "../../actions/auth/selectors";

const DropdownCalendar = ({
  rangeName,
  containerClass,
  // text,
  selectionRange,
  onChange,
  // startDate,
  // endDate,
  user
}) => {
  let color = user.theme ? theme[user.theme.toUpperCase()].primary : "#CF333C";
  const node = useRef();
  const [open, setOpen] = useState(false);

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      // setOpen(true);
      return;
    }
    // outside click
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClick);
    } else {
      document.removeEventListener("mousedown", handleClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [open]);

  return (
    <StyledDropdownCalendar
      className={`dropdown is-right ${open ? "is-active" : ""} button-space ${
        containerClass ? containerClass : ""
      }`}
      ref={node}
    >
      <div className="dropdown-trigger">
        <button
          className="button dropdown-calendar-button"
          aria-haspopup="true"
          aria-controls="dropdown-menu16"
          onClick={e => setOpen(!open)}
        >
          <p>{`${rangeName}: ${
            isNil(selectionRange.startDate)
              ? ""
              : moment(selectionRange.startDate).format("MMM DD")
          } - ${
            isNil(selectionRange.endDate)
              ? ""
              : moment(selectionRange.endDate).format("MMM DD")
          }`}</p>
          <Icon
            icon="arrow"
            width={20}
            height={10}
            className="is-flex align-items-center justify-content-center c-pointer"
          />
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu16" role="menu">
        <div className="dropdown-content">
          <div className="column is-12 is-paddingless is-flex flex-wrap align-items-center justify-content-around">
            <div>
              <p>{rangeName} inicial</p>
              <p>
                {isNil(selectionRange.startDate)
                  ? "--"
                  : moment(selectionRange.startDate).format("L")}
              </p>
            </div>
            <div>
              <p>{rangeName} final</p>
              <p>
                {isNil(selectionRange.endDate)
                  ? "--"
                  : moment(selectionRange.endDate).format("L")}
              </p>
            </div>
            <Icon icon="calendar" width={20} height={19} color={color} />
          </div>
          <CalendarDateRange
            selectionRange={selectionRange}
            onChange={onChange}
          />
        </div>
      </div>
    </StyledDropdownCalendar>
  );
};

const mapStateToProps = state => ({
  user: userSelector(state)
});

export default connect(mapStateToProps)(DropdownCalendar);
