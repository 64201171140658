import styled from "styled-components";

const StyledTrackingTab = styled.div`
  height: calc(100% - 5rem);
  .map-container {
    height: 60%;
    margin-bottom: 1rem;
  }
  .picture-container {
    height: 35%;
    background: rgb(242, 242, 242);
    border-radius: 5px;
    position: relative;
    .zoom {
      position: absolute;
      width: 100%;
      height: 100%;
      &:hover {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }
`;

export default StyledTrackingTab;
