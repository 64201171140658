import styled from "styled-components";

const StyledNotFound = styled.div`
  height: 100%;

  & .hero-body {
    padding: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  & .error-title {
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 0.0025em;
    margin-top: 2.625rem;
    color: gray;
    text-align: center;
  }

  & .back-link {
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.0015em;
    color: black;
    margin-top: 1rem;
  }

  & .left-icon-container {
    position: absolute;
    top: 1.875rem;
    left: 2.5rem;
  }

  & .right-icon-container {
    position: absolute;
    right: 3.188rem;
    bottom: 1.875rem;
  }
`;

export default StyledNotFound;
