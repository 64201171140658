import React, { useState } from "react";
import { connect } from "react-redux";
import StyledTrackingTab from "./styled/trackingTab";
import moment from "moment";
import "moment/locale/es";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Magnifier from "react-magnifier";
import { isEqual, includes, get } from "lodash";

//custom
import TrackingItem from "../../components/tracking-item";
import MyMapComponent from "../../components/map";
import { trackingScope } from "../../utils/constants";

//selectors
import { userSelector } from "../../actions/auth/selectors";

const images = [
  "https://www.bienesonline.com/usa/photos/vendo-casa-en-el-salvador-ahuachapan-1436662578.jpg",
  "http://www.elsv.info/wp-content/uploads/2017/04/imagen-de-una-casa.jpg",
  "https://images.evisos.com.sv/2009/06/25/vendo-bonita-casa-en-el-salvador-sonsonate-izalco_d7a993fc6_3.jpg",
  "https://images.evisos.com.sv/2012/06/05/casa-en-venta-en-residencial-panameno-ii-sonsonate_da45fdf989_3.jpg",
];

const TrackingTab = ({ data = [], customerDirection, user }) => {
  moment.locale("es");
  const trackingFiles = data.find((item) => item.movimiento === "DL");
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isImageValid, setIsImageValid] = useState(true);

  let getData = isEqual(Number(user.nivel), 3)
    ? data.filter((item) => includes(trackingScope, item.movimiento))
    : data;

  return (
    <StyledTrackingTab className="column is-12 is-flex flex-wrap p-0">
      <div className="column is-5 h-100 overflow-auto px-0 uex-scrollbar">
        {getData.map((item, i) => (
          <TrackingItem
            key={i}
            extraInfo={item.mot_descripcion}
            noDotted={data.length === i + 1}
            completed={item.movimiento !== "RE" && item.movimiento !== "DV"}
            incomplete={item.movimiento === "RE" || item.movimiento === "DV"}
            title={item.movimiento_descripcion}
            subtitle={item.mov_recibio}
            place={item.prov_nombre}
            date={moment(item.aud_fecha_proc + " " + item.aud_hora_proc).format(
              "D MMMM YYYY, h:mm:ss"
            )}
            hasRiskZone={
              item.movimiento !== "RE" &&
              item.movimiento !== "DV" &&
              (isEqual(item.zona_riesgo, 1) || isEqual(item.zona_riesgo, "1"))
            }
          />
        ))}
      </div>
      <div className="column is-7 h-100 px-0">
        {data &&
          data[0] &&
          trackingFiles &&
          trackingFiles.hasOwnProperty("geo") && (
            <div className="map-container has-background-grey-light">
              <MyMapComponent
                isMarkerShown
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                image={images[1]}
                text={
                  customerDirection === "" ? "Sin Dirección" : customerDirection
                }
                onShowPicture={() => setIsOpen(true)}
                position={{
                  lat: parseFloat(trackingFiles.geo.latitud),
                  lng: parseFloat(trackingFiles.geo.longitud),
                }}
                center={{
                  lat: parseFloat(trackingFiles.geo.latitud),
                  lng: parseFloat(trackingFiles.geo.longitud),
                }}
              />
            </div>
          )}
        {data && trackingFiles && trackingFiles.ruta && isImageValid && (
          <div className="picture-container is-flex align-items-center justify-content-center">
            <Magnifier
              onError={() => setIsImageValid(false)}
              src={trackingFiles.ruta}
            />
            {/* <img
                onError={() => setIsImageValid(false)}
                // onError={() => console.log("error")}
                src={trackingFiles.ruta}
                alt="Imageb"
              /> */}
          </div>
        )}
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </StyledTrackingTab>
  );
};

const mapStateToProps = (state) => ({
  user: userSelector(state),
});
export default connect(mapStateToProps)(TrackingTab);
