import request from "../../../api";
import getEnvironment from "../../../utils/getEnvironment";

//? Verifica si esta en modo dev o producción
const api = getEnvironment();

//REGISTRO DE USUARIOS
const userRegisterRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/registrausuario`,
    data
  });
};

//LISTADO DE USUARIOS
const usersListRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/listausuarios`,
    data
  });
};

//ACTUALIZAR USUARIO
const updateUserRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/updateuser`,
    data
  });
};

//LISTADO DE USUARIOS
const inactiveUserRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/inactivausuario`,
    data
  });
};

export {
  userRegisterRequest,
  usersListRequest,
  updateUserRequest,
  inactiveUserRequest
};
