import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import StyledFilterDropdown from "./styled";
import * as theme from "../../utils/colors";
import { FaFilter } from "react-icons/fa";

//Selectors
import { userSelector } from "../../actions/auth/selectors";

const FilterDropdown = ({ children, content, user }) => {
  let color = user.theme ? theme[user.theme.toUpperCase()].primary : "#CF333C";
  const node = useRef();
  const [open, setOpen] = useState(false);

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      setOpen(true);
      return;
    }
    // outside click
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClick);
    } else {
      document.removeEventListener("mousedown", handleClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [open]);

  return (
    <StyledFilterDropdown
      className={`is-relative button-space filters-input`}
      ref={node}
    >
      <button className="button h-100" onClick={e => setOpen(!open)}>
        <FaFilter size="1rem" color={color} />
        <p className="has-text-black button-dropdown-text">Filtrar</p>
      </button>
      <div
        className={`position-absolute dropdown-menu-filter ${
          open ? "is-active" : ""
        }`}
      >
        <div className="column is-12 is-paddingless dropdown-menu-filter-content">
          {children}
        </div>
      </div>
    </StyledFilterDropdown>
  );
};

const mapStateToProps = state => ({
  user: userSelector(state)
});

export default connect(mapStateToProps)(FilterDropdown);
