import styled from "styled-components";

const StyledFiles = styled.div`
  height: calc(100% - 3.6875rem);
  padding: 1rem 2rem;
  .report-container {
  }

  .filters-input-select {
    /* width: 12rem; */
  }
  .form-header-button {
    margin-left: 0.75rem;
    min-width: 5rem;
  }
  .clean-filter-button {
    background-color: #9fa1b7;
  }
  .modal-uploader-container {
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 0.5rem;
    &.drag-active {
      opacity: 0.7;
    }
    .modal-uploader-content {
      height: 60vh;
      border: 1px solid black;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px dashed black;
      border-radius: 0.5rem;
    }
  }
`;

export default StyledFiles;
