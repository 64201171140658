import React, { Component } from "react";
import StyledZones from "./styled";
import { connect } from "react-redux";
import { Formik } from "formik";
import moment from "moment";
import "moment/locale/es";
import { isNil, isEmpty, get, includes, split, intersection } from "lodash";
import { getParamsRequest } from "../../utils/paramsRequest";
import { SHIPPERS_WITH_CICLES } from "../../utils/shipperPermissions";
import cx from "classnames";

// custom
import SelectAutocomplete from "../../components/select-autocomplete";
import CalendarRangeDropdown from "../../components/calendar-range-dropdown";
import LoadingScreen from "../../components/loading_screen";
import Button from "../../components/button";
import Input from "../../components/Input";
import MapZones from "../../components/map-zones";

// actions
import {
  fetchProducts,
  fetchQueryAllData,
  fetchCheckpoints
} from "../../actions/postal";

// selectors
import {
  RGORanksSelector,
  productsSelector,
  querySelector,
  queryAllDataSelector,
  checkpointsSelector
} from "../../actions/postal/selectors";

import { tokenSelector, userSelector } from "../../actions/auth/selectors";

//DATE-RANGES
import { firstDateMonthRange, lastDate } from "../../utils/rangeDates";

// validators
import { validateFilterForm } from "./validators/validateForm";

const allOption = { value: "%", label: "Todos" };

class Zones extends Component {
  state = {
    selectedOption: false,
    selectedCheckpoint: false,
    selectionRangeCourt: {
      startDate: firstDateMonthRange,
      endDate: lastDate,
      key: "selection"
    },
    selectionRangeProcess: {
      startDate: firstDateMonthRange,
      endDate: lastDate,
      key: "selection"
    },
    cicle: "",
    hasCicle: !isEmpty(
      intersection(
        SHIPPERS_WITH_CICLES,
        split(this.props.user.shi_codigos, ",")
      )
    ),
    showHeatmap: true,
    showLocation: false,
    showLodingMap: false
  };

  async componentDidMount() {
    const { token, user } = this.props;
    let userToken = getParamsRequest({
      shi_codigo: get(user, "shi_codigo", null),
      token
    });
    this.setAttributeCalendar();
    await this.getCheckpoints(userToken);
    await this.getProducts(userToken);
  }

  setAttributeCalendar = () => {
    let component = document.getElementsByClassName("dropdown-calendar-button");
    for (let i = 0; i < component.length; i++) {
      component[i].setAttribute("type", "button");
    }
  };

  getCheckpoints = async data => {
    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        fetchCheckpoints(data, (error, data) => {
          if (error) {
          }
          resolve();
        })
      );
    });
  };

  getQueryAllData = async data => {
    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        fetchQueryAllData(data, (error, data) => {
          if (error) {
          }
          resolve();
        })
      );
    });
  };

  getProducts = async data => {
    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        fetchProducts(data, (error, data) => {
          if (error) {
          }
          resolve();
        })
      );
    });
  };

  getQueryData = async () => {
    const { token, user } = this.props;
    const {
      selectedOption,
      selectedCheckpoint,
      selectionRangeCourt,
      selectionRangeProcess,
      cicle
    } = this.state;
    let data = getParamsRequest({
      token,
      shi_codigo: get(user, "shi_codigo", null),
      pro_codigo:
        selectedOption[0].value === "%"
          ? "%"
          : `"${selectedOption.map(item => item.value).join()}"`,
      checkpoint: selectedCheckpoint.value,
      cic_inicio: isNil(selectionRangeCourt.startDate)
        ? 0
        : moment(selectionRangeCourt.startDate).format("L"),
      cic_fin: isNil(selectionRangeCourt.endDate)
        ? 0
        : moment(selectionRangeCourt.endDate).format("L"),
      f_inicio: moment(selectionRangeProcess.startDate).format("L"),
      f_fin: moment(selectionRangeProcess.endDate).format("L"),
      location: 1,
      shipper_prod: cicle
    });
    await this.getQueryAllData(data);
    this.setAttributeCalendar();
  };

  handleCleanFilters = () => {
    this.setState({
      cicle: "",
      selectedOption: false,
      selectedCheckpoint: false,
      selectionRangeCourt: {
        startDate: firstDateMonthRange,
        endDate: lastDate,
        key: "selection"
      },
      selectionRangeProcess: {
        startDate: firstDateMonthRange,
        endDate: lastDate,
        key: "selection"
      }
    });
  };

  handleSelectCourt = ranges => {
    this.setState({
      selectionRangeCourt: ranges.selection
    });
  };

  handleSelectProcess = ranges => {
    this.setState({
      selectionRangeProcess: ranges.selection
    });
  };

  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleChangeCheckpoint = selectedCheckpoint => {
    this.setState({ selectedCheckpoint });
  };

  handleChangeHeatmapStatus = () => {
    const { showHeatmap } = this.state;
    this.setState(
      { showHeatmap: !showHeatmap, showLocation: false, showLodingMap: true },
      () => {
        setTimeout(() => {
          this.setState({ showLodingMap: false });
        }, 2000);
      }
    );
  };

  handleChangeLocationStatus = () => {
    const { showLocation } = this.state;
    this.setState(
      {
        showLocation: !showLocation,
        showHeatmap: false,
        showLodingMap: true
      },
      () => {
        setTimeout(() => {
          this.setState({ showLodingMap: false });
        }, 2000);
      }
    );
  };

  handleInputDate = e => {
    if (e.target.value.length === 8) {
      const date = moment(e.target.value, "YYYYMMDD").format();
      let convertedStartDate = new Date(date).setMonth(
        new Date(date).getMonth() + 1
      );
      let convertedEndDate = new Date(
        new Date(date).setMonth(new Date(date).getMonth() + 2)
      );

      this.setState({
        selectedOption: [allOption],
        selectedCheckpoint: allOption,
        selectionRangeCourt: {
          startDate: convertedStartDate,
          endDate: convertedEndDate,
          key: "selection"
        },
        selectionRangeProcess: {
          startDate: convertedStartDate,
          endDate: convertedEndDate,
          key: "selection"
        }
      });
    } else if (e.target.value.length === 0) {
      this.setState({
        selectedOption: false,
        selectedCheckpoint: false,
        selectionRangeCourt: {
          startDate: firstDateMonthRange,
          endDate: lastDate,
          key: "selection"
        },
        selectionRangeProcess: {
          startDate: firstDateMonthRange,
          endDate: lastDate,
          key: "selection"
        }
      });
    }
  };

  render() {
    moment.locale("es");
    const {
      selectionRangeCourt,
      selectionRangeProcess,
      selectedOption,
      selectedCheckpoint,
      hasCicle,
      showHeatmap,
      showLocation,
      showLodingMap
    } = this.state;
    const { products, queryAllData, checkpoints } = this.props;
    const { data, loading } = queryAllData;

    let parseProducts = products.data.map(item => ({
      value: item.pro_codigo,
      label: isEmpty(get(item, "shipper_product_code"))
        ? item.pro_codigo
        : `${item.shipper_product_code} - ${item.pro_codigo}`
    }));

    let getCheckpoints = checkpoints.data.filter(item =>
      includes(["DL", "DV"], item.codigo)
    );

    let parseCheckpoints = getCheckpoints.map(item => ({
      value: item.codigo,
      label: `${item.codigo} - ${item.descripcion}`
    }));

    let mapData = data.map(
      item =>
        new window.google.maps.LatLng(
          Number(item.latitud),
          Number(item.longitud)
        )
    );
    let markersMapData = data.map(item => ({
      lat: Number(item.latitud),
      lng: Number(item.longitud)
    }));

    parseProducts.unshift(allOption);
    parseCheckpoints.unshift(allOption);

    if (loading) return <LoadingScreen />;

    return (
      <StyledZones className="w-100 h-100">
        <div className="column is-12 tabs-container is-flex flex-wrap justify-content-between align-items-center">
          <Formik
            initialValues={this.state}
            validationSchema={validateFilterForm}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
              this.getQueryData();
              setSubmitting(false);
            }}
          >
            {props => {
              const { errors, values, handleSubmit } = props;
              return (
                <form
                  onSubmit={handleSubmit}
                  className="column is-12 is-flex align-items-center flex-wrap is-paddingless"
                >
                  <div className="column is-9 is-paddingless is-flex flex-wrap">
                    {hasCicle && (
                      <div className="column is-4">
                        <Input
                          containerClass="column is-12 p-0"
                          // label="Anexo"
                          placeholder={"Ingrese ciclo"}
                          name="cicle"
                          value={values.cicle}
                          onChange={e => {
                            this.handleInputChange(e);
                            this.handleInputDate(e);
                          }}
                          errors={errors.cicle}
                        />
                      </div>
                    )}
                    <div className="column is-4">
                      <SelectAutocomplete
                        onChange={this.handleChange}
                        containerClass="input-form filters-input filters-input-select w-100 is-paddingless m-0"
                        options={parseProducts}
                        selectedOption={selectedOption}
                        error={errors.productsValidation}
                        isMulti
                      />
                    </div>
                    <div className="column is-4">
                      <SelectAutocomplete
                        onChange={this.handleChangeCheckpoint}
                        containerClass="input-form filters-input filters-input-select w-100 is-paddingless m-0"
                        options={parseCheckpoints}
                        selectedOption={selectedCheckpoint}
                        placeholder="Seleccione Checkpoint"
                        error={errors.checkpointsValidation}
                      />
                    </div>
                    <div className="column is-4">
                      <CalendarRangeDropdown
                        rangeName="Procesado"
                        containerClass="input-form filters-input w-100 m-0"
                        selectionRange={selectionRangeProcess}
                        onChange={this.handleSelectProcess}
                      />
                    </div>
                    <div className="column is-4">
                      <CalendarRangeDropdown
                        rangeName="Corte"
                        containerClass="input-form filters-input w-100 m-0"
                        selectionRange={selectionRangeCourt}
                        onChange={this.handleSelectCourt}
                      />
                    </div>
                    <div className="column is-4 is-flex align-items-center">
                      <div
                        className="column is-6 is-paddingless is-flex align-items-center c-pointer"
                        onClick={() => this.handleChangeHeatmapStatus()}
                      >
                        <input type="checkbox" checked={showHeatmap} readOnly />
                        <p className="bold has-text-grey-light check-label">
                          Mapa de calor
                        </p>
                      </div>
                      <div
                        className="column is-6 is-paddingless is-flex align-items-center c-pointer"
                        onClick={() => this.handleChangeLocationStatus()}
                      >
                        <input
                          type="checkbox"
                          checked={showLocation}
                          readOnly
                        />
                        <p className="bold has-text-grey-light check-label">
                          Mostrar puntos
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="column is-3 is-flex flex-wrap justify-content-center">
                    <div className="column is-10">
                      <Button
                        containerClass="w-100 clean-filter-button"
                        text="Limpiar filtros"
                        type="button"
                        onClick={() => this.handleCleanFilters()}
                      />
                    </div>
                    <div className="column is-10">
                      <Button containerClass="w-100" text="Buscar" />
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
        <section className="report-section">
          <div className="column is-12 is-paddingless h-100 is-relative">
            <div
              className={cx("map-loading", {
                "d-none": !showLodingMap
              })}
            >
              <h2 className="bold">Cambiando mapa espere...</h2>
            </div>
            <MapZones
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={
                <div
                  style={{ height: `100%` }}
                  className={cx("", {
                    "d-none": showHeatmap || (!showHeatmap && !showLocation)
                  })}
                />
              }
              mapElement={<div style={{ height: `100%` }} />}
              data={mapData}
              markers={markersMapData}
              showHeatmap={true}
              showLocation={false}
            />
            <MapZones
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={
                <div
                  style={{ height: `100%` }}
                  className={cx("", {
                    "d-none": showLocation
                  })}
                />
              }
              mapElement={<div style={{ height: `100%` }} />}
              data={mapData}
              markers={markersMapData}
              showHeatmap={false}
              showLocation={true}
            />
          </div>
        </section>
      </StyledZones>
    );
  }
}

const mapStateToProps = state => ({
  RGORanks: RGORanksSelector(state),
  products: productsSelector(state),
  query: querySelector(state),
  queryAllData: queryAllDataSelector(state),
  token: tokenSelector(state),
  user: userSelector(state),
  checkpoints: checkpointsSelector(state)
});
export default connect(mapStateToProps)(Zones);
