import styled from "styled-components";

const StyledInput = styled.button`
  cursor: pointer;
  background-color: #18353f;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 12rem;
  border: 0;
  &:focus {
    box-shadow: none !important;
  }
  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 1;
  }
  p {
    color: #ffffff;
    letter-spacing: 0.0125em;
    font-weight: bold;
  }
`;

export default StyledInput;
