import React from "react";
import StyledNotFound from "./styled";
import NotFoundIcon from "../../assets/images/no-found-icon-2.svg";

const NotFound = ({
  containerClass,
  icon = NotFoundIcon,
  title = "",
  subtitle = ""
}) => {
  return (
    <StyledNotFound className={containerClass ? containerClass : ""}>
      <div className="not-found-container is-flex">
        <img src={icon} alt="" />
        <h3 className="not-found-title">{title}</h3>
        <p className="not-found-subtitle">{subtitle}</p>
      </div>
    </StyledNotFound>
  );
};

export default NotFound;
