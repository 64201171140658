import request from "../../../api";
import getEnvironment from "../../../utils/getEnvironment";

//? Verifica si esta en modo dev o producción
const api = getEnvironment();

//CONSULTA CLIENTE (DETALLE Y TABLA TRACKING)
const customerQueryRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/cic1`,
    data
  });
};

//CONSULTA NOMBRE DE CLIENTES
const customerNameRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/nom1`,
    data
  });
};

//TRACKING DE CLIENTE
const customerTrackingRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/mov1`,
    data
  });
};

//AGREGAR RECLAMO A GUIA (CONSULTAS)
const addClaimRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/rec1`,
    data
  });
};

//INSERTAR COMENTARIO
const insertCommentRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/insert_comment`,
    data
  });
};

//LISTADO DE COMENTARIOS
const commentsListRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/get_comments_list`,
    data
  });
};

//LISTADO DE TIPOS DE RECLAMOS
const claimsTypesRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/get_tipos_reclamos`,
    data
  });
};

export {
  customerQueryRequest,
  customerNameRequest,
  customerTrackingRequest,
  addClaimRequest,
  insertCommentRequest,
  commentsListRequest,
  claimsTypesRequest
};
