import styled from "styled-components";
import { WHITE } from "../../../utils/colors";

const StyledTable = styled.div`
  .orders-table-head {
    .columns {
      background-color: #f7f8f9;
    }
    .column {
      /* padding: 0.938rem 0 1.188rem 0 !important; */
      height: 2.1875rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      letter-spacing: 0.004em;
      color: #9fa1b7;
    }
  }

  .orders-table-body {
    margin-top: 0.188rem;
    height: 6.5625rem;
    overflow: auto;
    @media (min-width: 1408px) {
      height: 10.9375rem;
    }
    .columns {
      height: 2.1875rem;
      border-bottom: 1px solid #dfe0eb;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .columns:not(:last-child) {
      margin-bottom: 0.063rem !important;
    }

    .column {
      /* padding: 1.5rem 0 1.563rem 0 !important; */
      background-color: ${WHITE};
    }

    p {
      color: #3e4345;
    }
  }
  .table-row {
    cursor: pointer;
    &:hover {
      background-color: #f7f8fc;
      .column {
        background-color: #bfbfbf;
      }
    }
    &.active {
      /* background-color: red; */
      .column {
        background-color: #bfbfbf;
      }
    }
  }
`;

export default StyledTable;
