import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import * as storage from "localforage";

//action-types
import {
  LOGOUT,
  FETCH_CURRENT_USER_PROFILE,
  FETCH_CURRENT_USER_PROFILE_SUCCESS,
  FETCH_CURRENT_USER_PROFILE_ERROR,
  CHANGE_CURRENT_SHIPPER,
  CHANGE_CURRENT_SHIPPER_SUCCESS,
  CHANGE_CURRENT_SHIPPER_ERROR,
  FINISH_CHANGE_CURRENT_SHIPPER,
  FETCH_HAS_IP,
  FETCH_HAS_IP_SUCCESS,
  FETCH_HAS_IP_ERROR
} from "../../action-types/auth";

const INITIAL_STATE = {
  isLogged: false,
  loading: false,
  changing: false,
  error: null,
  user: {}
};

function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_CURRENT_USER_PROFILE:
      return {
        ...state,
        loading: true
      };
    case FETCH_CURRENT_USER_PROFILE_SUCCESS:
      return {
        ...state,
        error: "",
        isLogged: true,
        user: {
          ...action.payload
        },
        loading: false
      };
    case FETCH_CURRENT_USER_PROFILE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case CHANGE_CURRENT_SHIPPER:
      return {
        ...state,
        error: false
        // changing: true
      };
    case CHANGE_CURRENT_SHIPPER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        },
        error: false
      };
    case FINISH_CHANGE_CURRENT_SHIPPER:
      return {
        ...state,
        changing: false
      };
    case CHANGE_CURRENT_SHIPPER_ERROR:
      return {
        ...state,
        error: action.payload,
        changing: false
      };
    case FETCH_HAS_IP:
      return {
        ...state,
        error: false,
        loading: true
      };
    case FETCH_HAS_IP_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false
      };
    case FETCH_HAS_IP_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

const authPersistConfig = {
  blacklist: ["loading", "error"],
  key: "auth",
  storage
};

export default persistReducer(authPersistConfig, auth);
