import styled from "styled-components";
import { WHITE } from "../../../utils/colors";

const StyledDashboard = styled.div`
  .emptyState {
    height: 60vh;
  }
  .details-table-container {
    margin: 1.25rem 0 0 0;

    .details-table-head {
      .column {
        padding: 0.938rem 0 1.188rem 0 !important;
        background-color: ${WHITE};
      }

      strong {
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.004em;
        color: #9fa1b7;
      }
    }
    /* Table Style */
    .details-table-body {
      margin-top: 0.188rem;
      height: calc(100vh - 25rem);
      overflow: auto;

      .columns {
        height: auto;
      }
      .columns:not(:last-child) {
        margin-bottom: 0.063rem !important;
      }

      .column {
        padding: 1.5rem 0 1.563rem 0 !important;
        background-color: ${WHITE};
      }

      p {
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.004em;
        color: #3e4345;
      }

      .table-item {
        /* &:hover {
          background: #f2f2f2;
          cursor: pointer;
        } */
      }

      .c-pointer {
        &:hover {
          background-color: #f6f6f6;
        }
      }
    }
  }
  .modalDetails {
    background-color: #f7f8fc;
    padding: 1.2rem;
    border-radius: 10px;
  }
  .title-details {
    margin: 0 0 0.5rem 0;
  }
  .form-header-button {
    margin-top: 1.45rem;
    min-width: 8rem;
    &:nth-child(2) {
      margin-left: 1rem;
    }
  }
  .form-header-download-button {
    margin-top: 1.45rem;
    /* margin-left: 1rem; */
  }
  .error-form {
    position: absolute;
    /* bottom: -1.3rem; */
  }
  .location-label {
    color: #60626e;
  }
  .location-input {
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default StyledDashboard;
