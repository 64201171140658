import React from "react";
import StyledButton from "./styled";
import cx from "classnames";

const Button = ({
  onClick,
  text,
  containerClass,
  type = "submit",
  loading
}) => {
  return (
    <StyledButton
      type={type}
      onClick={onClick}
      className={cx(`button ${containerClass ? containerClass : ""}`, {
        "is-loading": loading
      })}
    >
      {!loading && <p>{text}</p>}
    </StyledButton>
  );
};

export default Button;
