import React from "react";
import StyledModal from "./styled";
import "bulma-modal-fx/dist/css/modal-fx.min.css";

const Modal = ({ active, onClose, containerClass, contentClass, content }) => {
  return (
    <StyledModal
      className={`modal ${active ? "is-active" : ""} modal-fx-slideBottom ${
        containerClass ? containerClass : ""
      }`} //modal-fx-slideBottom
    >
      <div className="modal-background" onClick={() => onClose()} />
      <div className={`modal-content ${contentClass}`}>{content}</div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={() => onClose()}
      />
    </StyledModal>
  );
};

export default Modal;
