import React from "react";
import StyledInput from "./styled";
import InputMask from "react-input-mask";

const Input = ({
  mask = "",
  maskChar = null,
  containerClass = "",
  name = "",
  label = "",
  labelClass = "input-label",
  placeholder = "",
  inputClass = "input",
  inputType = "text",
  value = "",
  onChange = () => {},
  onBlur,
  errors,
  disabled,
  maxLength = 50
}) => {
  return (
    <StyledInput className={containerClass}>
      <label className={labelClass}>{label}</label>
      <InputMask
        disabled={disabled}
        autoComplete="off"
        mask={mask}
        maskChar={maskChar}
        name={name}
        placeholder={placeholder}
        className={`${inputClass} input`}
        type={inputType}
        value={value}
        onChange={e => onChange(e)}
        onBlur={onBlur}
        maxLength={maxLength}
      />
      {errors && <p className="help is-danger error error-form">{errors}</p>}
    </StyledInput>
  );
};

export default Input;
