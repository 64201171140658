import styled from "styled-components";

const StyledCollapseDetail = styled.div`
  .collapse-detail-header {
    cursor: pointer;
    height: 2.1875rem;
    background-color: #f7f8f9;
    padding: 0 0.875rem;
    position: relative;
    .arrow-icon {
      position: absolute;
      right: 1rem;
      &.active {
        transform: rotate(180deg);
      }
    }
  }
  .collapse-detail-body {
    border: 1px solid #dfe0eb;
    border-top: 0;
    max-height: 0;
    transition: max-height 0.45s ease-out;
    overflow: hidden;
    &.active {
      max-height: 500px;
      transition: max-height 1s ease;
    }
  }
`;

export default StyledCollapseDetail;
