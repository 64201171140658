import styled from "styled-components";

const StyledElSalvadorMap = styled.div`
  position: relative;
  .country-label {
    position: absolute;
    z-index: 1;
    top: 2rem;
    left: 2rem;
  }
`;

export default StyledElSalvadorMap;
