import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import StyledServerError from "./styled";
import LeftIcon from "../../assets/images/bg-error-left.svg";
import Error500Icon from "../../assets/images/error-500.svg";
import RightIcon from "../../assets/images/bg-error-right.svg";

class ServerError extends Component {
  render() {
    return (
      <StyledServerError className="hero is-fullheight is-relative">
        <div className="left-icon-container column is-2">
          <img src={LeftIcon} alt="" />
        </div>
        <div className="hero-body">
          <img className="column is-3" src={Error500Icon} alt="" />
          <h3 className="error-title">Error interno del sistema</h3>
          <p
            onClick={() => this.props.history.goBack()}
            className="back-link c-pointer reload has-text-info"
          >
            Recargar
          </p>
          <NavLink to="/" className="back-link">
            Regresar a dashboard principal
          </NavLink>
        </div>
        <div className="right-icon-container column is-2">
          <img src={RightIcon} alt="" />
        </div>
      </StyledServerError>
    );
  }
}

export default ServerError;
