import React from "react";
import { connect } from "react-redux";
import StyledModal from "./styled";
import "bulma-modal-fx/dist/css/modal-fx.min.css";
import * as theme from "../../utils/colors";
import { AiOutlineFileExcel } from "react-icons/ai";

//Selectors
import { userSelector } from "../../actions/auth/selectors";

const Modal = ({
  active,
  onClose,
  title = "Generando Reporte",
  text = "Por favor espere...",
  titleClass,
  user
}) => {
  return (
    <StyledModal
      className={`modal ${active ? "is-active" : ""} modal-fx-3dFlipVertical`}
    >
      <div className="modal-background" onClick={() => onClose()} />
      <div className={`modal-content `}>
        <div className="column is-9-mobile is-7-tablet is-7 has-background-white mx-auto has-text-centered modal-container">
          <div className="column is-12 p-0 is-flex justify-content-center">
            <div className={`column p-0 is-12`}>
              <AiOutlineFileExcel
                size="8rem"
                color={
                  user.theme
                    ? theme[user.theme.toUpperCase()].primary
                    : "#DF9C9F"
                }
              />
            </div>
          </div>
          <h2 className={`bold has-text-black ${titleClass ? titleClass : ""}`}>
            {title}
          </h2>
          <p>{text}</p>
        </div>
      </div>
    </StyledModal>
  );
};

const mapStateToProps = state => ({
  user: userSelector(state)
});

export default connect(mapStateToProps)(Modal);
