import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import StyledModalDetails from "./styled";
import { isEmpty, split, intersection } from "lodash";
import { Formik } from "formik";
import { MdLocationOn } from "react-icons/md";
import * as theme from "../../utils/colors";
import {
  TIGO_SHIPPER_CODES,
  CLARO_SHIPPER_CODES,
} from "../../utils/shipperPermissions";

// custom
import Modal from "../../components/modal";
import Input from "../../components/Input";
import SelectAutocomplete from "../../components/select-autocomplete";
import Toggle from "../../components/toggle";
import Button from "../../components/button";
import DownloadButton from "../../components/download-button";
import TableDetails from "./table-details";
import Paginator from "../../components/paginator";

// selectors
import {
  courierPartsSelector,
  courierPartsCompleteSelector,
  checkpointsSelector,
} from "../../actions/postal/selectors";
import { userSelector } from "../../actions/auth/selectors";

const ModalDetails = ({
  active,
  onCloseModal,
  detailTitle,
  initialValues,
  onChangeInput,
  onChangeAutocomplete,
  autocompleteOptions,
  autocompleteSelectedOption,
  hasLocation,
  onChangeToggle,
  onCleanFilters,
  onChangePagination,
  onSubmit,
  user,
  courierParts,
  courierPartsComplete,
  showCheckpointSelect,
  xlsxData,
}) => {
  return (
    <StyledModalDetails>
      <Modal
        active={active}
        onClose={onCloseModal}
        contentClass="column is-11-desktop is-11-fullhd modalDetails"
        content={
          <>
            <h1 className="bold title-details">{detailTitle}</h1>
            <Formik
              initialValues={initialValues}
              // validationSchema={validateForm}
              enableReinitialize
              onSubmit={(values, { setSubmitting }) => {
                onSubmit(values);
                setSubmitting(false);
              }}
            >
              {(props) => {
                const { values, handleSubmit } = props;
                return (
                  <form
                    onSubmit={handleSubmit}
                    className="column is-12 has-background-white form-container is-flex flex-wrap is-paddingless"
                  >
                    <div className="column is-12 has-background-white form-header is-flex flex-wrap align-items-center p-0">
                      <div className="column is-12 is-flex flex-wrap align-items-center justify-content-end p-0">
                        <div className="column is-2-desktop is-3-full-hd is-flex align-items-center is-paddingless form-header-input">
                          <Input
                            containerClass="column is-12"
                            label={
                              !isEmpty(
                                intersection(
                                  TIGO_SHIPPER_CODES,
                                  split(user.shi_codigos, ",")
                                )
                              )
                                ? "Cuenta"
                                : "Codigo cliente"
                            }
                            placeholder="Ej:000303393"
                            name="clientCode"
                            value={values.clientCode}
                            onChange={onChangeInput}
                            // errors={errors.clientCode}
                          />
                        </div>
                        <div className="column is-2-desktop is-3-full-hd is-flex align-items-center is-paddingless form-header-input">
                          {!isEmpty(
                            intersection(
                              TIGO_SHIPPER_CODES,
                              split(user.shi_codigos, ",")
                            )
                          ) && (
                            <Input
                              containerClass="column is-12"
                              label="Anexo"
                              placeholder={"Número de anexo"}
                              name="anexo"
                              value={values.anexo}
                              onChange={onChangeInput}
                              // errors={errors.anexo}
                            />
                          )}
                          {!isEmpty(
                            intersection(
                              CLARO_SHIPPER_CODES,
                              split(user.shi_codigos, ",")
                            )
                          ) && (
                            <Input
                              containerClass="column is-12 p-0"
                              label="Factura"
                              placeholder="Número de factura"
                              name="anexo"
                              value={values.anexo}
                              onChange={onChangeInput}
                              // errors={errors.anexo}
                            />
                          )}
                        </div>
                        {showCheckpointSelect && (
                          <div className="column is-2-desktop is-3-full-hd is-flex align-items-center is-paddingless form-header-input">
                            <SelectAutocomplete
                              label="Checkpoint"
                              onChange={onChangeAutocomplete}
                              containerClass="input-form filters-input filters-input-select column is-12 m-0"
                              options={autocompleteOptions}
                              selectedOption={autocompleteSelectedOption}
                              placeholder="Seleccionar"
                              // error={errors.checkpointsValidation}
                            />
                          </div>
                        )}
                        <div className="column is-1 align-items-center is-paddingless form-header-input">
                          <p className="bold location-label w-100 has-text-centered">
                            GPS
                          </p>
                          <div className="location-input">
                            <Toggle
                              containerClass="form-toggle"
                              checked={hasLocation}
                              onChange={onChangeToggle}
                            />
                          </div>
                        </div>
                        <div className="column is-4-desktop is-3-full-hd is-flex flex-wrap align-items-center p-0 is-relative">
                          <Button
                            containerClass="form-header-button"
                            text="Consultar"
                          />
                          <Button
                            containerClass="form-header-button"
                            text="Limpiar"
                            type="button"
                            onClick={onCleanFilters}
                          />
                          <DownloadButton
                            containerClass="form-header-download-button"
                            data={xlsxData}
                            fileName={detailTitle}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
            <TableDetails
              tableHead={[
                "Corr",
                "Prod",
                !isEmpty(
                  intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ","))
                )
                  ? "Cod. Cliente"
                  : "Cuenta",
                "Nombre",
                !isEmpty(
                  intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ","))
                )
                  ? "Anexo"
                  : "Telefono",
                "Entrega",
                "Fecha",
                "Chk",
                "GPS",
              ]}
              tableBody={
                courierParts.data.map((item, i) => [
                  {
                    title: i + 1,
                  },
                  {
                    title: item.pro_codigo,
                  },
                  {
                    title: (
                      <Link
                        to={`/?account=${item.cli_codigo}`}
                        target="_blank"
                        className="has-text-link"
                      >
                        {item.cli_codigo}
                      </Link>
                    ),
                  },
                  {
                    title: isEmpty(item.cli_nombre) ? "--" : item.cli_nombre,
                  },
                  {
                    ...(!isEmpty(
                      intersection(
                        TIGO_SHIPPER_CODES,
                        split(user.shi_codigos, ",")
                      )
                    )
                      ? {
                          title: isEmpty(item.anexo) ? "--" : item.anexo,
                        }
                      : {
                          title: `${
                            isEmpty(item.cli_telefono1) ||
                            isNaN(item.cli_telefono1)
                              ? "--"
                              : item.cli_telefono1
                          }${
                            isEmpty(item.cli_telefono2) ||
                            isNaN(item.cli_telefono2)
                              ? ""
                              : ` / ${item.cli_telefono2}`
                          }`,
                        }),
                  },
                  {
                    title: isEmpty(item.mov_recibio) ? "--" : item.mov_recibio,
                  },
                  {
                    title:
                      isEmpty(item.dat_fecha_chk) ||
                      item.dat_fecha_chk === "31-12-1969"
                        ? "--"
                        : item.dat_fecha_chk,
                  },
                  {
                    title: isEmpty(item.esto_codigo)
                      ? item.chk_codigo
                      : item.esto_codigo,
                  },
                  {
                    title:
                      !isEmpty(
                        intersection(
                          TIGO_SHIPPER_CODES,
                          split(user.shi_codigos, ",")
                        )
                      ) &&
                      (parseInt(item.latitud) !== 0 ||
                        item.latitud.length > 0) ? (
                        <MdLocationOn
                          size="1.5rem"
                          className="c-pointer"
                          onClick={() =>
                            window.open(
                              `https://www.google.com.sa/maps/search/${parseFloat(
                                item.latitud
                              )},${parseFloat(item.longitud)}`,
                              "_blank"
                            )
                          }
                          color={
                            parseInt(item.latitud) === 0 ||
                            item.latitud.length === 0
                              ? "#cccccc"
                              : user.theme
                              ? theme[user.theme.toUpperCase()].primary
                              : "#DF9C9F"
                          }
                        />
                      ) : (
                        <MdLocationOn
                          // onClick={() => alert("jjjjj")}
                          color={
                            parseInt(item.latitud) === 0 ||
                            item.latitud.length === 0
                              ? "#cccccc"
                              : user.theme
                              ? theme[user.theme.toUpperCase()].primary
                              : "#DF9C9F"
                          }
                        />
                      ),
                  },
                ]) || []
              }
            />
            <Paginator
              onChangePage={onChangePagination}
              totalText="Total de piezas:"
              totalData={courierParts.total}
              total={courierParts.total ? parseInt(courierParts.total) : 0}
              perPage={20}
              currentPage={parseInt(courierParts.page)}
            />
          </>
        }
      />
    </StyledModalDetails>
  );
};

const mapStateToProps = (state) => ({
  user: userSelector(state),
  courierParts: courierPartsSelector(state),
  courierPartsComplete: courierPartsCompleteSelector(state),
  checkpoints: checkpointsSelector(state),
});
export default connect(mapStateToProps)(ModalDetails);
