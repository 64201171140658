import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import StyledNotFound from "./styled";
import LeftIcon from "../../assets/images/bg-left.svg";
import Error404Icon from "../../assets/images/error-404.svg";
import RightIcon from "../../assets/images/bg-right.svg";

class NotFound extends Component {
  render() {
    return (
      <StyledNotFound className="hero is-fullheight is-relative">
        <div className="left-icon-container column is-2">
          <img src={LeftIcon} alt="" />
        </div>
        <div className="hero-body">
          <img className="column is-3" src={Error404Icon} alt="" />
          <h3 className="error-title">Página no encontrada</h3>
          <NavLink to="/" className="back-link">
            Regresar a dashboard principal
          </NavLink>
        </div>
        <div className="right-icon-container column is-2">
          <img src={RightIcon} alt="" />
        </div>
      </StyledNotFound>
    );
  }
}

export default NotFound;
