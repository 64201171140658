import React from "react";
import StyledDateButton from "./styled";

const DateButton = ({
  rangeName,
  containerClass,
  text,
  selectionRange,
  onChange,
  startDate,
  endDate
}) => {
  return (
    <StyledDateButton
      className={`dropdown is-right is-hoverable button-space ${
        containerClass ? containerClass : ""
      }`}
    >
      <div className="dropdown-trigger">
        <button
          className="button dropdown-calendar-button"
          aria-haspopup="true"
          aria-controls="dropdown-menu6"
        >
          <p>{text}</p>
        </button>
      </div>
    </StyledDateButton>
  );
};

export default DateButton;
