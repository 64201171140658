import React, { useEffect } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { get } from "lodash";
import * as theme from "../../utils/colors";

//Selectors
import { userSelector } from "../../actions/auth/selectors";

const CommentBubble = ({ isLeft, name, date, text, user }) => {
  let bgColorTheme = get(user, "theme", null)
    ? theme[user.theme.toUpperCase()].tertiary
    : "#CF333C";

  useEffect(() => {
    let commentBox = document.getElementById("commentBox");
    commentBox.scrollTop = commentBox.scrollHeight;
  }, []);

  return (
    <div
      className={cx("columns is-marginless", {
        "justify-content-end": !isLeft
      })}
    >
      <div
        className={cx("column comment-bubble", {
          left: isLeft,
          right: !isLeft
        })}
        style={{
          backgroundColor: isLeft ? bgColorTheme : "#e6e6e6"
        }}
      >
        <div className="w-100 is-flex justify-content-between">
          <p
            className={cx("bold comment-date", {
              "has-text-white": isLeft
            })}
          >
            {name}
          </p>
          <p
            className={cx("bold comment-date", {
              "has-text-white": isLeft
            })}
          >
            {date}
          </p>
        </div>
        <p
          className={cx("bold", {
            "has-text-white": isLeft
          })}
        >
          {text}
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: userSelector(state)
});
// applyExporting(Highcharts);
export default connect(mapStateToProps)(CommentBubble);
