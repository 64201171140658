import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { useDropzone } from "react-dropzone";
import { GoCloudDownload } from "react-icons/go";
import { FiFilePlus } from "react-icons/fi";
import { TiDelete } from "react-icons/ti";
import cx from "classnames";
import "rc-progress/assets/index.css";
import { post } from "axios";
import { intersection, split } from "lodash";

// custom
import Modal from "../../components/modal";
import { SHIPPERS_INBOX } from "../../utils/shipperPermissions";
import Button from "../../components/button";
import { getParamsRequest } from "../../utils/paramsRequest";

// actions
import { postUploadFiles } from "../../actions/postal";

//Selectors
import { userSelector, tokenSelector } from "../../actions/auth/selectors";

const ModalUploadFiles = ({
  active,
  onClose,
  user,
  token,
  refresh,
  ...props
}) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [succesMessage, setSuccesMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const onDrop = useCallback(newfiles => setFiles([...files, ...newfiles]), [
    files
  ]);

  const get_token = async () => {
    let getClient = SHIPPERS_INBOX.find(item =>
      intersection(item.shippers, split(user.shi_codigos, ","))
    );

    let url = process.env.REACT_APP_TOKEN_FILES_URL;

    let data = {
      grant_type: "password",
      scope: "api",
      api_key: getClient.apiKey,
      client_id: process.env.REACT_APP_FILES_CLIENT_ID,
      client_secret: process.env.REACT_APP_FILES_CLIENT_SECRET
    };

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    return post(url, data, config).then(response => {
      return response.data.access_token;
    });
  };

  const onUploadFile = async () => {
    setLoading(true);
    let fileToken = await get_token();

    files.some(file => {
      fileUpload(fileToken, file)
        .then(response => {
          let data = getParamsRequest({
            token,
            id_usuclie: user.shi_codigo,
            fileName: file.name,
            peso: file.size,
            path: response.data.id
          });

          insertFilesToDatabase(data);
        })
        .catch(error => {
          setErrorMessage("Error al cargar archivos");
          setLoading(false);
          setTimeout(() => {
            setErrorMessage("");
          }, 2000);
          return false;
        });
    });

    setFiles([]);
    setSuccesMessage("Archivos subidos con éxito");
    setLoading(false);
    setTimeout(() => {
      setSuccesMessage("");
      onClose();
      refresh();
    }, 2000);
  };

  const fileUpload = async (token, file) => {
    const url = process.env.REACT_APP_UPLOAD_FILES_URL;
    let getClient = SHIPPERS_INBOX.find(item =>
      intersection(item.shippers, split(user.shi_codigos, ","))
    );
    const formData = new FormData();

    formData.append("files[]", file);
    formData.append("reference", getClient.fileReference);
    formData.append("description", getClient.fileDescription);

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`
      }
    };
    return await post(url, formData, config);
  };

  const insertFilesToDatabase = async data => {
    const { dispatch } = props;

    await new Promise((resolve, reject) => {
      dispatch(
        postUploadFiles(data, (error, data) => {
          if (error) {
          }
          resolve();
        })
      );
    });
  };

  const removeFile = index => {
    let filesData = [...files];
    filesData.splice(index, 1);
    setFiles(filesData);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  let filesQty = files.length;
  let filesText = filesQty > 1 ? "Archivos" : "Archivo";

  return (
    <Modal
      active={active}
      onClose={onClose}
      contentClass="column is-5"
      content={
        <div
          className={cx("modal-uploader-container", {
            "drag-active": isDragActive
          })}
        >
          <div className="modal-uploader-content">
            <input {...getInputProps()} />
            <div className="column is-10">
              <div className="column is-12">
                <div className="w-100 has-text-centered" {...getRootProps()}>
                  <GoCloudDownload size="5rem" />
                  <h2 className="has-text-centered bold w-100">
                    {filesQty > 0
                      ? `${filesQty} ${filesText}`
                      : `Arrastre los archivos aquí o de click para seleccionar archivos`}
                  </h2>
                </div>
                <div
                  className="column is-12"
                  style={{ maxHeight: "15rem", overflow: "auto" }}
                >
                  {files.map((file, i) => {
                    return (
                      <div
                        key={i}
                        className="column is-12 is-flex align-items-center justify-content-between p-0"
                      >
                        <div className="column is-flex align-items-center">
                          <FiFilePlus size="3rem" />
                          <p>{file.name}</p>
                        </div>
                        <TiDelete
                          size="2rem"
                          className="c-pointer"
                          onClick={() => removeFile(i)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="column is-10 mx-auto">
                <p className="bold has-text-centered has-text-success">
                  {succesMessage}
                </p>
                <p className="bold has-text-centered has-text-danger">
                  {errorMessage}
                </p>
              </div>
              {files.length > 0 && (
                <Button
                  onClick={onUploadFile}
                  containerClass="mx-auto"
                  text={`Subir ${filesText}`}
                  loading={loading}
                />
              )}
            </div>
          </div>
        </div>
      }
    />
  );
};

const mapStateToProps = state => ({
  user: userSelector(state),
  token: tokenSelector(state)
});

export default connect(mapStateToProps)(ModalUploadFiles);
