import React, { Component } from "react";
import { connect } from "react-redux";
import Highcharts from "highcharts";
import {
  HighchartsChart,
  Chart,
  withHighcharts,
  XAxis,
  YAxis,
  Legend,
  ColumnSeries,
  LineSeries,
  Tooltip
} from "react-jsx-highcharts";
import * as theme from "../../utils/colors";

//Selectors
import { userSelector } from "../../actions/auth/selectors";

class TransitChart extends Component {
  render() {
    const { total, totalPercentage, xLabels, user } = this.props;

    delete total.dv;
    delete total.stock;
    delete total.recibido;
    delete total["1"];
    delete totalPercentage.dv;
    delete totalPercentage.stock;

    let newTotal = Object.values(total);
    let newTotalPercentage = Object.values(totalPercentage).map(item => {
      let number = item ? parseFloat(item.toFixed(2)) : 0;
      return number;
    });

    // newTotal.shift();
    newTotalPercentage.shift();

    const color = {
      accumulated: user.theme
        ? theme[user.theme.toUpperCase()].primary
        : "#CF333C",
      delivered: "#000000"
    };

    const plotOptions = {
      series: {
        pointWidth: 20,
        pointRange: 0.4
      }
    };

    return (
      <div className="app">
        <HighchartsChart plotOptions={plotOptions}>
          <Chart />

          {/* <Title>Combination chart</Title> */}

          <Legend />

          <XAxis categories={xLabels} />

          <YAxis>
            <YAxis.Title>Acumulado</YAxis.Title>
            <ColumnSeries
              color={color.accumulated}
              name="Acumulado"
              data={newTotal}
            />
            <Tooltip />
          </YAxis>
          <YAxis
            opposite
            min={0}
            max={100}
            tickInterval={20}
            labels={{
              formatter: function() {
                return this.value + "%";
              }
            }}
          >
            <YAxis.Title>Porcentage entrega</YAxis.Title>
            <LineSeries
              color={color.delivered}
              name="Entregado"
              data={newTotalPercentage}
              tooltip={{
                valueSuffix: " %"
              }}
            />
          </YAxis>
        </HighchartsChart>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: userSelector(state)
});

export default connect(mapStateToProps)(
  withHighcharts(TransitChart, Highcharts)
);
