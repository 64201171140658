import React from "react";
import { connect } from "react-redux";
import StyledModal from "./styled";
import SessionBox from "../../assets/images/session-box.svg";
import "bulma-modal-fx/dist/css/modal-fx.min.css";
import Icon from "../Icon";
import * as theme from "../../utils/colors";

//Selectors
import { userSelector } from "../../actions/auth/selectors";

const Modal = ({
  active,
  onClose,
  onAccept,
  icon = SessionBox,
  title,
  text,
  action,
  fail,
  titleClass,
  imageClassContainer = "is-6-mobile is-3",
  cancelText = "Cancelar",
  acceptText = "Aceptar",
  isIcon,
  user
}) => {
  return (
    <StyledModal
      className={`modal ${active ? "is-active" : ""} modal-fx-3dFlipVertical`}
    >
      <div className="modal-background" onClick={() => onClose()} />
      <div className={`modal-content `}>
        <div className="column is-9-mobile is-7-tablet is-7 has-background-white mx-auto has-text-centered modal-container">
          <div className="column is-12 p-0 is-flex justify-content-center">
            <div className={`column p-0 ${imageClassContainer}`}>
              {isIcon && (
                <Icon
                  icon="box"
                  width={68}
                  height={71}
                  color={
                    user.theme
                      ? theme[user.theme.toUpperCase()].primary
                      : "#FF0000"
                  }
                />
              )}
              {!isIcon && <img className="w-100" src={icon} alt="icon" />}
            </div>
          </div>
          <h2 className={`bold has-text-black ${titleClass ? titleClass : ""}`}>
            {title}
          </h2>
          <p>{text}</p>
          <div className="column is-12 is-flex flex-wrap p-0">
            {onClose && (
              <p
                onClick={onClose}
                className={`column ${
                  onAccept ? "is-6" : "is-12"
                } bold button-footer c-pointer left-btn`}
              >
                {cancelText}
              </p>
            )}
            {onAccept && (
              <p
                onClick={onAccept}
                className={`column ${
                  onClose ? "is-6" : "is-12"
                } bold button-footer right-btn c-pointer`}
              >
                {acceptText}
              </p>
            )}
          </div>
        </div>
      </div>
    </StyledModal>
  );
};

const mapStateToProps = state => ({
  user: userSelector(state)
});

export default connect(mapStateToProps)(Modal);
