import styled from "styled-components";
import { RED } from "../../../utils/colors";

const StyledDateRangeCalendar = styled.div`
  .rdrMonthAndYearWrapper {
    height: 2rem;
  }
  .rdrMonthAndYearPickers {
    font-family: "Muli";
    text-transform: capitalize;
    font-weight: bold;
  }
  .rdrNextPrevButton {
    background-color: transparent;
    &.rdrNextButton {
      i {
        border-color: transparent transparent transparent ${RED};
      }
    }
    &.rdrPprevButton {
      background-color: transparent;
      i {
        border-color: transparent ${RED} transparent transparent;
      }
    }
  }

  .rdrWeekDay {
    font-family: "Muli";
    color: ${RED};
    text-transform: capitalize;
  }
  .rdrDayNumber {
    font-family: "Muli";
    span {
      &:after {
        background: #cf333c !important;
      }
    }
  }
`;

export default StyledDateRangeCalendar;
