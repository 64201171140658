import React, { Component } from "react";
import { connect } from "react-redux";
import StyledAdministration from "./styled";
import { getParamsRequest } from "../../utils/paramsRequest";
import { isEmpty, get, find } from "lodash";

// custom
import HeaderPage from "../../components/header-page";
import Button from "../../components/button";
import Table from "../../components/Table";
import CreateUser from "./CreateUser";
import CreateClientUser from "./CreateClientUser";
import LoadingScreen from "../../components/loading_screen";
import ModalSuccess from "../../components/modal_alert";
import ModalError from "../../components/modal_alert";
import ModalStatus from "../../components/modal_alert";
import SuccessIcon from "../../assets/images/success-icon.svg";
import ErrorIcon from "../../assets/images/error-icon.svg";
import { userPermissions } from "../../utils/userPermissions";

// actions
import {
  fetchUsersList,
  registerUser,
  inactiveUser,
  updateUser
} from "../../actions/postal";

// selectors
import { usersListSelector } from "../../actions/postal/selectors";
import { tokenSelector, userSelector } from "../../actions/auth/selectors";

const prueba = true;
class Administration extends Component {
  state = {
    createUserModal: false,
    createClientUserModal: false,
    modalSuccess: false,
    modalError: false,
    modalStatus: false,
    modalType: 1,
    loadingRequest: false,
    user: {
      rol: "",
      usr: "",
      usr_codemp: "",
      usr_email: "",
      usr_nombre: "",
      pass: "",
      permisos: ""
    },
    userRol: "",
    userToSetStatus: null
  };

  async componentDidMount() {
    const { history } = this.props;

    if (!prueba) return history.push("/");
    this.getUsersList();
  }

  componentDidUpdate(nextProps) {
    const { history } = this.props;
    if (!prueba) return history.push("/");
  }

  getUsersList = async () => {
    const {
      dispatch,
      token,
      currentUser: { nivel }
    } = this.props;
    let userToken = getParamsRequest({
      token,
      user_level: Number(nivel)
    });

    await new Promise((resolve, reject) => {
      dispatch(
        fetchUsersList(userToken, (error, data) => {
          if (error) {
          }
          resolve();
        })
      );
    });
  };

  changeUserStatus = async () => {
    this.setState({ loadingRequest: true });
    const { userToSetStatus } = this.state;
    const { dispatch, token } = this.props;

    let userData = getParamsRequest({
      token,
      usr: userToSetStatus
    });

    await new Promise((resolve, reject) => {
      dispatch(
        inactiveUser(userData, (error, data) => {
          if (error) {
            this.setState({ loadingRequest: false });
          } else {
            this.getUsersList();
            this.setState({
              loadingRequest: false,
              userToSetStatus: null,
              modalStatus: false
            });
          }
          resolve();
        })
      );
    });
  };

  onChangeToggle = (userRol, isEdit) => {
    if (isEdit) {
      const { user } = this.state;
      user["rol"] = userRol;
      return this.setState({ user });
    }
    this.setState({ userRol });
  };

  onChangeUser = e => {
    const { user } = this.state;
    user[e.target.name] = e.target.value;

    return this.setState({
      user
    });
  };

  onShowModal = (createUserModal, modalType = 1) => {
    this.setState({
      createUserModal,
      modalType,
      ...(!createUserModal && {
        user: {
          rol: "",
          usr: "",
          usr_codemp: "",
          usr_email: "",
          usr_nombre: "",
          pass: "",
          permisos: ""
        },
        userRol: null
      })
    });
  };

  onShowClientUserModal = (createClientUserModal, modalType = 1) => {
    this.setState({
      createClientUserModal,
      modalType,
      ...(!createClientUserModal && {
        user: {
          rol: "",
          usr: "",
          usr_codemp: "",
          usr_email: "",
          usr_nombre: "",
          pass: "",
          permisos: ""
        },
        userRol: null
      })
    });
  };

  onShowEditUser = user => {
    this.setState({ user }, () => this.onShowModal(true, 2));
  };

  onEditUser = async userData => {
    this.setState({ loadingRequest: true });
    const { token, dispatch } = this.props;
    const {
      pass,
      rol,
      usr,
      usr_codemp,
      usr_email,
      usr_nombre,
      permisos,
      id_usr
    } = userData;

    let formData = getParamsRequest({
      token,
      ...(!isEmpty(pass) && {
        pass
      }),
      rol,
      permisos: rol === "ADMINISTRADOR" ? 1 : permisos,
      usr,
      nivel: 3,
      codemp: usr_codemp,
      email: usr_email,
      nombre: usr_nombre,
      id_usr
    });

    await new Promise((resolve, reject) => {
      dispatch(
        updateUser(formData, (error, data) => {
          if (error) {
            this.setState({ loadingRequest: false, modalError: true });
          } else {
            this.onShowModal(false);
            this.getUsersList();
            this.setState({ loadingRequest: false, modalSuccess: true });
          }
          resolve();
        })
      );
    });
  };

  onCreateUser = async userData => {
    this.setState({ loadingRequest: true });
    const { token } = this.props;
    const {
      pass,
      rol,
      usr,
      usr_codemp,
      usr_email,
      usr_nombre,
      permisos
    } = userData;

    let formData = getParamsRequest({
      token,
      pass,
      rol,
      permisos: rol === "ADMINISTRADOR" ? 1 : permisos,
      usr,
      nivel: 3,
      codemp: usr_codemp,
      email: usr_email,
      nombre: usr_nombre
    });

    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        registerUser(formData, (error, data) => {
          if (error) {
            this.setState({ loadingRequest: false, modalError: true });
          } else {
            this.onShowModal(false);
            this.getUsersList();
            this.setState({ loadingRequest: false, modalSuccess: true });
          }
          resolve();
        })
      );
    });
  };

  onCreateClientUser = async userData => {
    this.setState({ loadingRequest: true });
    const { token } = this.props;
    const {
      pass,
      usr,
      usr_codemp,
      usr_email,
      usr_nombre,
      permisos,
      userType,
      shipperId
    } = userData;

    let formData = getParamsRequest({
      token,
      pass,
      rol:
        Number(userType) === 1
          ? "ADMINURBANO"
          : Number(userType) === 2
          ? "USUARIOURBANO"
          : "USUARIO",
      permisos: Number(userType) === 1 ? 1 : permisos,
      usr,
      nivel: userType,
      codemp: usr_codemp,
      email: usr_email,
      nombre: usr_nombre,
      shi_codigo: shipperId
    });

    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        registerUser(formData, (error, data) => {
          if (error) {
            this.setState({ loadingRequest: false, modalError: true });
            reject(error);
          } else {
            this.onShowClientUserModal(false);
            this.getUsersList();
            this.setState({ loadingRequest: false, modalSuccess: true });
          }
          resolve();
        })
      );
    });
  };

  handleModalSuccess = () => {
    const { modalSuccess } = this.state;
    this.setState({ modalSuccess: !modalSuccess });
  };

  handleModalError = () => {
    const { modalError } = this.state;
    this.setState({ modalError: !modalError });
  };

  handleModalStatus = (modalStatus, userToSetStatus = null) => {
    this.setState({ modalStatus, userToSetStatus });
  };

  render() {
    const {
      modalSuccess,
      modalError,
      modalStatus,
      loadingRequest,
      createUserModal,
      createClientUserModal,
      modalType,
      user,
      userRol
    } = this.state;
    const { usersList, currentUser } = this.props;

    if (usersList.loading) {
      return <LoadingScreen />;
    }
    return (
      <StyledAdministration className="w-100 h-100">
        {loadingRequest && <LoadingScreen isRequest />}
        <HeaderPage
          title="Detalle de administración"
          content={
            <>
              {Number(currentUser.nivel) === 1 ? (
                <Button
                  containerClass="form-header-button"
                  text="Crear Usuario Cliente"
                  onClick={() => this.onShowClientUserModal(true, 1)}
                />
              ) : (
                <Button
                  containerClass="form-header-button"
                  text="Crear Usuarios"
                  onClick={() => this.onShowModal(true, 1)}
                />
              )}
            </>
          }
        />
        <Table
          tableHead={[
            "Nombre",
            "Usuario",
            "Rol",
            "Correo Electrónico",
            "Código empleado",
            "Permisos",
            "Fecha Asignación",
            ...(Number(currentUser.nivel) === 1
              ? ["Última Con.", "Cambio Contra."]
              : [])
          ]}
          tableBody={usersList.data.map(item => {
            if (currentUser.usr !== item.usr) {
              // if (true) {
              return [
                item.usr_nombre,
                item.usr,
                item.rol,
                item.usr_email,
                item.usr_codemp,
                get(
                  find(userPermissions, ["value", Number(item.permisos)]),
                  "label",
                  "--"
                ),
                item.creacion,
                ...(Number(currentUser.nivel) === 1
                  ? [item.usuario_conexion_log, item.usuario_contra_log]
                  : []),
                [
                  {
                    onClick: () => this.onShowEditUser(item),
                    label: "Editar"
                  },
                  {
                    onClick: () => this.handleModalStatus(true, item.usr),
                    label: "Desactivar"
                  }
                ]
              ];
            }
            return [];
          })}
          actions
        />
        <CreateUser
          modalType={modalType}
          active={createUserModal}
          onClose={() => this.onShowModal(false)}
          data={{ ...user, modalType }}
          onChangeToggle={this.onChangeToggle}
          onChange={this.onChangeUser}
          userRol={userRol}
          onCreate={this.onCreateUser}
          onEdit={this.onEditUser}
        />
        <CreateClientUser
          modalType={modalType}
          active={createClientUserModal}
          onClose={() => this.onShowClientUserModal(false)}
          data={{ ...user, modalType }}
          onChangeToggle={this.onChangeToggle}
          onChange={this.onChangeUser}
          userRol={userRol}
          onCreate={this.onCreateClientUser}
        />
        <ModalSuccess
          active={modalSuccess}
          onClose={() => this.handleModalSuccess()}
          cancelText="Aceptar"
          icon={SuccessIcon}
          title="Usuario creado con éxito"
          titleClass="column is-10 mx-auto"
        />
        <ModalError
          active={modalError}
          onClose={() => this.handleModalError()}
          cancelText="Aceptar"
          icon={ErrorIcon}
          title={`Error al ${modalType === 1 ? "crear" : "editar"} usuario`}
          titleClass="column is-10 mx-auto"
        />
        <ModalStatus
          active={modalStatus}
          onClose={() => this.handleModalStatus(false)}
          onAccept={() => this.changeUserStatus()}
          icon={SuccessIcon}
          title="¿Desea desactivar Usuario?"
          titleClass="column is-10 mx-auto"
        />
      </StyledAdministration>
    );
  }
}

const mapStateToProps = state => ({
  usersList: usersListSelector(state),
  token: tokenSelector(state),
  currentUser: userSelector(state)
});
export default connect(mapStateToProps)(Administration);
