import styled from "styled-components";

const StyledInputTextarea = styled.div`
  position: relative;

  .input-label {
    color: #60626e;
    margin-bottom: 4px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    text-transform: capitalize;
  }

  textarea {
    background: #ffffff;
    border: 1px solid #dfe0eb;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: "Muli";
    font-size: 14px;
    padding: 17px 12.25px;
    &:hover,
    &:focus {
      border: 1px solid #60626e;
      border-radius: 4px;
      box-shadow: none;
    }
  }
`;

export default StyledInputTextarea;
