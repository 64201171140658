import styled from "styled-components";
import { SOFTRED } from "../../../utils/colors";

const StyledSelectAutocomplete = styled.div`
  .input-label {
    color: #60626e;
    margin-bottom: 4px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    text-transform: capitalize;
  }
  .select-input {
    height: 100%;
    border: 1px solid #dfe0eb;
    border-radius: 5px;
    .select-input__control {
      height: 100%;
      border: 0;
    }
    .select-input__control--is-focused {
      box-shadow: none;
      border: 1px solid ${SOFTRED};
    }
    .select-input__value-container {
      overflow-y: auto;
      max-height: 4rem;
    }

    .select-input__placeholder {
      color: #c6c6c6;
      font-family: "Muli";
      font-size: 14px;
    }

    .select-input__input {
      font-family: "Muli";
      font-size: 14px;
    }

    .select-input__menu {
      margin-top: 3px;
    }

    .select-input__option {
      font-family: "Muli";
      font-size: 14px;
      cursor: pointer;
    }
  }
  .error-form {
    position: absolute;
    /* bottom: -1.2rem; */
  }
`;

export default StyledSelectAutocomplete;
