import styled from "styled-components";

const StyledNotFound = styled.div`
  width: 100%;
  padding-top: 1.063rem;

  .not-found-container {
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;

    .not-found-title {
      font-weight: bold;
      font-size: 19px;
      line-height: 19px;
      letter-spacing: 0.0015em;
      color: #000000;
      margin-top: 2.75rem;
    }

    .not-found-subtitle {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #979797;
      margin-top: 0.938rem;
    }
  }
`;

export default StyledNotFound;
