import {
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_ERROR,
  FETCH_RGO_CICLOS,
  FETCH_RGO_CICLOS_SUCCESS,
  FETCH_RGO_CICLOS_ERROR,
  FETCH_RGO_ABIERTOS,
  FETCH_RGO_ABIERTOS_SUCCESS,
  FETCH_RGO_ABIERTOS_ERROR,
  FETCH_RGO_RANKS,
  FETCH_RGO_RANKS_SUCCESS,
  FETCH_RGO_RANKS_ERROR,
  FETCH_TRANSIT_TIMES,
  FETCH_TRANSIT_TIMES_SUCCESS,
  FETCH_TRANSIT_TIMES_ERROR,
  FETCH_TRANSIT_TIMES_PRODUCTS,
  FETCH_TRANSIT_TIMES_PRODUCTS_SUCCESS,
  FETCH_TRANSIT_TIMES_PRODUCTS_ERROR,
  FETCH_CUSTOMER_QUERY,
  FETCH_CUSTOMER_QUERY_SUCCESS,
  FETCH_CUSTOMER_QUERY_ERROR,
  FETCH_CUSTOMER_NAME,
  FETCH_CUSTOMER_NAME_SUCCESS,
  FETCH_CUSTOMER_NAME_ERROR,
  FETCH_CUSTOMER_TRACKING,
  FETCH_CUSTOMER_TRACKING_SUCCESS,
  FETCH_CUSTOMER_TRACKING_ERROR,
  FETCH_ZONIFICACION_CIERRE_ENVIOS,
  FETCH_ZONIFICACION_CIERRE_ENVIOS_SUCCESS,
  FETCH_ZONIFICACION_CIERRE_ENVIOS_ERROR,
  FETCH_QUERY,
  FETCH_QUERY_SUCCESS,
  FETCH_QUERY_ERROR,
  FETCH_QUERY_ALL_DATA,
  FETCH_QUERY_ALL_DATA_SUCCESS,
  FETCH_QUERY_ALL_DATA_ERROR,
  CLEAN_TRACKING,
  CLEAN_CUSTOMER_QUERY,
  ADD_CLAIM,
  ADD_CLAIM_SUCCESS,
  ADD_CLAIM_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  FETCH_USERS_LIST,
  FETCH_USERS_LIST_SUCCESS,
  FETCH_USERS_LIST_ERROR,
  INACTIVE_USER,
  INACTIVE_USER_SUCCESS,
  INACTIVE_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  FETCH_CHECKPOINTS,
  FETCH_CHECKPOINTS_SUCCESS,
  FETCH_CHECKPOINTS_ERROR,
  FETCH_COURIER_PARTS_DETAILS,
  FETCH_COURIER_PARTS_DETAILS_SUCCESS,
  FETCH_COURIER_PARTS_DETAILS_ERROR,
  FETCH_COURIER_PARTS_DETAILS_COMPLETE,
  FETCH_COURIER_PARTS_DETAILS_COMPLETE_SUCCESS,
  FETCH_COURIER_PARTS_DETAILS_COMPLETE_ERROR,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_ERROR,
  CLEAN_RGO_CICLOS,
  CLEAN_RGO_ABIERTOS,
  GET_ALL_SHIPPERS,
  GET_ALL_SHIPPERS_SUCCESS,
  GET_ALL_SHIPPERS_ERROR,
  FETCH_CLAIMS,
  FETCH_CLAIMS_SUCCESS,
  FETCH_CLAIMS_ERROR,
  FETCH_CLAIMS_ALL,
  FETCH_CLAIMS_ALL_SUCCESS,
  FETCH_CLAIMS_ALL_ERROR,
  FETCH_CLAIMS_SUMMARY,
  FETCH_CLAIMS_SUMMARY_SUCCESS,
  FETCH_CLAIMS_SUMMARY_ERROR,
  FETCH_ZONING,
  FETCH_ZONING_SUCCESS,
  FETCH_ZONING_ERROR,
  FETCH_ZONING_ALL,
  FETCH_ZONING_ALL_SUCCESS,
  FETCH_ZONING_ALL_ERROR,
  INSERT_COMMENT,
  INSERT_COMMENT_SUCCESS,
  INSERT_COMMENT_ERROR,
  FETCH_COMMENTS_LIST,
  FETCH_COMMENTS_LIST_SUCCESS,
  FETCH_COMMENTS_LIST_ERROR,
  FETCH_CLAIMS_TYPES,
  FETCH_CLAIMS_TYPES_SUCCESS,
  FETCH_CLAIMS_TYPES_ERROR,
  FETCH_UPLOADED_FILES,
  FETCH_UPLOADED_FILES_SUCCESS,
  FETCH_UPLOADED_FILES_ERROR,
  FETCH_REMOVED_FILES,
  FETCH_REMOVED_FILES_SUCCESS,
  FETCH_REMOVED_FILES_ERROR,
  POST_UPLOAD_FILES,
  POST_UPLOAD_FILES_SUCCESS,
  POST_UPLOAD_FILES_ERROR,
  DELETE_INBOX_FILE,
  DELETE_INBOX_FILE_SUCCESS,
  DELETE_INBOX_FILE_ERROR
} from "../../action-types/postal";

export const fetchRGOCiclos = (data, callback) => ({
  type: FETCH_RGO_CICLOS,
  payload: data,
  callback
});

export const fetchRGOCiclosSuccess = data => ({
  type: FETCH_RGO_CICLOS_SUCCESS,
  payload: data
});

export const fetchRGOCiclosError = error => ({
  type: FETCH_RGO_CICLOS_ERROR,
  payload: error
});

export const fetchRGOAbiertos = (data, callback) => ({
  type: FETCH_RGO_ABIERTOS,
  payload: data,
  callback
});

export const fetchRGOAbiertosSuccess = data => ({
  type: FETCH_RGO_ABIERTOS_SUCCESS,
  payload: data
});

export const fetchRGOAbiertosError = error => ({
  type: FETCH_RGO_ABIERTOS_ERROR,
  payload: error
});

export const fetchRGORanks = (data, callback) => ({
  type: FETCH_RGO_RANKS,
  payload: data,
  callback
});

export const fetchRGORanksSuccess = data => ({
  type: FETCH_RGO_RANKS_SUCCESS,
  payload: data
});

export const fetchRGORanksError = error => ({
  type: FETCH_RGO_RANKS_ERROR,
  payload: error
});

export const fetchProducts = (data, callback) => ({
  type: FETCH_PRODUCTS,
  payload: data,
  callback
});

export const fetchProductsSuccess = data => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: data
});

export const fetchProductsError = error => ({
  type: FETCH_PRODUCTS_ERROR,
  payload: error
});

export const fetchTransitTimes = (data, callback) => ({
  type: FETCH_TRANSIT_TIMES,
  payload: data,
  callback
});

export const fetchTransitTimesSuccess = data => ({
  type: FETCH_TRANSIT_TIMES_SUCCESS,
  payload: data
});

export const fetchTransitTimesError = error => ({
  type: FETCH_TRANSIT_TIMES_ERROR,
  payload: error
});

export const fetchTransitTimesProducts = (data, callback) => ({
  type: FETCH_TRANSIT_TIMES_PRODUCTS,
  payload: data,
  callback
});

export const fetchTransitTimesProductsSuccess = data => ({
  type: FETCH_TRANSIT_TIMES_PRODUCTS_SUCCESS,
  payload: data
});

export const fetchTransitTimesProductsError = error => ({
  type: FETCH_TRANSIT_TIMES_PRODUCTS_ERROR,
  payload: error
});

export const fetchCustomerQuery = (data, callback) => ({
  type: FETCH_CUSTOMER_QUERY,
  payload: data,
  callback
});

export const fetchCustomerQuerySuccess = data => ({
  type: FETCH_CUSTOMER_QUERY_SUCCESS,
  payload: data
});

export const fetchCustomerQueryError = error => ({
  type: FETCH_CUSTOMER_QUERY_ERROR,
  payload: error
});

export const fetchCustomerName = (data, callback) => ({
  type: FETCH_CUSTOMER_NAME,
  payload: data,
  callback
});

export const fetchCustomerNameSuccess = data => ({
  type: FETCH_CUSTOMER_NAME_SUCCESS,
  payload: data
});

export const fetchCustomerNameError = error => ({
  type: FETCH_CUSTOMER_NAME_ERROR,
  payload: error
});

export const fetchCustomerTracking = (data, callback) => ({
  type: FETCH_CUSTOMER_TRACKING,
  payload: data,
  callback
});

export const fetchCustomerTrackingSuccess = data => ({
  type: FETCH_CUSTOMER_TRACKING_SUCCESS,
  payload: data
});

export const fetchCustomerTrackingError = error => ({
  type: FETCH_CUSTOMER_TRACKING_ERROR,
  payload: error
});

export const fetchZCEnvios = (data, callback) => ({
  type: FETCH_ZONIFICACION_CIERRE_ENVIOS,
  payload: data,
  callback
});

export const fetchZCEnviosSuccess = data => ({
  type: FETCH_ZONIFICACION_CIERRE_ENVIOS_SUCCESS,
  payload: data
});

export const fetchZCEnviosError = error => ({
  type: FETCH_ZONIFICACION_CIERRE_ENVIOS_ERROR,
  payload: error
});

export const fetchQuery = (data, callback) => ({
  type: FETCH_QUERY,
  payload: data,
  callback
});

export const fetchQuerySuccess = data => ({
  type: FETCH_QUERY_SUCCESS,
  payload: data
});

export const fetchQueryError = error => ({
  type: FETCH_QUERY_ERROR,
  payload: error
});

export const fetchQueryAllData = (data, callback) => ({
  type: FETCH_QUERY_ALL_DATA,
  payload: data,
  callback
});

export const fetchQueryAllDataSuccess = data => ({
  type: FETCH_QUERY_ALL_DATA_SUCCESS,
  payload: data
});

export const fetchQueryAllDataError = error => ({
  type: FETCH_QUERY_ALL_DATA_ERROR,
  payload: error
});

export const addClaim = (data, callback) => ({
  type: ADD_CLAIM,
  payload: data,
  callback
});

export const addClaimSuccess = data => ({
  type: ADD_CLAIM_SUCCESS,
  payload: data
});

export const addClaimError = error => ({
  type: ADD_CLAIM_ERROR,
  payload: error
});

export const registerUser = (data, callback) => ({
  type: REGISTER_USER,
  payload: data,
  callback
});

export const registerUserSuccess = data => ({
  type: REGISTER_USER_SUCCESS,
  payload: data
});

export const registerUserError = error => ({
  type: REGISTER_USER_ERROR,
  payload: error
});

export const fetchUsersList = (data, callback) => ({
  type: FETCH_USERS_LIST,
  payload: data,
  callback
});

export const fetchUsersListSuccess = data => ({
  type: FETCH_USERS_LIST_SUCCESS,
  payload: data
});

export const fetchUsersListError = error => ({
  type: FETCH_USERS_LIST_ERROR,
  payload: error
});

export const inactiveUser = (data, callback) => ({
  type: INACTIVE_USER,
  payload: data,
  callback
});

export const inactiveUserSuccess = data => ({
  type: INACTIVE_USER_SUCCESS,
  payload: data
});

export const inactiveUserError = error => ({
  type: INACTIVE_USER_ERROR,
  payload: error
});

export const updateUser = (data, callback) => ({
  type: UPDATE_USER,
  payload: data,
  callback
});

export const updateUserSuccess = data => ({
  type: UPDATE_USER_SUCCESS,
  payload: data
});

export const updateUserError = error => ({
  type: UPDATE_USER_ERROR,
  payload: error
});
export const updateUserPassword = (data, callback) => ({
  type: UPDATE_USER_PASSWORD,
  payload: data,
  callback
});

export const updateUserPasswordSuccess = data => ({
  type: UPDATE_USER_PASSWORD_SUCCESS,
  payload: data
});

export const updateUserPasswordError = error => ({
  type: UPDATE_USER_PASSWORD_ERROR,
  payload: error
});

export const cleanTracking = () => ({
  type: CLEAN_TRACKING
});

export const cleanCustomerQuery = () => ({
  type: CLEAN_CUSTOMER_QUERY
});

export const cleanRgosAbiertos = () => ({
  type: CLEAN_RGO_ABIERTOS
});

export const cleanRgosCiclos = () => ({
  type: CLEAN_RGO_CICLOS
});

export const fetchCheckpoints = (data, callback) => ({
  type: FETCH_CHECKPOINTS,
  payload: data,
  callback
});

export const fetchCheckpointsSuccess = data => ({
  type: FETCH_CHECKPOINTS_SUCCESS,
  payload: data
});

export const fetchCheckpointsError = error => ({
  type: FETCH_CHECKPOINTS_ERROR,
  payload: error
});

export const fetchCourierParts = (data, callback) => ({
  type: FETCH_COURIER_PARTS_DETAILS,
  payload: data,
  callback
});

export const fetchCourierPartsSuccess = data => ({
  type: FETCH_COURIER_PARTS_DETAILS_SUCCESS,
  payload: data
});

export const fetchCourierPartsError = error => ({
  type: FETCH_COURIER_PARTS_DETAILS_ERROR,
  payload: error
});

export const fetchCourierPartsComplete = (data, callback) => ({
  type: FETCH_COURIER_PARTS_DETAILS_COMPLETE,
  payload: data,
  callback
});

export const fetchCourierPartsCompleteSuccess = data => ({
  type: FETCH_COURIER_PARTS_DETAILS_COMPLETE_SUCCESS,
  payload: data
});

export const fetchCourierPartsCompleteError = error => ({
  type: FETCH_COURIER_PARTS_DETAILS_COMPLETE_ERROR,
  payload: error
});

export const getAllShippers = (data, callback) => ({
  type: GET_ALL_SHIPPERS,
  payload: data,
  callback
});

export const getAllShippersSuccess = data => ({
  type: GET_ALL_SHIPPERS_SUCCESS,
  payload: data
});

export const getAllShippersError = error => ({
  type: GET_ALL_SHIPPERS_ERROR,
  payload: error
});

export const fetchClaims = (data, callback) => ({
  type: FETCH_CLAIMS,
  payload: data,
  callback
});

export const fetchClaimsSuccess = data => ({
  type: FETCH_CLAIMS_SUCCESS,
  payload: data
});

export const fetchClaimsError = error => ({
  type: FETCH_CLAIMS_ERROR,
  payload: error
});

export const fetchClaimsAll = (data, callback) => ({
  type: FETCH_CLAIMS_ALL,
  payload: data,
  callback
});

export const fetchClaimsAllSuccess = data => ({
  type: FETCH_CLAIMS_ALL_SUCCESS,
  payload: data
});

export const fetchClaimsAllError = error => ({
  type: FETCH_CLAIMS_ALL_ERROR,
  payload: error
});

export const fetchClaimsSummary = (data, callback) => ({
  type: FETCH_CLAIMS_SUMMARY,
  payload: data,
  callback
});

export const fetchClaimsSummarySuccess = data => ({
  type: FETCH_CLAIMS_SUMMARY_SUCCESS,
  payload: data
});

export const fetchClaimsSummaryError = error => ({
  type: FETCH_CLAIMS_SUMMARY_ERROR,
  payload: error
});

export const fetchZoning = (data, callback) => ({
  type: FETCH_ZONING,
  payload: data,
  callback
});

export const fetchZoningSuccess = data => ({
  type: FETCH_ZONING_SUCCESS,
  payload: data
});

export const fetchZoningError = error => ({
  type: FETCH_ZONING_ERROR,
  payload: error
});

export const fetchZoningAll = (data, callback) => ({
  type: FETCH_ZONING_ALL,
  payload: data,
  callback
});

export const fetchZoningAllSuccess = data => ({
  type: FETCH_ZONING_ALL_SUCCESS,
  payload: data
});

export const fetchZoningAllError = error => ({
  type: FETCH_ZONING_ALL_ERROR,
  payload: error
});

export const insertComment = (data, callback) => ({
  type: INSERT_COMMENT,
  payload: data,
  callback
});

export const insertCommentSuccess = data => ({
  type: INSERT_COMMENT_SUCCESS,
  payload: data
});

export const insertCommentError = error => ({
  type: INSERT_COMMENT_ERROR,
  payload: error
});

export const fetchCommentsList = (data, callback) => ({
  type: FETCH_COMMENTS_LIST,
  payload: data,
  callback
});

export const fetchCommentsListSuccess = data => ({
  type: FETCH_COMMENTS_LIST_SUCCESS,
  payload: data
});

export const fetchCommentsListError = error => ({
  type: FETCH_COMMENTS_LIST_ERROR,
  payload: error
});

export const fetchClaimsTypes = (data, callback) => ({
  type: FETCH_CLAIMS_TYPES,
  payload: data,
  callback
});

export const fetchClaimsTypesSuccess = data => ({
  type: FETCH_CLAIMS_TYPES_SUCCESS,
  payload: data
});

export const fetchClaimsTypesError = error => ({
  type: FETCH_CLAIMS_TYPES_ERROR,
  payload: error
});

export const fetchUploadedFiles = (data, callback) => ({
  type: FETCH_UPLOADED_FILES,
  payload: data,
  callback
});

export const fetchUploadedFilesSuccess = data => ({
  type: FETCH_UPLOADED_FILES_SUCCESS,
  payload: data
});

export const fetchUploadedFilesError = error => ({
  type: FETCH_UPLOADED_FILES_ERROR,
  payload: error
});

export const fetchRemovedFiles = (data, callback) => ({
  type: FETCH_REMOVED_FILES,
  payload: data,
  callback
});

export const fetchRemovedFilesSuccess = data => ({
  type: FETCH_REMOVED_FILES_SUCCESS,
  payload: data
});

export const fetchRemovedFilesError = error => ({
  type: FETCH_REMOVED_FILES_ERROR,
  payload: error
});

export const postUploadFiles = (data, callback) => ({
  type: POST_UPLOAD_FILES,
  payload: data,
  callback
});

export const postUploadFilesSuccess = data => ({
  type: POST_UPLOAD_FILES_SUCCESS,
  payload: data
});

export const postUploadFilesError = error => ({
  type: POST_UPLOAD_FILES_ERROR,
  payload: error
});

export const deleteInboxFile = (data, callback) => ({
  type: DELETE_INBOX_FILE,
  payload: data,
  callback
});

export const deleteInboxFileSuccess = data => ({
  type: DELETE_INBOX_FILE_SUCCESS,
  payload: data
});

export const deleteInboxFileError = error => ({
  type: DELETE_INBOX_FILE_ERROR,
  payload: error
});
