import request from "../../../api";
import getEnvironment from "../../../utils/getEnvironment";

//? Verifica si esta en modo dev o producción
const api = getEnvironment();

//TIEMPOS DE TRANSITO
const transitTimesRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/tt1`,
    data
  });
};

const transitTimesProductsRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/transit_times_products`,
    data
  });
};

export { transitTimesRequest, transitTimesProductsRequest };
