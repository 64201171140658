import { includes, get, find } from "lodash";

export const userPermissions = [
  {
    value: 1,
    label: "ADMINISTRACION - DASHBOARD - CONSULTAS - REPORTES - ZONAS",
    activePage: "/",
  },
  {
    value: 2,
    label: "ADMINISTRACION - DASHBOARD - CONSULTAS - REPORTES",
    activePage: "/",
  },
  {
    value: 3,
    label: "ADMINISTRACION - DASHBOARD - CONSULTAS",
    activePage: "/",
  },
  {
    value: 4,
    label: "ADMINISTRACION - DASHBOARD",
    activePage: "/",
  },
  {
    value: 5,
    label: "ADMINISTRACION - CONSULTAS",
    activePage: "/",
    // activePage: "/clients",
  },
  {
    value: 6,
    label: "ADMINISTRACION - REPORTES",
    activePage: "/reports",
  },
  {
    value: 7,
    label: "ADMINISTRACION - ZONAS",
    activePage: "/zones",
  },
  {
    value: 8,
    label: "DASHBOARD - CONSULTAS - REPORTES - ZONAS",
    activePage: "/",
  },
  {
    value: 9,
    label: "DASHBOARD - CONSULTAS - REPORTES",
    activePage: "/",
  },
  {
    value: 10,
    label: "DASHBOARD - CONSULTAS",
    activePage: "/",
  },
  {
    value: 11,
    label: "DASHBOARD - REPORTES",
    activePage: "/",
  },
  {
    value: 12,
    label: "DASHBOARD - ZONAS",
    activePage: "/",
  },
  {
    value: 13,
    label: "CONSULTAS - REPORTES - ZONAS",
    activePage: "/",
    // activePage: "/clients",
  },
  {
    value: 14,
    label: "CONSULTAS - REPORTES",
    activePage: "/",
    // activePage: "/clients",
  },
  {
    value: 15,
    label: "CONSULTAS - ZONAS",
    activePage: "/",
    // activePage: "/clients",
  },
  {
    value: 16,
    label: "REPORTES - ZONAS",
    activePage: "/reports",
  },
  {
    value: 17,
    label: "REPORTES",
    activePage: "/reports",
  },
  {
    value: 18,
    label: "ZONAS",
    activePage: "/zones",
  },
  {
    value: 19,
    label:
      "ADMINISTRACION - DASHBOARD - CONSULTAS - REPORTES - ZONAS - ARCHIVOS",
    activePage: "/",
  },
  {
    value: 20,
    label: "DASHBOARD - CONSULTAS - REPORTES - ZONAS - ARCHIVOS",
    activePage: "/",
  },
  {
    value: 21,
    label: "CONSULTAS - REPORTES - ZONAS - ARCHIVOS",
    activePage: "/",
    // activePage: "/clients",
  },
  {
    value: 22,
    label: "REPORTES - ZONAS - ARCHIVOS",
    activePage: "/reports",
  },
  {
    value: 23,
    label: "ZONAS - ARCHIVOS",
    activePage: "/zones",
  },
  {
    value: 24,
    label: "ARCHIVOS",
    activePage: "/files",
  },
  {
    value: 25,
    label: "ADMINISTRACION - ARCHIVOS",
    activePage: "/files",
  },
];

export const getTabsPermissions = (userPermission, tabName) => {
  return includes(
    get(
      find(userPermissions, ["value", Number(userPermission)]),
      "label",
      false
    ),
    tabName
  );
};

export const getUrlPermissions = (userPermission, tabName) => {
  return includes(
    get(
      find(userPermissions, ["value", Number(userPermission)]),
      "activePage",
      ""
    ),
    tabName
  );
};
