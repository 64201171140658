import styled from "styled-components";

const StyledFilterDropdown = styled.div`
  .button-dropdown-text {
    margin-left: 0.5rem;
  }
  .dropdown-menu-filter {
    display: none;
    z-index: 1;
    width: calc(100vw - 4rem);
    right: 0;
    margin-top: 0.9rem;
    border-radius: 0;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &.is-active {
      display: block;
    }
  }
  .dropdown-menu-filter-content {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      right: 2rem;
      top: -0.625rem;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #ffffff transparent;
      -webkit-filter: drop-shadow(2px 0px 0px rgba(0, 0, 0, 0.2));
      filter: drop-shadow(2px 0px 0px rgba(0, 0, 0, 0.2));
    }
  }
`;

export default StyledFilterDropdown;
