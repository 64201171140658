import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get, map } from "lodash";
import { Formik } from "formik";
import moment from "moment";
import "moment/locale/es";

// custom
import LoadingScreen from "../../components/loading_screen";
import { getParamsRequest } from "../../utils/paramsRequest";
import Table from "../../components/Table";
import Paginator from "../../components/paginator";
import FilterDropdown from "../../components/filter-dropdown";
import Input from "../../components/Input";
import Button from "../../components/button";
import CalendarRangeDropdown from "../../components/calendar-range-dropdown";
import { firstDateMonthRange, lastDate } from "../../utils/rangeDates";

// actions
import { fetchRemovedFiles } from "../../actions/postal";

// selectors
import { tokenSelector, userSelector } from "../../actions/auth/selectors";
import { removedFilesSelector } from "../../actions/postal/selectors";

const initialCalendarValues = {
  startDate: firstDateMonthRange,
  endDate: lastDate,
  key: "selection"
};

const UploadedFiles = props => {
  const [selectionRange, setSelectionRange] = useState(initialCalendarValues);
  const [fileName, setFileName] = useState("");

  const getRemovedFiles = async (data = {}) => {
    const { dispatch, token, user } = props;

    let formData = getParamsRequest({
      shi_codigo: get(user, "shi_codigo", null),
      token,
      busqueda: fileName,
      fecha_inicio: moment(selectionRange.startDate).format("L"),
      fecha_fin: moment(selectionRange.endDate).format("L"),
      ...data
    });

    await new Promise((resolve, reject) => {
      dispatch(
        fetchRemovedFiles(formData, (error, data) => {
          if (error) {
            return reject(error);
          }
          setAttributeCalendar();
          return resolve();
        })
      );
    });
  };

  const handleChangeFindByFileName = e => {
    setFileName(e.target.value);
  };

  const handleSelectionRange = ranges => {
    setSelectionRange(ranges.selection);
  };

  const handleCleanFilters = setFieldValue => {
    setFileName("");
    setSelectionRange(initialCalendarValues);
  };

  const onChangePagination = async page => {
    let data = {
      p: page
    };

    await getRemovedFiles(data);
  };

  const setAttributeCalendar = () => {
    let component = document.getElementsByClassName("dropdown-calendar-button");
    for (let i = 0; i < component.length; i++) {
      component[i].setAttribute("type", "button");
    }
  };

  useEffect(() => {
    // componentDidMount
    // componentDidUpdate
    getRemovedFiles();
  }, []);

  const { removedFiles, setActiveTab } = props;

  if (removedFiles.loading) return <LoadingScreen />;

  return (
    <div className="h-100 w-100">
      <div className="column is-12 is-flex justify-content-between align-items-center px-0">
        <h2 className="c-pointer bold" onClick={setActiveTab}>
          Ver Archivos Cargados
        </h2>
        <div>
          <FilterDropdown>
            <Formik
              initialValues={{
                fileName
              }}
              enableReinitialize
              onSubmit={(values, { setSubmitting }) => {
                getRemovedFiles();
                setSubmitting(false);
              }}
            >
              {props => {
                const { values, handleSubmit, setFieldValue } = props;
                return (
                  <form
                    onSubmit={handleSubmit}
                    className="column is-12 is-flex align-items-center flex-wrap"
                  >
                    <div className="column is-9 is-paddingless is-flex flex-wrap">
                      <div className="column is-4">
                        <Input
                          containerClass="column is-12 p-0"
                          placeholder={"Nombre de archivo"}
                          name="fileName"
                          value={values.fileName}
                          onChange={handleChangeFindByFileName}
                        />
                      </div>
                      <div className="column is-4">
                        <CalendarRangeDropdown
                          rangeName="Fecha Creados"
                          containerClass="input-form filters-input w-100 m-0"
                          selectionRange={selectionRange}
                          onChange={handleSelectionRange}
                        />
                      </div>
                    </div>
                    <div className="column is-3 is-flex flex-wrap justify-content-center">
                      <div className="column is-10">
                        <Button
                          containerClass="w-100 clean-filter-button"
                          text="Limpiar filtros"
                          type="button"
                          onClick={() => handleCleanFilters(setFieldValue)}
                        />
                      </div>
                      <div className="column is-10">
                        <Button containerClass="w-100" text="Buscar" />
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </FilterDropdown>
        </div>
      </div>
      <Table
        tableHead={[
          "No",
          "Archivo",
          "Usuario",
          "Fecha Carga",
          "Usuario Eliminación",
          "Fecha eliminación"
        ]}
        tableBody={map(get(removedFiles, "data", []), (item, i) => [
          i + 1,
          item.archivo,
          item.aud_usuario_proc,
          `${item.aud_fecha_proc} ${item.aud_hora_proc}`,
          item.aud_usuario_modi,
          `${item.aud_fecha_modi} ${item.aud_hora_modi}`
        ])}
      />
      <Paginator
        onChangePage={onChangePagination}
        totalText="Total de archivos"
        totalData={get(removedFiles, "total", 0)}
        total={get(removedFiles, "total", 0)}
        perPage={10}
        currentPage={parseInt(get(removedFiles, "page", 0))}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  token: tokenSelector(state),
  user: userSelector(state),
  removedFiles: removedFilesSelector(state)
});
export default connect(mapStateToProps)(UploadedFiles);
