import React from "react";
import StyledBackPage from "./styled";
import Icon from "../Icon";

const BackPage = ({ onClick, text = "Regresar al Dashboard Principal" }) => {
  return (
    <StyledBackPage
      onClick={onClick}
      className="is-flex align-items-center flex-wrap c-pointer column is-4 is-3-fullhd is-paddingless"
    >
      <Icon icon="backIcon" color="#9FA1B7" width={20} height={20} />
      <h3 className="bold">{text}</h3>
    </StyledBackPage>
  );
};

export default BackPage;
