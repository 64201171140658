import React from "react";
import StyledInputTextarea from "./styled";

const InputTextarea = ({
  containerClass = "",
  name = "",
  label = "",
  labelClass = "input-label",
  placeholder = "",
  inputClass = "input",
  value = "",
  onChange = () => {},
  rows = 3,
  maxLength = 250,
  onBlur,
  errors,
  disabled
}) => {
  return (
    <StyledInputTextarea className={containerClass}>
      <label className={labelClass}>{label}</label>
      <textarea
        disabled={disabled}
        autoComplete="off"
        name={name}
        placeholder={placeholder}
        className={`textarea ${inputClass}`}
        value={value}
        onChange={e => onChange(e)}
        onBlur={onBlur}
        rows={rows}
        maxLength={maxLength}
      />
      {errors && <p className="help is-danger error">{errors}</p>}
    </StyledInputTextarea>
  );
};

export default InputTextarea;
