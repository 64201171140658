import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/es";
import { Formik } from "formik";
import {
  isEmpty,
  get,
  map,
  includes,
  lte,
  gte,
  split,
  intersection
} from "lodash";
import {
  SHIPPERS_WITH_CICLES,
  TIGO_SHIPPER_CODES
} from "../../utils/shipperPermissions";

//custom
import { firstDateMonthRange, lastDate } from "../../utils/rangeDates";
import { getParamsRequest } from "../../utils/paramsRequest";
import Table from "../../components/Table-delivery-detail";
import Button from "../../components/button";
import CalendarRangeDropdown from "../../components/calendar-range-dropdown";
import SelectAutocomplete from "../../components/select-autocomplete";
import FilterDropdown from "../../components/filter-dropdown";
import DownloadButton from "../../components/download-button";
import Input from "../../components/Input";
import LoadingScreen from "../../components/loading_screen";
import Paginator from "../../components/paginator";
import ModalSuccess from "../../components/modal_document";
import SuccessIcon from "../../assets/images/success-icon.svg";
import { getExcelWithTabsFile } from "../../utils/excelWithTabsFileGenerator";

//actions
import {
  fetchClaims,
  fetchClaimsAll,
  fetchClaimsSummary,
  fetchProducts,
  fetchClaimsTypes
} from "../../actions/postal";

//selectors
import { tokenSelector, userSelector } from "../../actions/auth/selectors";
import {
  claimsSelector,
  claimsAllSelector,
  claimsSummarySelector,
  productsSelector,
  claimsTypesSelector
} from "../../actions/postal/selectors";

// validators
import { validateFilterForm } from "./validators/validateForm";

const allOption = { value: "%", label: "Todos" };

class ClaimsReport extends Component {
  state = {
    selectionRange: {
      startDate: firstDateMonthRange,
      endDate: lastDate,
      key: "selection"
    },
    hasCicle: !isEmpty(
      intersection(
        SHIPPERS_WITH_CICLES,
        split(this.props.user.shi_codigos, ",")
      )
    ),
    selectedOption: false,
    selectedClaimTypeOption: allOption,
    cicle: "",
    claimNum: "",
    modalDocument: false,
    loading: false
  };

  async componentDidMount() {
    const { token, user } = this.props;
    let userToken = getParamsRequest({
      shi_codigo: get(user, "shi_codigo", null),
      token
    });

    await this.getProducts(userToken);
    await this.getClaimsTypes();

    this.setAttributeCalendar();
  }

  getProducts = async data => {
    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        fetchProducts(data, (error, data) => {
          if (error) {
          }
          resolve();
        })
      );
    });
  };

  fetchClaims = async (page = 1) => {
    this.setState({ loading: true });
    const { dispatch, token, user } = this.props;
    const {
      selectionRange,
      cicle,
      claimNum,
      selectedOption,
      selectedClaimTypeOption
    } = this.state;

    let data = getParamsRequest({
      token,
      cic_cliente: cicle,
      shi_codigo: get(user, "shi_codigo", null),
      pro_codigo:
        selectedOption[0].value === "%"
          ? "%"
          : `"${selectedOption.map(item => item.value).join()}"`,
      p: page,
      ciclo_inicio: moment(selectionRange.startDate).format("L"),
      ciclo_fin: moment(selectionRange.endDate).format("L"),
      tipo_reclamo: selectedClaimTypeOption.value,
      ...(!isEmpty(claimNum) && {
        num_reclamo: claimNum
      })
    });

    await new Promise((resolve, reject) => {
      dispatch(
        fetchClaims(data, (error, data) => {
          if (error) {
          }
          resolve();
          this.setState({ loading: false });
          return this.setAttributeCalendar();
        })
      );
    });
  };

  fetchClaimsSummary = async () => {
    const { dispatch, token, user } = this.props;
    const { selectionRange, selectedOption, cicle } = this.state;

    let data = getParamsRequest({
      token,
      cic_cliente: cicle,
      shi_codigo: get(user, "shi_codigo", null),
      pro_codigo:
        selectedOption[0].value === "%"
          ? "%"
          : `"${selectedOption.map(item => item.value).join()}"`,
      ciclo_inicio: moment(selectionRange.startDate).format("L"),
      ciclo_fin: moment(selectionRange.endDate).format("L")
    });

    await new Promise((resolve, reject) => {
      dispatch(
        fetchClaimsSummary(data, (error, data) => {
          if (error) {
          }
          this.setAttributeCalendar();
          resolve();
        })
      );
    });
  };

  fetchClaimsDocument = async data => {
    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        fetchClaimsAll(data, (error, data) => {
          if (error) {
          }
          resolve();
        })
      );
    });
  };

  getClaimsTypes = async () => {
    const { dispatch, user } = this.props;

    let data = getParamsRequest({
      shi_codigo: get(user, "shi_codigo", null),
      tipo: 2
    });

    await new Promise((resolve, reject) => {
      dispatch(
        fetchClaimsTypes(data, (error, data) => {
          if (error) {
            return reject(error);
          }
          return resolve();
        })
      );
    });
  };

  setAttributeCalendar = () => {
    let component = document.getElementsByClassName("dropdown-calendar-button");
    for (let i = 0; i < component.length; i++) {
      component[i].setAttribute("type", "button");
    }
  };

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleInputDate = e => {
    if (e.target.value.length === 8) {
      const date = moment(e.target.value, "YYYYMMDD").format();
      let convertedStartDate = new Date(date).setMonth(
        new Date(date).getMonth() + 1
      );
      let convertedEndDate = new Date(
        new Date(date).setMonth(new Date(date).getMonth() + 2)
      );

      this.setState({
        selectedOption: [allOption],
        selectionRange: {
          startDate: convertedStartDate,
          endDate: convertedEndDate,
          key: "selection"
        }
      });
    } else if (e.target.value.length === 0) {
      this.setState({
        selectedOption: false,
        selectionRange: {
          startDate: firstDateMonthRange,
          endDate: lastDate,
          key: "selection"
        }
      });
    }
  };

  handleCleanFilters = () => {
    this.setState({
      cicle: "",
      selectedOption: false,
      selectedClaimTypeOption: allOption,
      selectionRange: {
        startDate: firstDateMonthRange,
        endDate: lastDate,
        key: "selection"
      }
    });
  };

  handleSelectProcess = ranges => {
    this.setState({
      selectionRange: ranges.selection
    });
  };

  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };

  handleChangeClaimsType = selectedClaimTypeOption => {
    this.setState({ selectedClaimTypeOption });
  };

  onChangePagination = async page => {
    await this.fetchClaims(page);
  };

  handleModalDocument = modalDocument => {
    this.setState({ modalDocument });
  };

  handleReportFile = async () => {
    this.handleModalDocument(true);
    const { token, user } = this.props;
    const { selectionRange, cicle, selectedOption, claimNum } = this.state;

    let data = getParamsRequest({
      token,
      cic_cliente: cicle,
      shi_codigo: get(user, "shi_codigo", null),
      pro_codigo:
        selectedOption[0].value === "%"
          ? "%"
          : `"${selectedOption.map(item => item.value).join()}"`,
      ciclo_inicio: moment(selectionRange.startDate).format("L"),
      ciclo_fin: moment(selectionRange.endDate).format("L"),
      tipo: 2,
      ...(!isEmpty(claimNum) && {
        num_reclamo: claimNum
      })
    });

    await this.fetchClaimsDocument(data);
    await this.fetchClaimsSummary();

    let fileData = [];
    let cicloInicio = moment(selectionRange.startDate).format("L");
    let cicloFin = moment(selectionRange.endDate).format("L");
    let getData = await this.generateDocument();
    let getSummary = await this.generateSummaryDocument();
    fileData.push({ data: getData, bookName: "Detalle" });
    fileData.push({ data: getSummary, bookName: "Resumen" });

    getExcelWithTabsFile(
      fileData,
      `RECLAMOS_${isEmpty(cicle) ? `${cicloInicio}_${cicloFin}` : cicle}`
    );

    this.handleModalDocument(false);
  };

  generateDocument = () => {
    const {
      claimsAll: { data },
      user
    } = this.props;
    let claimsReport = [
      [
        "Corr",
        "Código",
        ...(!isEmpty(
          intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ","))
        )
          ? [
              "Ciclo",
              "Cod. Cliente",
              "Anexo",
              "Tipo Doc.",
              "Cliente",
              "Dirección"
            ]
          : []),
        "Fecha Reclamo",
        "Usuario solicitante",
        "Reclamo",
        "Tipo reclamo",
        "Fecha Solución",
        "Solución",
        "Cod. Auditoría",
        "Estado",
        "SLA"
      ]
    ];

    map(data, (item, index) =>
      claimsReport.push([
        index + 1,
        item.gui_numero,
        ...(!isEmpty(
          intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ","))
        )
          ? [
              get(item, "ciclo", "--"),
              get(item, "cliente", "--"),
              get(item, "anexo", "--"),
              get(item, "tipo_factura", "--"),
              get(item, "nombre_cliente", "--"),
              get(item, "direccion", "--")
            ]
          : []),
        `${item.fecha_inicia} ${item.hora_inicia}`,
        item.aud_usuario_proc,
        item.comentario_reclamo,
        item.trec_descripcion,
        isEmpty(item.fecha_fin) ? "" : `${item.fecha_fin} ${item.hora_fin}`,
        isEmpty(item.fecha_fin) ? "" : get(item, "comentario_resolucion", ""),
        item.num_reclamo,
        item.erec_codigo === "RP" ? "Abierto" : "Cerrado",
        gte(this.getSLA(item.fecha_inicia, item.fecha_fin), 1) &&
        lte(this.getSLA(item.fecha_inicia, item.fecha_fin), 3)
          ? "SI"
          : "NO"
      ])
    );

    return claimsReport;
  };

  generateSummaryDocument = () => {
    const {
      claimsSummary: { data },
      user
    } = this.props;

    let claimsSummaryReport = [
      [
        "Año",
        "Mes",
        "Dia",
        ...(!isEmpty(
          intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ","))
        )
          ? ["ciclo"]
          : []),
        "Tipo producto",
        "Total",
        "Abiertos",
        "Cerrados"
      ]
    ];

    map(data, (item, index) =>
      claimsSummaryReport.push([
        item.anio,
        item.mes,
        item.dia,
        ...(!isEmpty(
          intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ","))
        )
          ? [item.ciclo]
          : []),
        item.cat_tipo,
        item.total,
        item.abiertos,
        item.cerrados
      ])
    );

    return claimsSummaryReport;
  };

  getSLA = (startDate, endDate) => {
    var numWorkDays = 0;
    if (!isEmpty(endDate)) {
      let formatStartDate = moment(startDate, "DD/MM/YYYY").format();
      let formatEndDate = moment(endDate, "DD/MM/YYYY").format();
      var currentStartDate = formatStartDate;

      while (moment(currentStartDate) <= moment(formatEndDate)) {
        //* Skips Sunday and Saturday
        if (!includes([6, 7], moment(currentStartDate).day())) {
          numWorkDays++;
        }

        currentStartDate = moment(currentStartDate).add(1, "days");
      }
    }

    return numWorkDays;
  };

  render() {
    const {
      selectionRange,
      hasCicle,
      selectedOption,
      modalDocument,
      loading,
      selectedClaimTypeOption
    } = this.state;
    const { claims, products, claimsTypes, user } = this.props;
    let parseProducts = products.data.map(item => ({
      value: item.pro_codigo,
      label: isEmpty(get(item, "shipper_product_code"))
        ? item.pro_codigo
        : `${item.shipper_product_code} - ${item.pro_codigo}`
    }));

    let parseclaimsTypes = claimsTypes.data.map(item => ({
      value: item.codigo,
      label: item.descripcion
    }));
    parseProducts.unshift(allOption);
    parseclaimsTypes.unshift(allOption);

    if (loading) return <LoadingScreen />;

    return (
      <div className="h-100 w-100">
        <div className="column is-12 is-flex justify-content-end px-0">
          <DownloadButton
            // data={queryAllXLSXData}
            fileName={`DetalleDeEnvios_PROCESADO${moment(
              selectionRange.startDate
            ).format("L")}_${moment(selectionRange.endDate).format(
              "L"
            )}_CORTE${moment(selectionRange.startDate).format("L")}_${moment(
              selectionRange.endDate
            ).format("L")}`}
            onClick={() => this.handleReportFile()}
            disabled={claims.data.length === 0}
          />
          <FilterDropdown>
            <Formik
              initialValues={this.state}
              validationSchema={validateFilterForm}
              enableReinitialize
              onSubmit={(values, { setSubmitting }) => {
                this.fetchClaims();
                setSubmitting(false);
              }}
            >
              {props => {
                const { errors, values, handleSubmit } = props;
                return (
                  <form
                    onSubmit={handleSubmit}
                    className="column is-12 is-flex align-items-center flex-wrap"
                  >
                    <div className="column is-9 is-paddingless is-flex flex-wrap">
                      <div className="column is-4">
                        <Input
                          containerClass="column is-12 p-0"
                          placeholder={"Ingrese número de reclamo"}
                          name="claimNum"
                          value={values.claimNum}
                          onChange={e => {
                            this.handleInputChange(e);
                          }}
                          // errors={errors.claimNum}
                        />
                      </div>
                      {hasCicle && (
                        <div className="column is-4">
                          <Input
                            containerClass="column is-12 p-0"
                            placeholder={"Ingrese ciclo"}
                            name="cicle"
                            value={values.cicle}
                            onChange={e => {
                              this.handleInputChange(e);
                              this.handleInputDate(e);
                            }}
                            errors={errors.cicle}
                          />
                        </div>
                      )}
                      <div className="column is-4">
                        <SelectAutocomplete
                          onChange={this.handleChange}
                          containerClass="input-form filters-input filters-input-select w-100 is-paddingless m-0"
                          options={parseProducts}
                          selectedOption={selectedOption}
                          error={errors.productsValidation}
                          isMulti
                        />
                      </div>
                      <div className="column is-4">
                        <CalendarRangeDropdown
                          rangeName="Fecha"
                          containerClass="input-form filters-input w-100 m-0"
                          selectionRange={selectionRange}
                          onChange={this.handleSelectProcess}
                        />
                      </div>
                      <div className="column is-4">
                        <SelectAutocomplete
                          onChange={this.handleChangeClaimsType}
                          containerClass="input-form filters-input filters-input-select w-100 is-paddingless m-0"
                          options={parseclaimsTypes}
                          selectedOption={selectedClaimTypeOption}
                          error={errors.productsValidation}
                          placeholder="Tipo de reclamo"
                        />
                      </div>
                    </div>
                    <div className="column is-3 is-flex flex-wrap justify-content-center">
                      <div className="column is-10">
                        <Button
                          containerClass="w-100 clean-filter-button"
                          text="Limpiar filtros"
                          type="button"
                          onClick={() => this.handleCleanFilters()}
                        />
                      </div>
                      <div className="column is-10">
                        <Button containerClass="w-100" text="Buscar" />
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </FilterDropdown>
        </div>
        <Table
          tableHead={[
            "Código",
            ...(!isEmpty(
              intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ","))
            )
              ? ["Ciclo", "Cod. Cliente", "Anexo"]
              : []),
            // "Tipo Doc.",
            // "Cliente",
            // "Dirección",
            "Fecha Reclamo",
            "Usuario Solicitante",
            "Reclamo",
            "Fecha Solución",
            "Solución",
            "Cod. Auditoría",
            "SLA"
          ]}
          tableBody={map(get(claims, "data", []), item => [
            item.gui_numero,
            ...(!isEmpty(
              intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ","))
            )
              ? [
                  get(item, "ciclo", "--"),
                  get(item, "cliente", "--"),
                  get(item, "anexo", "--")
                ]
              : []),
            // item.tipo_factura,
            // item.nombre_cliente,
            // item.direccion,
            `${item.fecha_inicia} ${item.hora_inicia}`,
            item.aud_usuario_proc,
            item.comentario_reclamo,
            isEmpty(item.fecha_fin)
              ? "--"
              : `${item.fecha_fin} ${item.hora_fin}`,
            isEmpty(item.fecha_fin) ? (
              "--"
            ) : (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.urbano.com.sv/respuestas/${item.num_reclamo}.pdf`}
                className="has-text-info"
              >
                Solución Auditoría
              </a>
            ),
            item.num_reclamo,
            gte(this.getSLA(item.fecha_inicia, item.fecha_fin), 1) &&
            lte(this.getSLA(item.fecha_inicia, item.fecha_fin), 3)
              ? "SI"
              : "NO"
          ])}
        />
        <Paginator
          onChangePage={this.onChangePagination}
          totalText="Total Reclamos"
          totalData={get(claims, "total", 0)}
          total={get(claims, "total", 0)}
          perPage={20}
          currentPage={parseInt(get(claims, "page", 0))}
        />
        <ModalSuccess
          active={modalDocument}
          onClose={() => this.handleModalDocument(false)}
          icon={SuccessIcon}
          title="Generando Reporte"
          titleClass="column is-10 mx-auto"
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: tokenSelector(state),
  claims: claimsSelector(state),
  claimsAll: claimsAllSelector(state),
  claimsSummary: claimsSummarySelector(state),
  user: userSelector(state),
  products: productsSelector(state),
  claimsTypes: claimsTypesSelector(state)
});
export default connect(mapStateToProps)(ClaimsReport);
