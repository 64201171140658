import React, { Component } from "react";
import { Link } from "react-router-dom";
import StyledReports from "./styled";
import { connect } from "react-redux";
import { Formik } from "formik";
import moment from "moment";
import "moment/locale/es";
import { getParamsRequest } from "../../utils/paramsRequest";
import { MdLocationOn } from "react-icons/md";
import { isEmpty, get, split, intersection } from "lodash";
import * as theme from "../../utils/colors";
import { getExcelFile } from "../../utils/excelFileGenerator";
import {
  TIGO_SHIPPER_CODES,
  SHIPPERS_WITH_CICLES,
} from "../../utils/shipperPermissions";

// custom
import DownloadButton from "../../components/download-button";
import SelectAutocomplete from "../../components/select-autocomplete";
import CalendarRangeDropdown from "../../components/calendar-range-dropdown";
import FilterDropdown from "../../components/filter-dropdown";
import Table from "../../components/Table-delivery-detail";
import LoadingScreen from "../../components/loading_screen";
import Paginator from "../../components/paginator";
import Button from "../../components/button";
import Toggle from "../../components/toggle";
import Input from "../../components/Input";
import ModalSuccess from "../../components/modal_document";
import SuccessIcon from "../../assets/images/success-icon.svg";
import ClaimsReport from "./ClaimsReport";
import ZoningReport from "./ZoningReport";

// actions
import {
  fetchProducts,
  fetchQuery,
  fetchQueryAllData,
  fetchZCEnvios,
  fetchCheckpoints,
} from "../../actions/postal";

// selectors
import {
  RGORanksSelector,
  productsSelector,
  zonificacionCierreEnviosSelector,
  querySelector,
  queryAllDataSelector,
  checkpointsSelector,
} from "../../actions/postal/selectors";

import { tokenSelector, userSelector } from "../../actions/auth/selectors";

//DATE-RANGES
import { firstDateMonthRange, lastDate } from "../../utils/rangeDates";

// validators
import { validateFilterForm } from "./validators/validateForm";

const allOption = { value: "%", label: "Todos" };

class Reports extends Component {
  state = {
    selectedOption: false,
    selectedCheckpoint: false,
    selectionRange: {
      startDate: firstDateMonthRange,
      endDate: lastDate,
      key: "selection",
    },
    selectionRangeCourt: {
      startDate: firstDateMonthRange,
      endDate: lastDate,
      key: "selection",
    },
    selectionRangeProcess: {
      startDate: firstDateMonthRange,
      endDate: lastDate,
      key: "selection",
    },
    cicle: "",
    hasCicle: !isEmpty(
      intersection(
        SHIPPERS_WITH_CICLES,
        split(this.props.user.shi_codigos, ",")
      )
    ),
    dateSelected: new Date(),
    activeTab: 1,
    zonificacionFile: null,
    cierreFile: null,
    location: false,
    modalDocument: false,
  };
  async componentDidMount() {
    const { token, user } = this.props;

    let userToken = getParamsRequest({
      shi_codigo: get(user, "shi_codigo", null),
      token,
    });
    this.setAttributeCalendar();
    await this.getCheckpoints(
      getParamsRequest({
        token,
        shi_codigo: get(user, "shi_codigo", null),
        checkpoint: 1,
      })
    );
    await this.getProducts(userToken);
  }

  setAttributeCalendar = () => {
    let component = document.getElementsByClassName("dropdown-calendar-button");
    for (let i = 0; i < component.length; i++) {
      component[i].setAttribute("type", "button");
    }
  };

  getCheckpoints = async (data) => {
    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        fetchCheckpoints(data, (error, data) => {
          if (error) {
          }
          resolve();
        })
      );
    });
  };

  getQuery = async (data) => {
    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        fetchQuery(data, (error, data) => {
          if (error) {
          }
          resolve();
        })
      );
    });
  };

  getQueryAllData = async (data) => {
    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        fetchQueryAllData(data, (error, data) => {
          if (error) {
          }
          resolve();
        })
      );
    });
  };

  getZCEnvios = async (data) => {
    const { activeTab } = this.state;
    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        fetchZCEnvios(data, (error, data) => {
          if (error) {
          }
          if (data) {
            if (activeTab === 2) {
              this.setState({ zonificacionFile: data[0].ruta });
            } else if (activeTab === 3) {
              this.setState({ cierreFile: data[0].ruta });
            }
          }
          resolve();
        })
      );
    });
  };

  getProducts = async (data) => {
    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      dispatch(
        fetchProducts(data, (error, data) => {
          if (error) {
          }
          resolve();
        })
      );
    });
  };

  getQueryData = async (page = 1) => {
    const { token, user } = this.props;
    const {
      selectedOption,
      selectedCheckpoint,
      selectionRangeCourt,
      selectionRangeProcess,
      location,
      cicle,
    } = this.state;
    let data = getParamsRequest({
      token,
      shi_codigo: get(user, "shi_codigo", null),
      pro_codigo:
        selectedOption[0].value === "%"
          ? "%"
          : `"${selectedOption.map((item) => item.value).join()}"`,
      checkpoint: selectedCheckpoint.value,
      cic_inicio: moment(selectionRangeCourt.startDate).format("L"),
      cic_fin: moment(selectionRangeCourt.endDate).format("L"),
      f_inicio: moment(selectionRangeProcess.startDate).format("L"),
      f_fin: moment(selectionRangeProcess.endDate).format("L"),
      location: location ? 1 : 0,
      p: page,
      shipper_prod: cicle,
    });
    await this.getQuery(data);
    this.setAttributeCalendar();
  };

  handleCleanFilters = () => {
    this.setState({
      selectedOption: false,
      selectedCheckpoint: false,
      selectionRange: {
        startDate: firstDateMonthRange,
        endDate: lastDate,
        key: "selection",
      },
      selectionRangeCourt: {
        startDate: firstDateMonthRange,
        endDate: lastDate,
        key: "selection",
      },
      selectionRangeProcess: {
        startDate: firstDateMonthRange,
        endDate: lastDate,
        key: "selection",
      },
    });
  };

  getZonificacion = async () => {
    const { selectedOption, dateSelected } = this.state;
    const { token, user } = this.props;
    let data = getParamsRequest({
      token,
      shi_codigo: get(user, "shi_codigo", null),
      pro_codigo: selectedOption.value,
      f_inicio: moment(dateSelected).format("L"),
      // f_fin: moment(lastDate).format("L")
      tipo: "Z",
    });
    await this.getZCEnvios(data);
  };

  getCierre = async () => {
    const { selectedOption, dateSelected } = this.state;
    const { token, user } = this.props;
    let data = getParamsRequest({
      token,
      shi_codigo: get(user, "shi_codigo", null),
      pro_codigo: selectedOption.value,
      f_inicio: moment(dateSelected).format("L"),
      // f_fin: moment(lastDate).format("L")
      tipo: "C",
    });
    await this.getZCEnvios(data);
  };

  setActiveTab = (activeTab) => {
    this.setState({
      activeTab,
      selectedOption: null,
      dateSelected: new Date(),
    });
  };

  handleSelect = (dateSelected) => {
    this.setState({ dateSelected }, async () => { });
  };

  handleSelectCourt = (ranges) => {
    this.setState({
      selectionRangeCourt: ranges.selection,
    });
  };

  handleSelectProcess = (ranges) => {
    this.setState({
      selectionRangeProcess: ranges.selection,
    });
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeCheckpoint = (selectedCheckpoint) => {
    this.setState({ selectedCheckpoint });
  };

  onChangePagination = async (page) => {
    await this.getQueryData(page);
  };

  onChangeToggle = () => {
    const { location } = this.state;
    this.setState({ location: !location });
  };

  handleInputDate = (e) => {
    if (e.target.value.length === 8) {
      const date = moment(e.target.value, "YYYYMMDD").format();
      let convertedStartDate = new Date(date).setMonth(
        new Date(date).getMonth() + 1
      );
      let convertedEndDate = new Date(
        new Date(date).setMonth(new Date(date).getMonth() + 2)
      );

      this.setState({
        selectedOption: [allOption],
        selectedCheckpoint: allOption,
        selectionRangeCourt: {
          startDate: convertedStartDate,
          endDate: convertedEndDate,
          key: "selection",
        },
        selectionRangeProcess: {
          startDate: convertedStartDate,
          endDate: convertedEndDate,
          key: "selection",
        },
      });
    } else if (e.target.value.length === 0) {
      this.setState({
        selectedOption: false,
        selectedCheckpoint: false,
        selectionRangeCourt: {
          startDate: firstDateMonthRange,
          endDate: lastDate,
          key: "selection",
        },
        selectionRangeProcess: {
          startDate: firstDateMonthRange,
          endDate: lastDate,
          key: "selection",
        },
      });
    }
  };

  handleReportFile = async () => {
    this.handleModalDocument(true);
    const { token, user } = this.props;
    const {
      selectedOption,
      selectedCheckpoint,
      selectionRangeCourt,
      selectionRangeProcess,
      location,
      cicle,
    } = this.state;
    let data = getParamsRequest({
      token,
      shi_codigo: get(user, "shi_codigo", null),
      pro_codigo:
        selectedOption[0].value === "%"
          ? "%"
          : `"${selectedOption.map((item) => item.value).join()}"`,
      checkpoint: selectedCheckpoint.value,
      cic_inicio: moment(selectionRangeCourt.startDate).format("L"),
      cic_fin: moment(selectionRangeCourt.endDate).format("L"),
      f_inicio: moment(selectionRangeProcess.startDate).format("L"),
      f_fin: moment(selectionRangeProcess.endDate).format("L"),
      location: location ? 1 : 0,
      shipper_prod: cicle,
    });
    await this.getQueryAllData(data);
    this.setAttributeCalendar();

    let fileData = await this.generateDocument();
    let cicloInicio = moment(selectionRangeCourt.startDate).format("L");
    let cicloFin = moment(selectionRangeCourt.endDate).format("L");

    getExcelFile(
      fileData,
      `Distribucion_Urbano_${isEmpty(cicle) ? `${cicloInicio}_${cicloFin}` : cicle
      }`
    );

    this.handleModalDocument(false);
  };

  generateDocument = () => {
    const { queryAllData, user } = this.props;
    let queryAllXLSXData = [
      [
        "Corr",
        "Proveedor",
        "Shipper",
        "Producto",
        "Corte",
        ...(!isEmpty(
          intersection(SHIPPERS_WITH_CICLES, split(user.shi_codigos, ","))
        )
          ? ["Ciclo"]
          : []),
        "Codigo cliente",
        ...(!isEmpty(
          intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ","))
        )
          ? [
            "Anexo",
            // "Ciclo",
            // "Cliente",
            "ID Sobre",
            "Tipo Sobre",
            "Nombre Cliente",
            "Dirección",
          ]
          : []),
        ...(isEmpty(
          intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ","))
        )
          ? ["Nombre", "Dirección", "telefono"]
          : []),
        // "Entrega",
        "Chk",
        "Motivo",
        "Comentario",
        "Fecha",
        "Latitud",
        "Longitud",

        // "FECHA RECEPCIÓN"
        ...(!isEmpty(
          intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ","))
        )
          ? ["Direccion sugerida"]
          : []),
      ],
    ];

    queryAllData.data &&
      queryAllData.data.map((item, i) =>
        queryAllXLSXData.push([
          i + 1,
          "Urbano Express",
          item.shi_codigo,
          isEmpty(item.shipper_prod)
            ? item.pro_codigo
            : `${item.shipper_prod}-${item.pro_codigo}`,
          item.cic_inicio,
          ...(!isEmpty(
            intersection(SHIPPERS_WITH_CICLES, split(user.shi_codigos, ","))
          )
            ? [isEmpty(item.ciclo) ? "--" : item.ciclo]
            : []),
          item.cli_codigo,
          ...(!isEmpty(
            intersection(SHIPPERS_WITH_CICLES, split(user.shi_codigos, ","))
          )
            ? [
              isEmpty(item.anexo) ? "--" : item.anexo,
              // isEmpty(item.ciclo) ? "--" : item.ciclo,
              // isEmpty(item.cliente_tigo) ? "--" : item.cliente_tigo,
              isEmpty(item.id_sobre) ? "--" : item.id_sobre,
              isEmpty(item.sobre_tipo) ? "--" : item.sobre_tipo,
              isEmpty(item.nombre_cliente) ? "--" : item.nombre_cliente,
              isEmpty(item.direccion) ? "--" : item.direccion,
            ]
            : []),
          ...(isEmpty(
            intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ","))
          )
            ? [
              item.cli_nombre,
              `${item.cli_direccion1} ${item.cli_direccion2}`,
              `${isEmpty(item.cli_telefono1) ? "--" : item.cli_telefono1} / ${isEmpty(item.cli_telefono2) ? "--" : item.cli_telefono2
              }`,
            ]
            : []),
          // item.mov_recibio,
          ...[
            ...(item.chk_codigo === "DL"
              ? [
                !isEmpty(
                  intersection(
                    TIGO_SHIPPER_CODES,
                    split(user.shi_codigos, ",")
                  )
                )
                  ? "ENTREGA EFECTIVA"
                  : "ENTREGADO",
              ]
              : []),
            ...(item.chk_codigo === "DV"
              ? [
                !isEmpty(
                  intersection(
                    TIGO_SHIPPER_CODES,
                    split(user.shi_codigos, ",")
                  )
                )
                  ? "RECHAZO"
                  : "DEVUELTO",
              ]
              : []),
            ...(item.chk_codigo === "RE"
              ? [
                !isEmpty(
                  intersection(
                    TIGO_SHIPPER_CODES,
                    split(user.shi_codigos, ",")
                  )
                )
                  ? "RETENCION"
                  : "RETENIDO",
              ]
              : []),
          ],
          item.mot_descripcion === "" || item.mot_descripcion.length === 0
            ? ""
            : item.mot_descripcion,
          item.comentario,
          item.dat_fecha_chk,
          !isEmpty(
            intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ","))
          )
            ? parseInt(item.latitud) === 0 || item.latitud.length === 0
              ? ""
              : item.latitud
            : parseInt(item.latitud) === 0 || item.latitud.length === 0
              ? "NO"
              : "SI",
          !isEmpty(
            intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ","))
          )
            ? parseInt(item.longitud) === 0 || item.longitud.length === 0
              ? ""
              : item.longitud
            : parseInt(item.longitud) === 0 || item.longitud.length === 0
              ? "NO"
              : "SI",
          // item.fecha_recepcion
          ...(isEmpty(
            intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ","))
          )
            ? [""]
            : []),
        ])
      );

    return queryAllXLSXData;
  };

  handleModalDocument = (modalDocument) => {
    this.setState({ modalDocument });
  };

  render() {
    moment.locale("es");
    const {
      selectionRangeCourt,
      selectionRangeProcess,
      activeTab,
      selectedOption,
      selectedCheckpoint,
      hasCicle,
      location,
      modalDocument,
    } = this.state;
    const {
      RGORanks,
      products,
      ZCEnvios,
      query,
      user,
      checkpoints,
    } = this.props;
    const { loading } = RGORanks;
    const { page, total } = query;

    let parseProducts = products.data.map((item) => ({
      value: item.pro_codigo,
      label: isEmpty(get(item, "shipper_product_code"))
        ? item.pro_codigo
        : `${item.shipper_product_code} - ${item.pro_codigo}`,
    }));

    let parseCheckpoints = checkpoints.data.map((item) => ({
      value: item.codigo,
      label: `${item.codigo} - ${item.descripcion}`,
    }));

    parseProducts.unshift(allOption);
    parseCheckpoints.unshift(allOption);

    if (loading || query.loading) return <LoadingScreen />;

    return (
      <StyledReports className="w-100 h-100">
        {ZCEnvios.loading && <LoadingScreen isRequest />}
        <div className="column is-12 tabs-container is-flex flex-wrap">
          <div onClick={() => this.setActiveTab(1)} className="tab-content">
            <p
              className={`bold has-text-black h-100 is-flex align-items-center justify-content-center ${activeTab === 1 ? "active" : ""
                }`}
            >
              Detalle de envíos
            </p>
          </div>
          {!isEmpty(
            intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ","))
          ) && (
              <div onClick={() => this.setActiveTab(2)} className="tab-content">
                <p
                  className={`bold has-text-black h-100 is-flex align-items-center justify-content-center ${activeTab === 2 ? "active" : ""
                    }`}
                >
                  Zonificación
                </p>
              </div>
            )}
          <div onClick={() => this.setActiveTab(4)} className="tab-content">
            <p
              className={`bold has-text-black h-100 is-flex align-items-center justify-content-center ${activeTab === 4 ? "active" : ""
                }`}
            >
              Reclamos
            </p>
          </div>
        </div>
        <section className="report-section">
          {activeTab === 1 && (
            <div className="report-container h-100 w-100">
              <div className="column is-12 is-flex justify-content-end px-0">
                <DownloadButton
                  // data={queryAllXLSXData}
                  fileName={`DetalleDeEnvios_PROCESADO${moment(
                    selectionRangeProcess.startDate
                  ).format("L")}_${moment(selectionRangeProcess.endDate).format(
                    "L"
                  )}_CORTE${moment(selectionRangeCourt.startDate).format(
                    "L"
                  )}_${moment(selectionRangeCourt.endDate).format("L")}`}
                  onClick={() => this.handleReportFile()}
                  disabled={query.data.length === 0}
                />
                <FilterDropdown>
                  <Formik
                    initialValues={this.state}
                    validationSchema={validateFilterForm}
                    enableReinitialize
                    onSubmit={(values, { setSubmitting }) => {
                      this.getQueryData();
                      setSubmitting(false);
                    }}
                  >
                    {(props) => {
                      const { errors, values, handleSubmit } = props;
                      return (
                        <form
                          onSubmit={handleSubmit}
                          className="column is-12 is-flex align-items-center flex-wrap"
                        >
                          <div className="column is-9 is-paddingless is-flex flex-wrap">
                            {hasCicle && (
                              <div className="column is-4">
                                <Input
                                  containerClass="column is-12 p-0"
                                  placeholder={"Ingrese ciclo"}
                                  name="cicle"
                                  value={values.cicle}
                                  onChange={(e) => {
                                    this.handleInputChange(e);
                                    this.handleInputDate(e);
                                  }}
                                  errors={errors.cicle}
                                />
                              </div>
                            )}
                            <div className="column is-4">
                              <SelectAutocomplete
                                onChange={this.handleChange}
                                containerClass="input-form filters-input filters-input-select w-100 is-paddingless m-0"
                                options={parseProducts}
                                selectedOption={selectedOption}
                                error={errors.productsValidation}
                                isMulti
                              />
                            </div>
                            <div className="column is-4">
                              <SelectAutocomplete
                                onChange={this.handleChangeCheckpoint}
                                containerClass="input-form filters-input filters-input-select w-100 is-paddingless m-0"
                                options={parseCheckpoints}
                                selectedOption={selectedCheckpoint}
                                placeholder="Seleccione Checkpoint"
                                error={errors.checkpointsValidation}
                              />
                            </div>
                            <div className="column is-4">
                              <CalendarRangeDropdown
                                rangeName="Procesado"
                                containerClass="input-form filters-input w-100 m-0"
                                selectionRange={selectionRangeProcess}
                                onChange={this.handleSelectProcess}
                              />
                            </div>
                            <div className="column is-4">
                              <CalendarRangeDropdown
                                rangeName="Corte"
                                containerClass="input-form filters-input w-100 m-0"
                                selectionRange={selectionRangeCourt}
                                onChange={this.handleSelectCourt}
                              />
                            </div>
                            <div className="column is-4 is-flex align-items-center location-container justify-content-between">
                              <div className="is-flex align-items-center">
                                <MdLocationOn
                                  size="2rem"
                                  color={
                                    location
                                      ? user.theme
                                        ? theme[user.theme.toUpperCase()]
                                          .primary
                                        : "#DF9C9F"
                                      : "#cccccc"
                                  }
                                />
                                <p
                                  className="bold"
                                  style={{
                                    color: location
                                      ? user.theme
                                        ? theme[user.theme.toUpperCase()]
                                          .primary
                                        : "#DF9C9F"
                                      : "#cccccc",
                                  }}
                                >
                                  Con localización
                                </p>
                              </div>
                              <Toggle
                                containerClass="form-toggle"
                                checked={location}
                                onChange={() => this.onChangeToggle()}
                              />
                            </div>
                          </div>
                          <div className="column is-3 is-flex flex-wrap justify-content-center">
                            <div className="column is-10">
                              <Button
                                containerClass="w-100 clean-filter-button"
                                text="Limpiar filtros"
                                type="button"
                                onClick={() => this.handleCleanFilters()}
                              />
                            </div>
                            <div className="column is-10">
                              <Button containerClass="w-100" text="Buscar" />
                            </div>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </FilterDropdown>
              </div>
              <Table
                tableHead={[
                  "Corr",
                  "Prod",
                  "Corte",
                  "Codigo cliente",
                  "Nombre",
                  !isEmpty(
                    intersection(
                      TIGO_SHIPPER_CODES,
                      split(user.shi_codigos, ",")
                    )
                  )
                    ? "Anexo"
                    : "Teléfono",
                  "Entrega",
                  "Motivo",
                  "Fecha",
                  "Chk",
                  "GPS",
                  // "Agencia"
                ]}
                tableBody={
                  query.data
                    ? query.data.map((item, i) => [
                      i + 1,
                      isEmpty(item.shipper_prod)
                        ? item.pro_codigo
                        : `${item.shipper_prod}-${item.pro_codigo}`,
                      item.cic_inicio,
                      <Link
                        to={`/?account=${item.cli_codigo}`}
                        target="_blank"
                        className="has-text-link"
                      >
                        {item.cli_codigo}
                      </Link>,
                      item.cli_nombre,
                      // `${item.cli_telefono1}${
                      //   item.cli_telefono1 !== "" && item.cli_telefono2 !== ""
                      //     ? " - "
                      //     : ""
                      // }${item.cli_telefono2}`,
                      !isEmpty(
                        intersection(
                          TIGO_SHIPPER_CODES,
                          split(user.shi_codigos, ",")
                        )
                      ) ? (
                        item.anexo.length === 0 || item.anexo === null ? (
                          "--"
                        ) : (
                          <Link
                            to={`clients?reference=${item.anexo}`}
                            target="_blank"
                            className="has-text-link"
                          >
                            {item.anexo}
                          </Link>
                        )
                      ) : (
                        `${item.cli_telefono1 === ""
                          ? "--"
                          : item.cli_telefono1
                        } / ${item.cli_telefono2 === ""
                          ? "--"
                          : item.cli_telefono2
                        }`
                      ),
                      item.mov_recibio,
                      item.mot_descripcion === "" ||
                        item.mot_descripcion.length === 0
                        ? "Sin Motivo"
                        : item.mot_descripcion,
                      item.dat_fecha_chk,
                      item.chk_codigo,
                      !isEmpty(
                        intersection(
                          TIGO_SHIPPER_CODES,
                          split(user.shi_codigos, ",")
                        )
                      ) &&
                        (parseInt(item.latitud) !== 0 ||
                          item.latitud.length > 0) ? (
                        <MdLocationOn
                          size="1.5rem"
                          className="c-pointer"
                          onClick={() =>
                            window.open(
                              `https://www.google.com.sa/maps/search/${parseFloat(
                                item.latitud
                              )},${parseFloat(item.longitud)}`,
                              "_blank"
                            )
                          }
                          color={
                            parseInt(item.latitud) === 0 ||
                              item.latitud.length === 0
                              ? "#cccccc"
                              : user.theme
                                ? theme[user.theme.toUpperCase()].primary
                                : "#DF9C9F"
                          }
                        />
                      ) : (
                        <MdLocationOn
                          onClick={() => alert("jjjjj")}
                          color={
                            parseInt(item.latitud) === 0 ||
                              item.latitud.length === 0
                              ? "#cccccc"
                              : user.theme
                                ? theme[user.theme.toUpperCase()].primary
                                : "#DF9C9F"
                          }
                        />
                      ),
                      // item.prov_codigo
                    ])
                    : []
                }
              />
              <Paginator
                onChangePage={this.onChangePagination}
                totalText="Total Ciclos"
                totalData={total}
                total={total ? parseInt(total) : 0}
                perPage={20}
                currentPage={parseInt(page)}
              />
              {/* <NotFound
              containerClass="reports-not-state"
              title="No hay reportes generados"
            /> */}
            </div>
          )}
          {activeTab === 2 &&
            !isEmpty(
              intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ","))
            ) && <ZoningReport />}
          {activeTab === 4 && <ClaimsReport />}
          <ModalSuccess
            active={modalDocument}
            onClose={() => this.handleModalDocument(false)}
            // cancelText="Aceptar"
            icon={SuccessIcon}
            title="Generando Reporte"
            titleClass="column is-10 mx-auto"
          />
        </section>
      </StyledReports>
    );
  }
}

const mapStateToProps = (state) => ({
  RGORanks: RGORanksSelector(state),
  products: productsSelector(state),
  ZCEnvios: zonificacionCierreEnviosSelector(state),
  query: querySelector(state),
  queryAllData: queryAllDataSelector(state),
  token: tokenSelector(state),
  user: userSelector(state),
  checkpoints: checkpointsSelector(state),
});
export default connect(mapStateToProps)(Reports);
