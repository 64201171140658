import { put, takeLatest, call, delay } from "redux-saga/effects";
import queryString from "query-string";
import { isEmpty, split, intersection } from "lodash";
import { TIGO_SHIPPER_CODES } from "../../utils/shipperPermissions";

//actions
import {
  fetchCurrentUserProfileSuccess,
  fetchCurrentUserProfileError,
  changeCurrentShipperSuccess,
  changeCurrentShipperError,
  fetchHasIpSuccess,
  fetchHasIpError
} from "../auth";

//action types
import {
  FETCH_CURRENT_USER_PROFILE,
  CHANGE_CURRENT_SHIPPER,
  FETCH_HAS_IP
} from "../../action-types/auth";

//request
import { currentProfileRequest, hasIpRequest } from "../../api/auth";

function* fetchProfile(action) {
  try {
    const data = yield call(currentProfileRequest, action.payload);
    let parseData = queryString.parse(action.payload);

    data.token = parseData.token;

    if (
      !isEmpty(intersection(TIGO_SHIPPER_CODES, split(data.shi_codigos, ",")))
    ) {
      data.theme = "blueTheme";
    }
    yield put(fetchCurrentUserProfileSuccess(data));
    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(fetchCurrentUserProfileError(e));
    action.callback && action.callback(e);
  }
}

function* changeCurrentShipper(action) {
  try {
    const data = Object.assign({}, { shi_codigo: action.payload });
    yield put(changeCurrentShipperSuccess(data));
    yield delay(2000);
    // yield put(finishChangeCurrentShipper());

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(changeCurrentShipperError(e));

    action.callback && action.callback(e);
  }
}

function* hasIp(action) {
  try {
    const data = yield call(hasIpRequest, action.payload);
    yield put(fetchHasIpSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(fetchHasIpError(e));

    action.callback && action.callback(e);
  }
}

// function* refreshTokenSagas(action) {
//   const { auth } = yield select();
//   const { refresh_token } = auth;

//   try {
//     const data = yield call(refreshTokenRequest, { refresh_token });
//     yield put(loginSuccess(data));
//     yield put(refreshTokenSuccess(data));
//     action.callback && action.callback(null, data);
//   } catch (e) {
//     yield put(refreshTokenError(e));
//     yield put(logout());
//     global.location.href = '/sign-in';
//     action.callback && action.callback(e);
//   }
// }

// function* catchError(action) {
//   try {
//     const status = action.payload ? action.payload.status : 0;

//     if (status === 401) {
//       yield put(refreshTokenSagas());
//     }
//   } catch (error) {
//     yield put(logout());
//     global.location.href = '/sign-in';
//   }
// }

function* authSaga() {
  yield takeLatest(FETCH_CURRENT_USER_PROFILE, fetchProfile);
  yield takeLatest(CHANGE_CURRENT_SHIPPER, changeCurrentShipper);
  yield takeLatest(FETCH_HAS_IP, hasIp);
  // yield takeLatest(FETCH_CURRENT_USER_PROFILE_ERROR, catchError);
  // yield takeLatest(REFRESH_TOKEN, refreshTokenSagas);
}

export default authSaga;
