import styled from "styled-components";

const StyledInput = styled.div`
  .input-label {
    color: #60626e;
    margin-bottom: 4px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    text-transform: capitalize;
  }

  .select-wrapper {
    position: relative;
    select {
      font-family: "Muli";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      height: 2.5rem;
      background: #ffffff;
      border: 1px solid #dfe0eb;
      box-sizing: border-box;
      border-radius: 4px;
      color: #c5c7cd;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      padding: 0 2rem 0 12.25px;
      &:hover,
      &:focus {
        border: 1px solid #60626e;
        border-radius: 4px;
        box-shadow: none;
        outline: none;
      }
    }
    .arrow-icon {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      margin-right: 1rem;
    }
  }
`;

export default StyledInput;
