import React from "react";
import { connect } from "react-redux";
import StyledDownloadButton from "./styled";
import Icon from "../Icon";
import { getExcelFile } from "../../utils/excelFileGenerator";
import * as theme from "../../utils/colors";

//Selectors
import { userSelector } from "../../actions/auth/selectors";

const DownloadButton = ({
  data = [],
  fileName = "EmptyFile",
  user,
  containerClass,
  onClick,
  disabled
}) => {
  let color = user.theme ? theme[user.theme.toUpperCase()].primary : "#CF333C";
  return (
    <StyledDownloadButton
      type="button"
      onClick={() => (onClick ? onClick() : getExcelFile(data, fileName))}
      className={`button-space ${containerClass ? containerClass : ""}`}
      disabled={disabled}
    >
      <Icon icon="download" color={color} height={20} width={20} />
    </StyledDownloadButton>
  );
};

const mapStateToProps = state => ({
  user: userSelector(state)
});

export default connect(mapStateToProps)(DownloadButton);
