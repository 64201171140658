import styled from "styled-components";
import { WHITE, LAYOUTBACKGROUND } from "../../../utils/colors";
const headerHeight = "10.2rem";

const StyledZones = styled.div`
  .tabs-container {
    height: ${headerHeight};
    background-color: ${WHITE};
    padding: 0 1rem;
    box-shadow: 0px 2px 2px rgba(187, 187, 187, 0.25);
    .tab-content {
      cursor: pointer;
      padding: 0 1rem;
      p {
        &:hover,
        &.active {
          color: #3e4345;
          border-bottom: 0.1875rem solid #ff0000;
          background-color: transparent;
        }
      }
    }
  }
  .report-section {
    height: calc(100% - ${headerHeight});
  }
  .filters-input-select {
    /* width: 12rem; */
  }
  .form-header-button {
    margin-left: 0.75rem;
    min-width: 5rem;
  }
  .check-label {
    margin-left: 0.75rem;
    line-height: 0;
  }
  .clean-filter-button {
    background-color: #9fa1b7;
  }
  .map-loading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: ${LAYOUTBACKGROUND};
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default StyledZones;
