import React, { useState } from "react";
import StyledFiles from "./styled";
import { connect } from "react-redux";
import { get } from "lodash";
import moment from "moment";
import "moment/locale/es";

// customs
import UploadedFiles from "./UploadedFiles";
import RemovedFiles from "./RemovedFiles";
import ModalUploadFiles from "./ModalUploadFiles";
import { firstDateMonthRange, lastDate } from "../../utils/rangeDates";
import { getParamsRequest } from "../../utils/paramsRequest";

// actions
import { fetchUploadedFiles } from "../../actions/postal";

// selectors
import { tokenSelector, userSelector } from "../../actions/auth/selectors";

const initialCalendarValues = {
  startDate: firstDateMonthRange,
  endDate: lastDate,
  key: "selection"
};
const FilePage = props => {
  const [activeTab, setActiveTab] = useState(1);
  const [activeModal, setActiveModal] = useState(false);
  const [selectionRange, setSelectionRange] = useState(initialCalendarValues);

  const getUploadedFiles = async () => {
    const { dispatch, token, user } = props;

    let formData = getParamsRequest({
      shi_codigo: get(user, "shi_codigo", null),
      token,
      fecha_inicio: moment(selectionRange.startDate).format("L"),
      fecha_fin: moment(selectionRange.endDate).format("L")
    });

    await new Promise((resolve, reject) => {
      dispatch(
        fetchUploadedFiles(formData, (error, data) => {
          if (error) {
            return reject(error);
          }
          return resolve();
        })
      );
    });
  };

  return (
    <StyledFiles className="h-100 w-100">
      {activeTab === 1 && (
        <UploadedFiles
          setActiveTab={() => setActiveTab(2)}
          activeModal={() => setActiveModal(true)}
        />
      )}
      {activeTab === 2 && <RemovedFiles setActiveTab={() => setActiveTab(1)} />}
      <ModalUploadFiles
        active={activeModal}
        onClose={() => setActiveModal(false)}
        refresh={() => getUploadedFiles()}
      />
    </StyledFiles>
  );
};

const mapStateToProps = state => ({
  token: tokenSelector(state),
  user: userSelector(state)
});
export default connect(mapStateToProps)(FilePage);
