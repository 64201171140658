import * as Yup from "yup";

export const validateFilterForm = Yup.object().shape({
  productsValidation: Yup.string().when("selectedOption", {
    is: false,
    then: Yup.string().required("Seleccione producto")
  }),
  checkpointsValidation: Yup.string().when("selectedCheckpoint", {
    is: false,
    then: Yup.string().required("Seleccione checkpoint")
  })
  // cicle: Yup.string().when("hasCicle", {
  //   is: true,
  //   then: Yup.string().required("Seleccione ciclo")
  // })
});
