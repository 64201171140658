import styled from "styled-components";
// import { RED_SMOOTH } from "../../../utils/colors";

const Styled = styled.div`
  .rc-pagination {
    .rc-pagination-prev,
    .rc-pagination-next {
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .rc-pagination-prev a,
  .rc-pagination-next a {
    color: gray;
  }

  .rc-pagination-item {
    font-weight: bold;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: #9fa1b7 !important;
    }
    a {
      color: black;
      &:hover {
        color: #9fa1b7 !important;
      }
    }
    &:focus {
      outline: none;
    }
    &.rc-pagination-item-active {
      background-color: #ffffff;
      a {
        color: #9fa1b7 !important;
      }
    }
  }
  .rc-pagination-jump-prev,
  .rc-pagination-jump-next,
  .rc-pagination-jump-prev:after,
  .rc-pagination-jump-next:after {
    color: #9fa1b7;
    &:focus {
      outline: none !important;
    }
  }

  .rc-pagination-prev a:after,
  .rc-pagination-next a:after {
    font-size: 25px !important;
    color: #9fa1b7;
  }
`;

export default Styled;
