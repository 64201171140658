import request from "../../../api";
import getEnvironment from "../../../utils/getEnvironment";

//? Verifica si esta en modo dev o producción
const api = getEnvironment();

//ZONIFICACION Y CIERRE DE ENVIOS
//!BORRAR
const zonificacionCierreEnviosRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/zonificacion`,
    data
  });
};

const zonificacionRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/get_zonificacion_list`,
    data
  });
};

//CHECKPOINTS
const checkpointsRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/get_checkpoints`,
    data
  });
};

//DETALLE DE ENVIOS (query)
const queryRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/query`,
    data
  });
};

//DETALLE DE ENVIOS (query)
const queryAllDataRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/queryt`,
    data
  });
};

//RECLAMOS
const fetchClaimsRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/get_reclamos_list`,
    data
  });
};

//RESUMEN RECLAMOS
const fetchClaimsSummaryRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/get_reclamos_summary`,
    data
  });
};

export {
  zonificacionCierreEnviosRequest,
  zonificacionRequest,
  checkpointsRequest,
  queryRequest,
  queryAllDataRequest,
  fetchClaimsRequest,
  fetchClaimsSummaryRequest
};
