import styled from "styled-components";
import { STRONGRED } from "../../../utils/colors";

const StyledClaimsTab = styled.div`
  height: calc(100% - 5rem);
  button {
    background-color: ${STRONGRED};
  }
  .input-margin {
    margin-bottom: 0.625rem;
  }
  .input-button {
    margin-top: 1rem;
    margin-bottom: 0.75rem;
  }
  .collapse-container {
    height: 7rem;
    overflow: auto;
    @media (min-width: 1408px) {
      height: 16rem;
    }
  }
  .message {
    margin-left: 1rem;
  }
`;

export default StyledClaimsTab;
