import React from "react";
import StyledChangePassword from "./styled";
import { Formik } from "formik";

// custom
import Modal from "../../components/modal";
import Input from "../../components/Input";
import Button from "../../components/button";

// validators
import { validatePasswordForm } from "./validators/validateForm";

const ChangePassword = ({
  active,
  onClose,
  titleText,
  state,
  onChange,
  onSubmit
}) => {
  return (
    <StyledChangePassword>
      <Modal
        active={active}
        onClose={onClose}
        // containerClass="password-modal"
        contentClass="column is-4-desktop is-3-fullhd"
        content={
          <Formik
            initialValues={state}
            validationSchema={validatePasswordForm}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
              onSubmit(values.password);
              setSubmitting(false);
            }}
          >
            {props => {
              const { errors, values, handleSubmit } = props;
              return (
                <form
                  onSubmit={handleSubmit}
                  className="column is-12 has-background-white form-password-container is-flex flex-wrap"
                >
                  <h2 className="bold has-text-black has-text-centered w-100">
                    {titleText}
                  </h2>
                  <div className="column is-12 is-flex flex-wrap justify-content-center p-0">
                    <div className="column is-12 is-flex align-items-center justify-content-center is-paddingless form-input p-0">
                      <Input
                        containerClass="column is-8 p-0 input-form"
                        label="Ingrese nueva contraseña"
                        placeholder="*******"
                        inputType="password"
                        name="password"
                        value={values.password}
                        onChange={onChange}
                        errors={errors.password}
                      />
                    </div>
                    <div className="column is-12 is-flex align-items-center justify-content-center is-paddingless form-input p-0">
                      <Input
                        containerClass="column is-8 p-0 input-form"
                        label="Confirme nueva contraseña"
                        placeholder="*******"
                        inputType="password"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={onChange}
                        errors={errors.confirmPassword}
                      />
                    </div>
                    <Button
                      containerClass="form-header-button"
                      text={`Guardar`}
                    />
                  </div>
                </form>
              );
            }}
          </Formik>
        }
      />
    </StyledChangePassword>
  );
};

export default ChangePassword;
