import styled from "styled-components";

const StyledTrackingItem = styled.div`
  margin-bottom: 4px;
  .circle-indicator {
    width: 1.25rem;
    height: 1.25rem;
    background-color: #c5c7cd;
    border-radius: 50%;
    margin-bottom: 0.5rem;
    &.completed {
      background-color: #4ed864;
    }
    &.incomplete {
      background-color: #ff7e0c;
    }
    &.risk-zone {
      background-color: #cf333c !important;
    }
  }
  .dotted-indicator {
    width: 0;
    height: 60%;
    border-left-width: 1.5px;
    border-left-style: dashed;
    border-left-color: #4ed864;
    &.incomplete {
      border-left-color: #cf333c;
    }
  }

  p {
    margin-bottom: 0.3125rem;
  }
`;

export default StyledTrackingItem;
