import { put, takeLatest, call, takeEvery } from "redux-saga/effects";

// actions
import { logout } from "../auth";
import {
  fetchRGOCiclosSuccess,
  fetchRGOCiclosError,
  fetchRGOAbiertosSuccess,
  fetchRGOAbiertosError,
  fetchProductsSuccess,
  fetchProductsError,
  fetchRGORanksSuccess,
  fetchRGORanksError,
  fetchTransitTimesSuccess,
  fetchTransitTimesError,
  fetchCustomerQuerySuccess,
  fetchCustomerQueryError,
  fetchCustomerNameSuccess,
  fetchCustomerNameError,
  fetchCustomerTrackingSuccess,
  fetchCustomerTrackingError,
  fetchZCEnviosSuccess,
  fetchZCEnviosError,
  fetchQuerySuccess,
  fetchQueryError,
  fetchQueryAllDataSuccess,
  fetchQueryAllDataError,
  fetchTransitTimesProductsSuccess,
  fetchTransitTimesProductsError,
  addClaimSuccess,
  addClaimError,
  registerUserSuccess,
  registerUserError,
  fetchUsersListSuccess,
  fetchUsersListError,
  inactiveUserSuccess,
  inactiveUserError,
  updateUserSuccess,
  updateUserError,
  fetchCheckpointsSuccess,
  fetchCheckpointsError,
  fetchCourierPartsSuccess,
  fetchCourierPartsError,
  fetchCourierPartsCompleteSuccess,
  fetchCourierPartsCompleteError,
  updateUserPasswordSuccess,
  updateUserPasswordError,
  getAllShippersSuccess,
  getAllShippersError,
  fetchClaimsSuccess,
  fetchClaimsError,
  fetchClaimsAllSuccess,
  fetchClaimsAllError,
  fetchClaimsSummarySuccess,
  fetchClaimsSummaryError,
  fetchZoningSuccess,
  fetchZoningError,
  fetchZoningAllSuccess,
  fetchZoningAllError,
  insertCommentSuccess,
  insertCommentError,
  fetchCommentsListSuccess,
  fetchCommentsListError,
  fetchClaimsTypesSuccess,
  fetchClaimsTypesError,
  fetchUploadedFilesSuccess,
  fetchUploadedFilesError,
  fetchRemovedFilesSuccess,
  fetchRemovedFilesError,
  postUploadFilesSuccess,
  postUploadFilesError,
  deleteInboxFileSuccess,
  deleteInboxFileError
} from "../postal";

// action types
import * as actions from "../../action-types/postal";

import {
  productsRequest,
  courierPartsRequest,
  courierPartsCompleteRequest,
  updateUserPasswordRequest
} from "../../api/postal";

import {
  RGOCiclosRequest,
  RGOAbiertosRequest,
  getAllShippersRequest
} from "../../api/postal/dashboard";

import {
  transitTimesRequest,
  transitTimesProductsRequest
} from "../../api/postal/transit";

import { RGORegionRequest } from "../../api/postal/region";

import {
  customerQueryRequest,
  customerNameRequest,
  customerTrackingRequest,
  addClaimRequest,
  insertCommentRequest,
  commentsListRequest,
  claimsTypesRequest
} from "../../api/postal/clients";

import {
  zonificacionCierreEnviosRequest,
  zonificacionRequest,
  queryRequest,
  queryAllDataRequest,
  checkpointsRequest,
  fetchClaimsRequest,
  fetchClaimsSummaryRequest
} from "../../api/postal/reports";

import {
  userRegisterRequest,
  usersListRequest,
  inactiveUserRequest,
  updateUserRequest
} from "../../api/postal/administration";

import {
  uploadedFilesRequest,
  removedFilesRequest,
  postUploadFilesRequest,
  deleteInboxFileRequest
} from "../../api/postal/files";

const {
  FETCH_RGO_CICLOS,
  FETCH_RGO_ABIERTOS,
  FETCH_PRODUCTS,
  FETCH_RGO_RANKS,
  FETCH_TRANSIT_TIMES,
  FETCH_TRANSIT_TIMES_PRODUCTS,
  FETCH_CUSTOMER_QUERY,
  FETCH_CUSTOMER_NAME,
  FETCH_CUSTOMER_TRACKING,
  FETCH_ZONIFICACION_CIERRE_ENVIOS,
  FETCH_QUERY,
  FETCH_QUERY_ALL_DATA,
  ADD_CLAIM,
  REGISTER_USER,
  FETCH_USERS_LIST,
  INACTIVE_USER,
  UPDATE_USER,
  FETCH_CHECKPOINTS,
  FETCH_COURIER_PARTS_DETAILS,
  FETCH_COURIER_PARTS_DETAILS_COMPLETE,
  UPDATE_USER_PASSWORD,
  GET_ALL_SHIPPERS,
  FETCH_CLAIMS,
  FETCH_CLAIMS_ALL,
  FETCH_CLAIMS_SUMMARY,
  FETCH_ZONING,
  FETCH_ZONING_ALL,
  INSERT_COMMENT,
  FETCH_COMMENTS_LIST,
  FETCH_CLAIMS_TYPES,
  FETCH_UPLOADED_FILES,
  FETCH_REMOVED_FILES,
  POST_UPLOAD_FILES,
  DELETE_INBOX_FILE
} = actions;

function* getRGOCiclos(action) {
  try {
    const data = yield call(RGOCiclosRequest, action.payload);
    const response = {
      data: data.data.collection,
      page: data.data.meta.pagination.current_page,
      total: data.data.meta.pagination.total,
      totalPage: data.data.meta.pagination.last_page,
      perPage: data.data.meta.pagination.per_page
    };
    yield put(fetchRGOCiclosSuccess(response));

    action.callback && action.callback(null, response);
  } catch (e) {
    yield put(fetchRGOCiclosError(e));

    action.callback && action.callback(e);
  }
}

function* getRGORanks(action) {
  try {
    const data = yield call(RGORegionRequest, action.payload);
    yield put(fetchRGORanksSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(fetchRGORanksError(e));

    action.callback && action.callback(e);
  }
}

function* getRGOAbiertos(action) {
  try {
    const data = yield call(RGOAbiertosRequest, action.payload);

    const response = {
      data: data.data.collection
      // page: data.data.meta.pagination.current_page,
      // total: data.data.meta.pagination.total,
      // totalPage: data.data.meta.pagination.last_page,
      // perPage: data.data.meta.pagination.per_page
    };
    yield put(fetchRGOAbiertosSuccess(response));

    action.callback && action.callback(null, response);
  } catch (e) {
    yield put(fetchRGOAbiertosError(e));

    action.callback && action.callback(e);
  }
}

function* getProducts(action) {
  try {
    const data = yield call(productsRequest, action.payload);
    yield put(fetchProductsSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(fetchProductsError(e));

    action.callback && action.callback(e);
  }
}

function* getTransitTimes(action) {
  try {
    const data = yield call(transitTimesRequest, action.payload);
    yield put(fetchTransitTimesSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(fetchTransitTimesError(e));

    action.callback && action.callback(e);
  }
}

function* getTransitTimesProducts(action) {
  try {
    const data = yield call(transitTimesProductsRequest, action.payload);
    yield put(fetchTransitTimesProductsSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(fetchTransitTimesProductsError(e));

    action.callback && action.callback(e);
  }
}

function* getCustomerQuery(action) {
  try {
    const data = yield call(customerQueryRequest, action.payload);
    yield put(fetchCustomerQuerySuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(fetchCustomerQueryError(e));

    action.callback && action.callback(e);
  }
}

function* getCustomerName(action) {
  try {
    const data = yield call(customerNameRequest, action.payload);
    yield put(fetchCustomerNameSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(fetchCustomerNameError(e));

    action.callback && action.callback(e);
  }
}

function* getCustomerTracking(action) {
  try {
    const data = yield call(customerTrackingRequest, action.payload);
    yield put(fetchCustomerTrackingSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(fetchCustomerTrackingError(e));

    action.callback && action.callback(e);
  }
}

function* getZonificacionCierreEnvios(action) {
  try {
    const data = yield call(zonificacionCierreEnviosRequest, action.payload);
    yield put(fetchZCEnviosSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(fetchZCEnviosError(e));

    action.callback && action.callback(e);
  }
}

function* getCheckpoints(action) {
  try {
    const data = yield call(checkpointsRequest, action.payload);
    yield put(fetchCheckpointsSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(fetchCheckpointsError(e));

    action.callback && action.callback(e);
  }
}

function* getQuery(action) {
  try {
    const data = yield call(queryRequest, action.payload);
    const response = {
      data: data.data.collection,
      page: data.data.meta.pagination.current_page,
      total: data.data.meta.pagination.total,
      totalPage: data.data.meta.pagination.last_page,
      perPage: data.data.meta.pagination.per_page
    };
    yield put(fetchQuerySuccess(response));

    action.callback && action.callback(null, response);
  } catch (e) {
    yield put(fetchQueryError(e));

    action.callback && action.callback(e);
  }
}

function* getQueryAllData(action) {
  try {
    const data = yield call(queryAllDataRequest, action.payload);
    yield put(fetchQueryAllDataSuccess(data.data.collection));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(fetchQueryAllDataError(e));

    action.callback && action.callback(e);
  }
}

function* addClaim(action) {
  try {
    const data = yield call(addClaimRequest, action.payload);
    yield put(addClaimSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(addClaimError(e));

    action.callback && action.callback(e);
  }
}

function* registerUser(action) {
  try {
    const data = yield call(userRegisterRequest, action.payload);
    yield put(registerUserSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(registerUserError(e));

    action.callback && action.callback(e);
  }
}

function* getUsersList(action) {
  try {
    const data = yield call(usersListRequest, action.payload);
    yield put(fetchUsersListSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(fetchUsersListError(e));

    action.callback && action.callback(e);
  }
}

function* inactiveUser(action) {
  try {
    const data = yield call(inactiveUserRequest, action.payload);
    yield put(inactiveUserSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(inactiveUserError(e));

    action.callback && action.callback(e);
  }
}

function* updateUser(action) {
  try {
    const data = yield call(updateUserRequest, action.payload);
    yield put(updateUserSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(updateUserError(e));

    action.callback && action.callback(e);
  }
}

function* updateUserPassword(action) {
  try {
    const data = yield call(updateUserPasswordRequest, action.payload);
    yield put(updateUserPasswordSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(updateUserPasswordError(e));

    action.callback && action.callback(e);
  }
}

function* getCourierParts(action) {
  try {
    const data = yield call(courierPartsRequest, action.payload);
    const response = {
      data: data.data.collection,
      page: data.data.meta.pagination.current_page,
      total: data.data.meta.pagination.total,
      totalPage: data.data.meta.pagination.last_page,
      perPage: data.data.meta.pagination.per_page
    };
    yield put(fetchCourierPartsSuccess(response));

    action.callback && action.callback(null, response);
  } catch (e) {
    yield put(fetchCourierPartsError(e));

    action.callback && action.callback(e);
  }
}

function* getCourierPartsComplete(action) {
  try {
    const data = yield call(courierPartsCompleteRequest, action.payload);
    yield put(fetchCourierPartsCompleteSuccess(data.data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(fetchCourierPartsCompleteError(e));

    action.callback && action.callback(e);
  }
}

function* getAllShippers(action) {
  try {
    const data = yield call(getAllShippersRequest, action.payload);
    yield put(getAllShippersSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(getAllShippersError(e));

    action.callback && action.callback(e);
  }
}

function* fetchClaims(action) {
  try {
    const data = yield call(fetchClaimsRequest, action.payload);
    const response = {
      data: data.data.collection,
      page: data.data.meta.pagination.current_page,
      total: data.data.meta.pagination.total,
      totalPage: data.data.meta.pagination.last_page
    };
    yield put(fetchClaimsSuccess(response));

    action.callback && action.callback(null, response);
  } catch (e) {
    yield put(fetchClaimsError(e));

    action.callback && action.callback(e);
  }
}

function* fetchClaimsAll(action) {
  try {
    const data = yield call(fetchClaimsRequest, action.payload);
    yield put(fetchClaimsAllSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(fetchClaimsAllError(e));

    action.callback && action.callback(e);
  }
}

function* fetchClaimsSummary(action) {
  try {
    const data = yield call(fetchClaimsSummaryRequest, action.payload);
    yield put(fetchClaimsSummarySuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(fetchClaimsSummaryError(e));

    action.callback && action.callback(e);
  }
}

function* fetchZoning(action) {
  try {
    const data = yield call(zonificacionRequest, action.payload);
    const response = {
      data: data.data.collection,
      page: data.data.meta.pagination.current_page,
      total: data.data.meta.pagination.total,
      totalPage: data.data.meta.pagination.last_page
    };
    yield put(fetchZoningSuccess(response));

    action.callback && action.callback(null, response);
  } catch (e) {
    yield put(fetchZoningError(e));

    action.callback && action.callback(e);
  }
}

function* fetchZoningAll(action) {
  try {
    const data = yield call(zonificacionRequest, action.payload);
    yield put(fetchZoningAllSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(fetchZoningAllError(e));

    action.callback && action.callback(e);
  }
}

function* createComment(action) {
  try {
    const data = yield call(insertCommentRequest, action.payload);
    yield put(insertCommentSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(insertCommentError(e));

    action.callback && action.callback(e);
  }
}

function* getCommentsList(action) {
  try {
    const data = yield call(commentsListRequest, action.payload);
    yield put(fetchCommentsListSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(fetchCommentsListError(e));

    action.callback && action.callback(e);
  }
}

function* getClaimsTypes(action) {
  try {
    const data = yield call(claimsTypesRequest, action.payload);
    yield put(fetchClaimsTypesSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(fetchClaimsTypesError(e));

    action.callback && action.callback(e);
  }
}

function* getUploadedFiles(action) {
  try {
    const data = yield call(uploadedFilesRequest, action.payload);
    const response = {
      data: data.data.collection,
      page: data.data.meta.pagination.current_page,
      total: data.data.meta.pagination.total,
      totalPage: data.data.meta.pagination.last_page
    };
    yield put(fetchUploadedFilesSuccess(response));

    action.callback && action.callback(null, response);
  } catch (e) {
    yield put(fetchUploadedFilesError(e));

    action.callback && action.callback(e);
  }
}

function* getRemovedFiles(action) {
  try {
    const data = yield call(removedFilesRequest, action.payload);
    const response = {
      data: data.data.collection,
      page: data.data.meta.pagination.current_page,
      total: data.data.meta.pagination.total,
      totalPage: data.data.meta.pagination.last_page
    };
    yield put(fetchRemovedFilesSuccess(response));

    action.callback && action.callback(null, response);
  } catch (e) {
    yield put(fetchRemovedFilesError(e));

    action.callback && action.callback(e);
  }
}

function* postUploadFiles(action) {
  try {
    const data = yield call(postUploadFilesRequest, action.payload);
    yield put(postUploadFilesSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(postUploadFilesError(e));

    action.callback && action.callback(e);
  }
}

function* deleteInboxFile(action) {
  try {
    const data = yield call(deleteInboxFileRequest, action.payload);
    yield put(deleteInboxFileSuccess(data));

    action.callback && action.callback(null, data);
  } catch (e) {
    yield put(deleteInboxFileError(e));

    action.callback && action.callback(e);
  }
}

function* catchError(action) {
  // const ERROR_ACTIONS = Object.values(actions).filter(_action =>
  //   _action.includes("_ERROR")
  // );

  try {
    const status = action.payload ? action.payload.status : 0;
    // if (ERROR_ACTIONS.includes(action.type) && status === 401) {
    //   yield put(refreshToken());
    // }
    if (status === 500) {
      global.location.href = "/500";
    }

    if (status === 401) {
      yield put(logout());

      // global.location.href = "/";
      window.location.replace(process.env.REACT_APP_REDIRECT_URL);
    }
  } catch (error) {
    yield put(logout());
    global.location.href = "/";
  }
}

function* insideSaga() {
  yield takeLatest(FETCH_RGO_CICLOS, getRGOCiclos);
  yield takeLatest(FETCH_RGO_ABIERTOS, getRGOAbiertos);
  yield takeLatest(FETCH_RGO_RANKS, getRGORanks);
  yield takeLatest(FETCH_PRODUCTS, getProducts);
  yield takeLatest(FETCH_TRANSIT_TIMES, getTransitTimes);
  yield takeLatest(FETCH_TRANSIT_TIMES_PRODUCTS, getTransitTimesProducts);
  yield takeLatest(FETCH_CUSTOMER_QUERY, getCustomerQuery);
  yield takeLatest(FETCH_CUSTOMER_NAME, getCustomerName);
  yield takeLatest(FETCH_CUSTOMER_TRACKING, getCustomerTracking);
  yield takeLatest(
    FETCH_ZONIFICACION_CIERRE_ENVIOS,
    getZonificacionCierreEnvios
  );
  yield takeLatest(FETCH_QUERY, getQuery);
  yield takeLatest(FETCH_QUERY_ALL_DATA, getQueryAllData);
  yield takeLatest(ADD_CLAIM, addClaim);
  yield takeLatest(REGISTER_USER, registerUser);
  yield takeLatest(FETCH_USERS_LIST, getUsersList);
  yield takeLatest(INACTIVE_USER, inactiveUser);
  yield takeLatest(UPDATE_USER, updateUser);
  yield takeLatest(UPDATE_USER_PASSWORD, updateUserPassword);
  yield takeLatest(FETCH_CHECKPOINTS, getCheckpoints);
  yield takeLatest(FETCH_COURIER_PARTS_DETAILS, getCourierParts);
  yield takeLatest(
    FETCH_COURIER_PARTS_DETAILS_COMPLETE,
    getCourierPartsComplete
  );
  yield takeLatest(GET_ALL_SHIPPERS, getAllShippers);
  yield takeLatest(FETCH_CLAIMS, fetchClaims);
  yield takeLatest(FETCH_CLAIMS_ALL, fetchClaimsAll);
  yield takeLatest(FETCH_CLAIMS_SUMMARY, fetchClaimsSummary);
  yield takeLatest(FETCH_ZONING, fetchZoning);
  yield takeLatest(FETCH_ZONING_ALL, fetchZoningAll);
  yield takeLatest(INSERT_COMMENT, createComment);
  yield takeLatest(FETCH_COMMENTS_LIST, getCommentsList);
  yield takeLatest(FETCH_CLAIMS_TYPES, getClaimsTypes);
  yield takeLatest(FETCH_UPLOADED_FILES, getUploadedFiles);
  yield takeLatest(FETCH_REMOVED_FILES, getRemovedFiles);
  yield takeLatest(POST_UPLOAD_FILES, postUploadFiles);
  yield takeLatest(DELETE_INBOX_FILE, deleteInboxFile);
  yield takeEvery("*", catchError);
}

export default insideSaga;
