import { concat } from "lodash";

export const TIGO_SHIPPER_CODES = ["000144", "000094"];

export const CLARO_SHIPPER_CODES = ["000298"];

export const BA_SHIPPER_CODES = [
  "000009",
  "000039",
  "000055",
  "000120",
  "000336",
  "000337"
];

export const BAC_SHIPPER_CODES = ["000010"];

export const SHIPPERS_WITH_CICLES = concat(
  TIGO_SHIPPER_CODES
  // CLARO_SHIPPER_CODES
);

export const SHIPPERS_INBOX = [
  {
    client: "Banco Agricola",
    fileReference: "BancoAgricola",
    fileDescription: "Archivo Banco Agricola",
    shippers: BA_SHIPPER_CODES,
    apiKey: process.env.REACT_APP_BA_FILES_API_KEY
  }
];

export const ADMINISTRATION_PERMISSIONS = {
  hasFilesPermissions: concat(BA_SHIPPER_CODES, BAC_SHIPPER_CODES)
};
