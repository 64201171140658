export const LOGOUT = "LOGOUT";

export const FETCH_CURRENT_USER_PROFILE = "FETCH_CURRENT_USER_PROFILE";
export const FETCH_CURRENT_USER_PROFILE_SUCCESS =
  "FETCH_CURRENT_USER_PROFILE_SUCCESS";
export const FETCH_CURRENT_USER_PROFILE_ERROR =
  "FETCH_CURRENT_USER_PROFILE_ERROR";

export const CHANGE_CURRENT_SHIPPER = "CHANGE_CURRENT_SHIPPER";
export const CHANGE_CURRENT_SHIPPER_SUCCESS = "CHANGE_CURRENT_SHIPPER_SUCCESS";
export const CHANGE_CURRENT_SHIPPER_ERROR = "CHANGE_CURRENT_SHIPPER_ERROR";

export const FINISH_CHANGE_CURRENT_SHIPPER = "FINISH_CHANGE_CURRENT_SHIPPER";

export const FETCH_HAS_IP = "FETCH_HAS_IP";
export const FETCH_HAS_IP_SUCCESS = "FETCH_HAS_IP_SUCCESS";
export const FETCH_HAS_IP_ERROR = "FETCH_HAS_IP_ERROR";
