import React from "react";
import { GRAY } from "../../utils/colors";

export default ({ width = 30, height = 30, icon, color = GRAY, ...props }) => {
  const icons = {
    downarrow: {
      path: "M0.75 0.5L7 6.75L13.25 0.5H0.75Z",
      properties: {
        fillRule: "evenodd",
        clipRule: "evenodd",
        opacity: "0.54"
      }
    },
    arrow: {
      path: "M0.75 0.5L7 6.75L13.25 0.5H0.75Z",
      properties: {
        fillRule: "evenodd",
        clipRule: "evenodd"
      }
    },
    download: {
      path:
        "M5 5V2C5 0.895431 5.89543 0 7 0H13C14.1046 0 15 0.895431 15 2V5V9H20L10 17.5847L0 9H5V5ZM13 2V5V9V11H14.2967L10 14.9505L5.70326 11H7V9V5V2H13ZM17 20C18.1046 20 19 19.1046 19 18H1C1 19.1046 1.89543 20 3 20H17Z",
      properties: {
        fillRule: "evenodd",
        clipRule: "evenodd"
      }
    },
    calendar: {
      path:
        "M3.88184 0.699219H7.36184V3.39922H3.88184V0.699219Z M11.2002 0.699219H14.6802V3.39922H11.2002V0.699219Z M7.95898 2.05039H10.599 M1 6.32422H17.56 M4.48145 9.36289H14.0814 M4.48145 12.9625H14.0814 M3.88 2.0498H1V16.2248H17.56V2.0498H14.68",
      properties: {
        fillRule: "evenodd",
        clipRule: "evenodd",
        stroke: color,
        fill: "#FFFFFF",
        strokeWidth: "1.35",
        strokeLinecap: "round",
        strokeLinejoin: "round"
      }
    },
    points: {
      path:
        "M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM0 14C0 12.9 0.9 12 2 12C3.1 12 4 12.9 4 14C4 15.1 3.1 16 2 16C0.9 16 0 15.1 0 14Z",
      properties: {
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: color
      }
    },
    backIcon: {
      path:
        "M20 8.75H4.75L11.75 1.75L10 0L0 10L10 20L11.75 18.25L4.75 11.25H20V8.75Z",
      properties: {
        fill: color,
        fillRule: "evenodd",
        clipRule: "evenodd"
      }
    },
    checked: {
      path:
        "M4.34493 8.98888C4.1641 8.98713 3.99033 8.91849 3.85712 8.79619L0.749805 5.93034C0.666007 5.87028 0.595846 5.7932 0.543921 5.70413C0.491995 5.61507 0.459477 5.51604 0.448498 5.41353C0.437518 5.31102 0.448324 5.20734 0.480209 5.1093C0.512094 5.01126 0.564337 4.92106 0.633514 4.84462C0.702691 4.76818 0.787242 4.70722 0.881624 4.66574C0.976006 4.62426 1.07809 4.60319 1.18118 4.60391C1.28428 4.60463 1.38605 4.62714 1.47984 4.66994C1.57363 4.71274 1.65732 4.77489 1.72541 4.85229L4.26444 7.19375L9.43273 0.932779C9.55628 0.783029 9.73426 0.688493 9.92752 0.669968C10.1208 0.651443 10.3135 0.710446 10.4632 0.833998C10.613 0.95755 10.7075 1.13553 10.726 1.32878C10.7446 1.52204 10.6856 1.71474 10.562 1.86449L4.90834 8.72302C4.84517 8.80003 4.76694 8.86333 4.67845 8.90904C4.58996 8.95476 4.49307 8.98192 4.39371 8.98888H4.34493Z",
      properties: {
        fill: color
      }
    },
    sort: {
      path:
        "M0.73826 3.43633L3.57858 0.698174C3.80046 0.484363 4.16036 0.484432 4.38209 0.698174L7.22231 3.43633C7.57991 3.78099 7.32526 4.37119 6.82054 4.37119H5.11671V15.3236C5.11671 15.626 4.86235 15.8712 4.54853 15.8712H3.41218C3.09837 15.8712 2.844 15.626 2.844 15.3236V4.37119H1.14003C0.634318 4.37119 0.381408 3.78031 0.73826 3.43633ZM9.09396 2.72833H18.1848C18.4986 2.72833 18.753 2.48317 18.753 2.18071V1.08548C18.753 0.783021 18.4986 0.537859 18.1848 0.537859H9.09396C8.78015 0.537859 8.52578 0.783021 8.52578 1.08548V2.18071C8.52578 2.48317 8.78015 2.72833 9.09396 2.72833ZM8.52578 6.56166V5.46642C8.52578 5.16397 8.78015 4.91881 9.09396 4.91881H15.9121C16.2259 4.91881 16.4803 5.16397 16.4803 5.46642V6.56166C16.4803 6.86412 16.2259 7.10928 15.9121 7.10928H9.09396C8.78015 7.10928 8.52578 6.86412 8.52578 6.56166ZM8.52578 15.3236V14.2283C8.52578 13.9259 8.78015 13.6807 9.09396 13.6807H11.3667C11.6805 13.6807 11.9349 13.9259 11.9349 14.2283V15.3236C11.9349 15.626 11.6805 15.8712 11.3667 15.8712H9.09396C8.78015 15.8712 8.52578 15.626 8.52578 15.3236ZM8.52578 10.9426V9.84737C8.52578 9.54491 8.78015 9.29975 9.09396 9.29975H13.6394C13.9532 9.29975 14.2076 9.54491 14.2076 9.84737V10.9426C14.2076 11.2451 13.9532 11.4902 13.6394 11.4902H9.09396C8.78015 11.4902 8.52578 11.2451 8.52578 10.9426Z",
      properties: {
        fill: color
      }
    },
    search: {
      path:
        "M13.882 8.02885C13.882 11.5031 10.9655 14.3608 7.31601 14.3608C3.66653 14.3608 0.75 11.5031 0.75 8.02885C0.75 4.55461 3.66653 1.6969 7.31601 1.6969C10.9655 1.6969 13.882 4.55461 13.882 8.02885Z M12.3809 12.9316L16.883 17.2898",
      properties: {
        fill: "none",
        stroke: color,
        strokeWidth: "1.5",
        strokeLinecap: "round"
      }
    },
    box: {
      path:
        "M24.37 51.2719L31.3328 44.3091L24.37 37.3462C23.6315 36.6077 23.6315 35.3418 24.37 34.6033C25.1085 33.8648 26.3744 33.8648 27.1129 34.6033L34.0758 41.5661L41.0386 34.6033C41.7771 33.8648 43.0431 33.8648 43.7816 34.6033C44.52 35.3418 44.52 36.6077 43.7816 37.3462L36.8187 44.3091L43.7816 51.2719C44.52 52.0104 44.52 53.2764 43.7816 54.0149C43.3596 54.4368 42.9376 54.5423 42.4101 54.5423C41.8826 54.5423 41.4606 54.3313 41.0386 54.0149L34.0758 47.052L27.1129 54.0149C26.6909 54.4368 26.2689 54.5423 25.7414 54.5423C25.214 54.5423 24.792 54.3313 24.37 54.0149C23.6315 53.2764 23.6315 52.0104 24.37 51.2719ZM67.5186 18.4621C67.7296 18.7786 67.9405 19.2006 67.9405 19.6226V69.101C67.9405 70.156 67.0966 71 66.0416 71H1.89896C0.843985 71 0 70.156 0 69.101V19.6226C0 19.2006 0.105491 18.7786 0.421985 18.4621L14.3477 0.738497C14.6642 0.316506 15.2972 0 15.8247 0H52.1159C52.7489 0 53.2764 0.316506 53.5929 0.738497L67.5186 18.4621ZM35.8692 17.7236H62.1382L51.2719 3.90343H35.9747V17.7236H35.8692ZM5.80238 17.7236H32.0713V3.90343H16.7741L5.80238 17.7236ZM64.1426 21.627H3.79791V67.2021H64.1426V21.627Z",
      properties: {
        fill: color
      }
    },
    xCircle: {
      path:
        "M7.7927 6.81652C8.06909 7.09318 8.06909 7.52354 7.7927 7.80019C7.6545 7.93852 7.4856 8 7.30134 8C7.11708 8 6.94818 7.93852 6.80999 7.80019L4 4.98752L1.19001 7.80019C1.05182 7.93852 0.882918 8 0.698656 8C0.514395 8 0.345497 7.93852 0.207301 7.80019C-0.0690904 7.52354 -0.0690904 7.09318 0.207301 6.81652L3.01727 4.00384L0.207301 1.19116C-0.0690904 0.914507 -0.0690904 0.484148 0.207301 0.207491C0.483693 -0.0691661 0.91362 -0.0691661 1.19001 0.207491L4 3.02017L6.80999 0.207491C7.08638 -0.0691661 7.51631 -0.0691661 7.7927 0.207491C8.06909 0.484148 8.06909 0.914507 7.7927 1.19116L4.98271 4.00384L7.7927 6.81652Z",
      properties: {
        fill: color
      }
    }
  };

  return (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d={icons[icon].path} fill={color} {...icons[icon].properties} />
    </svg>
  );
};
