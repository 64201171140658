import React from "react";
import { connect } from "react-redux";
import { isEmpty, split, intersection } from "lodash";
import StyledMap from "./styled";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import HeatmapLayer from "react-google-maps/lib/components/visualization/HeatmapLayer";
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";
import { TIGO_SHIPPER_CODES } from "../../utils/shipperPermissions";

// selectors
import { userSelector } from "../../actions/auth/selectors";

const MapZones = withGoogleMap(props => {
  // const data = [new window.google.maps.LatLng(13.6775805, -89.2491554)];
  const {
    center = { lat: 13.794185, lng: -88.89652999999998 },
    defaultZoom = 9,
    data,
    markers,
    showHeatmap,
    showLocation,
    user
  } = props;
  return (
    <StyledMap>
      <GoogleMap
        center={center}
        defaultZoom={defaultZoom}
        defaultOptions={
          {
            // disableDefaultUI: true,
            // styles: mapStyles
          }
        }
      >
        <MarkerClusterer
          onClick={props.onMarkerClustererClick}
          averageCenter
          enableRetinaIcons
          gridSize={60}
        >
          {showLocation &&
            markers.map((item, i) => <Marker key={i} position={item} />)}
        </MarkerClusterer>

        {showHeatmap && (
          <HeatmapLayer
            data={data}
            options={{
              maxIntensity: 1,
              ...(!isEmpty(
                intersection(TIGO_SHIPPER_CODES, split(user.shi_codigos, ","))
              ) && {
                gradient: ["rgba(0, 255, 255, 0)", "#43C7F4", "#00377b"]
              })
            }}
          />
        )}
      </GoogleMap>
    </StyledMap>
  );
});

const mapStateToProps = state => ({
  user: userSelector(state)
});
export default connect(mapStateToProps)(MapZones);
