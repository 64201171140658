import styled from "styled-components";

const StyledModal = styled.div`
  .modal-container {
    background-color: white;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

export default StyledModal;
