import React from "react";
import StyledTable from "./styled";
import TableActions from "../table_actions";

const Table = ({
  tableHead = [],
  tableBody = [],
  actions = false,
  filter = false,
  emptyMessage = "Sin datos",
  headClass,
  bodyClass
}) => {
  return (
    <StyledTable className="orders-table-container">
      {window.innerWidth > 769 && (
        <div className={`orders-table-head ${headClass}`}>
          <div className="columns is-fullhd is-gapless">
            {filter && <div className="column is-1"></div>}
            {tableHead.map((title, i) => (
              <div key={i} className="column has-text-centered">
                <strong>{title}</strong>
              </div>
            ))}
            {actions && <div className="column is-1"></div>}
          </div>
        </div>
      )}
      {window.innerWidth > 769 && (
        <div className={`orders-table-body uex-scrollbar ${bodyClass}`}>
          {tableBody.length > 0 &&
            tableBody.map((item, index) => {
              return (
                <div
                  className="columns is-fullhd is-gapless is-marginless"
                  key={index}
                >
                  {item.map((title, i) => {
                    if (actions && Array.isArray(title)) {
                      return (
                        <TableActions
                          key={i}
                          actions={title}
                          isLast={tableBody.length === index + 1}
                        />
                      );
                    } else if (filter && typeof title === "object") {
                      return (
                        <div
                          key={i}
                          className="column is-1 is-flex justify-content-center"
                        >
                          <div
                            key={i}
                            className={`status-color`}
                            style={{
                              backgroundColor: title.color,
                              borderColor: title.color
                            }}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div key={i} className="column has-text-centered">
                          <p>{title}</p>
                        </div>
                      );
                    }
                  })}
                </div>
              );
            })}
          {tableBody.length === 0 && (
            <div className="columns is-fullhd is-gapless is-marginless">
              <div className="column has-text-centered">
                <p>{emptyMessage}</p>
              </div>
            </div>
          )}
        </div>
      )}
    </StyledTable>
  );
};

export default Table;
