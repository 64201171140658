import * as Yup from "yup";

export const validateUsersForm = Yup.object().shape({
  usr_nombre: Yup.string().required("Nombre es requerido"),
  usr_email: Yup.string()
    .email("Email invalido")
    .required("Email es requerido"),
  usr_codemp: Yup.string().required("Codigo de usuario es requerido"),
  usr: Yup.string().required("Nombre de usuario es requerido"),
  rol: Yup.string().required("Rol es requerido"),
  permisos: Yup.string().when("rol", {
    is: rol => rol === "USUARIO",
    then: Yup.string().required("Permisos es requerido")
  }),
  modalType: Yup.number(),
  pass: Yup.string().when("modalType", {
    is: modalType => Number(modalType) === 1,
    then: Yup.string()
      .required("Contraseña es requerida")
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,70}$/,
        "Contraseña debe tener al menos 6 caracteres, 1 letra mayúscula y un número"
      )
  })
  // pass: Yup.string()
  //   .required("Contraseña es requerida")
  //   .matches(
  //     /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,70}$/,
  //     "Contraseña debe tener al menos 6 caracteres, 1 letra mayúscula y un número"
  //   )
});

export const validateClientUserForm = Yup.object().shape({
  usr_nombre: Yup.string().required("Nombre es requerido"),
  usr_email: Yup.string()
    .email("Email invalido")
    .required("Email es requerido"),
  usr_codemp: Yup.string().required("Codigo de usuario es requerido"),
  usr: Yup.string().required("Nombre de usuario es requerido"),
  shipperId: Yup.string().required("Cliente es requerido"),
  userType: Yup.string().required("Tipo usuario es requerido"),
  // rol: Yup.string().when("userType", {
  //   is: userType => Number(userType) === 2 || Number(userType) === 3,
  //   then: Yup.string().required("Rol es requerido")
  // }),
  permisos: Yup.string().when("userType", {
    is: userType => Number(userType) === 2 || Number(userType) === 3,
    then: Yup.string().required("Permisos es requerido")
  }),
  modalType: Yup.number(),
  pass: Yup.string().when("modalType", {
    is: modalType => Number(modalType) === 1,
    then: Yup.string()
      .required("Contraseña es requerida")
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,70}$/,
        "Contraseña debe tener al menos 6 caracteres, 1 letra mayúscula y un número"
      )
  })
  // pass: Yup.string()
  //   .required("Contraseña es requerida")
  //   .matches(
  //     /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,70}$/,
  //     "Contraseña debe tener al menos 6 caracteres, 1 letra mayúscula y un número"
  //   )
});
