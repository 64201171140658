import React from "react";
import StyledHeaderPage from "./styled";

const HeaderPage = ({ title, content }) => {
  return (
    <StyledHeaderPage className="column is-12 is-paddingless">
      <h1 className="bold is-size-4">{title}</h1>
      <div className="column is-flex align-items-center justify-content-end px-0">
        {content}
      </div>
    </StyledHeaderPage>
  );
};

export default HeaderPage;
