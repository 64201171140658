import React from "react";
import Pagination from "rc-pagination";
import Styled from "./styled";
import "rc-pagination/assets/index.css";
// import 'rc-pagination/dist/rc-pagination.css';

const CustomPagination = ({
  containerClassName = "",
  currentPage = 1,
  total,
  perPage = 10,
  onChangePage
}) => {
  return (
    <Styled className={containerClassName}>
      <Pagination
        onChange={page => onChangePage(page)}
        current={currentPage}
        total={total}
        defaultCurrent={perPage}
        defaultPageSize={perPage}
      />
    </Styled>
  );
};

export default CustomPagination;
