import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const request = async options => {
  instance.defaults.headers.common["Content-Type"] =
    "application/x-www-form-urlencoded";

  try {
    const resource = await instance(options);

    return Promise.resolve(resource.data);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export default request;
