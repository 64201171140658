import request from "../../../api";
import getEnvironment from "../../../utils/getEnvironment";

//? Verifica si esta en modo dev o producción
const api = getEnvironment();

//REPORTE DE GESITON OPERATIVA POR CICLOS
const RGOCiclosRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/rgos_ciclos`,
    data
  });
};

// OBTENER TODOS LOS SHIPPERS
const getAllShippersRequest = async data => {
  return await request({
    method: "POST",
    url: `jwt2/${api}/get_shippers`,
    data
  });
};

//REPORTE DE GESITON OPERATIVA ABIERTAS
const RGOAbiertosRequest = async data => {
  getEnvironment();
  return await request({
    method: "POST",
    url: `jwt2/${api}/rgos_abiertos`,
    data
  });
};

export { RGOCiclosRequest, RGOAbiertosRequest, getAllShippersRequest };
