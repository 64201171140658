export const userTypes = [
  {
    value: 1,
    label: "Administrador Urbano"
  },
  {
    value: 2,
    label: "Usuario Urbano"
  },
  {
    value: 3,
    label: "Usuario Cliente"
  }
];
