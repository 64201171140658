import styled from "styled-components";

const StyledInput = styled.div`
  position: relative;
  .input-label {
    color: #60626e;
    margin-bottom: 4px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    text-transform: capitalize;
  }

  .input {
    height: 2.5rem;
    padding: 17px 12.25px;
    background: #ffffff;
    border: 1px solid #dfe0eb;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    &:hover,
    &:focus {
      border: 1px solid #60626e;
      border-radius: 4px;
      box-shadow: none;
    }
  }
  .error-form {
    position: absolute;
    /* bottom: -1.2rem; */
  }
`;

export default StyledInput;
