import styled from "styled-components";

const StyledSeacrhInputTable = styled.div`
  .search-icon {
    margin-right: 0.5rem;
  }
  input[type="text"] {
    width: 80px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
    border: 0;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #4b506d;
    &:focus {
      outline: none;
    }
    &::-webkit-input-placeholder {
      /* Edge */
      color: #4b506d;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #4b506d;
    }

    &::placeholder {
      color: #4b506d;
    }
  }

  /* When the input field gets focus, change its width to 100% */
  input[type="text"]:focus {
    width: 100%;
  }
`;

export default StyledSeacrhInputTable;
