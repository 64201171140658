import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get, map, includes, intersection, split } from "lodash";
import { Formik } from "formik";
import moment from "moment";
import "moment/locale/es";
import axios from "axios";

// custom
import LoadingScreen from "../../components/loading_screen";
import { getParamsRequest } from "../../utils/paramsRequest";
import Table from "../../components/Table";
import Paginator from "../../components/paginator";
import FilterDropdown from "../../components/filter-dropdown";
import Input from "../../components/Input";
import Button from "../../components/button";
import CalendarRangeDropdown from "../../components/calendar-range-dropdown";
import { firstDateMonthRange, lastDate } from "../../utils/rangeDates";
import { SHIPPERS_INBOX } from "../../utils/shipperPermissions";
import ModalSuccess from "../../components/modal_alert";
import ModalError from "../../components/modal_alert";
import ModalStatus from "../../components/modal_alert";
import SuccessIcon from "../../assets/images/success-icon.svg";
import ErrorIcon from "../../assets/images/error-icon.svg";

// actions
import { fetchUploadedFiles, deleteInboxFile } from "../../actions/postal";

// selectors
import { tokenSelector, userSelector } from "../../actions/auth/selectors";
import { uploadedFilesSelector } from "../../actions/postal/selectors";

const initialCalendarValues = {
  startDate: firstDateMonthRange,
  endDate: lastDate,
  key: "selection"
};

const UploadedFiles = ({ user, ...props }) => {
  const [selectionRange, setSelectionRange] = useState(initialCalendarValues);
  const [fileName, setFileName] = useState("");
  const [fileId, setFileId] = useState(null);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);

  const getUploadedFiles = async (data = {}) => {
    const { dispatch, token } = props;

    let formData = getParamsRequest({
      shi_codigo: get(user, "shi_codigo", null),
      token,
      busqueda: fileName,
      fecha_inicio: moment(selectionRange.startDate).format("L"),
      fecha_fin: moment(selectionRange.endDate).format("L"),
      ...data
    });

    await new Promise((resolve, reject) => {
      dispatch(
        fetchUploadedFiles(formData, (error, data) => {
          if (error) {
            return reject(error);
          }
          setAttributeCalendar();
          return resolve();
        })
      );
    });
  };

  const handleChangeFindByFileName = e => {
    setFileName(e.target.value);
  };

  const handleSelectionRange = ranges => {
    setSelectionRange(ranges.selection);
  };

  const handleCleanFilters = setFieldValue => {
    setFileName("");
    setSelectionRange(initialCalendarValues);
  };

  const onChangePagination = async page => {
    let data = {
      p: page
    };

    await getUploadedFiles(data);
  };

  const setAttributeCalendar = () => {
    let component = document.getElementsByClassName("dropdown-calendar-button");
    for (let i = 0; i < component.length; i++) {
      component[i].setAttribute("type", "button");
    }
  };

  const downloadFile = async fileId => {
    let fileToken = await get_token();
    let fileUrl = await getFile(fileToken, fileId);

    window.location.href = fileUrl;
  };

  const getFile = (token, fileId) => {
    let url = `${process.env.REACT_APP_UPLOAD_FILES_URL}/${fileId}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    };

    return axios.get(url, config).then(response => {
      return response.data.files[0].service_url;
    });
  };

  const get_token = async () => {
    let getClient = SHIPPERS_INBOX.find(item =>
      intersection(item.shippers, split(user.shi_codigos, ","))
    );

    let url = process.env.REACT_APP_TOKEN_FILES_URL;

    let data = {
      grant_type: "password",
      scope: "api",
      api_key: getClient.apiKey,
      client_id: process.env.REACT_APP_FILES_CLIENT_ID,
      client_secret: process.env.REACT_APP_FILES_CLIENT_SECRET
    };

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    return axios.post(url, data, config).then(response => {
      return response.data.access_token;
    });
  };

  const handleModalStatusOpen = fileId => {
    setFileId(fileId);
    setModalStatus(true);
  };

  const handleModalStatusClose = () => {
    setFileId(null);
    setModalStatus(false);
  };

  const onDeleteFile = async () => {
    const { dispatch, token } = props;

    let formData = getParamsRequest({
      token,
      file_id: fileId
    });

    await new Promise((resolve, reject) => {
      dispatch(
        deleteInboxFile(formData, (error, data) => {
          handleModalStatusClose();
          if (error) {
            setModalError(true);
            setTimeout(() => {
              setModalError(false);
            }, 2000);
            return reject(error);
          }
          resolve();
          setModalSuccess(true);
          setTimeout(() => {
            setModalSuccess(false);
          }, 2000);
          return getUploadedFiles();
        })
      );
    });
  };

  useEffect(() => {
    // componentDidMount
    // componentDidUpdate
    getUploadedFiles();
  }, []);

  const { uploadedFiles, setActiveTab, activeModal } = props;

  if (uploadedFiles.loading) return <LoadingScreen />;

  return (
    <div className="h-100 w-100">
      <div className="column is-12 is-flex justify-content-between align-items-center px-0">
        <h2 className="c-pointer bold" onClick={setActiveTab}>
          Ver Archivos Eliminados
        </h2>
        <div className="is-flex">
          <Button
            onClick={activeModal}
            containerClass="form-header-button"
            text="Cargar Archivos"
          />
          <FilterDropdown>
            <Formik
              initialValues={{
                fileName
              }}
              enableReinitialize
              onSubmit={(values, { setSubmitting }) => {
                getUploadedFiles();
                setSubmitting(false);
              }}
            >
              {props => {
                const { values, handleSubmit, setFieldValue } = props;
                return (
                  <form
                    onSubmit={handleSubmit}
                    className="column is-12 is-flex align-items-center flex-wrap"
                  >
                    <div className="column is-9 is-paddingless is-flex flex-wrap">
                      <div className="column is-4">
                        <Input
                          containerClass="column is-12 p-0"
                          placeholder={"Nombre de archivo"}
                          name="fileName"
                          value={values.fileName}
                          onChange={handleChangeFindByFileName}
                        />
                      </div>
                      <div className="column is-4">
                        <CalendarRangeDropdown
                          rangeName="Fecha Creados"
                          containerClass="input-form filters-input w-100 m-0"
                          selectionRange={selectionRange}
                          onChange={handleSelectionRange}
                        />
                      </div>
                    </div>
                    <div className="column is-3 is-flex flex-wrap justify-content-center">
                      <div className="column is-10">
                        <Button
                          containerClass="w-100 clean-filter-button"
                          text="Limpiar filtros"
                          type="button"
                          onClick={() => handleCleanFilters(setFieldValue)}
                        />
                      </div>
                      <div className="column is-10">
                        <Button containerClass="w-100" text="Buscar" />
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </FilterDropdown>
        </div>
      </div>
      <Table
        tableHead={["No", "Archivo", "Usuario", "Fecha"]}
        tableBody={map(get(uploadedFiles, "data", []), (item, i) => [
          i + 1,
          item.archivo,
          item.aud_usuario_proc,
          `${item.aud_fecha_proc} ${item.aud_hora_proc}`,
          [
            {
              onClick: () => {
                if (includes(item.path, "upload/")) {
                  window.location.href = `https://www.urbano.com.sv/bancoagricola/extranet/${item.path}`;
                } else {
                  downloadFile(item.path);
                }
              },
              label: "Descargar"
            },
            {
              onClick: () => handleModalStatusOpen(item.id_carga),
              label: "Eliminar"
            }
          ]
        ])}
        actions
      />
      <Paginator
        onChangePage={onChangePagination}
        totalText="Total de archivos"
        totalData={get(uploadedFiles, "total", 0)}
        total={get(uploadedFiles, "total", 0)}
        perPage={10}
        currentPage={parseInt(get(uploadedFiles, "page", 0))}
      />
      <ModalSuccess
        active={modalSuccess}
        onClose={() => setModalSuccess(false)}
        cancelText="Aceptar"
        icon={SuccessIcon}
        title="Archivo eliminado con éxito"
        titleClass="column is-10 mx-auto"
      />
      <ModalError
        active={modalError}
        onClose={() => setModalError(false)}
        cancelText="Aceptar"
        icon={ErrorIcon}
        title={`Error al eliminar archivo`}
        titleClass="column is-10 mx-auto"
      />
      <ModalStatus
        active={modalStatus}
        onClose={() => handleModalStatusClose()}
        onAccept={() => onDeleteFile()}
        icon={SuccessIcon}
        title="¿Desea eliminar archivo?"
        titleClass="column is-10 mx-auto"
      />
    </div>
  );
};

const mapStateToProps = state => ({
  token: tokenSelector(state),
  user: userSelector(state),
  uploadedFiles: uploadedFilesSelector(state)
});
export default connect(mapStateToProps)(UploadedFiles);
