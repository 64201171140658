import { createSelector } from "reselect";

const postalSelector = state => state.postal;

export const productsSelector = createSelector(
  postalSelector,
  state => state.products
);

export const RGOCiclosSelector = createSelector(
  postalSelector,
  state => state.RGO_CICLOS
);

export const RGOAbiertosSelector = createSelector(
  postalSelector,
  state => state.RGO_ABIERTOS
);

export const RGORanksSelector = createSelector(
  postalSelector,
  state => state.RGO_RANKS
);

export const transitTimesSelector = createSelector(
  postalSelector,
  state => state.transitTimes
);

export const transitTimesProductsSelector = createSelector(
  postalSelector,
  state => state.transitTimesProducts
);

export const customerQuerySelector = createSelector(
  postalSelector,
  state => state.customerQuery
);

export const customerNameSelector = createSelector(
  postalSelector,
  state => state.customerName
);

export const customerTrackingSelector = createSelector(
  postalSelector,
  state => state.customerTracking
);

export const zonificacionCierreEnviosSelector = createSelector(
  postalSelector,
  state => state.ZCEnvios
);

export const querySelector = createSelector(
  postalSelector,
  state => state.query
);

export const queryAllDataSelector = createSelector(
  postalSelector,
  state => state.queryAllData
);

export const usersListSelector = createSelector(
  postalSelector,
  state => state.usersList
);

export const checkpointsSelector = createSelector(
  postalSelector,
  state => state.checkpoints
);

export const courierPartsSelector = createSelector(
  postalSelector,
  state => state.courierParts
);

export const courierPartsCompleteSelector = createSelector(
  postalSelector,
  state => state.courierPartsComplete
);

export const shippersSelector = createSelector(
  postalSelector,
  state => state.shippers
);

export const claimsSelector = createSelector(
  postalSelector,
  state => state.claims
);

export const claimsAllSelector = createSelector(
  postalSelector,
  state => state.claimsAll
);

export const claimsSummarySelector = createSelector(
  postalSelector,
  state => state.claimsSummary
);

export const zoningSelector = createSelector(
  postalSelector,
  state => state.zoning
);

export const zoningAllSelector = createSelector(
  postalSelector,
  state => state.zoningAll
);

export const commentsListSelector = createSelector(
  postalSelector,
  state => state.commentsList
);

export const claimsTypesSelector = createSelector(
  postalSelector,
  state => state.claimsTypes
);

export const uploadedFilesSelector = createSelector(
  postalSelector,
  state => state.uploadedFiles
);

export const removedFilesSelector = createSelector(
  postalSelector,
  state => state.removedFiles
);

export const currentFilesSelector = createSelector(
  postalSelector,
  state => state.currentFiles
);
