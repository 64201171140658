export const SET_PAGE_TITLE = "SET_PAGE_TITLE";

export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_ERROR = "FETCH_PRODUCTS_ERROR";

export const FETCH_RGO_CICLOS = "FETCH_RGO_CICLOS";
export const FETCH_RGO_CICLOS_SUCCESS = "FETCH_RGO_CICLOS_SUCCESS";
export const FETCH_RGO_CICLOS_ERROR = "FETCH_RGO_CICLOS_ERROR";

export const FETCH_RGO_ABIERTOS = "FETCH_RGO_ABIERTOS";
export const FETCH_RGO_ABIERTOS_SUCCESS = "FETCH_RGO_ABIERTOS_SUCCESS";
export const FETCH_RGO_ABIERTOS_ERROR = "FETCH_RGO_ABIERTOS_ERROR";

export const FETCH_RGO_RANKS = "FETCH_RGO_RANKS";
export const FETCH_RGO_RANKS_SUCCESS = "FETCH_RGO_RANKS_SUCCESS";
export const FETCH_RGO_RANKS_ERROR = "FETCH_RGO_RANKS_ERROR";

export const FETCH_TRANSIT_TIMES = "FETCH_TRANSIT_TIMES";
export const FETCH_TRANSIT_TIMES_SUCCESS = "FETCH_TRANSIT_TIMES_SUCCESS";
export const FETCH_TRANSIT_TIMES_ERROR = "FETCH_TRANSIT_TIMES_ERROR";

export const FETCH_TRANSIT_TIMES_PRODUCTS = "FETCH_TRANSIT_TIMES_PRODUCTS";
export const FETCH_TRANSIT_TIMES_PRODUCTS_SUCCESS =
  "FETCH_TRANSIT_TIMES_PRODUCTS_SUCCESS";
export const FETCH_TRANSIT_TIMES_PRODUCTS_ERROR =
  "FETCH_TRANSIT_TIMES_PRODUCTS_ERROR";

export const FETCH_CUSTOMER_QUERY = "FETCH_CUSTOMER_QUERY";
export const FETCH_CUSTOMER_QUERY_SUCCESS = "FETCH_CUSTOMER_QUERY_SUCCESS";
export const FETCH_CUSTOMER_QUERY_ERROR = "FETCH_CUSTOMER_QUERY_ERROR";

export const FETCH_CUSTOMER_NAME = "FETCH_CUSTOMER_NAME";
export const FETCH_CUSTOMER_NAME_SUCCESS = "FETCH_CUSTOMER_NAME_SUCCESS";
export const FETCH_CUSTOMER_NAME_ERROR = "FETCH_CUSTOMER_NAME_ERROR";

export const FETCH_CUSTOMER_TRACKING = "FETCH_CUSTOMER_TRACKING";
export const FETCH_CUSTOMER_TRACKING_SUCCESS =
  "FETCH_CUSTOMER_TRACKING_SUCCESS";
export const FETCH_CUSTOMER_TRACKING_ERROR = "FETCH_CUSTOMER_TRACKING_ERROR";

export const FETCH_ZONIFICACION_CIERRE_ENVIOS =
  "FETCH_ZONIFICACION_CIERRE_ENVIOS";
export const FETCH_ZONIFICACION_CIERRE_ENVIOS_SUCCESS =
  "FETCH_ZONIFICACION_CIERRE_ENVIOS_SUCCESS";
export const FETCH_ZONIFICACION_CIERRE_ENVIOS_ERROR =
  "FETCH_ZONIFICACION_CIERRE_ENVIOS_ERROR";

export const FETCH_QUERY = "FETCH_QUERY";
export const FETCH_QUERY_SUCCESS = "FETCH_QUERY_SUCCESS";
export const FETCH_QUERY_ERROR = "FETCH_QUERY_ERROR";

export const FETCH_QUERY_ALL_DATA = "FETCH_QUERY_ALL_DATA";
export const FETCH_QUERY_ALL_DATA_SUCCESS = "FETCH_QUERY_ALL_DATA_SUCCESS";
export const FETCH_QUERY_ALL_DATA_ERROR = "FETCH_QUERY_ALL_DATA_ERROR";

export const CLEAN_TRACKING = "CLEAN_TRACKING";
export const CLEAN_TRACKING_SUCCESS = "CLEAN_TRACKING_SUCCESS";
export const CLEAN_TRACKING_ERROR = "CLEAN_TRACKING_ERROR";

export const CLEAN_CUSTOMER_QUERY = "CLEAN_CUSTOMER_QUERY";
export const CLEAN_CUSTOMER_QUERY_SUCCESS = "CLEAN_CUSTOMER_QUERY_SUCCESS";
export const CLEAN_CUSTOMER_QUERY_ERROR = "CLEAN_CUSTOMER_QUERY_ERROR";

export const CLEAN_RGO_CICLOS = "CLEAN_RGO_CICLOS";
export const CLEAN_RGO_CICLOS_SUCCESS = "CLEAN_RGO_CICLOS_SUCCESS";
export const CLEAN_RGO_CICLOS_ERROR = "CLEAN_RGO_CICLOS_ERROR";

export const CLEAN_RGO_ABIERTOS = "CLEAN_RGO_ABIERTOS";
export const CLEAN_RGO_ABIERTOS_SUCCESS = "CLEAN_RGO_ABIERTOS_SUCCESS";
export const CLEAN_RGO_ABIERTOS_ERROR = "CLEAN_RGO_ABIERTOS_ERROR";

export const ADD_CLAIM = "ADD_CLAIM";
export const ADD_CLAIM_SUCCESS = "ADD_CLAIM_SUCCESS";
export const ADD_CLAIM_ERROR = "ADD_CLAIM_ERROR";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";

export const FETCH_USERS_LIST = "FETCH_USERS_LIST";
export const FETCH_USERS_LIST_SUCCESS = "FETCH_USERS_LIST_SUCCESS";
export const FETCH_USERS_LIST_ERROR = "FETCH_USERS_LIST_ERROR";

export const INACTIVE_USER = "INACTIVE_USER";
export const INACTIVE_USER_SUCCESS = "INACTIVE_USER_SUCCESS";
export const INACTIVE_USER_ERROR = "INACTIVE_USER_ERROR";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const FETCH_CHECKPOINTS = "FETCH_CHECKPOINTS";
export const FETCH_CHECKPOINTS_SUCCESS = "FETCH_CHECKPOINTS_SUCCESS";
export const FETCH_CHECKPOINTS_ERROR = "FETCH_CHECKPOINTS_ERROR";

export const FETCH_COURIER_PARTS_DETAILS = "FETCH_COURIER_PARTS_DETAILS";
export const FETCH_COURIER_PARTS_DETAILS_SUCCESS =
  "FETCH_COURIER_PARTS_DETAILS_SUCCESS";
export const FETCH_COURIER_PARTS_DETAILS_ERROR =
  "FETCH_COURIER_PARTS_DETAILS_ERROR";

export const FETCH_COURIER_PARTS_DETAILS_COMPLETE =
  "FETCH_COURIER_PARTS_DETAILS_COMPLETE";
export const FETCH_COURIER_PARTS_DETAILS_COMPLETE_SUCCESS =
  "FETCH_COURIER_PARTS_DETAILS_COMPLETE_SUCCESS";
export const FETCH_COURIER_PARTS_DETAILS_COMPLETE_ERROR =
  "FETCH_COURIER_PARTS_DETAILS_COMPLETE_ERROR";

export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const UPDATE_USER_PASSWORD_ERROR = "UPDATE_USER_PASSWORD_ERROR";

export const GET_ALL_SHIPPERS = "GET_ALL_SHIPPERS";
export const GET_ALL_SHIPPERS_SUCCESS = "GET_ALL_SHIPPERS_SUCCESS";
export const GET_ALL_SHIPPERS_ERROR = "GET_ALL_SHIPPERS_ERROR";

export const FETCH_CLAIMS = "FETCH_CLAIMS";
export const FETCH_CLAIMS_SUCCESS = "FETCH_CLAIMS_SUCCESS";
export const FETCH_CLAIMS_ERROR = "FETCH_CLAIMS_ERROR";

export const FETCH_CLAIMS_ALL = "FETCH_CLAIMS_ALL";
export const FETCH_CLAIMS_ALL_SUCCESS = "FETCH_CLAIMS_ALL_SUCCESS";
export const FETCH_CLAIMS_ALL_ERROR = "FETCH_CLAIMS_ALL_ERROR";

export const FETCH_CLAIMS_SUMMARY = "FETCH_CLAIMS_SUMMARY";
export const FETCH_CLAIMS_SUMMARY_SUCCESS = "FETCH_CLAIMS_SUMMARY_SUCCESS";
export const FETCH_CLAIMS_SUMMARY_ERROR = "FETCH_CLAIMS_SUMMARY_ERROR";

export const FETCH_ZONING = "FETCH_ZONING";
export const FETCH_ZONING_SUCCESS = "FETCH_ZONING_SUCCESS";
export const FETCH_ZONING_ERROR = "FETCH_ZONING_ERROR";

export const FETCH_ZONING_ALL = "FETCH_ZONING_ALL";
export const FETCH_ZONING_ALL_SUCCESS = "FETCH_ZONING_ALL_SUCCESS";
export const FETCH_ZONING_ALL_ERROR = "FETCH_ZONING_ALL_ERROR";

export const INSERT_COMMENT = "INSERT_COMMENT";
export const INSERT_COMMENT_SUCCESS = "INSERT_COMMENT_SUCCESS";
export const INSERT_COMMENT_ERROR = "INSERT_COMMENT_ERROR";

export const FETCH_COMMENTS_LIST = "FETCH_COMMENTS_LIST";
export const FETCH_COMMENTS_LIST_SUCCESS = "FETCH_COMMENTS_LIST_SUCCESS";
export const FETCH_COMMENTS_LIST_ERROR = "FETCH_COMMENTS_LIST_ERROR";

export const FETCH_CLAIMS_TYPES = "FETCH_CLAIMS_TYPES";
export const FETCH_CLAIMS_TYPES_SUCCESS = "FETCH_CLAIMS_TYPES_SUCCESS";
export const FETCH_CLAIMS_TYPES_ERROR = "FETCH_CLAIMS_TYPES_ERROR";

export const FETCH_UPLOADED_FILES = "FETCH_UPLOADED_FILES";
export const FETCH_UPLOADED_FILES_SUCCESS = "FETCH_UPLOADED_FILES_SUCCESS";
export const FETCH_UPLOADED_FILES_ERROR = "FETCH_UPLOADED_FILES_ERROR";

export const FETCH_REMOVED_FILES = "FETCH_REMOVED_FILES";
export const FETCH_REMOVED_FILES_SUCCESS = "FETCH_REMOVED_FILES_SUCCESS";
export const FETCH_REMOVED_FILES_ERROR = "FETCH_REMOVED_FILES_ERROR";

export const POST_UPLOAD_FILES = "POST_UPLOAD_FILES";
export const POST_UPLOAD_FILES_SUCCESS = "POST_UPLOAD_FILES_SUCCESS";
export const POST_UPLOAD_FILES_ERROR = "POST_UPLOAD_FILES_ERROR";

export const DELETE_INBOX_FILE = "DELETE_INBOX_FILE";
export const DELETE_INBOX_FILE_SUCCESS = "DELETE_INBOX_FILE_SUCCESS";
export const DELETE_INBOX_FILE_ERROR = "DELETE_INBOX_FILE_ERROR";
