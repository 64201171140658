import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Dashboard from "./layouts/Dashboard";

// pages
import LoginPage from "./pages/login";
import UserPage from "./pages/user";
import AdministrationPage from "./pages/administration";
import DashboardPage from "./pages/dashboard";
import RegionPage from "./pages/region";
import TransitPage from "./pages/transit";
import ClientsPage from "./pages/clients";
import ReportsPage from "./pages/reports";
import ZonesPage from "./pages/zones";
import FilesPage from "./pages/files";
import NotFoundPage from "./pages/not-found";
import ServerErrorPage from "./pages/server-error";

const ROUTES = {
  user: "/token/:token",
  administration: "/administration",
  dashboard: "/dashboard",
  region: "/region/:product/:cicle/:shipperCicle?",
  transit: "/transit/:product/:cicle/:shipperCicle?",
  clients: "/",
  reports: "/reports",
  zones: "/zones",
  files: "/files",
  login: "/sign-in",
  notFound: "/404",
  serverError: "/500",
};

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route path={ROUTES.login} exact component={LoginPage} />
      <Route path={ROUTES.user} exact component={UserPage} />
      <Dashboard>
        <Switch>
          <Route
            path={ROUTES.administration}
            exact
            component={AdministrationPage}
          />
          <Route path={ROUTES.dashboard} exact component={DashboardPage} />
          <Route path={ROUTES.region} exact component={RegionPage} />
          <Route path={ROUTES.transit} exact component={TransitPage} />
          <Route path={ROUTES.clients} exact component={ClientsPage} />
          <Route path={ROUTES.reports} exact component={ReportsPage} />
          <Route path={ROUTES.zones} exact component={ZonesPage} />
          <Route path={ROUTES.files} exact component={FilesPage} />
          <Route path={ROUTES.notFound} exact component={NotFoundPage} />
          <Route path={ROUTES.serverError} exact component={ServerErrorPage} />
          <Redirect from="*" to={ROUTES.notFound} push={false} />
        </Switch>
      </Dashboard>
    </Switch>
  </BrowserRouter>
);

export default Router;
