import React from "react";
import StyledInputSelect from "./styled";
import Icon from "../Icon";

const InputSelect = ({
  containerClass = "",
  name = "",
  label = "",
  labelClass = "input-label",
  placeholder = "",
  selectClass = "",
  value = "",
  data = [],
  onChange = () => {},
  onBlur,
  errors,
  disabled
}) => {
  return (
    <StyledInputSelect className={`${containerClass}`}>
      <label className={labelClass}>{label}</label>
      <div className="select-wrapper">
        <select
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          className={`uex-select w-100 ${selectClass}`}
          disabled={disabled}
        >
          {placeholder !== "" && (
            <option value="none" disabled="">
              {placeholder}
            </option>
          )}
          {data.map((item, i) => (
            <option key={i} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <Icon
          className="arrow-icon"
          icon="arrow"
          width={14}
          height={9}
          color="#9FA1B7"
        />
      </div>
      {errors && <p className="help is-danger error">{errors}</p>}
    </StyledInputSelect>
  );
};

export default InputSelect;
