import React from "react";
import Select, { components } from "react-select";
import StyledSelectAutocomplete from "./styled";
import Icon from "../Icon";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected
      ? "#60626e"
      : state.isFocused
      ? "#60626e"
      : "#FFFFFF",
    "&:active": {
      // Overwrittes the different states of border
      background: "#60626e"
    },
    color: state.isSelected
      ? "#FFFFFF"
      : state.isFocused
      ? "#FFFFFF"
      : "#60626e"
  })
};

const CaretDownIcon = () => {
  return (
    <Icon
      icon="arrow"
      width={20}
      height={10}
      className="is-flex align-items-center justify-content-center c-pointer"
    />
  );
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};

const SelectAutocomplete = ({
  containerClass,
  selectedOption,
  onChange,
  options = [],
  placeholder = "Seleccionar Producto",
  error,
  labelClass = "input-label",
  label,
  isMulti
}) => {
  return (
    <StyledSelectAutocomplete
      className={`button-space is-relative h-100 ${
        containerClass ? containerClass : "column is-12"
      }`}
    >
      {label && <label className={labelClass}>{label}</label>}
      <Select
        components={{ IndicatorSeparator: () => null, DropdownIndicator }}
        value={selectedOption}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        className="select-input"
        classNamePrefix="select-input"
        styles={customStyles}
        isMulti={isMulti}
      />
      <p className="help is-danger error error-form">{error}</p>
    </StyledSelectAutocomplete>
  );
};

export default SelectAutocomplete;
