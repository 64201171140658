import styled from "styled-components";
import { WHITE, N3, G2 } from "../../../utils/colors";

const StyledTable = styled.div`
  margin: 1.25rem 0 0 0;

  .orders-table-head {
    .column {
      padding: 0.938rem 0 1.188rem 0 !important;
      background-color: ${WHITE};
      &.first-column {
        padding: 0.938rem 1rem 1.188rem 2rem !important;
        text-align: left !important;
        /* padding: 1.5rem 1rem 1.563rem 1rem !important; */
      }
    }

    strong {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.004em;
      color: #9fa1b7;
    }
  }

  .orders-table-body {
    margin-top: 0.188rem;
    min-height: 5vh;
    max-height: 65vh;
    overflow: auto;
    @media (min-width: 1024px) {
      max-height: 50vh;
    }
    @media (min-width: 1216px) {
      max-height: 55vh;
    }
    @media (min-width: 1408px) {
      max-height: 65vh;
    }

    .columns {
      height: auto;
    }
    .columns:not(:last-child) {
      /* margin-bottom: 0.063rem !important; */
    }

    .column {
      padding: 1.5rem 0 1.563rem 0 !important;
      background-color: ${WHITE};
      border-bottom: 1px solid #f7f8fc;
      &.first-column {
        padding: 1.5rem 1rem 1.563rem 2rem !important;
        text-align: left !important;
      }
    }

    p {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.004em;
      color: #3e4345;
    }
    .c-pointer {
      &:hover {
        background-color: #f6f6f6;
      }
    }
  }
  .border-space {
    border-right: 3px solid #f7f8fc;
  }

  .pagination-container {
    width: 100;
    padding-top: 0.875rem;
    padding-bottom: 0.938rem;
    padding-right: 2.625rem;
    padding-left: 4.563rem;
    background-color: ${WHITE};
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-top: 0.938rem;
  }

  .is-justify-end {
    justify-content: flex-end;
  }

  .orders-count {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.004em;
    color: ${N3};
  }
  .status-color {
    border: 1px solid ${G2};
    border-radius: 2px;
    width: 0.938rem;
    height: 0.938rem;
    margin-top: 0.16rem;
  }
`;

export default StyledTable;
