import styled from "styled-components";
import { connect } from "react-redux";
import {
  RED,
  LAYOUTBACKGROUND,
  BLUETHEME,
  GREENTHEME
} from "../../../utils/colors";

//Selectors
import { userSelector } from "../../../actions/auth/selectors";

const StyledDashboard = styled.div`
  width: 100%;
  height: 100vh;
  /* background-color: gray; */
  .uex-navbar {
    height: 3.6875rem;
    background-color: #ffffff;
    padding: 0 1.5rem 0 0;
    box-shadow: 0px 4px 4px rgba(187, 187, 187, 0.25);
    .uex-navbar-item {
      height: 100%;
      color: #c5c7cd;
      &:hover,
      &.active {
        color: #3e4345;
        border-bottom: 0.1875rem solid #ff0000;
        background-color: transparent;
      }
    }
  }
  .content-layout {
    height: calc(100% - 3.6875rem);
    /* overflow: auto; */
    background-color: ${LAYOUTBACKGROUND};
  }

  .password-modal {
    z-index: 200;
  }

  .form-password-container {
    border-radius: 4px;
    h2 {
      margin: 1rem 0;
    }
    .input-form {
      margin-bottom: 2rem;
    }
    button {
      margin: 1rem 0;
    }
  }

  &.default {
    .user-circle {
      background-color: ${RED};
    }
  }

  /* BLUE THEME */
  &.blueTheme {
    /* theme for scrollbar */
    .uex-scrollbar::-webkit-scrollbar-thumb {
      background-color: ${BLUETHEME.primary};
    }

    /* theme for navbar */
    .user-circle {
      background-color: ${BLUETHEME.primary};
    }

    .navbar-item {
      &.uex-navbar-item {
        &.active,
        &:hover {
          border-bottom: 0.1875rem solid ${BLUETHEME.primary};
        }
      }
    }

    /* theme for select autocomplete */
    .select-input {
      .select-input__control--is-focused {
        box-shadow: none;
        border: 1px solid ${BLUETHEME.tertiary};
      }
    }

    /* theme for dropdowns options */
    .dropdown-menu {
      .dropdown-content {
        .table-action-options,
        .dropdown-item {
          &:hover {
            color: ${BLUETHEME.primary};
          }
        }
      }
    }

    /* theme for calendars */
    .rdrNextPrevButton {
      &.rdrNextButton {
        i {
          border-color: transparent transparent transparent ${BLUETHEME.primary};
        }
      }
      &.rdrPprevButton {
        background-color: transparent;
        i {
          border-color: transparent ${BLUETHEME.primary} transparent transparent;
        }
      }
    }
    .rdrWeekDay {
      font-family: "Muli";
      color: ${BLUETHEME.primary};
      text-transform: capitalize;
    }
    .rdrDayNumber {
      font-family: "Muli";
      span {
        &:after {
          background: ${BLUETHEME.primary} !important;
        }
      }
    }

    /* theme for transit table */
    .footerTable {
      .column {
        background-color: ${BLUETHEME.opacity};
      }
    }

    /* theme for tracking */
    .tracking-section {
      .tracking-section-container {
        .tracking-info-content {
          .tracking-info-title {
            color: ${BLUETHEME.primary};
          }
          .tabs-container {
            .tab-content {
              p {
                &:hover,
                &.active {
                  border-bottom: 0.1875rem solid ${BLUETHEME.primary};
                }
              }
            }
          }
        }
      }
    }

    /* theme for form buttons */
    .button {
      &.input-button {
        background-color: ${BLUETHEME.primary};
      }
    }

    /* theme for reports */
    .tabs-container {
      .tab-content {
        p {
          &:hover,
          &.active {
            border-bottom: 0.1875rem solid ${BLUETHEME.primary};
          }
        }
      }
    }

    .modal-container {
      .right-btn {
        color: ${BLUETHEME.primary};
        &:hover {
          color: ${BLUETHEME.opacity};
        }
      }
    }
  }

  /* GREEN THEME */

  &.greenTheme {
    /* theme for scrollbar */
    .uex-scrollbar::-webkit-scrollbar-thumb {
      background-color: ${GREENTHEME.primary};
    }

    /* theme for navbar */
    .user-circle {
      background-color: ${GREENTHEME.primary};
    }

    .navbar-item {
      &.uex-navbar-item {
        &.active,
        &:hover {
          border-bottom: 0.1875rem solid ${GREENTHEME.primary};
        }
      }
    }

    /* theme for select autocomplete */
    .select-input {
      .select-input__control--is-focused {
        box-shadow: none;
        border: 1px solid ${GREENTHEME.tertiary};
      }
    }

    /* theme for dropdowns options */
    .dropdown-menu {
      .dropdown-content {
        .table-action-options,
        .dropdown-item {
          &:hover {
            color: ${GREENTHEME.primary};
          }
        }
      }
    }

    /* theme for calendars */
    .rdrNextPrevButton {
      &.rdrNextButton {
        i {
          border-color: transparent transparent transparent
            ${GREENTHEME.primary};
        }
      }
      &.rdrPprevButton {
        background-color: transparent;
        i {
          border-color: transparent ${GREENTHEME.primary} transparent
            transparent;
        }
      }
    }
    .rdrWeekDay {
      font-family: "Muli";
      color: ${GREENTHEME.primary};
      text-transform: capitalize;
    }
    .rdrDayNumber {
      font-family: "Muli";
      span {
        &:after {
          background: ${GREENTHEME.primary} !important;
        }
      }
    }

    /* theme for transit table */
    .footerTable {
      .column {
        background-color: ${GREENTHEME.opacity};
      }
    }

    /* theme for tracking */
    .tracking-section {
      .tracking-section-container {
        .tracking-info-content {
          .tracking-info-title {
            color: ${GREENTHEME.primary};
          }
          .tabs-container {
            .tab-content {
              p {
                &:hover,
                &.active {
                  border-bottom: 0.1875rem solid ${GREENTHEME.primary};
                }
              }
            }
          }
        }
      }
    }

    /* theme for form buttons */
    .button {
      &.input-button {
        background-color: ${GREENTHEME.primary};
      }
    }

    /* theme for reports */
    .tabs-container {
      .tab-content {
        p {
          &:hover,
          &.active {
            border-bottom: 0.1875rem solid ${GREENTHEME.primary};
          }
        }
      }
    }

    .modal-container {
      .right-btn {
        color: ${GREENTHEME.primary};
        &:hover {
          color: ${GREENTHEME.opacity};
        }
      }
    }
  }
`;

// export default StyledDashboard;

const mapStateToProps = state => ({
  user: userSelector(state)
});

export default connect(mapStateToProps)(StyledDashboard);
