import React from "react";
import Select from "react-select";
import StyledInputAutocomplete from "./styled";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected
      ? "#60626e"
      : state.isFocused
      ? "#60626e"
      : "#FFFFFF",
    "&:active": {
      // Overwrittes the different states of border
      background: "#60626e"
    },
    color: state.isSelected
      ? "#FFFFFF"
      : state.isFocused
      ? "#FFFFFF"
      : "#60626e"
  })
};

const InputAutocomplete = ({
  containerClass,
  labelClass = "input-label",
  label,
  selectedOption,
  onChange,
  options = [],
  onKeyDown
}) => {
  return (
    <StyledInputAutocomplete
      className={`button-space ${
        containerClass ? containerClass : "column is-12"
      }`}
    >
      <label className={labelClass}>{label}</label>
      <Select
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
          NoOptionsMessage: () => (
            <p className="w-100 has-text-centered">No existen registros</p>
          )
        }}
        value={selectedOption}
        onChange={onChange}
        options={options}
        placeholder="Ej: Carlos Pérez"
        className="select-input"
        classNamePrefix="select-input"
        styles={customStyles}
        onKeyDown={onKeyDown}
      />
    </StyledInputAutocomplete>
  );
};

export default InputAutocomplete;
