import React from "react";
import { connect } from "react-redux";
import StyledLoadingScreen from "./styled";
import * as theme from "../../utils/colors";
import { BarLoader } from "react-spinners";

//Selectors
import { userSelector } from "../../actions/auth/selectors";

const LoadingScreen = ({ width = "200", height = "5", isRequest, user }) => {
  return (
    <StyledLoadingScreen
      className={`column is-12-mobile is-12-tablet is-flex align-items-center justify-content-center ${
        isRequest ? "isRequest" : ""
      }`}
    >
      <BarLoader
        height={height}
        width={width}
        color={user.theme ? theme[user.theme.toUpperCase()].primary : "#CF333C"}
      />
    </StyledLoadingScreen>
  );
};

const mapStateToProps = state => ({
  user: userSelector(state)
});

export default connect(mapStateToProps)(LoadingScreen);
