import styled from "styled-components";

const StyledServerError = styled.div`
  height: 100%;

  & .hero-body {
    padding: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  & .error-title {
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 0.0025em;
    margin-top: 1.813rem;
    color: gray;
    text-align: center;
  }

  & .back-link {
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.0015em;
    color: black;
    margin-top: 1rem;
    &:hover {
      opacity: 0.6;
    }
  }

  .reload {
    margin-bottom: 1rem;
  }

  & .left-icon-container {
    position: absolute;
    bottom: 1.75rem;
    left: 2.313rem;
  }

  & .right-icon-container {
    position: absolute;
    right: 1.563rem;
    top: 1.375rem;
  }
`;

export default StyledServerError;
