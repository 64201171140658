import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import StyledUserInfo from "./styled";
import Icon from "../Icon";
import { RED } from "../../utils/colors";
import * as theme from "../../utils/colors";

//Selectors
import { userSelector } from "../../actions/auth/selectors";

const UserInfo = ({ userName, userCode, actions, user }) => {
  const node = useRef();
  const [open, setOpen] = useState(false);

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      // setOpen(true);
      return;
    }
    // outside click
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClick);
    } else {
      document.removeEventListener("mousedown", handleClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [open]);
  return (
    <StyledUserInfo
      className={`dropdown is-right ${open ? "is-active" : ""}`}
      ref={node}
      onClick={e => setOpen(!open)}
    >
      <div className="dropdown-trigger">
        <button
          className="button user-dropdown arrow-dropdown"
          aria-haspopup="true"
          aria-controls="dropdown-menu6"
        >
          <div className="has-text-right user-dropdown-name">
            <p>{userName}</p>
            <p className="bold">COD. {userCode}</p>
          </div>
          <div>
            <p className="user-dropdown-shortname-rounded user-circle">
              {userName.substring(0, 2)}
            </p>
          </div>
          <Icon
            icon="arrow"
            width={13}
            height={13}
            color={user.theme ? theme[user.theme.toUpperCase()].primary : RED}
            className="arrow-icon"
          />
        </button>
      </div>
      <div
        className="dropdown-menu is-paddingless"
        id="dropdown-menu6"
        role="menu"
      >
        <div className="dropdown-content">
          {actions.map((item, i) => (
            <React.Fragment key={i}>
              <p onClick={item.onClick} className="dropdown-item">
                {item.label}
              </p>
              {i + 1 < actions.length && <hr className="dropdown-divider" />}
            </React.Fragment>
          ))}
        </div>
      </div>
    </StyledUserInfo>
  );
};

const mapStateToProps = state => ({
  user: userSelector(state)
});

export default connect(mapStateToProps)(UserInfo);
