import styled from "styled-components";
import { RED } from "../../../utils/colors";

const StyledTableActions = styled.div`
  @media (max-width: 769px) {
    padding: 0;
  }
  .button-more {
    border: 0;
    cursor: pointer;
    outline: none;
    background: transparent;
    .horizontal {
      transform: rotate(90deg);
    }
    @media (max-width: 1024px) {
      background: transparent;
      svg {
        transform: rotate(90deg);
      }
    }
  }

  .dropdown-menu {
    min-width: 12rem;
    padding-top: 0;
    top: 1rem;
    &.last {
      top: 0;
      right: 2rem;
    }

    .dropdown-divider {
      background: rgba(126, 126, 126, 0.1);
    }

    .dropdown-content {
      box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.24);
      border: 1px solid #dfe0eb;
      cursor: pointer;
      padding: 0;

      .table-action-options {
        text-align: center;
        padding: 0.5rem 0;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        color: #9fa1b7;
        width: 100%;
        &:hover {
          color: ${RED};
        }
      }
    }
  }
`;

export default StyledTableActions;
