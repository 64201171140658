import request from "../../api";
import getEnvironment from "../../utils/getEnvironment";

//? Verifica si esta en modo dev o producción
const api = getEnvironment();

const currentProfileRequest = async data => {
  return await request({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}jwt2/${api}/infotoken`,
    data
  });
};

const hasIpRequest = async data => {
  return await request({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}jwt2/${api}/has_ip`,
    data
  });
};

export { currentProfileRequest, hasIpRequest };
