import React from "react";
import StyledToggle from "./styled";

const Toggle = ({ containerClass = "", onChange, checked, name = "" }) => {
  return (
    <StyledToggle className={containerClass}>
      <input type="checkbox" onChange={onChange} checked={checked} />
      <span className="slider round" />
    </StyledToggle>
  );
};

export default Toggle;
