import * as Yup from "yup";

export const validatePasswordForm = Yup.object().shape({
  password: Yup.string()
    .required("Contraseña es requerida")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,70}$/,
      "Contraseña debe tener al menos 6 caracteres, 1 letra mayúscula y un número"
    ),
  confirmPassword: Yup.string()
    .required("Confirmación es requerida")
    .oneOf([Yup.ref("password"), null], "Contraseñas deben ser iguales")
});
