import styled from "styled-components";

const CreateUser = styled.div`
  .form-container {
    border-radius: 8px;
    padding: 1rem 3rem 2rem 3rem;
    h1 {
      margin: 1rem 0;
    }
    .form-input {
      width: 48%;
      flex: none;
    }
    .input-form {
      margin-bottom: 1.2rem;
    }
    .form-toggle {
      margin: 0 1rem;
    }
    button {
      margin-top: 1rem;
      background-color: #252733;
    }
  }
`;

export default CreateUser;
