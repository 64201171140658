import { Component } from "react";
import { connect } from "react-redux";
import { fetchCurrentUserProfile, fetchHasIp } from "../../actions/auth";
import { getParamsRequest } from "../../utils/paramsRequest";
import { userPermissions } from "../../utils/userPermissions";
import { find } from "lodash";
import publicIp from "public-ip";

class User extends Component {
  async componentDidMount() {
    let token = this.props.match.params.token;
    if (!token)
      return window.location.replace(process.env.REACT_APP_REDIRECT_URL);
    let data = getParamsRequest({
      token
    });
    await this.getProfile(data);
  }

  getProfile = async token => {
    const { dispatch } = this.props;
    return await new Promise((resolve, reject) => {
      dispatch(
        fetchCurrentUserProfile(token, (error, data) => {
          if (error) {
            reject(error);
            return window.location.replace(process.env.REACT_APP_REDIRECT_URL);
          }

          this.validateWhiteList(data);

          return resolve();
        })
      );
    });
  };

  validateWhiteList = async data => {
    let user_ip = await publicIp.v4();
    const { dispatch, history } = this.props;
    return await new Promise((resolve, reject) => {
      dispatch(
        fetchHasIp(
          { user_ip, shi_codigo: data.shi_codigo },
          (error, requestData) => {
            if (error) {
              reject(error);
              return window.location.replace(
                process.env.REACT_APP_REDIRECT_URL
              );
            }

            let tab = find(userPermissions, [
              "value",
              Number(data.user_permissions)
            ]);

            resolve();

            return history.push(tab.activePage);
          }
        )
      );
    });
  };

  render() {
    return null;
  }
}

const mapStateToProps = state => ({});
export default connect(mapStateToProps)(User);
