import React, { useState } from "react";
import StyledMap from "./styled";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";
import LocationCircle from "../../assets/images/location-circle.svg";
import mapStyles from "../../utils/map_style_json.json";

const MyMapComponent = withGoogleMap(props => {
  const [onToggle, setOnToggle] = useState(true);
  const {
    isMarkerShown,
    center = { lat: 13.6775805, lng: -89.2491554 },
    defaultZoom = 15,
    position = { lat: 13.6775805, lng: -89.2491554 },
    // image,
    text
    // onShowPicture
  } = props;
  return (
    <StyledMap>
      <GoogleMap
        center={center}
        defaultZoom={defaultZoom}
        defaultOptions={{
          disableDefaultUI: true,
          styles: mapStyles
        }}
      >
        {isMarkerShown && (
          <Marker
            position={position}
            onClick={() => {
              setOnToggle(true);
              window.open(
                `https://www.google.com.sa/maps/search/${parseFloat(
                  position.lat
                )},${parseFloat(position.lng)}`,
                "_blank"
              );
            }}
            icon={LocationCircle}
          >
            {onToggle && (
              <InfoWindow onCloseClick={() => setOnToggle(false)}>
                <div className="is-flex align-items-center">
                  {/* <img
                    onClick={onShowPicture}
                    style={{
                      width: "3.0625rem",
                      height: "3.0625rem",
                      borderRadius: "4px",
                      cursor: "pointer"
                    }}
                    src={image}
                    alt="house"
                  /> */}
                  <p
                    className="bold"
                    style={{
                      color: "#9FA1B7",
                      padding: "0.75rem",
                      width: "15rem"
                    }}
                  >
                    {text}
                  </p>
                </div>
              </InfoWindow>
            )}
          </Marker>
        )}
      </GoogleMap>
    </StyledMap>
  );
});

export default MyMapComponent;
