import styled from "styled-components";

const StyledCommentsTab = styled.div`
  .comment-button {
    min-width: 8rem;
  }
  .comments-box {
    overflow: auto;
    height: calc(100vh - 23rem);
    @media (min-width: 1408px) {
      height: calc(100vh - 25rem);
    }
    .comment-bubble {
      border-radius: 1rem;
      margin-bottom: 1rem;
      width: 80%;
      max-width: 80%;
      .comment-date {
        opacity: 0.5;
      }
      &.right {
        border-bottom-right-radius: 0rem;
      }

      &.left {
        border-top-left-radius: 0rem;
        p {
          color: "#949caa";
        }
      }
    }
  }
`;

export default StyledCommentsTab;
