import React from "react";
import Styled from "./styled";
import Pagination from "../pagination";

const Paginator = ({
  totalText,
  totalData,
  onChangePage,
  currentPage,
  total,
  perPage
}) => {
  return (
    <Styled className={`pagination-container ${total > 20 ? "" : "is-hidden"}`}>
      <div className="columns is-fullhd is-gapless">
        <div className="column is-12-mobile is-7 is-flex flex-wrap">
          <div className="is-flex is-align-center is-100 is-hidden-mobile is-hidden-tablet-only">
            <p className="is-flex align-items-center bold has-text-grey">{`${totalText} ${totalData}`}</p>
          </div>
        </div>
        <div className="column is-12-mobile is-5">
          <Pagination
            currentPage={currentPage}
            onChangePage={onChangePage}
            total={parseInt(total)}
            containerClassName="is-flex is-justify-end pagination-space"
            perPage={perPage}
          />
        </div>
      </div>
    </Styled>
  );
};

export default Paginator;
