import React from "react";
import StyledDropdownLabel from "./styled";
import Icon from "../Icon";

const DropdownLabel = ({ userName, userCode, active, actions = [] }) => {
  return (
    <StyledDropdownLabel className="dropdown is-left is-hoverable">
      <div className="dropdown-trigger">
        <button
          className="button user-dropdown is-flex align-items-center"
          aria-haspopup="true"
          aria-controls="dropdown-menu7"
        >
          <h2 className="bold">{active}</h2>
          <Icon
            icon="arrow"
            width={14}
            height={9}
            color="#000000"
            className="arrow-icon"
          />
        </button>
      </div>
      <div
        className="dropdown-menu is-paddingless"
        id="dropdown-menu7"
        role="menu"
      >
        <div className="dropdown-content">
          {actions.map((item, i) => {
            if (!item.active) {
              return (
                <p key={i} onClick={item.onClick} className="dropdown-item">
                  {item.label}
                </p>
              );
            }
            return null;
          })}
        </div>
      </div>
    </StyledDropdownLabel>
  );
};

export default DropdownLabel;
