export const trackingScope = [
  "AR",
  "AS",
  "LD",
  "DL",
  "DV",
  "CA",
  "BA",
  "NT",
  "LI",
  "LV"
];
